import UserAvatar from 'app/shared-components/user/UserAvatar';
import UserName from 'app/shared-components/user/UserName';
import UserLocation from 'app/shared-components/user/UserLocation';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import useThemeMediaQuery from '../../../@fuse/hooks/useThemeMediaQuery';
import { selectContactByTypeAndId } from 'app/store/contactsSlice';
import { selectGivenRecommendationByContactId } from 'app/store/userRecommendationSlice';
import { setUserToMessage } from 'app/store/externalMessageSlice';
import { selectUser } from 'app/store/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import { convertToPlain, truncateText } from 'app/shared-components/utils'
import React from "react";
import UserProfileHeaderTags from 'app/shared-components/helmet/UserProfileHeaderTags';
import ExternalMessageDialog from 'app/shared-components/public/ExternalMessageDialog';
import UserNotesDialog from 'app/shared-components/user/UserNotesDialog';
import UserTagsDialog from 'app/shared-components/user/UserTagsDialog';
import UserRecommendDialog from 'app/shared-components/user/UserRecommendDialog';
import UserProfileActions from './UserProfileActions';
import _ from '@lodash';


function UserProfileViewHeader(props) {
  const { user, isPublic, chips, actions, forceNarrow}= props;
  const userId= user ? user.id : null;
  const dispatch= useDispatch();
  const currentUser = useSelector(selectUser);
  const isLoggedOut = currentUser.id == '1';
  const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
  const contactStatus = isPublic ? null : useSelector(selectContactByTypeAndId('contactStatus', userId));
  const givenRecommendation= isPublic ? null : useSelector(selectGivenRecommendationByContactId(userId));
  const hasRecommendation= givenRecommendation && !givenRecommendation.notFound;
  const providerInfo= user?.providerInfo;
  const isProvider= providerInfo ? true : false;
  const description= user ? truncateText(convertToPlain(user.about)) : "";
  const fullName= user?.firstName + " " + user?.lastName;
  const isStatusLoaded= contactStatus ? true : false;
  const isFavorite= contactStatus ? contactStatus.isFavorite : false;
  const hasNotes= Boolean(contactStatus?.notes);
  const hasTags= contactStatus?.tags && contactStatus.tags.length > 0;
  const externalMessages= isPublic && isProvider && isLoggedOut && providerInfo?.allowExternalMessages; 
  const isCurrentUser= currentUser.id == userId;
  console.log("Reco status: " + hasRecommendation);

  let isFollowing= false;
  let followsYou= false;
  let isConnected= false;
  let isInvited= false;
  let invitedYou= false;

  const allChips= [];
  if (isStatusLoaded){
	    isFollowing= contactStatus.details.includes("following");
  	  followsYou= contactStatus.details.includes("followedBy");
  	  isConnected= contactStatus.details.includes("connected");
  	  isInvited= contactStatus.details.includes("invited");
  	  invitedYou= contactStatus.details.includes("invitedBy");
  	  if (contactStatus.tags){
  	     for (let i in contactStatus.tags) {
    			allChips.push({label: contactStatus.tags[i], color: "secondary"});
         }
  	  }
  }
  
  //Actions
  const allActions= [];
  if (actions){
     for (let i in actions) {
			allActions.push(actions[i]);
     }
  }
  if (externalMessages){
    allActions.push({label: "Message", handler: handleOpenMessageDialog});
  }
  
  //Chips
  if (followsYou){
     allChips.push({label: "Follows You"});
  }
  if (isFollowing){
     allChips.push({label: "Following"});
  }
  if (isConnected){
     allChips.push({label: "Connected"});
  }
  if (isInvited && !isConnected){
     allChips.push({label: "Invited"});
  }
  if (invitedYou && !isConnected){
     allChips.push({label: "Invited You"});
  }
  
  if (chips){
     for (let i in chips) {
			allChips.push(chips[i]);
     }
  }
  
  //Display flags
  let displayChips= false;
  if (allChips.length > 0) displayChips= true;
  let displayCounts= true;
  if (displayChips && !forceNarrow) displayCounts= false;

  
  function handleOpenMessageDialog(){
	  dispatch(setUserToMessage({id: user.id, name: user.firstName + " " + user.lastName, profileImageUrl: user.profileImageUrl}));
  }

  
  return (
    <>
    {isPublic &&
      <UserProfileHeaderTags id={userId} name={fullName} screenName={user?.screenName} description={description} image={user.profileImageUrl}/>
    }
    
    <div className="flex flex-col">
      <img
        className="h-160 lg:h-320 object-cover w-full"
        src={user.headerImageUrl ? user.headerImageUrl : 'assets/images/pages/profile/cover3.jpg'}
        alt="Profile Cover"
      />
      
      <div className={(forceNarrow ? "flex-col" : "flex-col lg:flex-row lg:h-72") + " flex flex-0 items-center max-w-5xl w-full mx-auto"}>
        <div className="relative -mt-96 lg:-mt-88 rounded-full" >
          <motion.div initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.1 } }}>
            <UserAvatar
              sx={{ borderColor: 'background.paper' }}
              className="w-128 h-128 border-4"
              mode={isPublic ? "public" : ""}
              user={user}
            />              
          </motion.div>
        </div>

        <div id="user-info-display" className={(forceNarrow ? "items-center mt-16" : "items-center lg:items-start mt-16 lg:mt-0 lg:ml-24") +  " relative flex flex-col "}>
          <UserName user={user} className="text-lg font-bold leading-none" />
          <UserLocation user={user} color="text.secondary"/>

        </div>
        
        { displayChips && !forceNarrow &&
           <DisplayChips />
        }
        
       {displayCounts && !forceNarrow &&
           <DisplayCounts />
       }
        
      {!forceNarrow &&
           <DisplayActions />
       }
        
      </div>
      
      {forceNarrow && displayChips &&
      <div className="flex flex-row items-center max-w-5xl w-full mx-auto px-32 mb-12 ">
          <DisplayChips />
      </div>
      }
      
      {forceNarrow && displayCounts &&
      <div className="flex flex-row items-center max-w-5xl w-full mx-auto px-32 mb-12 ">

        <DisplayCounts />
       
      </div>
      }
      
      {forceNarrow &&
      <div className="flex flex-row items-center max-w-5xl w-full mx-auto px-32 mt-12 ">
    
       <DisplayActions />
       
      </div>
      }
      
    </div>
    
    {externalMessages &&
      <ExternalMessageDialog />
    }
    
    {!isPublic &&
      <UserNotesDialog />
    }
    
    {!isPublic &&
      <UserTagsDialog />
    }
    
    {!isPublic &&
      <UserRecommendDialog />
    }
    
    </>
   );
   
   function DisplayChips(){
     return (
      <>
        {!forceNarrow && 
        <div className="hidden lg:flex h-32 mx-24 border-l-2" />
        }
        
        <div className="relative flex flex-1 flex-col items-center mt-16 lg:mt-0 ">

           <div className="flex flex-wrap items-center">
           
    		      <div className="flex flex-wrap items-center mt-8">
    		         {allChips.map((chip) => (
                  <Chip
                    key={chip.label}
                    label={chip.label}
                    className="mr-6 mb-6"
                    size="small"
                  />
                 ))}           
              </div>
           </div>
        </div>
      </>
    )
   }
   
   function DisplayCounts(){
     return (
      <>
        {!forceNarrow && 
        <div className="hidden lg:flex h-32 mx-24 border-l-2" />
        }

        <div className="relative flex flex-1 flex-col items-center mt-16 lg:mt-0">
          <div className="flex flex-wrap items-center space-x-12 sm:space-x-24">
            <div className="flex flex-col items-center">
              <Typography className="font-bold">{user?.followerCount}</Typography>
              <Typography  className="text-sm font-medium" color="text.secondary">
                FOLLOWERS
              </Typography>
            </div>
            <div className="flex flex-col items-center">
              <Typography className="font-bold">{user?.followingCount}</Typography>
              <Typography  className="text-sm font-medium" color="text.secondary">
                FOLLOWING
              </Typography>
            </div>
            <div className="flex flex-col items-center">
              <Typography className="font-bold">{user?.connectionCount}</Typography>
              <Typography className="text-sm font-medium" color="text.secondary">
                CONNECTIONS
              </Typography>
            </div>
          </div>
        </div>
     </>
    )
   }
   
   
   function DisplayActions(){
     return (
      <>
        {!forceNarrow && 
        <div className="hidden lg:flex h-32 mx-24 border-l-2" />
        }
        
        <div className="relative flex flex-1 flex-col items-center mt-16 mb-16 lg:mt-0 lg:mb-0">
          <div className="flex flex-wrap items-center space-x-6">
          
           {isStatusLoaded && !isCurrentUser && (
            <UserProfileActions
                 user={user}
                 userId={userId} 
                 isFollowing= {isFollowing}
                 followsYou= {followsYou}
                 isConnected= {isConnected}
                 isInvited= {isInvited}
                 invitedYou={invitedYou}
                 hasTags={hasTags}
                 hasNotes={hasNotes}
                 hasRecommendation={hasRecommendation}
                 isFavorite={isFavorite}
                 maxButtons={(isMobile || forceNarrow) ? 8 : 6}
                 actions={allActions}
                 className="mx-4" />
		     )}
        </div> 
       </div>
      
      </>
     )
   }
   
}



export default UserProfileViewHeader;
