import lazyLoad from 'app/shared-components/util/lazyLoad';

const AcceptTermsApp = lazyLoad(() => import('./AcceptTermsApp'));

const AcceptTermsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/acceptTerms',
      element: <AcceptTermsApp />,
    },
  ],
};

export default AcceptTermsAppConfig;
