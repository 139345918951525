import _ from '@lodash';

export const USER_TYPE_PUBLIC = "public";
export const USER_TYPE_IDENTIFIED = "identified";
export const USER_TYPE_PROVIDER = "provider";
export const USER_TYPE_IDENTIFIED_PROVIDER = "ident_provider";
export const USER_TYPE_VERIFIED = "verified";
export const ACCESS_LEVEL_NONE = "none";
export const ACCESS_LEVEL_READ = "read";
export const ACCESS_LEVEL_POST = "post";
export const ACCESS_LEVEL_COMMENT = "comment";
export const ACCESS_LEVEL_INHERIT = "inherit";
export const MEMBERSHIP_SETTING_NONE = "none";
export const MEMBERSHIP_SETTING_OPEN = "open";
export const MEMBERSHIP_SETTING_INVITE = "invite";
export const MEMBERSHIP_SETTING_INHERIT = "inherit";
export const MEMBERSHIP_ROLE_MEMBER = "member";

const AccessSettings = (userType, defaultAccess, membershipSetting, membershipRole) =>{
  return {
    userType: userType,
    defaultAccess: defaultAccess,
    membershipSetting: membershipSetting,
    membershipRole: membershipRole,
  }
}

const ChannelAccessSettingsModel = () =>{
  return {
    public: AccessSettings(USER_TYPE_PUBLIC, ACCESS_LEVEL_NONE, MEMBERSHIP_SETTING_NONE, MEMBERSHIP_ROLE_MEMBER),
    identified: AccessSettings(USER_TYPE_IDENTIFIED, ACCESS_LEVEL_NONE, MEMBERSHIP_SETTING_NONE, MEMBERSHIP_ROLE_MEMBER),
    provider: AccessSettings(USER_TYPE_PROVIDER, ACCESS_LEVEL_NONE, MEMBERSHIP_SETTING_NONE, MEMBERSHIP_ROLE_MEMBER),
    ident_provider: AccessSettings(USER_TYPE_IDENTIFIED_PROVIDER, ACCESS_LEVEL_NONE, MEMBERSHIP_SETTING_NONE, MEMBERSHIP_ROLE_MEMBER),
    verified: AccessSettings(USER_TYPE_VERIFIED, ACCESS_LEVEL_NONE, MEMBERSHIP_SETTING_NONE, MEMBERSHIP_ROLE_MEMBER)
  }
}


export default ChannelAccessSettingsModel;
