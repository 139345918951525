import TermsPage from './TermsPage';
import PrivacyPage from './PrivacyPage';
import CookiePage from './CookiePage';
import BAAPage from './BAAPage';
import { authRoles } from 'src/app/auth';

const legalPageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null, //anyone
  routes: [
    {
      path: 'pages/terms',
      element: <TermsPage />,
    },
    {
      path: 'pages/privacy',
      element: <PrivacyPage />,
    },
    {
      path: 'pages/cookies',
      element: <CookiePage />,
    },
    {
      path: 'pages/baa',
      element: <BAAPage />,
    },
  ],
};

export default legalPageConfig;
