import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectSubscriptionExceededMessage, setSubscriptionExceededMessage } from 'app/store/subscriptionPlanSlice';
import { selectIsLoading, startLoading, stopLoading} from 'app/store/utilSlice';
import { useNavigate } from 'react-router-dom';
import DefaultButton from '../layout/DefaultButton';



const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SubscriptionExceededDialog({actions, chips}) {
  const dispatch= useDispatch();
  const navigate = useNavigate();
  const message = useSelector(selectSubscriptionExceededMessage);
  const title= "Upgrade Your Plan!";

  if (!message) {
    return null;
  }
  

  function handleCloseDialog() {
    
  }

  function handleDiscard() {
    dispatch(setSubscriptionExceededMessage(null))
  }
  
  function handleUpgrade() {
    dispatch(setSubscriptionExceededMessage(null))
    navigate('/apps/account?showPlans=true');
  }

 

  return (
    <Dialog
      classes={{
        paper: 'w-full m-24',
      }}
      TransitionComponent={Transition}
      onClose={handleCloseDialog}
      open={Boolean(message)}
    >
        <AppBar position="static" color="secondary" elevation={0}>
    	      <Toolbar className="flex w-full">
    	 
    	        <Typography className='' variant="subtitle1" color="inherit">
    	          {title}
    	        </Typography>
    	      </Toolbar>
    	  </AppBar>
          <DialogContent >
             <Typography>{message}</Typography>
          </DialogContent>

          <DialogActions className="flex flex-col sm:flex-row sm:items-center justify-between py-16 sm:py-24 px-24">
            <div className="flex items-center space-x-8 mt-16 sm:mt-0">
              <DefaultButton text="Upgrade Options" warning onClick={handleUpgrade} />
              <DefaultButton text="Close" onClick={handleDiscard} />
            </div>
          </DialogActions>
        
    </Dialog>
  );
}

export default SubscriptionExceededDialog;
