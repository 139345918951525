import lazyLoad from 'app/shared-components/util/lazyLoad';
import { Navigate } from 'react-router-dom';
import authRoles from '../../../auth/authRoles';

const EarlyAccessPage = lazyLoad(() => import('./EarlyAccessPage'));

const EarlyAccessPageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'pages/early-access',
      element: <Navigate to="/" />,
    },
  ],
};

export default EarlyAccessPageConfig;
