import homePageConfig from "./home/homePageConfig";
import legalPageConfig from "./legal/legalPageConfig";
import comingSoonPageConfig from "./coming-soon/comingSoonPageConfig";
import earlyAccessPageConfig from "./early-access/earlyAccessPageConfig";

const pagesConfigs = [
  homePageConfig,
  legalPageConfig,
  comingSoonPageConfig,
  earlyAccessPageConfig
];

export default pagesConfigs;
