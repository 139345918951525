import IconButton from '@mui/material/IconButton';
import React, { useState, useRef} from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { lighten } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import MediaFileSelect from 'app/shared-components/controls/MediaFileSelect';
import AttachmentFileSelect from 'app/shared-components/controls/AttachmentFileSelect';
import PostVideoPlayer from 'app/shared-components/videojs/PostVideoPlayer';
import FileItem from '../file/FileItem';
import ReferralShareItem from 'app/shared-components/referral/ReferralShareItem';
import SelectReferralDialog from 'app/shared-components/referral/SelectReferralDialog';
import { useThemeMediaQuery } from '@fuse/hooks';
import { UserAutocomplete } from '../user/autocomplete/UserAutocomplete';
import LinkDisplay from 'app/shared-components/util/LinkDisplay';
import uploadFile from 'app/shared-components/uploadFunction';
import { isValidUrl, getMetadata } from 'app/shared-components/linkMetadataFunction';
import { replaceAt, dispatchAxiosErrorMessage } from 'app/shared-components/utils.js';
import confirmationDialog from '../util/confirmationDialog';
import dispatchSubmit from '../util/dispatchSubmit';
import { startLoading, stopLoading, selectIsLoading} from 'app/store/utilSlice';
import { setSubscriptionExceededMessage } from 'app/store/subscriptionPlanSlice';
import { selectUser } from 'app/store/userSlice';
import { submitPost, TYPE_CHAT, TYPE_CHAT_COMMENT, TYPE_NOTE, TYPE_NOTE_COMMENT, TYPE_DM, TYPE_VIDEO_CALL } from 'app/store/postSlice';
import { useSelector, useDispatch } from 'react-redux';
import IconButtonMenu from '../layout/IconButtonMenu';
import DialogSafeIconMenu from '../layout/DialogSafeIconMenu';
import { FEATURE_CONFIG } from "app/configs/aws.ts";
import { errorMessage } from 'app/shared-components/util/message';


import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation
} from "emoji-picker-react";


import { createMeeting } from 'app/store/chimeMeetingSlice'


const MB= 1048576;
const maxFileSize= 50 * MB;
const maxFileSizeText= "50 MB";

function validateFile(file) 
{
	const out= {};
	const fileType= file?.type;
    out.size= file?.size ? file.size : 0;
    out.type= fileType ? fileType : "NONE";
    out.maxSize= maxFileSize;
    out.maxSizeText= maxFileSizeText;
    out.validType= true;
    out.validSize= out.size < out.maxSize;
    out.isValid= out.validType && out.validSize;
    out.message= !out.validType ? "File type is not allowed: " + fileType :
                 !out.validSize ? "Maximum file size is " + out.maxSizeText :
                 "";
    return out;
}

function ChatInput(props) {
  const {channelId, parentPostId, rootChannelId, channelType, isNotes, isDM, textOnly, processPost}= props;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const emojiOpen = Boolean(anchorEl);
  const emojiPickerId = emojiOpen ? 'emoji-picker' : undefined;
  const [messageText, setMessageText] = useState("");
  const [expanded, setExpanded] = useState(false);
  const [cursorPosition, setCursorPosition] = useState();
  const [postMediaUrl, setPostMediaUrl] = useState();
  const [postMediaFile, setPostMediaFile] = useState();
  const [selectedFile, setSelectedFile] = useState();  
  const [postLinkUrl, setPostLinkUrl] = useState();
  const [openReferralDialog, setOpenReferralDialog] = useState(false);
  const [important, setImportant] = useState(false);
  const [shareReferral, setShareReferral] = useState(null);
  const isReferral= Boolean(shareReferral);
  const user = useSelector(selectUser);
  const isLoading= useSelector(selectIsLoading)
  const imageInput = useRef();
  const fileInput = useRef();
  const isXS = useThemeMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isVideo=  postMediaFile?.type && postMediaFile.type.includes("video");                              
  const postType= isDM ? TYPE_DM : parentPostId ? (isNotes ? TYPE_NOTE_COMMENT : TYPE_CHAT_COMMENT) : (isNotes ? TYPE_NOTE : TYPE_CHAT);
  const isBotChannel= channelType == 'bot';
  const allowImportant= !Boolean(parentPostId) && !isBotChannel;

  function onSelectFile(file){
	const validation= validateFile(file);
	if (!validation.isValid){
	     errorMessage(dispatch, validation.message);
	     return;
	}
    setSelectedFile(file);
    removeMedia();
    removeLink();
    removeReferral();
  }

  function handleSaveMedia(dataUrl, file){
    //set the media url and file	 
	 setPostMediaUrl(dataUrl);
	 setPostMediaFile(file);
	 removeLink();
	 removeReferral();
  }
  
  function removeMedia(){
     //Clear the media url and file	 
	 setPostMediaUrl(null);
	 setPostMediaFile(null);
  }
  
  function removeFile(){
	 setSelectedFile(null);
  }
  
  function removeLink(){
    //Clear the link 
	 setPostLinkUrl(null);
  }
  
  function removeReferral(){
    setShareReferral(null);
  }
  
  function toggleImportant(){
    setImportant(!important);
  }
  
  function handlePaste(e){
	 var clipboardData, pastedData;

	  // Get pasted data via clipboard API
	  clipboardData = e.clipboardData || window.clipboardData;
	  pastedData = clipboardData.getData('Text');
	  
	  if (isValidUrl(pastedData)){
		 setPostLinkUrl(pastedData);
		 // Stop data actually being pasted into div
		  e.stopPropagation();
		  e.preventDefault();
		  
		  removeFile();
		  removeMedia(); 
		  removeReferral();
	  }
  }
  
  const handleEmojiIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEmojiIconClose = () => {
    setAnchorEl(null);
  };
  
  const handleEnterPress= (event) => {
	  event.stopPropagation();
	  event.preventDefault();
	  handleSubmitPost();
  }
  
  function onSelectEmoji(emojiData, event) {
    let sym= emojiData.unified;
    let codesArray = [];
    codesArray.push("0x" + sym);
    let emoji = String.fromCodePoint(...codesArray);
    const newValue = replaceAt(
        messageText,
        emoji,
        cursorPosition
    );
    setMessageText(newValue);
    handleEmojiIconClose();
  }
  
  function handleSubmitPost(){
	 if (isLoading || !messageText || messageText === "") return;
	 if (important){
	    confirmationDialog(dispatch, {
				title: "Send 'Important' Message?",
				content: "This will notify all members, and you will not be able to send another important message to this channel for a period of time.",
				onOk: submitPostInternal,
				onCancel: () => {},
				okText: "Send",
				cancelText: "Cancel",
				width: '350px',
			});
	 }else{
	   submitPostInternal();
	 }
  }
  
  function submitPostInternal(){
   //Set base post object and process if needed
   const post= {channelId: channelId, messageText: messageText,
                rootChannelId: rootChannelId, channelType: channelType,  
                important: important, parentPostId: parentPostId, 
                type: postType};
   if (processPost) processPost(post);
   
	 if (selectedFile){
		  //Upload file and update user data
		 uploadFile(selectedFile, user.id, 'post_attachment', channelId).then((fileId) => {
			 const attachment= {name: selectedFile.name, type: selectedFile.type, size: selectedFile.size, objectId: fileId};
			 post.attachment= attachment;
			 dispatchSubmit({dispatch, noLoading: true, submitFunction: submitPost(post)}); 
	     }).catch((err) => {
  			  dispatchAxiosErrorMessage(dispatch, err);
  		 });
	 }else if (postMediaFile){
		  //Upload file and update user data
		 uploadFile(postMediaFile, user.id, 'post_media', channelId).then((fileId) => {
		   post.fileId= fileId;
		   post.fileType= postMediaFile.type;
		   post.fileSize= postMediaFile.size;
			 dispatchSubmit({dispatch, noLoading: true, submitFunction: submitPost(post)}); 
	     }).catch((err) => {
  			  dispatchAxiosErrorMessage(dispatch, err);
  		 });
	 }else{
	   post.linkUrl= postLinkUrl;
	   post.sharedReferralId= shareReferral?.id;
		 dispatchSubmit({dispatch, noLoading: true, submitFunction: submitPost(post)}); 
	 }
	 setMessageText("");
	 removeFile();
	 removeLink();
	 removeMedia();
	 removeReferral();
	 setImportant(false);
  }

  const handleInputFocus = (event) => {
	  setExpanded(true);
  };
  
  const handleInputBlur = (event) => {
	  setExpanded(false);
  };
  
  const startVideoCall = (event) => {
	  confirmationDialog(dispatch, {
				title: "Start Video Call?",
				content: "Do you want to start a video call?",
				onOk: createChimeMeeting,
				onCancel: () => {},
				okText: "Start Call",
				cancelText: "Cancel"
			});
  };
  
  const createChimeMeeting = async () => {
   dispatch(startLoading());
    let meetingId;
    try{
  	 const data= await dispatch(createMeeting({channelId: channelId, title: "Video Call"})).unwrap();
  	 console.log(data);
  	 meetingId= data.id;
    }
    catch(error){
      const message= error?.message;
      if (message && message.includes("current Subscription plan")){
	      dispatch(setSubscriptionExceededMessage(message));
	    }
    }
    finally{
      dispatch(stopLoading());
    }
    if (meetingId) dispatchSubmit({dispatch, submitFunction: submitPost({channelId: channelId,
            	                                                           chimeMeetingId: meetingId,
            			 					                                             type: TYPE_VIDEO_CALL })});
  }
  

  const moreActionsMenuItems= [];
  
  if (!isBotChannel) moreActionsMenuItems.push({
    key: "image",
    icon: "heroicons-solid:photograph",
    text: "Add Media",
    onClick: () => imageInput.current.click()
  });
  moreActionsMenuItems.push({
    key: "attachment",
    icon: "heroicons-solid:paper-clip",
    text: "Add File",
    onClick: () => fileInput.current.click()
  });
  if (!isBotChannel) moreActionsMenuItems.push({
    key: "referral",
    icon: "material-outline:outbound",
    text: "Share Referral",
    onClick: () => setOpenReferralDialog(true)
  })
  if (!parentPostId && FEATURE_CONFIG.VIDEO_CHAT && !isBotChannel){
	  moreActionsMenuItems.push({
	    key: "videocall",
	    icon: "heroicons-solid:video-camera",
	    text: "Video Call",
	    onClick: startVideoCall
	  })
  }  
  if (allowImportant){
    moreActionsMenuItems.push({
        key: "important",
        icon: "heroicons-solid:exclamation-circle",
        text: important ? "Mark Normal" : "Mark Important",
        onClick: () => toggleImportant()
      });
  }

  return (
	  
	  <Paper
          square
          className="absolute border-t-1 bottom-0 right-0 left-0 py-16 px-16 w-full"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? lighten(theme.palette.background.default, 0.4)
                : lighten(theme.palette.background.default, 0.02),
          }}
        >
          <div className="flex items-center relative">
            {!textOnly &&
            <>
              <IconButton className="" type="submit" size="small" onClick={handleEmojiIconClick}>
                <FuseSvgIcon className="text-24" color="action">
                  heroicons-outline:emoji-happy
                </FuseSvgIcon>
              </IconButton>
              
              <DialogSafeIconMenu items={moreActionsMenuItems} icon="heroicons-outline:ellipsis-horizontal-circle" iconSize={24} iconOnly />
             
              <AttachmentFileSelect fileInputRef={fileInput} onSelectFile={onSelectFile} />
              
              <MediaFileSelect fileInputRef={imageInput} aspectRatio={1.5} resize={{width:768, height: 512}} 
                        dialogTitle="Crop Image" onSaveImage={handleSaveMedia} onSaveVideo={handleSaveMedia}/>
            </>
            }

			<div style={{ backgroundColor: 'white', maxHeight: '100px', borderColor: important ? 'red' : undefined}} className={"flex-1 flex grow shrink-0 mx-8 px-16 rounded-full " + (important ? "border-4" : "border-2")}>
			   <UserAutocomplete mode='channel' 
        				  channelId={rootChannelId}
                          value={messageText} 
                          setTextValue={setMessageText}
                          onPaste={handlePaste}
                          onEnterPress={handleEnterPress}
                          setCursorPosition={setCursorPosition}/>
            </div>
	       
            {!isXS && 
            <IconButton className="" type="submit" size="large" onClick={handleSubmitPost} >
              <FuseSvgIcon className="rotate-90" color="action">
                heroicons-outline:paper-airplane
              </FuseSvgIcon>
            </IconButton>
            }
          </div>

       {(postMediaUrl || postLinkUrl || selectedFile || isReferral) && 
        <Box
          className="card-footer flex items-center flex-row border-t-1 px-24 py-12"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? lighten(theme.palette.background.default, 0.4)
                : lighten(theme.palette.background.default, 0.02),
          }}
        >
          
          {(postMediaUrl && isVideo) &&
           <Box className="relative w-xs h-xs m-10 mt-0">
             <IconButton
  		        className="absolute z-20 top-0 right-0 m-6 w-32 h-32 min-h-32"
  		        onClick={() => removeMedia()}
  		      >
  		        <FuseSvgIcon size={20}>heroicons-solid:x</FuseSvgIcon>
  		      </IconButton>
  		    
  		         <PostVideoPlayer mediaUrl={postMediaUrl} mediaType={postMediaFile.type} />

           </Box>}
           
           {(postMediaUrl && !isVideo) &&
           <Box className="relative max-w-xs max-h-xs m-10 mt-0">
             <IconButton
  		        className="absolute z-20 top-0 right-0 m-6 w-32 h-32 min-h-32"
  		        onClick={() => removeMedia()}
  		      >
  		        <FuseSvgIcon size={20}>heroicons-solid:x</FuseSvgIcon>
  		      </IconButton>
        
             <img src={postMediaUrl} alt="post" className="rounded-8" />
  
           </Box>}
           
           {isReferral && 
           <Box className="relative max-w-sm max-h-sm m-10 mt-0">
             <IconButton
			        className="absolute z-20 top-0 right-0 m-6 w-32 h-32 min-h-32"
			        onClick={() => removeReferral()}
  			      >
  			        <FuseSvgIcon size={20}>heroicons-solid:x</FuseSvgIcon>
  			     </IconButton>
    				 <ReferralShareItem referral={shareReferral} />	
    		   </Box>} 
           
           {(selectedFile) &&
           <Box className="relative max-w-xs max-h-xs">
             <IconButton
		        className="absolute z-20 top-0 right-0 m-6 w-32 h-32 min-h-32"
		        onClick={() => removeFile()}
		      >
		        <FuseSvgIcon size={20}>heroicons-solid:x</FuseSvgIcon>
		      </IconButton>
             <FileItem item={{name: selectedFile.name, type: selectedFile.type}}/>
           </Box>}
	        
	       {postLinkUrl && 
           <Box className="relative max-w-xs">
               <IconButton
    			        className="absolute z-20 top-0 right-0 m-6 w-32 h-32 min-h-32"
    			        onClick={() => removeLink()}
    			      >
    			        <FuseSvgIcon size={20}>heroicons-solid:x</FuseSvgIcon>
    			    </IconButton>
			        <LinkDisplay url={postLinkUrl} />
           
		   </Box>}
		   
        </Box>
       }
          
                      
          <Popover
	        id={emojiPickerId}
	        open={emojiOpen}
	        anchorEl={anchorEl}
	        onClose={handleEmojiIconClose}
	        anchorOrigin={{
			    vertical: 'top',
			    horizontal: 'left',
			}}
			transformOrigin={{
			    vertical: 'bottom',
			    horizontal: 'left',
			}}
	      >
	          <EmojiPicker
		        onEmojiClick={onSelectEmoji}
		        autoFocusSearch={false}
		        width="100%"
		        emojiStyle={EmojiStyle.NATIVE}
		        // theme={Theme.AUTO}
		        // searchDisabled
		        // skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
		        // height={350}
		        // width="50%"
		        // emojiVersion="0.6"
		        // lazyLoadEmojis={true}
		        // previewConfig={{
		        //   defaultCaption: "Pick one!",
		        //   defaultEmoji: "1f92a" // 🤪
		        // }}
		        // suggestedEmojisMode={SuggestionMode.RECENT}
		        // skinTonesDisabled
		        // searchPlaceHolder="Filter"
		        // defaultSkinTone={SkinTones.MEDIUM}
		        // emojiStyle={EmojiStyle.NATIVE}
		      />
	    </Popover>
	    
	    
	      {openReferralDialog && 
           <SelectReferralDialog open={openReferralDialog} title="Share My Open Referral" buttonText="Select" 
                                 onClose={() => setOpenReferralDialog(false)} 
                                 onSubmit={(referral) => setShareReferral(referral)}
           />
        }

        
      </Paper>
      
  );
}

export default ChatInput;
