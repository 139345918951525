import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { stopLoading, startLoading} from 'app/store/utilSlice';
import { setChannelRead, setChannelUnread } from './channelsSlice';
import { isUnread } from 'app/shared-components/utils';
import { submitPost } from './postSlice';
import { addDmChannel, addDmChannels, removeDmChannel } from './dmChannelSlice';
import { bulkReferralListCalls } from 'app/shared-components/util/bulkApi';
import sortComparer from 'app/shared-components/util/lastMessageSortComparer';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';


const referralAdapter = createEntityAdapter({
	sortComparer: (a, b) => b.createdAt - a.createdAt
});

const interestAdapter = createEntityAdapter({
	sortComparer: sortComparer
});

const { selectAll: selectAllInterest } = interestAdapter.getSelectors();

export const fetchReferral = createAsyncThunk(
  "referrals/fetchReferral",
  async (id, { dispatch }) => {
	dispatch(startLoading());
	try{
      const apiName = 'CoreAPI';
	  const path = '/outboundreferral/' + id;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	  const data= await apiGet(apiName, path, options);
	  dispatch(fetchReferralInterest({referralId: id}));
	  return data;
	}finally{
	  dispatch(stopLoading());
	}
  }
);

export const fetchReferralInterest = createAsyncThunk(
  "referrals/fetchReferralInterest",
  async ({referralId, status, bulk}, { dispatch }) => {
	try{
	  let data= bulk;
	  if (!data){
	      const apiName = 'CoreAPI';
		  const path = '/outboundreferral/' + referralId + '/referralinterest/pairs' + (status ? '?status=STARTS:' + status : "");
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		  data= await apiGet(apiName, path, options);
	  }
	  
	  const out= data.map((pair) => {
		  return {...pair.user, ...pair.interest };
	  });
	  dispatch(addDmChannels(out.map((dm) => ({type: 'interestInReferral', foreignId: dm.referralId +':' + dm.userId, channelId: dm.dmChannelId, data: dm}))));
	  return out;

	}finally{
	  dispatch(stopLoading());
	}
  }
);

export const createReferral = createAsyncThunk(
  "referrals/createReferral",
  async (params, { dispatch, getState }) => {
	  try{
	      const apiName = 'CoreAPI';
		  const path = '/outboundreferral/';
		  let body= { ...params};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const createReferralPublic = createAsyncThunk(
  "referrals/createReferralPublic",
  async (params, { dispatch, getState }) => {
      const apiName = 'PublicAPI';
	  const path = '/outboundreferral/public/create';
	  let body= { ...params};
      const options = {
		  headers: {},
		  body: body,
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	 let data= await apiPut(apiName, path, options);
	 return data;
  }
)

export const updateReferral = createAsyncThunk(
  "referrals/updateReferral",
  async (params, { dispatch, getState }) => {
	  try{
	      const apiName = 'CoreAPI';
		  const path = '/outboundreferral/' + params.id;
		  let body= { ...params};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const closeReferral = createAsyncThunk(
  "referrals/closeReferral",
  async ({referralId}, { dispatch, getState }) => {
	  return await changeReferralStatus({referralId: referralId, status: "closed", dispatch: dispatch});
  }
)

export const pendingReferral = createAsyncThunk(
  "referrals/pendingReferral",
  async ({referralId}, { dispatch, getState }) => {
	  return await changeReferralStatus({referralId: referralId, status: "pending", dispatch: dispatch});
  }
)

export const openReferral = createAsyncThunk(
  "referrals/openReferral",
  async ({referralId}, { dispatch, getState }) => {
	  return await changeReferralStatus({referralId: referralId, status: "open", dispatch: dispatch});
  }
)

const changeReferralStatus= async ({referralId, status, dispatch}) => {
	  try{
		  dispatch(startLoading());
	      const apiName = 'CoreAPI';
		  const path = '/outboundreferral/' + referralId;
		  let body= { status: status};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
}



export const deleteReferral = createAsyncThunk(
  "referrals/deleteReferral",
  async ({ referralId }, { dispatch }) => {
	  try{
		  dispatch(startLoading());
	      const apiName = 'CoreAPI';
		  const path = '/outboundreferral/' + referralId;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiDel(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const fetchReferrals = createAsyncThunk(
  "referrals/fetchReferrals",
  async ({bulk, status}, { dispatch, getState }) => {
	  const {user} = getState();
	  let data= bulk;
	  if (!data){
	      const apiName = 'CoreAPI';
		  const path = '/outboundreferral/byowner/' + user.id + (status ? '/?status=STARTS:' + status : "");
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	      data= await apiGet(apiName, path, options);
	  }
	 
	 //Use bulk load to get referral interests for the referrals
	 //Do this async so it doesn't block the return of this thunk
	 const useStatus= status == 'open' ? 'open' : undefined;
	 bulkReferralListCalls({referrals: data, status: useStatus}).then((bulk) => {
		 const bulkData= bulk.items;
		 if (bulkData){
			 for (let i in data) {
				const referral= data[i];
				const interests= bulkData[referral.id];
				dispatch(fetchReferralInterest({referralId: referral.id, status: useStatus, bulk: interests.code == 200 ? interests.body : undefined}));
			 }
		 }
	 });
     return data;
  }
)

const newReferral= (data) => {
	return {...data, unread: false,
	        interest: interestAdapter.getInitialState({status: "idle"})};
}

const findInterestWithChannelId= (state, channelId) => {
	let out= findInterestWithChannelIdFromEntityState(state.open, channelId);
	if (out.interest) return out;
	out= findInterestWithChannelIdFromEntityState(state.closed, channelId);
	return out;
}

const findInterestWithChannelIdFromEntityState= (entityState, channelId) => {
	for (var i = 0; i < entityState.ids.length; i++){
	  const id= entityState.ids[i];
	  const referral= entityState.entities[id];
      if (referral?.interest){
		for (var j = 0; j < referral.interest.ids.length; j++){
	       const interestId= referral.interest.ids[j];
		   const interest= referral.interest.entities[interestId];
		   if (interest && interest.dmChannelId == channelId) 
		   	  return {interest: interest, referral: referral, entities: entityState};
		}
	  }
	}
    return {};
}

const findEntry= (state, referralId) => {
	  let referral= state.open.entities[referralId];
	  if (referral){
	  	  return { entities: state.open, referral: referral };
	  }
	  referral= state.pending.entities[referralId];
	  if (referral){
	  	  return { entities: state.pending, referral: referral };
	  }
	  referral= state.closed.entities[referralId];
	  if (referral){
	  	  return { entities: state.closed, referral: referral };
	  }
      return {};
}

const referralsSlice = createSlice({
  name: "referrals",
  initialState: { 
  	open: referralAdapter.getInitialState({status: "idle", unread: false }),
  	pending: referralAdapter.getInitialState({status: "idle", unread: false }),
  	closed: referralAdapter.getInitialState({status: "idle", unread: false }),
  	viewingReferralId: null,
    shareReferralId: null, 
    selectedInterestId: null
  },
  reducers: {
    setViewingReferralId: (state, action) => {
      state.viewingReferralId= action.payload;
    },
    setShareReferralId: (state, action) => {
      state.shareReferralId= action.payload;
    },
    setSelectedInterestId: (state, action) => {
      state.selectedInterestId= action.payload;
    },
    updateReferralInterest: (state, action) => {
      const referralId = action.payload.referralId;
      const entry= findEntry(state, referralId);
      let referralEntry = entry.referral;
      if (referralEntry) {
		  interestAdapter.upsertOne(referralEntry.interest, action.payload); 
		  referralEntry.unread= isUnread(referralEntry.interest.entities); 
		  entry.entities.unread= isUnread(entry.entities.entities);
      }
    },
  },
  extraReducers: builder => {
    builder
    .addCase(fetchReferral.fulfilled, (state, action) => {
    	const referral= newReferral(action.payload);
    	const stateEntry= state[referral.status];
        referralAdapter.setOne(stateEntry, referral);
    })
    .addCase(fetchReferrals.fulfilled, (state, action) => {
		const referralEntries = action.payload.map(referral => {
           return newReferral(referral);
        });
        const stateEntry= state[action.meta.arg.status];
        referralAdapter.setMany(stateEntry, referralEntries);
        stateEntry.status= 'suceeded';
        stateEntry.unread= isUnread(stateEntry.entities);

    })
    .addCase(createReferral.fulfilled, (state, action) => {
		const referral= action.payload;
		const stateEntry= state.open;
        referralAdapter.addOne(stateEntry, newReferral(referral));
    })
    .addCase(updateReferral.fulfilled, (state, action) => {
    	const referral= action.payload;
    	const stateEntry= state[referral.status];
        referralAdapter.upsertOne(stateEntry, action.payload);
    })
    .addCase(deleteReferral.fulfilled, (state, action) => {
      const referralId = action.meta.arg.referralId;
      const entry= findEntry(state, referralId);
      const stateEntry= entry.entities;
      referralAdapter.removeOne(stateEntry, referralId);
      stateEntry.unread= isUnread(stateEntry.entities);
    })
    .addCase(closeReferral.fulfilled, (state, action) => {
      const referralId = action.meta.arg.referralId;
      processStatusChange(state, referralId, "closed");
    })
    .addCase(pendingReferral.fulfilled, (state, action) => {
	  const referralId = action.meta.arg.referralId;
      processStatusChange(state, referralId, "pending");
    })
    .addCase(openReferral.fulfilled, (state, action) => {
      const referralId = action.meta.arg.referralId;
      processStatusChange(state, referralId, "open");
    })
    .addCase(fetchReferralInterest.fulfilled, (state, action) => {
      const referralId = action.meta.arg.referralId;
      const entry= findEntry(state, referralId);
      let referralEntry = entry.referral;
      if (referralEntry) {
		  referralEntry.interest.status= 'suceeded';
		  interestAdapter.setAll(referralEntry.interest, action.payload); 
		  referralEntry.unread= isUnread(referralEntry.interest.entities); 
		  entry.entities.unread= isUnread(entry.entities.entities);
      }
    })
    .addCase(setChannelRead.fulfilled, (state, action) =>  {
        const channelId = action.payload;
        const {interest, referral, entities} = findInterestWithChannelId(state, channelId);
        if (interest) interest.unread= false;
        if (referral) referral.unread= isUnread(referral.interest.entities);
        if (entities) entities.unread= isUnread(entities.entities);
    })
    .addCase(setChannelUnread, (state, action) =>  {
        const channelId = action.payload;
        const {interest, referral, entities} = findInterestWithChannelId(state, channelId);
        if (interest) interest.unread= true;
        if (referral) referral.unread= true;
        if (entities) entities.unread= true;

    })
    .addCase(submitPost.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const {interest, referral} = findInterestWithChannelId(state, channelId);
      if (interest){
		  interest.lastMessageTimestamp= action.payload.createdAt;
	  }
    })
  }
})

const processStatusChange= (state, referralId, status) => {
      const entry= findEntry(state, referralId);
      const referral= entry.referral;
      const stateEntry= entry.entities;
      referral.status= status;
      referralAdapter.removeOne(stateEntry, referralId);
      referralAdapter.addOne(state[status], referral);
      stateEntry.unread= isUnread(stateEntry.entities);
      state[status].unread= isUnread(state[status].entities);
}

const { selectAll: selectAllOpen, selectById: selectOpenById } = referralAdapter.getSelectors(state => state.referrals.open);
const { selectAll: selectAllClosed, selectById: selectClosedById } = referralAdapter.getSelectors(state => state.referrals.closed);
const { selectAll: selectAllPending, selectById: selectPendingById } = referralAdapter.getSelectors(state => state.referrals.pending);
const { selectAll: selectAllInterests, selectById: selectInterestById,  } = interestAdapter.getSelectors(state => state.interest);


export const selectReferralById = (referralId) => state => {
    const entry= findEntry(state.referrals, referralId);
    let referralEntry = entry.referral;
	return referralEntry ? referralEntry : null;
}

export const selectReferralInterestsById = (referralId) => state => {
	const entry= findEntry(state.referrals, referralId);
    let referral = entry.referral;
	if (referral) return selectAllInterests(referral);
	else return null;
}

export const selectReferralInterestByIds = (referralId, interestId) => state => {
	const entry= findEntry(state.referrals, referralId);
    let referral = entry.referral;
	if (referral && interestId) return selectInterestById(referral, interestId);
	else return null;
}

export const selectAllOpenReferrals = () => state => {
	return selectAllOpen(state);
}

export const selectAllClosedReferrals = () => state => {
	return selectAllClosed(state);
}

export const selectAllPendingReferrals = () => state => {
	return selectAllPending(state);
}

export const selectOpenUnread = ({ referrals }) => referrals.open.unread;
export const selectPendingUnread = ({ referrals }) => referrals.pending.unread;
export const selectClosedUnread = ({ referrals }) => referrals.closed.unread;
export const selectShareReferralId = ({ referrals }) => referrals.shareReferralId;
export const selectViewingReferralId = ({ referrals }) => referrals.viewingReferralId;
export const selectSelectedInterestId = ({ referrals }) => referrals.selectedInterestId;
export const { setViewingReferralId, setShareReferralId, setSelectedInterestId, updateReferralInterest } = referralsSlice.actions;

export default referralsSlice.reducer;
