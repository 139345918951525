import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import { fetchChannels, setChannelRead } from 'app/store/channelsSlice';
import { submitPost } from './postSlice';
import { addDmChannel, addDmChannels, removeDmChannel } from './dmChannelSlice';
import sortComparer from 'app/shared-components/util/lastMessageSortComparer';
import { isUnread } from 'app/shared-components/utils';


export const getConnections = createAsyncThunk('connections/getConnections', 
  async ({bulk}, { getState, dispatch }) => {
	  const { user } = getState();
	  let data= bulk;
	  
	  if (!data){
		  const url= '/user/' + user.id + '/userconnection/pairs';
		  const apiName = 'CoreAPI';
		  const path = url;
		  const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  data= await apiGet(apiName, path, options);
	  }
	  
	  const out= data.map(resp => ({... resp.connection, ... resp.user}));
	  dispatch(addDmChannels(out.map((dm) => ({type: 'connection', foreignId: dm.id, channelId: dm.dmChannelId, data: dm}))));
	  return out;
});


const connectionsAdapter = createEntityAdapter({
    sortComparer: sortComparer,
});

export const { selectAll: selectConnections, selectById: selectById } =
  connectionsAdapter.getSelectors((state) => state.connections);

const connectionsSlice = createSlice({
  name: 'connections',
  initialState: connectionsAdapter.getInitialState({unread: false}),
  reducers: {
    updateConnection:  (state, action) => {
        connectionsAdapter.upsertOne(state, action.payload);
        state.unread= isUnread(state.entities);
	},
  },
  extraReducers: builder => {
    builder
    .addCase(setChannelRead.fulfilled, (state, action) => {
	  const channelId= action.meta.arg.channelId;
      let connection= findConnectionWithChannelId(state, channelId);
      if (connection){
		  connection.unread= false;
	  }
	  state.unread= isUnread(state.entities);
    })
    .addCase(submitPost.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      let connection= findConnectionWithChannelId(state, channelId);
      if (connection){
		  connection.lastMessageTimestamp= action.payload.createdAt;
	  }
    })
    .addCase(getConnections.fulfilled, (state, action) => {
      connectionsAdapter.setAll(state, action.payload);
      state.unread= isUnread(state.entities);
    })
  },
});

const findConnectionWithChannelId= (state, channelId) => {
	for (var i = 0; i < state.ids.length; i++){
	  const id= state.ids[i];
	  const connection= state.entities[id];
	  if (connection && connection.dmChannelId == channelId) 
		   	  return connection;
	}
    return null;
}

export const { setSelectedContactId, removeSelectedContactId, updateConnection } = connectionsSlice.actions;

export const selectSelectedContactId = ({ connections }) => connections.selectedContactId;
export const selectDMsUnread= ({ connections }) => connections.unread;

export const selectConnectionById = (id) => state => {
	if (!id) return null;
    return selectById(state, id);
}

export default connectionsSlice.reducer;
