import { showMessage, hideMessage } from 'app/store/fuse/messageSlice';

export function infoMessage(dispatch, message, duration){
	  dispatch(
        showMessage({
            message     : message,
            autoHideDuration: duration ? duration : 5000,
            anchorOrigin: {
                vertical  : 'top',//top bottom
                horizontal: 'center'//left center right
            },
            variant: 'info'//success error info warning null
        }))

}

export function successMessage(dispatch, message, duration){
	  dispatch(
        showMessage({
            message     : message,
            autoHideDuration: duration ? duration : 5000,
            anchorOrigin: {
                vertical  : 'top',//top bottom
                horizontal: 'center'//left center right
            },
            variant: 'success'//success error info warning null
        }))

}

export function warningMessage(dispatch, message, duration){
	  dispatch(
        showMessage({
            message     : message,
            autoHideDuration: duration ? duration : 5000,
            anchorOrigin: {
                vertical  : 'top',//top bottom
                horizontal: 'center'//left center right
            },
            variant: 'warning'//success error info warning null
        }))

}

export function errorMessage(dispatch, message, duration){
	  dispatch(
        showMessage({
            message     : message,
            autoHideDuration: duration ? duration : 5000,
            anchorOrigin: {
                vertical  : 'top',//top bottom
                horizontal: 'center'//left center right
            },
            variant: 'error'//success error info warning null
        }))

}

export function clearMessage(dispatch){
	  dispatch(hideMessage());
}
