import Typography from '@mui/material/Typography';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import { getMetadata, getGoogleDocType } from 'app/shared-components/linkMetadataFunction';
import LinkMetaModel from 'app/shared-components/post/LinkMetaModel';
import GoogleDocItem from 'app/shared-components/util/GoogleDocItem';
import { memo } from 'react';

const LinkDisplay= memo(function LinkDisplay({url, metadata}){
   const googleType= getGoogleDocType(url);
    
   async function retrieveMetadata(){
	  if (metadata) return LinkMetaModel(metadata);
	  else return await getMetadata(url);
   }
  
  
    return (
        <>
        {googleType ?
            <GoogleDocItem link={url} type={googleType} />
                    :
            <LinkPreview url={url} width='100%' fetcher={retrieveMetadata}
              fallback={
    			  <Typography>{url}</Typography>
    		  } />
            
        }
        </>
        
    );
});

export default LinkDisplay;