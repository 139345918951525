import { useState, useEffect, useRef, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectNativeDialog, setNativeDialog} from 'app/store/utilSlice';
import NativeDialog from './NativeDialog';


function UtilDialog(props) {
  const dispatch= useDispatch();
  const dialogContent = useSelector(selectNativeDialog);
  const open= Boolean(dialogContent?.children);
  if (!open) return null;
  const {width, height, maxWidth, maxHeight} = dialogContent;

  function handleClose(){
	  dispatch(setNativeDialog(null));
  }
  

  return (

      <NativeDialog open={open} 
                    width={width}
                    height={height}
                    maxHeight={maxHeight}
                    maxWidth={maxWidth}
                    onClose={handleClose}>

          {dialogContent.children}
    	    
      </NativeDialog>
  );
  
}

export default UtilDialog;
