import axios from 'axios';

import { STRAPI_CONFIG } from 'app/configs/strapi.js';

const instance = axios.create({
  baseURL: STRAPI_CONFIG.API_BASE
});


export default instance;
