import authRoles from '../../../auth/authRoles';
import Invest from './Invest';

const ProvidersConfig = {
  settings: {
    layout: {
      style: 'layoutMarketing',

      config: {
        header: {
          display: true
        },

        footer: {
          display: true
        }
      },
    },
  },

  auth: authRoles.onlyGuest,

  routes: [
    {
      path: '/invest',
      element: <Invest />,
    },
  ],
};

export default ProvidersConfig;
