import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import { Controller } from 'react-hook-form';



function ControlledCheckbox({control, name, label, errors, className, infoText, ...props}) {
   const useClasses = className ? className : "items-center mb-10";
   
   return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, name, ref } }) => (
        <FormControl className={useClasses} error={!!errors[name]}>
          <FormControlLabel
            label={label}
            control={
              <Checkbox size="small"
                               onBlur={onBlur} // notify when input is touched
							   onChange={onChange} // send value to hook form
							   checked={value ? value : false}
							   inputRef={ref}
							   {...props}
							/>}
          />
          <FormHelperText className="-mt-6">{errors && errors[name]?.message ? errors[name].message : infoText}</FormHelperText>
        </FormControl>
      )}
    />
   );
}

export default ControlledCheckbox;
