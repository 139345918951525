import lazyLoad from 'app/shared-components/util/lazyLoad';
const ClientsApp= lazyLoad(() => import('./ClientsApp'));
const ManageClient = lazyLoad(() => import('./client/ManageClient'));
const ClientForm = lazyLoad(() => import('./client/ClientForm'));
const ClientNotes = lazyLoad(() => import('./client/ClientNotes'));
const ClientTeams = lazyLoad(() => import('./client/ClientTeams'));
const ClientAuthorizedUsers = lazyLoad(() => import('./client/ClientAuthorizedUsers'));
const AuthorizedClientChat = lazyLoad(() => import('./AuthorizedClientChat'));
const AuthorizedUserChatMulti = lazyLoad(() => import('./AuthorizedUserChatMulti'));


const ClientsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/clients',
      element: <ClientsApp />,
      children: [
        {
          path: ':id',
          element: <ClientForm />,
        },
        {
          path: 'authorized',
          element: <div/>,
        },
        {
          path: 'authorized/:id',
          element: <AuthorizedClientChat/>,
        },
        {
          path: 'chat/:id',
          element: <AuthorizedUserChatMulti/>,
        },
        {
          path: 'pending',
          element: <div/>,
        },
      ],
    },
    {
      path: 'apps/client/:clientId',
      element: <ManageClient />,
      children: [
        {
          path: '',
          element: <ClientNotes />,
        },
        {
          path: 'teams',
          element: <ClientTeams />,
        },
        {
          path: 'teams/view/:teamId',
          element: <ClientTeams />,
        },
        {
          path: 'teams/add/view/:teamId',
          element: <ClientTeams />,
        },
        {
          path: 'teams/add',
          element: <ClientTeams />,
        },
        {
          path: 'users',
          element: <ClientAuthorizedUsers />,
        },        
        {
          path: 'users/view/:userId',
          element: <ClientAuthorizedUsers />,
        },
        {
          path: 'users/chat/:userId',
          element: <ClientAuthorizedUsers />,
        },
        {
          path: 'users/pending',
          element: <ClientAuthorizedUsers />,
        },
        {
          path: 'users/pending/view/:userId',
          element: <ClientAuthorizedUsers />,
        },
        {
          path: 'users/add',
          element: <ClientAuthorizedUsers />,
        },
        {
          path: 'users/add/view/:userId',
          element: <ClientAuthorizedUsers />,
        },
      ],
    },
  ],
};

export default ClientsAppConfig;
