import { ReactNode, createRef, memo, useEffect } from 'react';
import { isEqual, escape } from 'lodash';


const maybeScrollSuggestionIntoView = (
  suggestionEl,
  suggestionsContainer
) => {
  const containerHeight = suggestionsContainer.offsetHeight;
  const suggestionHeight = suggestionEl.offsetHeight;
  const relativeSuggestionTop =
    suggestionEl.offsetTop - suggestionsContainer.scrollTop;
  if (relativeSuggestionTop + suggestionHeight >= containerHeight) {
    suggestionsContainer.scrollTop +=
      relativeSuggestionTop - containerHeight + suggestionHeight;
  } else if (relativeSuggestionTop < 0) {
    suggestionsContainer.scrollTop += relativeSuggestionTop;
  }
};

const shouldRenderSuggestions = (
  query,
  minQueryLength,
  isFocused,
  shouldRenderSuggestionsCb
) => {
  if (typeof shouldRenderSuggestionsCb === 'function') {
    return shouldRenderSuggestionsCb(query);
  }
  return query.length >= minQueryLength && isFocused;
};


const SuggestionsComp = (props) => {
  const suggestionsContainerRef = createRef();
  const {
    labelField,
    minQueryLength,
    isFocused,
    classNames,
    selectedIndex,
    query,
  } = props;

  useEffect(() => {
    if (!suggestionsContainerRef.current) {
      return;
    }
    const activeSuggestion = suggestionsContainerRef.current.querySelector(
      `.${classNames.activeSuggestion}`
    );

    if (activeSuggestion) {
      maybeScrollSuggestionIntoView(
        activeSuggestion,
        suggestionsContainerRef.current
      );
    }
  }, [selectedIndex]);

  const markIt = (tag, query) => {
    const escapedRegex = query.trim().replace(/[-\\^$*+?.()|[\]{}]/g, '\\$&');

    const { [labelField]: labelValue } = tag;

    return {
      __html: labelValue.replace(RegExp(escapedRegex, 'gi'), (x) => {
        return `<mark>${escape(x)}</mark>`;
      }),
    };
  };

  const renderSuggestion = (tag, query) => {
    if (typeof props.renderSuggestion === 'function') {
      return props.renderSuggestion(tag, query);
    }
    return <span dangerouslySetInnerHTML={markIt(tag, query)} />;
  };

  const suggestions = props.suggestions.map((tag, index) => {
    return (
      <li
        key={index}
        onMouseDown={props.handleClick.bind(null, index)}
        onTouchStart={props.handleClick.bind(null, index)}
        onMouseOver={props.handleHover.bind(null, index)}
        className={
          index === props.selectedIndex ? props.classNames.activeSuggestion : ''
        }>
        {renderSuggestion(tag, props.query)}
      </li>
    );
  });

  // use the override, if provided
  if (
    suggestions.length === 0 ||
    !shouldRenderSuggestions(
      query,
      minQueryLength || 2,
      isFocused,
      props.shouldRenderSuggestions
    )
  ) {
    return null;
  }

  return (
    <div
      ref={suggestionsContainerRef}
      className={classNames.suggestions}
      data-testid="suggestions">
      <ul> {suggestions} </ul>
    </div>
  );
};

export const arePropsEqual = (
  prevProps,
  nextProps
) => {
  const { query, minQueryLength = 2, isFocused, suggestions } = nextProps;

  if (
    prevProps.isFocused === isFocused &&
    isEqual(prevProps.suggestions, suggestions) &&
    shouldRenderSuggestions(
      query,
      minQueryLength,
      isFocused,
      nextProps.shouldRenderSuggestions
    ) ===
      shouldRenderSuggestions(
        prevProps.query,
        prevProps.minQueryLength ?? 2,
        prevProps.isFocused,
        prevProps.shouldRenderSuggestions
      ) &&
    prevProps.selectedIndex === nextProps.selectedIndex
  ) {
    return true;
  }
  return false;
};

const Suggestions = memo(SuggestionsComp, arePropsEqual);

export default Suggestions;
