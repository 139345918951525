import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import sanitizeHtml from 'sanitize-html';
import _ from '@lodash';
import { Link } from 'react-router-dom';

/**
 * @param {{ testimonial: import('../../store/marketing/marketing').Testimonial }}
 */
export default function TestimonialPreview({ testimonial, className, ...props }) {
  return (
    <article
      className={twMerge(classNames([
        'bg-hs-white rounded-20 px-28 pt-20 pb-64',
        className
      ]))}
      {...props}
    >
      <div>
        <Link
          className="text-12 text-hs-blue bg-hs-purple px-10 py-4 font-medium rounded-full"
        >
          {/* TODO: i18n */}
          {_.startCase(testimonial.type)}
        </Link>
      </div>

      <h3 className="mt-20 text-hs-grey-black text-20 font-semibold">
        {testimonial.title}
      </h3>

      <div className="mt-12 text-hs-grey-black text-16"
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(testimonial.body) }}
      />
    </article>
  );
}