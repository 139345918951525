import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { stopLoading, startLoading} from 'app/store/utilSlice';
import sortComparer from 'app/shared-components/util/lastMessageSortComparer';
import { isUnread } from 'app/shared-components/utils';
import { setChannelRead, setChannelUnread, addChannel } from './channelsSlice';
import { bulkApiGet, buildGetItem } from 'app/shared-components/util/bulkApi';
import { fetchProgLocsByLocation } from './orgLocationsSlice';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';


const emptyArray= [];

const programAdapter = createEntityAdapter({
});

const orgAdapter = createEntityAdapter({
});

const locationAdapter = createEntityAdapter({
});


export const fetchProgram = createAsyncThunk(
  "programs/fetchProgram",
  async (id, { dispatch }) => {
	dispatch(startLoading());
	try{
      const apiName = 'CoreAPI';
	  const path = '/programinfo/' + id;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	  const data= await apiGet(apiName, path, options);
	  dispatch(fetchProgLocsByProgram({programId: id}));
	  return data;
	  
	}finally{
	  dispatch(stopLoading());
	}
  }
);

export const fetchPrograms = createAsyncThunk(
  "programs/fetchPrograms",
  async ({organizationId, bulk}, { dispatch, getState }) => {
	  const {user} = getState();
	  let data= bulk;
	  if (!data){ //pre-fetched data via bulk api
	      const apiName = 'CoreAPI';
		  const path = '/programinfo/byorg/' + organizationId;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  data= await apiGet(apiName, path, options);
	 }
	 
     //Use bulk load to get locations for the programs
     //Do this async so it doesn't block the return of this thunk
	 bulkProgramListCalls({programs: data}).then((bulk) => {
		 const bulkData= bulk.items;
		 for (let i in data) {
			const program= data[i];
			const locations= bulkData[program.id];
			dispatch(fetchProgLocsByProgram({programId: program.id, bulk: locations.code == 200 ? locations.body : undefined}));
		 }
	 });
	
     return data;
  }
)

export const bulkProgramListCalls= async ({programs}) => { 
	let items= [];
	for (let i in programs) {
		const program= programs[i];
		items.push(buildGetItem(program.id, '/programinfo/' + program.id + '/programlocation/locations', {id: program.id})); 
	}
	return await bulkApiGet({items: items});
}

 export const fetchProgLocsByProgram = createAsyncThunk(
  "programLocations/fetchProgLocsByProgram",
  async ({programId, bulk}, { dispatch, getState }) => {
	  let data= bulk;
	  if (!data){ //pre-fetched data via bulk api
	      const apiName = 'CoreAPI';
		  const path = '/programinfo/' + programId + "/programlocation/locations";
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  data= await apiGet(apiName, path, options);
	 }
     return data;
  }
);


export const createProgram = createAsyncThunk(
  "programs/createProgram",
  async (params, { dispatch, getState }) => {
	  try{
	      const apiName = 'CoreAPI';
		  const path = '/programinfo/';
		  let body= { ...params};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let out= await apiPut(apiName, path, options);
		 return out;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const updateProgram = createAsyncThunk(
  "programs/updateProgram",
  async (params, { dispatch, getState }) => {
	  try{
	      const apiName = 'CoreAPI';
		  const path = '/programinfo/' + params.id;
		  let body= { ...params};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)


export const deleteProgram = createAsyncThunk(
  "programs/deleteProgram",
  async (params, { dispatch }) => {
	  try{
	      const { programId } = params;
	      const apiName = 'CoreAPI';
		  const path = '/programinfo/' + programId;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiDel(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)


export const createProgramLocation = createAsyncThunk(
  "programs/createProgramLocation",
  async ({programId, locationId}, { dispatch, getState }) => {
	  try{
		  dispatch(startLoading());
	      const apiName = 'CoreAPI';
		  const path = '/programinfo/' + programId + "/programlocation";
		  let body= { programId: programId, locationId: locationId};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let out= await apiPut(apiName, path, options);
		 
		 //Update the relevant program and location
		 dispatch(fetchProgLocsByProgram({programId: programId}));
		 dispatch(fetchProgLocsByLocation({locationId: locationId}));
		 
		 return out;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)


export const deleteProgramLocation = createAsyncThunk(
  "programs/deleteProgramLocation",
  async ({programId, locationId}, { dispatch }) => {
	  try{
		  dispatch(startLoading());
	      const apiName = 'CoreAPI';
		  const path = '/programinfo/' + programId + "/programlocation/" + locationId;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiDel(apiName, path, options);
		 
		 //Update the relevant program and location
		 dispatch(fetchProgLocsByProgram({programId: programId}));
		 dispatch(fetchProgLocsByLocation({locationId: locationId}));
		 
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const fetchProgramProfile = createAsyncThunk(
  "programs/fetchProgramProfile",
  async (id, { dispatch, getState }) => {
	try{
	  dispatch(startLoading());
	  let url= '/programinfo/' + id + '/public/profile';
	  const apiName = 'PublicAPI';
	  const path = url;
	  const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	
	  let data= await apiGet(apiName, path, options);
	  return { ...data, id: data.program.id};
	}finally{
		dispatch(stopLoading());
	}
	  
  }
)



const newProgram= (data) => {
	return {...data, unread: false, locations: locationAdapter.getInitialState()};
}

const newOrgEntry= (orgId) => {
	return {id: orgId, programs: programAdapter.getInitialState()};
}

const programsSlice = createSlice({
  name: "programs",
  initialState: { all: programAdapter.getInitialState(),
                  orgMap: orgAdapter.getInitialState(),
                  profiles: programAdapter.getInitialState(),
                  selectedProgramsTab: 0,
                },  
  reducers: {
    setSelectedProgramsTab: {
      reducer: (state, action) => {
        state.selectedProgramsTab = action.payload;
      }
    },
  },
  extraReducers: builder => {
    builder
    .addCase(fetchProgram.fulfilled, (state, action) => {
		const orgId= action.payload.organizationId;
		const orgEntry= state.orgMap.entities[orgId];
		const program= newProgram(action.payload);
		programAdapter.addOne(state.all, {id: program.id, organizationId: orgId});
		if (orgEntry){
			programAdapter.setOne(orgEntry.programs, program);
		}else{
			const newEntry= newOrgEntry(orgId);
			newEntry.programs= programAdapter.addOne(newEntry.programs, program);
			orgAdapter.addOne(state.orgMap, newEntry);
		}  
    })
    .addCase(fetchPrograms.fulfilled, (state, action) => {
		const orgId= action.meta.arg.organizationId;
		const newEntry= newOrgEntry(orgId);
		const programEntries = action.payload.map(p => {
           return newProgram(p);
        });
        const allEntries = action.payload.map(p => {
           return {id: p.id, organizationId: p.organizationId};
        });
        programAdapter.addMany(state.all, allEntries);
        newEntry.programs= programAdapter.addMany(newEntry.programs, programEntries);
	    orgAdapter.setOne(state.orgMap, newEntry);
    })
    .addCase(fetchProgLocsByProgram.fulfilled, (state, action) => {
		const programId = action.meta.arg.programId;
	    const entry= state.all.entities[programId];
	    if (entry){
			const orgEntry= state.orgMap.entities[entry.organizationId];
			if (orgEntry){
				const progEntry= orgEntry.programs.entities[programId];
				if (progEntry) locationAdapter.setAll(progEntry.locations, action.payload);
			}    
		} 
    })
    .addCase(createProgram.fulfilled, (state, action) => {
		const orgId= action.payload.organizationId;
		const orgEntry= state.orgMap.entities[orgId];
		const program= newProgram(action.payload);
		programAdapter.addOne(state.all, {id: program.id, organizationId: orgId});
		if (orgEntry){
			programAdapter.addOne(orgEntry.programs, program);
		}else{
			const newEntry= newOrgEntry(orgId);
			newEntry.programs= programAdapter.addOne(newEntry.programs, program);
			orgAdapter.addOne(state.orgMap, newEntry);
		}  
    })
    .addCase(updateProgram.fulfilled, (state, action) => {
		const orgId= action.payload.organizationId;
		const orgEntry= state.orgMap.entities[orgId];
		if (orgEntry){
			programAdapter.upsertOne(orgEntry.programs, action.payload);
		}
    })
    .addCase(deleteProgram.fulfilled, (state, action) => {
	    const programId = action.meta.arg.programId;
	    const entry= state.all.entities[programId];
	    if (entry){
			programAdapter.removeOne(state.all, programId);
			const orgEntry= state.orgMap.entities[entry.organizationId];
			if (orgEntry){
				programAdapter.removeOne(orgEntry.programs, programId);
			}    
		}     
    })
    .addCase(fetchProgramProfile.fulfilled, (state, action) => {
        programAdapter.setOne(state.profiles, action.payload);
    })
  }
})

const { selectById: selectFromAllById } = programAdapter.getSelectors(state => state.programs.all);
const { selectById: selectOrgById } = orgAdapter.getSelectors(state => state.programs.orgMap);
const { selectById: selectFromOrgById, selectAll: selectAllFromOrg} = programAdapter.getSelectors(state => state.programs);
const { selectAll: selectLocationsFromProgram} = locationAdapter.getSelectors(state => state.locations);
const { selectById: selectProfileById} = programAdapter.getSelectors(state => state.programs.profiles);


export const selectProgramById = (programId) => state => {
	if (programId) {
		const entry= selectFromAllById(state, programId);
		if (entry){
			const orgEntry= selectOrgById(state, entry.organizationId);
			if (orgEntry){
				return selectFromOrgById(orgEntry, programId);
			} 
		}
	}
	return null;
}

export const selectProgramProfileById = (programId) => state => {
	if (programId) return selectProfileById(state, programId);
	else return null;
}

export const selectProgramLocationsById = (programId) => state => {
	if (programId) {
		const entry= selectFromAllById(state, programId);
		if (entry){
			const orgEntry= selectOrgById(state, entry.organizationId);
			if (orgEntry){
				const progEntry= selectFromOrgById(orgEntry, programId);
				if (progEntry) return selectLocationsFromProgram(progEntry);
			} 
		}
	}
	return emptyArray;
}

export const selectProgramsByOrgId = (orgId) => state => {
	if (orgId) {
		const orgEntry= selectOrgById(state, orgId);
		if (orgEntry){
			return selectAllFromOrg(orgEntry);
		} 
	}
	return emptyArray;
}

export const selectSelectedProgramsTab = () => state => {
	return state.programs.selectedProgramsTab;
}

export const { setSelectedProgramsTab } = programsSlice.actions;

export default programsSlice.reducer;
