import lazyLoad from 'app/shared-components/util/lazyLoad';

const AccountReturnApp = lazyLoad(() => import('./AccountReturnApp'));

const AccountReturnAppConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: false,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  routes: [
    {
      path: 'apps/account/return',
      element: <AccountReturnApp />,
    },
  ],
};

export default AccountReturnAppConfig;
