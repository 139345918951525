import BrowserRouter from '@fuse/core/BrowserRouter';
import FuseLayout from '@fuse/core/FuseLayout';
import FuseTheme from '@fuse/core/FuseTheme';
import { SnackbarProvider } from 'notistack';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { selectCurrentLanguageDirection } from 'app/store/i18nSlice';
import { selectUser } from 'app/store/userSlice';
import themeLayouts from 'app/theme-layouts/themeLayouts';
import { selectMainTheme } from 'app/store/fuse/settingsSlice';
import FuseAuthorization from '@fuse/core/FuseAuthorization';
import settingsConfig from 'app/configs/settingsConfig';
import withAppProviders from './withAppProviders';
import { AuthProvider } from './auth/AuthContext';
import { Amplify } from 'aws-amplify';
import HeaderTags from 'app/shared-components/helmet/HeaderTags';
import AppInitialization from './AppInitialization';
import AppStatusCheck from './AppStatusCheck';
import { API_CONFIG, COGNITO_CONFIG } from "app/configs/aws.ts";
import { selectNotificationsUnread } from 'app/store/notificationsSlice';
import { selectUnreadByType} from 'app/store/groupsSlice';
import { selectDMsUnread } from 'app/store/connectionsSlice';
import { selectOpenUnread, selectPendingUnread, selectClosedUnread } from 'app/store/referralsSlice';
import { selectOpenInterestUnread, selectClosedInterestUnread } from 'app/store/referralInterestSlice';
import { selectClientsUnread} from 'app/store/clientsSlice';
import { selectAuthorizedClientsUnread} from 'app/store/clientsAuthorizedSlice';
import favicon from "app/shared-components/assets/logo.svg";
import faviconBadge from "app/shared-components/assets/logo_badge.svg";

Amplify.configure({
  Auth: {
    Cognito: {
      region: COGNITO_CONFIG.REGION, // REQUIRED - Amazon Cognito Region
      userPoolId: COGNITO_CONFIG.USER_POOL_ID, // OPTIONAL - Amazon Cognito User Pool ID
      userPoolClientId: COGNITO_CONFIG.APP_CLIENT_ID, // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    },
  },
  API: {
    REST: {
      CoreAPI:
        {
          endpoint: API_CONFIG.URL_BASE,
        },
      CoreAPI2:
        {
          endpoint: API_CONFIG.URL2_BASE,
        },
      PublicAPI:
        {
          endpoint: API_CONFIG.URL_BASE        
        },
      PublicAPI2:
        {
          endpoint: API_CONFIG.URL2_BASE        
        },
    },
    GraphQL: {
      endpoint: API_CONFIG.GQL_BASE,
      region: COGNITO_CONFIG.REGION,
      defaultAuthMode: 'userPool',
    },
  },
});

const emotionCacheOptions = {
  rtl: {
    key: 'muirtl',
    stylisPlugins: [rtlPlugin],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
  ltr: {
    key: 'muiltr',
    stylisPlugins: [],
    insertionPoint: document.getElementById('emotion-insertion-point'),
  },
};

function changeFavicon(src) {
    var link = document.querySelector("link[rel~='icon']");
    link.href = src;
}

const App = () => {
  const user = useSelector(selectUser);
  const langDirection = useSelector(selectCurrentLanguageDirection);
  const mainTheme = useSelector(selectMainTheme);
  const host = window.location.protocol + '//' + window.location.host;
  const defaultImage= host +"/assets/images/logo/HALESPRING-HEADER-191.jpg";
  const notificationsUnread = useSelector(selectNotificationsUnread);
  const groupsUnread= useSelector(selectUnreadByType('group'));
  const teamsUnread= useSelector(selectUnreadByType('team'));
  const directMessagesUnread= useSelector(selectDMsUnread);
  const referralUnread= useSelector(selectOpenUnread);
  const pendingUnread= useSelector(selectPendingUnread);
  const closedUnread= useSelector(selectClosedUnread);
  const closedInterestUnread= useSelector(selectClosedInterestUnread);
  const interestUnread= useSelector(selectOpenInterestUnread);
  const clientsUnread= useSelector(selectClientsUnread);
  const authClientsUnread= useSelector(selectAuthorizedClientsUnread);
  const unread= notificationsUnread || groupsUnread || teamsUnread || directMessagesUnread ||
                referralUnread || closedUnread || pendingUnread || interestUnread || closedInterestUnread ||
                clientsUnread || authClientsUnread;

  useEffect(() => {
    changeFavicon(unread ? faviconBadge : favicon);
  }, [unread]);

  return (
    <CacheProvider value={createCache(emotionCacheOptions[langDirection])}>
      <HeaderTags title="Halespring" 
                  description="The community for mental health." 
                  keywords="Mental Health,Therapist,Therapy,Community,Referrals,Pyschologist,Psychiatrist"
                  image={defaultImage}
      />
      <FuseTheme theme={mainTheme} direction={langDirection}>
        <AuthProvider>
          <BrowserRouter>
            <FuseAuthorization
              userRole={user.role}
              loginRedirectUrl={settingsConfig.loginRedirectUrl}
            >
              <SnackbarProvider
	                maxSnack={5}
	            anchorOrigin={{
	              vertical: 'bottom',
	              horizontal: 'right',
	            }}
	            classes={{
	              containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99',
	            }}
	          >
	              <AppStatusCheck>
	                <AppInitialization>
                    <FuseLayout layouts={themeLayouts} />
                  </AppInitialization>
                </AppStatusCheck>
              </SnackbarProvider>
            </FuseAuthorization>
          </BrowserRouter>
        </AuthProvider>
      </FuseTheme>
    </CacheProvider>
  );
};

export default withAppProviders(App)();
