import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { stopLoading, startLoading} from 'app/store/utilSlice';
import { addFeedEntries, submitPost } from 'app/store/postSlice';


import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';

const postsAdapter = createEntityAdapter({
	sortComparer: (a, b) => b.createdAt - a.createdAt
});

const initialState = {
  timelineId: null,
  feed: postsAdapter.getInitialState({status: "idle"}),
  featured: postsAdapter.getInitialState({status: "idle"}),
  postsFetched: {timestamp: null, newest: null, oldest: null, fetchedAll: false},
  featuredFetched: {timestamp: null, newest: null, oldest: null, fetchedAll: false},
  searchResults: postsAdapter.getInitialState({status: "idle"}),
  searchCriteria: null,
  searchExpanded: true,
  selectedHomeTab: 0,
  activityFilters: ["team", "thread", "group","client","clientDirect","referrals","connections","organization"],
};


export const fetchSystemConfig = createAsyncThunk(
  "adminApp/admin/fetchSystemConfig",
  async (id, { dispatch }) => {
  	dispatch(startLoading());
  	try{
      const apiName = 'CoreAPI';
  	  const path = '/systemconfig/1';
      const options = {
  		  headers: {},
  		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  	  };
      const data= await apiGet(apiName, path, options);
  	  return data;
  	}finally{
  	  dispatch(stopLoading());
  	}
  }
);
 
export const fetchFeedPosts = createAsyncThunk(
  "home/fetchFeedPosts",
  async ({before, after}, { dispatch, getState }) => {
	  const { user } = getState();
      const apiName = 'CoreAPI';
	  const path = '/user/' + user.id + '/contentfeedentry/posts';
	  const query= after ? "?createdAt=GT:" + after : before ? "?createdAt=LT:" + before : "";
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	 let data= await apiGet(apiName, path + query, options);
	 return processFeedEntries(dispatch, data);
  }
)

export const fetchOlderFeedPosts = createAsyncThunk(
  "home/fetchOlderFeedPosts",
  async (params, { dispatch, getState }) => {
      const { home } = getState();
	  const {oldest, fetchedAll }= home.postsFetched;
	  if (fetchedAll) return;
	  const updatesFrom= oldest?.createdAt;
	  if (updatesFrom){
		  const resp= await dispatch(fetchFeedPosts({before: updatesFrom}));
		  if (!resp.error && resp.payload.length === 0){
			  return {fetchedAll: true};
		  }
	  }
	  return;
  }
);

export const fetchNewerFeedPosts = createAsyncThunk(
  "home/fetchNewerFeedPosts",
  async (params, { dispatch, getState }) => {
      const { home } = getState();
      const { newest }= home.postsFetched;
	  const updatesFrom= newest?.createdAt;
	  if (updatesFrom){
		  dispatch(fetchFeedPosts({after: updatesFrom}));
	  }
  }
);

export const fetchFeaturedFeedPosts = createAsyncThunk(
  "home/fetchFeaturedFeedPosts",
  async ({before, after, type}, { dispatch, getState }) => {
	  const { user } = getState();
    const apiName = 'CoreAPI';
    const useType = type ? type : 'featured';
	  const path = '/x2/featuredfeedpost/bytype/' + useType;
	  const query= after ? "?createdAt=GT:" + after : before ? "?createdAt=LT:" + before : "";
    const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	 let data= await apiGet(apiName, path + query, options);
	 return processFeedEntries(dispatch, data, true);
  }
)

export const createFeaturedFeedPost = createAsyncThunk(
  "home/createFeaturedFeedPost",
  async ({postId, type}, { dispatch, getState }) => {
    const apiName = 'CoreAPI';
	  const path = '/x2/featuredfeedpost/';
    const options = {
    	body: {type: type, postId: postId},
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	  let data= await apiPut(apiName, path, options);
	  return data;
  }
)

export const removeFeaturedFeedPost = createAsyncThunk(
  "home/removeFeaturedFeedPost",
  async ({postId}, { dispatch, getState }) => {
    const apiName = 'CoreAPI';
	  const path = '/x2/featuredfeedpost/' + postId;
    const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	  let data= await apiDel(apiName, path, options);
	  return postId;
  }
)

const processFeedEntries= (dispatch, data, isFeatured) => {
	 let out= [];
	 for (let i in data) {
		let post= data[i];
		if (!post.callerInteraction) post.callerInteraction= ""; //Needed to use immer mutation in reducer
    if (isFeatured) post.isFeaturedFeed= true;
	  out.push(post);
	 }
	 dispatch(addFeedEntries(out));
   return out;
}


export const deleteEntry = createAsyncThunk(
  "home/deleteEntry",
  async (params, { dispatch, getState }) => {
	  try{
	      const { postId } = params;
	      const { user } = getState();
	      const apiName = 'CoreAPI';
		  const path = '/contentpost/' + postId + '/contentfeedentry/' + user.id;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiDel(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)



const convertPost= (post) => {
  return {id: post.id, createdAt: post.createdAt }
}

const convertPosts= (posts) => {
  return posts.map((post) => convertPost(post));
}

export const { selectAll: selectFeedPosts, selectById: selectFeedPostById} = postsAdapter.getSelectors(state => state.home.feed);
export const { selectAll: selectFeedFeatured, selectById: selectFeedFeaturedById} = postsAdapter.getSelectors(state => state.home.featured);
export const { selectAll: selectSearchResults } = postsAdapter.getSelectors(state => state.home.searchResults);



export const selectFeedPostsStatus = () => state => {
	return state.home.feed.status;
}

export const selectFeaturedStatus = () => state => {
	return state.home.featured.status;
}

export const selectFeedPostsFetched= () => state => {
	return state.home.postsFetched;
}

export const selectActivityFilters = () => state => {
	return state.home.activityFilters;
}

export const selectSelectedHomeTab = () => state => {
	return state.home.selectedHomeTab;
}

export const selectSearchCriteria = () => state => {
	return state.home.searchCriteria;
}

export const selectSearchExpanded = () => state => {
	return state.home.searchExpanded;
}

const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
		setTimelineId: (state, action) => {
			state.timelineId= action.payload;
		},
		setActivityFilters: (state, action) => {
			state.activityFilters= action.payload;
		},
		setSelectedHomeTab: (state, action) => {
			state.selectedHomeTab= action.payload;
		},
		setRefreshFeed: (state, action) => {
			state.feed.status= "idle";
		},
    setSearchResults: {
      reducer: (state, action) => {
		postsAdapter.setAll(state.searchResults, action.payload)
      }
    },
    setSearchCriteria: {
      reducer: (state, action) => {
		state.searchCriteria= action.payload;     
	  }
    },
    setSearchExpanded: {
      reducer: (state, action) => {
		state.searchExpanded= action.payload;     
	  }
    },
    clearSearch: {
      reducer: (state, action) => {
		state.searchResults= postsAdapter.getInitialState();
		state.searchCriteria= null;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchFeedPosts.fulfilled, (state, action) => {
	    state.feed.status= "succeeded";
	    if (action.payload.length > 0){
			postsAdapter.setMany(state.feed, convertPosts(action.payload));
      const newest= action.payload[0];
      const oldest= action.payload[action.payload.length -1];
    	if (!state.postsFetched.newest || state.postsFetched.newest.createdAt < newest.createdAt)
    	  state.postsFetched.newest= newest;
      if (!state.postsFetched.oldest || state.postsFetched.oldest.createdAt > oldest.createdAt)
        state.postsFetched.oldest= oldest;
		}
    })
    .addCase(fetchOlderFeedPosts.fulfilled, (state, action) => {
		if (action.payload?.fetchedAll) state.postsFetched.fetchedAll= true;
    })
    .addCase(fetchFeedPosts.pending, (state, action) => {
        state.feed.status= "loading";
    })
    .addCase(fetchFeedPosts.rejected, (state, action) => {
	    state.feed.status= "failed";
    })
    builder.addCase(fetchFeaturedFeedPosts.fulfilled, (state, action) => {
	    state.featured.status= "succeeded";
	    if (action.payload.length > 0){
			postsAdapter.setMany(state.featured, convertPosts(action.payload));
      const newest= action.payload[0];
      const oldest= action.payload[action.payload.length -1];
    	if (!state.featuredFetched.newest || state.featuredFetched.newest.createdAt < newest.createdAt)
    	  state.featuredFetched.newest= newest;
      if (!state.featuredFetched.oldest || state.featuredFetched.oldest.createdAt > oldest.createdAt)
    	  state.featuredFetched.oldest= oldest;
		}
    })
    .addCase(fetchFeaturedFeedPosts.pending, (state, action) => {
        state.featured.status= "loading";
    })
    .addCase(fetchFeaturedFeedPosts.rejected, (state, action) => {
	    state.featured.status= "failed";
    })
    .addCase(deleteEntry.fulfilled, (state, action) => {
      const postId = action.meta.arg.postId;
      postsAdapter.removeOne(state.feed, postId);
    })
    .addCase(submitPost.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      if (channelId === state.timelineId) postsAdapter.addOne(state.feed, convertPost(action.payload)); 
    })
  }
});

export const {setTimelineId, setActivityFilters, setSelectedHomeTab, setSearchResults,
              setSearchCriteria, setSearchExpanded, clearSearch, setRefreshFeed}= homeSlice.actions;
export default homeSlice.reducer;
