import Typography from '@mui/material/Typography';
import sanitizeHtml from 'sanitize-html';
import 'app/shared-components/util/wysiwygStyles.css'
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function DisplayUserHtml({html, className, id, processLinks, variant, maxHeight, overflow}) {
   const navigate = useNavigate();
   const useVariant = variant ? variant : "body2";
   const useMaxHeight = maxHeight ? maxHeight : "300px";
   const useOverflow= overflow ? overflow : "hidden";
   let valueClasses= className ? "wysiwyg " + className : "wysiwyg";
   
   //Set intial value
  useEffect(() => {
    if (html && processLinks) {
       handleProcessLinks();
    }
  }, [html, processLinks]);
  
  useEffect(() => {
    if (html) {
       handleProcessBreaks();
    }
  }, [html]);
  
  function handleProcessLinks(){
    const element = document.getElementById(id);
    const links = element.getElementsByTagName("a");
    
    for (const link of links) {
      const href = link.getAttribute("href");
      if (href && href.startsWith("/")) {
         link.addEventListener("click", (event) => {event.preventDefault(); event.stopPropagation(); navigate(href);}, false); 
      }
    }
  }
  
  function handleProcessBreaks(){
    const element = document.getElementById(id);
    const paras = element.getElementsByTagName("p");
    for (const para of paras) {
      const content = para.textContent;
      if (!content) {
         var lineBreak = document.createElement("br");
         para.parentNode.replaceChild(lineBreak, para);
      }
    }
  }

   
   return (
        <div style={{ maxHeight: useMaxHeight, overflow: useOverflow}} id={id} className={valueClasses}
          variant={useVariant}
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }}
        />
   );
}
   

export default DisplayUserHtml;
