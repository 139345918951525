import lazyLoad from 'app/shared-components/util/lazyLoad';


const HomeApp = lazyLoad(() => import('./HomeApp'));

const HomeAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'home',
      element: <HomeApp />,
    },
  ],
};

export default HomeAppConfig;
