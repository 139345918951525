import { memo } from 'react';
import ChatPanel from '../../shared-components/chatPanel/ChatPanel';
import QuickPanel from '../../shared-components/quickPanel/QuickPanel';
import NotificationPanel from 'app/shared-components/layout/notificationPanel/NotificationPanel';

function RightSideLayout1(props) {
  return (
    <>

      <NotificationPanel />
      
    </>
  );
}

export default memo(RightSideLayout1);
