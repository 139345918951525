import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';


export default function ImageView({ object, className, ...props }) {
  return (
    <div
      className={twMerge(classNames([
        'bg-hs-white rounded-20 px-20 pt-20 pb-20',
        className
      ]))}
      {...props}
    >
      <div className="aspect-w-3 aspect-h-2">
        <img
        onClick={object.onClick}
          src={object.src}
          alt={object.alternativeText}
          className="rounded-20"
        />
      </div>
      
    </div>
  )
}
