/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onAddNotification = /* GraphQL */ `
  subscription OnAddNotification($userId: ID!) {
    onAddNotification(userId: $userId) {
      id
      userId
      content
    }
  }
`;
export const onAddChannelUpdate = /* GraphQL */ `
  subscription OnAddChannelUpdate($channelId: ID!) {
    onAddChannelUpdate(channelId: $channelId) {
      channelId
      type
      contentType
      publishedAt
      content
    }
  }
`;
export const onUpdateChatStatus = /* GraphQL */ `
  subscription OnUpdateChatStatus($sourceUserId: ID!) {
    onUpdateChatStatus(sourceUserId: $sourceUserId) {
      sourceUserId
      targetUserId
      type
      contentType
      content
    }
  }
`;
