import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FuseSplashScreen from '@fuse/core/FuseSplashScreen';
import { selectUser } from 'app/store/userSlice';
import { useAuth } from './auth/AuthContext';
import { useLocation } from 'react-router-dom';
import history from '@history';


function AppStatusCheck({ children }) {
  const dispatch = useDispatch();
  const user= useSelector(selectUser);
  const location = useLocation();
  const userId= user?.id;
  const requireAcceptTerms= user?.requireAcceptTerms;
  const isAcceptTermsApp = location.pathname.includes('apps/acceptTerms');
  const isAdminApp = location.pathname.includes('apps/admin');
  const forceRedirect= requireAcceptTerms && !isAcceptTermsApp && !isAdminApp;


  useEffect(() => {
   	if (forceRedirect) {
   	  setTimeout(() => history.push('/apps/acceptTerms'), 0);
   	}
  }, [userId, forceRedirect]);
 
  return forceRedirect ? (
	  <FuseSplashScreen />
  ) : (
    children
  );
}


export default AppStatusCheck;
