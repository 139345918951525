import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import CircularProgress from '@mui/material/CircularProgress'
import { selectPostsByChannelId, selectPostsStatusByChannelId,
         setChannelRead, selectChannel, fetchChannel, fetchOlderChannelPosts,
         refreshChannelPosts, addViewingChannelId, removeViewingChannelId} from 'app/store/channelsSlice';
import { selectUser } from 'app/store/userSlice';
import { subscribeToChannel } from 'app/store/subscriptionSlice';
import ChatInput from './ChatInput';
import DMChatEntry from './DMChatEntry';
import ReferralInterestDialog from '../referral/ReferralInterestDialog';


function DMChat(props) {
  const dispatch = useDispatch();
  const { channelId, textOnly, processPost } = props;
  const chatChannelId= channelId;
  console.log("Got chat channel id: " + chatChannelId)
  const chatChannel= useSelector(selectChannel(chatChannelId));
  const channelReady= Boolean(chatChannel);
  const channelType= chatChannel?.type;
  const unread= chatChannel?.unread;
  const needsRefresh= channelReady ? chatChannel.needsRefresh : false;
  const chatIds = useSelector(selectPostsByChannelId(chatChannelId));
  const postsStatus= useSelector(selectPostsStatusByChannelId(chatChannelId));
  const user = useSelector(selectUser);
  const chatRef = useRef(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const lastMessage= chatIds ? chatIds[chatIds.length - 1] : undefined;
  const lastMessageTimestamp= lastMessage?.createdAt;
  
  useEffect(() => {
	  //Fetch the chat channel if needed
	   if (chatChannelId && !channelReady){
		   	dispatch(fetchChannel({channelId: chatChannelId, reverseSort: true}));
	   }
  }, [chatChannelId, channelReady]);
  
  useEffect(() => {
	if (needsRefresh){
   	   dispatch(refreshChannelPosts({channelId: chatChannelId}));
   	}
  }, [dispatch, chatChannelId, needsRefresh]);

  useEffect(() => {
    if (lastMessageTimestamp){
		  setTimeout(scrollToBottom);
	    if (unread){
  			dispatch(setChannelRead({channelId: chatChannelId}))
  		}
	} 
  }, [dispatch, lastMessageTimestamp]);
  
  useEffect(() => {
	// Load posts and Subscribe to channel
   if (channelReady){
	   	dispatch(subscribeToChannel({channelId: chatChannelId, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar}));
        dispatch(addViewingChannelId(chatChannelId));
   }
   
   return () => {
        // remove viewing channel. subscription cleanup is handled in subscription manager middleware
        dispatch(removeViewingChannelId(chatChannelId));
    };
  }, [dispatch, chatChannelId, channelReady]);
  
  
  function scrollToBottom() {
    if (!chatRef.current) {
      return;
    }
    chatRef.current.scrollTo({
      top: chatRef.current.scrollHeight,
      behavior: 'instant',
    });
  }
  
  function handleScroll(event){
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const scroll = scrollHeight - scrollTop - clientHeight

    if (scroll == 0){
      console.log("Scroll at bottom.");
    }else if (scrollTop == 0){
	  console.log("Scroll at top.");
	  dispatch(fetchOlderChannelPosts({channelId: chatChannelId}));
	}
  }
  

  if (!chatChannelId || !chatIds) {
    return null;
  }

  return (
	  
  <div className="flex flex-auto grow shrink-0 basis-0 h-full min-h-0 w-full">
    <div className={clsx('flex flex-1 z-10 flex-col relative', props.className)}>
      <div ref={chatRef} onScroll={handleScroll} className="flex flex-1 flex-col overflow-y-auto">
        {chatIds?.length === 0 && (
          <div className="flex flex-col flex-1">
            {(!channelReady || postsStatus === 'loading') && (
          	   <div className="flex flex-col flex-1 items-center justify-items-center">
                <CircularProgress />
               </div>
            )}
            {(postsStatus != 'loading') && (
	            <Typography className="px-16 pb-24 text-center" color="text.secondary">
	              Start a conversation by typing your message below.
	            </Typography>
            )}
          </div>
        )}
        {chatIds?.length > 0 && (
          <div className="flex flex-col pt-16 px-16 pb-40">
            {chatIds.map((item, i) => {
              return item ? (
                <DMChatEntry
                  key={item.id}
                  postId={item.id}
                  channelId={chatChannelId}
                  nextPostId={chatIds[i+1]?.id}
                  prevPostId={chatIds[i-1]?.id}
                  user={user}
                  chatLength={chatIds.length}
                  suppressVideoCall={textOnly}
                  i={i}
                />
              ) : null;
            })}
          </div>
        )}
        <div className="mb-80"></div>
      </div>
      {chatIds && (
	    <ChatInput channelId={chatChannelId} rootChannelId={chatChannelId} channelType={channelType} isDM={true} textOnly={textOnly} processPost={processPost} />
      )}
    </div>
    
    <ReferralInterestDialog />

  </div>
  );
}

export default DMChat;
