import lazyLoad from 'app/shared-components/util/lazyLoad';

const LocationProfile = lazyLoad(() => import('app/shared-components/organization/LocationProfile'));

const LocationProfileAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/location/:locationId',
      element: <LocationProfile />,
    },
  ],
};

export default LocationProfileAppConfig;
