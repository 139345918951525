import { Icon } from '@mui/material';
import Button from 'app/shared-components/marketing/Button';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTestimonials, selectTestimonials } from 'app/store/marketing/testimonialsSlice';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import TestimonialPreview from 'app/shared-components/marketing/TestimonialPreview';
import PreviewSlider from 'app/shared-components/marketing/PreviewSlider';
import { useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import _ from '@lodash';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import { showMessage } from 'app/store/fuse/messageSlice';
import {  startLoading, stopLoading} from 'app/store/utilSlice'; 
import { errorMessage } from'app/shared-components/util/message';
import LoadingBackdrop from 'app/shared-components/layout/LoadingBackdrop';
import ControlledTextField from 'app/shared-components/form/ControlledTextField';
import ControlledCheckbox from 'app/shared-components/form/ControlledCheckbox';
import ControlledDatePicker from 'app/shared-components/form/ControlledDatePicker';
import DefaultButton from 'app/shared-components/layout/DefaultButton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { motion } from 'framer-motion';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextBubble from 'app/shared-components/marketing/TextBubble';
import HeaderTags from 'app/shared-components/helmet/HeaderTags';

const features = [
  {
    id: 0,
    title: 'Find Care',
    body: 'Halespring can help find the care that works best for you, including group therapy and higher leve-of-care programs. Submit a request for care and get responses from relevant providers.',
    icon: 'search'
  },
  {
    id: 1,
    title: 'Provider Collaboration',
    body: 'Efficiently collaborate with your providers, share information and documents securely, and communicate in real-time. End the frustration of trying to coordinate multiple care providers through text, email, and phone.',
    icon: 'chat'
  },
  {
    id: 2,
    title: 'Private',
    body: "Your personal information will not be disclosed unless you decide to. Anything you share with your providers is protected and covered by HIPAA. And we don't sell your data, ever.",
    icon: 'lock'
  },
  {
    id: 3,
    title: 'Connect and Share',
    body: 'Find helpful resources, and share experiences with other patients and families. Join support and community groups tailored to your needs.',
    icon: 'share'
  },
]

function getFeatureBgClasses(i) {
  switch (i % 4) {
    case 0: return { bg: 'bg-hs-blue-pale', icon: 'bg-hs-blue-light' };
    case 2: return { bg: 'bg-hs-green-light', icon: 'bg-hs-green' };
    default: return { bg: 'bg-hs-grey-light', icon: 'bg-hs-grey' }
  }
}


const LAUNCH_SUBSCRIPTION_NAME= "Launch Updates";
const SUPPORT_SUBSCRIPTION_NAME= "News and Offers";
const LEGAL_BASIS_INTERESTED_LEAD= "LEGITIMATE_INTEREST_PQL";


/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup.string().email('You must enter a valid email').required('You must enter a email'),
  firstName: yup.string().required('Please enter your first name'),
}, []);

const defaultValues = {
  email: '',
  firstName: '',
};


export default function Patients() {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [submitted, setSubmitted] = useState(false);
  const email= searchParams.get("email");
  const initialValues =  email ? { email: email } : undefined;
  const signupRef = useRef(null);
  
  const { control, formState, handleSubmit, setError, reset } = useForm({
    mode: 'onChange',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  
  useEffect(() => {
    if (initialValues) {
        reset( initialValues );
    }
  }, [initialValues]);
  
  
  function onSubmit({ email, firstName }) {
	   dispatch(startLoading());
	   const apiName = 'PublicAPI2';
	   const path = '/x2/emailsubscription/public/create';
	   const interests= ["patients"];
	   const subscriptionNames= [SUPPORT_SUBSCRIPTION_NAME];

	   const options = {
			  body: { type: "nonProvider",
			          interests: interests,
			          firstName: firstName,
			          subscriptionNames: subscriptionNames,
			          legalBasis: LEGAL_BASIS_INTERESTED_LEAD,
			          legalBasisExplanation: "User provided email and opted-in to communications.",
			          email: email},
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		 };

  	  apiPut(apiName, path, options)
		    .then(resp => setSubmitted(true))
		    .catch(error => errorMessage(dispatch, error.message))
		    .finally(() => dispatch(stopLoading()));
  }

  useEffect(() => {
    dispatch(fetchTestimonials({
      pagination: {
        page: 1,
        pageSize: 3
      },
      sort: ['publishedAt:desc']
    }));
  }, [])

  const testimonials = useSelector(selectTestimonials());

  const testimonialSlider = useRef(null);
  
  const host = window.location.protocol + '//' + window.location.host;
  const orgImage=  host + '/assets/images/logo/HALESPRING-HEADER-191.jpg';

  return (
    <>
      <HeaderTags title="Halespring for Patients: Coming Soon" 
                  description="An online community for the mental health ecosystem, offering essential tools for professionals, patients, and loved ones." 
                  keywords="Mental Health,Therapist,Therapy,Community,Referrals,Pyschologist,Psychiatrist"
                  image={orgImage}
      />
    
    <div className="pt-48">
      {/* Hero */}
      <section>
        <div className="container">
          <h1 className="h3 lg:h1 text-center">
            Coming Soon for Patients! 
          </h1>

          <p className="body lg:body-lg text-center mt-12 mb-12 lg:mt-20">
            The Halespring platform will help patients and caretakers find the most relevant care, and communicate more effectively with their mental health care providers.
          </p>
        </div>

        <div className="relative mt-20">
          <div className="bg-hs-white absolute bottom-0 left-0 w-full h-1/2 z-0" />

          <div className="container">
            <div className="grid grid-cols-2 lg:grid-cols-4 lg:grid-rows-2 lg:grid-flow-col items-center gap-20 relative z-10">
              {/* <img className="w-full hidden lg:block lg:order-0" src="/assets/images/marketing/providers/early-access.svg" /> */}
              <TextBubble
                className="w-full hidden lg:block lg:order-0"
                shape="onePoint"
                label={['Provider', 'Search']}
                shapeClass="rotate-180"
              />
              {/* <img className="w-full order-1 lg:order-1" src="/assets/images/marketing/providers/request.svg" /> */}
              <TextBubble
                className="w-full order-1 lg:order-1"
                shape="twoPoints"
                label={['Support', 'And Resources']}
                shapeClass="fill-hs-green"
              />
              <img className="w-full col-span-2 row-span-2 rounded-20 order-0 lg:order-2" src="/assets/images/marketing/patients/family.jpg" />
              {/* <img className="w-full hidden lg:block lg:order-3" src="/assets/images/marketing/providers/reserve.svg" /> */}
              <TextBubble
                className="w-full hidden lg:block lg:order-3"
                shape="twoPoints"
                label={['Find Groups', 'And Programs']}
                shapeClass="fill-hs-green"
              />
              {/* <img className="w-full order-2 lg:order-4" src="/assets/images/marketing/providers/pros.svg" /> */}
              <TextBubble
                className="w-full order-2 lg:order-4"
                shape="onePoint"
                label={['Secure', 'And Private']}
              />
            </div>
          </div>
        </div>
      </section>

      {/* Features */}
      <section className="bg-hs-white">
        <div className="container py-40">
          <h2 className="h3 lg:h2 text-center uppercase">
            Our Features
          </h2>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 mt-40">
            {features.map((feature, i) => {
              const bgClasses = getFeatureBgClasses(i);

              return (
                <article
                  key={feature.id}
                  className={classNames([
                    'p-20 lg:p-40 pb-72 lg:pb-120 rounded-20',
                    bgClasses.bg
                  ])}
                >
                  <div className="flex justify-between items-start">
                    <h3 className="h3 lg:h2">
                      {feature.title}
                    </h3>

                    <div className={classNames([
                      'w-60 h-60 lg:w-80 lg:h-80 flex justify-center items-center rounded-full text-hs-white flex-none ml-20',
                      bgClasses.icon
                    ])}>
                      <Icon>{feature.icon}</Icon>
                    </div>
                  </div>

                  <p className="body-md lg:body-lg mt-28">
                    {feature.body}
                  </p>
                </article>
              );
            })}
          </div>
        </div>
      </section>

      {/* Sign Up */}
      <section className="relative bg-hs-blue-pale">
        <div className="absolute z-0 top-0 left-0 w-full h-1/2 bg-hs-white" />

        <div className="lg:container">
          <div className="relative z-10 bg-hs-blue lg:rounded-20 text-hs-white text-center px-16 py-60 lg:pt-92 lg:pb-68 ">
            <h2 className="h2 uppercase">
              Learn More
            </h2>

            <p className="body mt-20 lg:mt-30">
              Halespring for patients and families is coming soon. Please provide your email below to get updates, 
              and/or opportunities to support Halespring's mission.
            </p>

            <div className="flex w-full items-start justify-center">
             { !submitted &&
             <Card className="max-w-md min-w-350 mt-36 p-20">
               
               <CardContent className="px-32 items-center">
              
                <form
                  name="emailSignupForm"
                  id="emailSignupForm"
                  noValidate
                  className="flex flex-col items-start justify-center w-full mt-10"
                  onSubmit={handleSubmit(onSubmit)}
                >
              
                  <ControlledTextField key="email" name="email" label="Email" type="email"
                           control={control} errors={errors}
           				  required fullWidth />
           				  
                  <ControlledTextField key="firstName" name="firstName" label="First Name" type="text"
                           control={control} errors={errors}
           				  fullWidth />

                  <div className="flex w-full items-center justify-center">
                    <DefaultButton text="I'm In!" id="emailSignupButton"
                        disabled={ _.isEmpty(dirtyFields) || !isValid}
                        submit />
                  </div>
                </form>
       
              </CardContent>
            </Card>
            }
            
            { submitted &&
            <Card className="max-w-md min-w-350 mt-36 p-20">
            
                <CardContent className="px-32 items-center">
                
                    <Typography className="text-3xl font-extrabold tracking-tight leading-tight">
                      Thank you! Stay tuned for updates!
                    </Typography>
    
                </CardContent>
            </Card>
            }
           </div>
          </div>
        </div>
      </section>
      

      {/* Testimonials */}
      <section className="bg-hs-blue-pale py-80">
        <div className="container">
          <PreviewSlider
            title="How we Help"
          >
            {testimonials.map((testimonial) =>
              <TestimonialPreview
                key={testimonial.id}
                testimonial={testimonial}
              />
            )}
          </PreviewSlider>
        </div>
      </section>
    </div>
    </>
  );
}