import { Typography, Stack} from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { CheckBadgeIcon, ShieldCheckIcon} from '@heroicons/react/24/solid'

function UserName(props) {
  let { user, className, style, sx, component, to, color, paragraph} = props;
  if (user.raw) user= user.raw;
  const isHalespring= user.status === 'staff';
  const isVerified= user.status === 'verified';
  const isIdentified= user.status === 'identified' || user.status === 'indent_verified';
  const isEnhanced= user.providerInfo?.enhancedProfile;
  const isProvider= user.type == 'provider';
  const showEnhanced= isVerified && !isHalespring && isEnhanced;
  const showBadge= isVerified && !isHalespring;
  const preferences= user.providerInfo?.namePreferences ? user.providerInfo.namePreferences : "prefix,suffix";
  const hasCredentials= user.providerInfo?.credentials && user.providerInfo.credentials.length > 0;
  const hasCertifications= user.providerInfo?.certifications && user.providerInfo.certifications.length > 0;
  const credentials= hasCredentials && preferences.includes("credentials") ? " " + user.providerInfo.credentials.join()  : "";
  const certifications= hasCertifications && preferences.includes("certifications") ?  (credentials ? "," : " ") + user.providerInfo.certifications.join()  : "";
  const prefix= user.prefix && preferences.includes("prefix") ? user.prefix + " " : "";
  const suffix= user.suffix && preferences.includes("suffix") ? " " + user.suffix : "";
  
  return (
	  <Stack direction="row" alignItems="center">
		  <Typography
	              sx={sx}
	              className={className}
	              style={style}
	              component={component}
	              to={to}
	              color={color}
	              paragraph={paragraph}
	            >
	              {prefix + user.firstName + " " + user.lastName + suffix + credentials + certifications} 
	              
	      </Typography> 
	      
	      {showBadge && <ShieldCheckIcon className="h-16 w-16 text-slate-500" />}
	      {showEnhanced && <CheckBadgeIcon className="h-16 w-16 text-blue-500" />}
	      {isHalespring && <div><img className="w-20 h-20" src="assets/images/logo/HALESPRING-LOGO-100.png" alt="logo" /></div>}
      </Stack>
  );
  
}


export default UserName;