import lazyLoad from 'app/shared-components/util/lazyLoad';

const ReferralsApp= lazyLoad(() => import('./ReferralsApp'));
const ReferralForm= lazyLoad(() => import('./ReferralForm'));
const InterestChat= lazyLoad(() => import('./InterestChat'));
const InterestChatMulti= lazyLoad(() => import('./InterestChatMulti'));
const ReferralDetails= lazyLoad(() => import('./ReferralDetails'));

const ReferralsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/referrals',
      element: <ReferralsApp />,
      children: [
        {
          path: ':id',
          element: <ReferralForm />,
        },
        {
          path: 'chat/:id',
          element: <InterestChatMulti />,
        },
        {
          path: 'closed',
          element: <div/>,
        },
        {
          path: 'closed/chat/:id',
          element: <InterestChatMulti />,
        },
         {
          path: 'closed/details/:id',
          element: <ReferralDetails/>,
        },
        {
          path: 'pending',
          element: <div/>,
        },
        {
          path: 'pending/chat/:id',
          element: <InterestChatMulti />,
        },
         {
          path: 'pending/details/:id',
          element: <ReferralDetails/>,
        },
        {
          path: 'interest',
          element: <div/>,
        },
        {
          path: 'previous',
          element: <div/>,
        },
        {
          path: 'interest/chat/:id',
          element: <InterestChat/>,
        },
        {
          path: 'interest/details/:id',
          element: <ReferralDetails/>,
        },
        {
          path: 'previous/chat/:id',
          element: <InterestChat/>,
        },
        {
          path: 'previous/details/:id',
          element: <ReferralDetails/>,
        },
        {
          path: 'search',
          element: <div/>,
        },
        {
          path: 'search/details/:id',
          element: <ReferralDetails/>,
        },
        {
          path: 'notifications',
          element: <div/>,
        },
        {
          path: 'notifications/details/:id',
          element: <ReferralDetails/>,
        },
      ],
    },
  ],
};

export default ReferralsAppConfig;
