import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import axios from 'axios';


import { dispatchResultMessage } from 'app/shared-components/utils.js';
 
 
export default async function uploadFile(file, entityId, fileType, channelId) 
{
	console.log("Uploading file:");
	console.log(file);
	
    const apiName = 'CoreAPI';
	const path = '/uploadlink/';
	let options = {
	  headers: {},
	  body: {
		  type: fileType,
		  entityId: entityId,
		  channelId: channelId
	  },
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	};

    //Request the upload link from the server
	let uploadLink= await apiPut(apiName, path, options);
	if (uploadLink.signedURL){
		 console.log('Got upload link from server with fileId: ' + uploadLink.fileId);
		 
		 //Create axios call to put to S3 with signed url
		 options = {
	        headers: {
	          'Content-Type': file.type
	        }
	     }
	     let axiosResponse= await axios.put(uploadLink.signedURL, file, options);
	     console.log(axiosResponse);
	     
	     //Return the fileId of the successfully uploaded file
	     return uploadLink.fileId;
	}
}