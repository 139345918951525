import IconButton from '@mui/material/IconButton';
import React, { useState, useRef} from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { lighten } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import { UserAutocomplete } from '../user/autocomplete/UserAutocomplete';
import { replaceAt, dispatchResultMessage } from 'app/shared-components/utils.js';
import { startLoading, stopLoading, selectIsLoading} from 'app/store/utilSlice';
import { submitUpdatePost } from 'app/store/postSlice';
import { useSelector, useDispatch } from 'react-redux';

import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation
} from "emoji-picker-react";

function ChatEdit({postId, rootChannelId, originalText, closeEdit, isDM}) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const emojiOpen = Boolean(anchorEl);
  const emojiPickerId = emojiOpen ? 'emoji-picker' : undefined;
  const [messageText, setMessageText] = useState(originalText);
  const [cursorPosition, setCursorPosition] = useState();
  const isLoading= useSelector(selectIsLoading)
  
  const handleEmojiIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleEmojiIconClose = () => {
    setAnchorEl(null);
  };
  
  const handleEnterPress= (event) => {
	  event.stopPropagation();
	  event.preventDefault();
	  handleSubmitPost();
  }
  
  function onSelectEmoji(emojiData, event) {
    let sym= emojiData.unified;
    let codesArray = [];
    codesArray.push("0x" + sym);
    let emoji = String.fromCodePoint(...codesArray);
    const newValue = replaceAt(
        messageText,
        emoji,
        cursorPosition
    );
    setMessageText(newValue);
    handleEmojiIconClose();
  }
  
  function handleSubmitPost(){
	 if (isLoading || !messageText || messageText === "" || messageText === originalText) return;
	 dispatch(startLoading());
	 try{
        dispatch(submitUpdatePost({postId: postId, messageText: messageText})).then((resp) => {
			dispatchResultMessage(dispatch, resp);
		});  
		closeEdit();
	 }catch(err){
		 console.log(err);
		 dispatch(stopLoading());
	 }
  }
  
	
  return (
	  
	  <Paper
          square
          className="py-6 px-10"
          style={{width: '100%'}}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? lighten(theme.palette.background.default, 0.4)
                : lighten(theme.palette.background.default, 0.02),
          }}
        >
          <div className="flex items-center relative">
            <IconButton className="" type="submit" size="small" onClick={handleEmojiIconClick}>
              <FuseSvgIcon className="text-24" color="action">
                heroicons-outline:emoji-happy
              </FuseSvgIcon>
            </IconButton>
            
			<div style={{ backgroundColor: 'white', maxHeight: '100px' }} className="flex-1 flex grow shrink-0 mx-8 px-16 border-2 rounded-full">
			   <UserAutocomplete mode='channel' 
        				  channelId={rootChannelId}
                          value={messageText} 
                          setTextValue={setMessageText}
                          onEnterPress={handleEnterPress}
                          setCursorPosition={setCursorPosition}/>
            </div>
	       
            
            <IconButton type="submit" size="large" onClick={closeEdit}>
              <FuseSvgIcon color="action">
                heroicons-outline:x
              </FuseSvgIcon>
            </IconButton>
            
            <IconButton type="submit" size="large" onClick={handleSubmitPost}>
              <FuseSvgIcon className="rotate-90" color="action">
                heroicons-outline:paper-airplane
              </FuseSvgIcon>
            </IconButton>
          </div>
          
          <Popover
	        id={emojiPickerId}
	        open={emojiOpen}
	        anchorEl={anchorEl}
	        onClose={handleEmojiIconClose}
	        anchorOrigin={{
			    vertical: 'top',
			    horizontal: 'left',
			}}
			transformOrigin={{
			    vertical: 'bottom',
			    horizontal: 'left',
			}}
	      >
	          <EmojiPicker
		        onEmojiClick={onSelectEmoji}
		        autoFocusSearch={false}
		        width="100%"
		        emojiStyle={EmojiStyle.NATIVE}
		        // theme={Theme.AUTO}
		        // searchDisabled
		        // skinTonePickerLocation={SkinTonePickerLocation.PREVIEW}
		        // height={350}
		        // width="50%"
		        // emojiVersion="0.6"
		        // lazyLoadEmojis={true}
		        // previewConfig={{
		        //   defaultCaption: "Pick one!",
		        //   defaultEmoji: "1f92a" // 🤪
		        // }}
		        // suggestedEmojisMode={SuggestionMode.RECENT}
		        // skinTonesDisabled
		        // searchPlaceHolder="Filter"
		        // defaultSkinTone={SkinTones.MEDIUM}
		        // emojiStyle={EmojiStyle.NATIVE}
		      />
	    </Popover>

      </Paper>  
  );
}

export default ChatEdit;
