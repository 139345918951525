import lazyLoad from 'app/shared-components/util/lazyLoad';

const PublicPost = lazyLoad(() => import('./PublicPost'));

const PublicPostConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null, //anyone
  routes: [
    {
      path: 'public/post/:id',
      element: <PublicPost />,
    },
  ],
};

export default PublicPostConfig;


