import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { stopLoading, startLoading} from 'app/store/utilSlice';
import { setChannelRead, setChannelUnread } from './channelsSlice';
import { addDmChannel, addDmChannels, removeDmChannel } from './dmChannelSlice';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import sortComparer from 'app/shared-components/util/createdAtSortComparer';


const recAdapter = createEntityAdapter({
	sortComparer: sortComparer,
	selectId: (reco) => reco.sourceUserId,
});

const givenRecAdapter = createEntityAdapter({
	sortComparer: sortComparer,
	selectId: (reco) => reco.targetUserId,
});

const emptyArray= [];


export const fetchRecommendationsByTarget = createAsyncThunk(
  "userRecommendations/fetchRecommendationsByTarget",
  async ({contactId}, { dispatch, getState }) => {
	dispatch(startLoading());
	try{
      const apiName = 'CoreAPI2';
	  const path = '/x2/userx/' + contactId + '/userrecommendation/bytarget/';
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  const data= await apiGet(apiName, path, options);
	  await dispatch(intializeRecommendationsForUser({contactId: contactId}));
	  return data;
	}finally{
	  dispatch(stopLoading());
	}
  }
);

export const fetchGivenRecommendations = createAsyncThunk(
  "userRecommendations/fetchGivenRecommendations",
  async (params, { dispatch, getState }) => {
	dispatch(startLoading());
	try{
	  const { user }= getState();
      const apiName = 'CoreAPI2';
	  const path = '/x2/userx/' + user.id + '/userrecommendation/bysource/';
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  const data= await apiGet(apiName, path, options);
	  return data;
	}finally{
	  dispatch(stopLoading());
	}
  }
);

export const fetchReceivedRecommendations = createAsyncThunk(
  "userRecommendations/fetchReceivedRecommendations",
  async (params, { dispatch, getState }) => {
	dispatch(startLoading());
	try{
	  const { user }= getState();
      const apiName = 'CoreAPI2';
	  const path = '/x2/userx/' + user.id + '/userrecommendation/bytarget/';
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  const data= await apiGet(apiName, path, options);
	  return data;
	}finally{
	  dispatch(stopLoading());
	}
  }
);

export const fetchGivenRecommendation = createAsyncThunk(
  "userRecommendations/fetchGivenRecommendation",
  async ({contactId, refresh}, { dispatch, getState }) => {
	dispatch(startLoading());
	try{
	  const { user, userRecommendations }= getState();
	  
	  if(!refresh){
		  const entry= userRecommendations.givenRecommendations.entities[contactId];
		  if (entry) return entry;
	  } 
	  
      const apiName = 'CoreAPI2';
	  const path = '/x2/userx/' + user.id + '/userrecommendation/' + contactId;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  let data;
	  try{
	  	data= await apiGet(apiName, path, options);
	  }catch(err){
	  	console.log(err);
	  }
	  if (!data){
	  	 data= { sourceUserId: user.id, targetUserId: contactId, notFound: true };
	  }
	  return data;
	}finally{
	  dispatch(stopLoading());
	}
  }
);

export const createRecommendation = createAsyncThunk(
  "userRecommendations/createRecommendation",
  async ({ contactId, text }, { dispatch, getState }) => {
	  dispatch(startLoading());
	  try{
		  const { user }= getState();
	      const apiName = 'CoreAPI2';
	      const path = '/x2/userx/' + user.id + '/userrecommendation/';
		  const body= { sourceUserId: user.id, targetUserId: contactId, text: text};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 const data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const updateRecommendation = createAsyncThunk(
  "userRecommendations/updateRecommendation",
  async ({ contactId, text }, { dispatch, getState }) => {
	  dispatch(startLoading());
	  try{
		  const { user }= getState();
	      const apiName = 'CoreAPI2';
	      const path = '/x2/userx/' + user.id + '/userrecommendation/' + contactId;
		  const body= { text: text};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 const data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const updateVisibility = createAsyncThunk(
  "userRecommendations/updateVisibility",
  async ({ contactId, isVisible }, { dispatch, getState }) => {
	  dispatch(startLoading());
	  try{
		  const { user }= getState();
	      const apiName = 'CoreAPI2';
	      const path = '/x2/userx/' + contactId + '/userrecommendation/' + user.id;
		  const body= { visible: isVisible};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 const data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)


export const deleteRecommendation = createAsyncThunk(
  "userRecommendations/deleteRecommendation",
  async ({ contactId }, { dispatch, getState }) => {
	  dispatch(startLoading());
	  try{
		  const { user }= getState();
	      const apiName = 'CoreAPI2';
	      const path = '/x2/userx/' + user.id + '/userrecommendation/' + contactId;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 const data= await apiDel(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)



export const { selectById: selectGivenById, selectAll: selectAllGiven } = givenRecAdapter.getSelectors(state => state.userRecommendations.givenRecommendations);
export const { selectById: selectReceivedById, selectAll: selectAllReceived } = recAdapter.getSelectors(state => state.userRecommendations.receivedRecommendations);
const { selectById, selectAll } = recAdapter.getSelectors();

export const selectRecommendationsByContactId= (contactId) => (state) => {
	console.log("Select recommendations for user " + contactId);
	const useState= state.userRecommendations.recommendationsForUser[contactId];
	console.log(useState);
	if (useState) return selectAll(useState);
	else return emptyArray;
}

export const selectRecommendationBySourceAndContactId= (sourceId, contactId) => (state) => {
	if (!contactId || !sourceId) return null;
	const useState= state.userRecommendations.recommendationsForUser[contactId];
	if (useState) return selectById(useState, sourceId);
	else return emptyArray;
}

export const selectGivenRecommendationByContactId= (contactId) => (state) => {
	if (!contactId) return null;
	else return selectGivenById(state, contactId);
}

const userRecommendationsSlice = createSlice({
  name: "userRecommendations",
  initialState: { 
  	recommendationsForUser: {},
  	givenRecommendations: givenRecAdapter.getInitialState({status: 'idle'}),
  	receivedRecommendations: recAdapter.getInitialState({status: 'idle'}),
  },
  reducers: {
	 intializeRecommendationsForUser:  (state, action) => {
	 	const contactId = action.payload.contactId;
	 	if (!state.recommendationsForUser[contactId]){
	 		const newRecoState= {...state.recommendationsForUser};
    		newRecoState[contactId]= recAdapter.getInitialState();
    		state.recommendationsForUser= newRecoState;
    		console.log("Initializing recommendations entry for user " + contactId);
	 	}
	},
  },
  extraReducers: builder => {
    builder
    .addCase(fetchRecommendationsByTarget.fulfilled, (state, action) => {
    	const contactId = action.meta.arg.contactId;
    	let entry= state.recommendationsForUser[contactId];
    	console.log("fetchRecommendationsByTarget fulfilled for user " + contactId);
    	console.log(state.recommendationsForUser);
        recAdapter.setMany(entry, action.payload);
        console.log(state.recommendationsForUser);
    })
    .addCase(fetchGivenRecommendations.fulfilled, (state, action) => {
    	givenRecAdapter.setMany(state.givenRecommendations, action.payload);
    })
    .addCase(fetchGivenRecommendation.fulfilled, (state, action) => {
    	givenRecAdapter.setOne(state.givenRecommendations, action.payload);
    })
    .addCase(fetchReceivedRecommendations.fulfilled, (state, action) => {
        recAdapter.setMany(state.receivedRecommendations, action.payload);
    })
    .addCase(createRecommendation.fulfilled, (state, action) => {
        givenRecAdapter.setOne(state.givenRecommendations, action.payload);
    })
    .addCase(updateRecommendation.fulfilled, (state, action) => {
        givenRecAdapter.setOne(state.givenRecommendations, action.payload);
    })
    .addCase(updateVisibility.fulfilled, (state, action) => {
        recAdapter.upsertOne(state.receivedRecommendations, action.payload);
    })
    .addCase(deleteRecommendation.fulfilled, (state, action) => {
    	const contactId = action.meta.arg.contactId;
        givenRecAdapter.removeOne(state.givenRecommendations, contactId);
    })
  }
})

const { intializeRecommendationsForUser }= userRecommendationsSlice.actions;
export default userRecommendationsSlice.reducer;
