import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useState } from 'react';


function NestedMenuItem({ icon, unread, text, items, onItemClick }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleItemClick(item){
	 item.onClick ? item.onClick(item) : onItemClick(item);
	 handleClose();
  }

  return (
    <div>
      <MenuItem onClick={handleClick}>
          {icon &&
          <ListItemIcon className="min-w-40">
            <Badge color="secondary" variant="dot" invisible={!unread}>
                <FuseSvgIcon>{icon}</FuseSvgIcon>
	        </Badge>
          </ListItemIcon>
          }
          <ListItemText primary={text} />
          <ListItemIcon className="min-w-40">
            <Badge color="secondary" variant="dot" invisible={!unread}>
                <FuseSvgIcon>heroicons-outline:chevron-right</FuseSvgIcon>
	        </Badge>
          </ListItemIcon>
      </MenuItem>	
      <Menu
        id="nested-menu"
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{
	      vertical: 'center',
	      horizontal: 'right',
	    }}
	    transformOrigin={{
	      vertical: 'center',
	      horizontal: 'left',
	    }}
        onClose={handleClose}
      >
        {items && items.map((item) => (
		<MenuItem key={item.key} onClick={() => handleItemClick(item)}>
		  <ListItemText primary={item.text} />
		  {item.icon &&
          <ListItemIcon className="min-w-40">
            <Badge color="secondary" variant="dot" invisible={!item.unread}>
                <FuseSvgIcon>{item.icon}</FuseSvgIcon>
	        </Badge>
          </ListItemIcon>
          }
        </MenuItem>	 
		 ))}	  
      </Menu>
    </div>
  );
}

export default NestedMenuItem;
