import { styled } from '@mui/material/styles';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';

const Root = styled('div')(({ theme }) => ({
  '& > .logo-icon': {
    transition: theme.transitions.create(['width', 'height'], {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
  '& > .badge': {
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shortest,
      easing: theme.transitions.easing.easeInOut,
    }),
  },
}));



function BrandName({target}) {
  const useTarget= target ? target : '/';
  return (
    <Root className="flex items-center">
      <NavLinkAdapter to={useTarget}>
      	<img className="logo-icon h-32" src="assets/images/logo/HALESPRING-TEXT.png" alt="logo" />
      </NavLinkAdapter>
    </Root>
  );
}

export default BrandName;
