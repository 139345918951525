import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { darken } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';


function BAAPageContent() {


  return (
    
        <div className="flex flex-col items-center">
        
        <div className="flex flex-col items-center max-w-md"> 
          <div>
            <Typography className="mb-24 text-2xl sm:text-4xl font-semibold tracking-tight leading-tight text-center">
              Last Updated: July 8, 2024
            </Typography>
          </div>
          
          <div>
            <Typography className="mt-4 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
              1. Introduction
            </Typography>
          </div>

          <div className="w-full">
            <Typography
              className="mt-12 sm:text-2xl text-left tracking-tight"
              color="text.secondary"
            >
              <p className="mb-12">
              This BUSINESS ASSOCIATE AGREEMENT (“BAA”), is made and entered into at the date and time your Halespring account is created 
              (“Effective Date”) and is between you (“Covered Entity”) and Halespring Public Benefit Corporation (“Business Associate”). 
              This BAA, which supersedes any previous business associate agreement between the parties, amends, supplements, and is made a
              part of the <a href="/pages/terms">Terms of Service</a>, by and between Covered Entity and Business Associate, as the same may be amended from time to time
              (the “Agreement”).
              </p>
            </Typography>
          </div>
          
          <div>
            <Typography className="mt-4 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
              2. Recitals
            </Typography>
          </div>

          <div className="w-full">
            <Typography
              className="mt-12 sm:text-2xl text-left tracking-tight"
              color="text.secondary"
            >
              <p className="mb-12">
              WHEREAS, Covered Entity is a “covered entity” as that term is defined at 45 C.F.R. § 160.103;
              </p>
              <p className="mb-12">
              WHEREAS, in connection with Business Associate providing services to Covered Entity pursuant to the Agreement, Business Associate may, on behalf of Covered Entity, create, receive, maintain, or transmit certain Protected Health Information (“PHI”) as defined below;
              </p>
              <p className="mb-12">
              WHEREAS, Covered Entity and Business Associate intend to protect the privacy and provide for the security of PHI in compliance with the Health Insurance Portability and Accountability Act of 1996, Subtitle D of the Health Information Technology for Economic and Clinical Health Act of 2009 (“HITECH”), and the regulations and other guidance promulgated under both laws by the U.S. Department of Health and Human Services (collectively, “HIPAA”), as well as other applicable federal and state laws;
              </p>        
              <p className="mb-12">
              WHEREAS, the purpose of this BAA is to satisfy certain standards and requirements of HIPAA including, but not limited to, 45 C.F.R. §§ 164.308(b), 164.314(a), 164.502(e), and 164.504(e); and
              </p>
              <p className="mb-12">
              WHEREAS, this BAA shall be applicable only in the event that Business Associate meets, with respect to Covered Entity, the definition of “business associate” set forth in 45 C.F.R. § 160.103.
              </p>              
              <p className="mb-12">
              NOW, THEREFORE, in consideration of the mutual promises below and the exchange of information pursuant to this BAA, the parties agree as follows:
              </p>
            </Typography>
          </div>
          
          <div>
            <Typography className="mt-20 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
              3. Definitions
            </Typography>
          </div>
          
          <div className="w-full">
            <Typography
              className="mt-12 sm:text-2xl text-left tracking-tight"
              color="text.secondary"
            >
              <p className="mb-12">
              “Breach” shall have the meaning given to the term “breach” at 45 C.F.R. § 164.402, as applied to the Unsecured PHI created, received, maintained, or transmitted by Business Associate from or on behalf of Covered Entity.
              </p>
              <p className="mb-12">
              “Data Aggregation” shall have the same meaning as the term “data aggregation” in 45 CFR § 164.501.
              </p>
              <p className="mb-12">
              “Designated Record Set” shall have the meaning given to such term in 45 CFR § 164.501.
              </p>        
              <p className="mb-12">
              “Electronic Protected Health Information” or “ePHI” shall have the meaning given to the term “electronic protected health information” at 45 C.F.R. § 160.103, as applied to the information created, received, maintained, or transmitted by Business Associate from or on behalf of Covered Entity.
              </p>
              <p className="mb-12">
              “Individual” shall have the meaning given to such term at 45 C.F.R. § 160.103, and shall include a person who qualifies as a personal representative in accordance with 45 C.F.R. § 164.502(g).
              </p>              
              <p className="mb-12">
              “Privacy Rule” shall mean the Standards for Privacy of Individually Identifiable Health Information at 45 C.F.R. Part 160 and Part 164, Subparts A and E, as amended.
              </p>
              <p className="mb-12">
              “Protected Health Information” or “PHI” shall have the meaning given to the term “protected health information” at 45 C.F.R. § 160.103, as applied to the information created, received, maintained, or transmitted by Business Associate from or on behalf of Covered Entity.
              </p>
              <p className="mb-12">
              “Reportable Event” means any (1) use or disclosure of PHI not provided for by this BAA; (2) Security Incident; or (3) Breach of Unsecured PHI.
              </p>
              <p className="mb-12">
              “Required by Law” shall have the meaning given to the term “required by law” at 45 C.F.R. § 164.103.
              </p>
              <p className="mb-12">
              “Secretary” shall mean the Secretary of the U.S. Department of Health and Human Services or his or her designee.
              </p>
              <p className="mb-12">
              “Security Incident” shall have the meaning given to the term “security incident” at 45 C.F.R. § 164.304, as applied to the ePHI created, received, maintained, or transmitted by Business Associate from or on behalf of Covered Entity.
              </p>
              <p className="mb-12">
              “Security Rule” shall mean the Security Standards for the Protection of Electronic Protected Health Information at 45 C.F.R. Part 160 and Part 164, Subparts A and C, as amended.
              </p>
              <p className="mb-12">
              “Subcontractor” shall have the meaning given to the term “subcontractor” at 45 C.F.R. § 160.103.
              </p>
              <p className="mb-12">
              “Unsecured PHI” shall have the meaning given to the term “unsecured protected health information” at 45 C.F.R. § 164.402, as applied to the information created, received, maintained, or transmitted by Business Associate from or on behalf of Covered Entity.
              </p>
              <p className="mb-12">
              Any other capitalized terms used, but not otherwise defined, in this BAA shall have the same meaning as those terms in HIPAA. Any inconsistency in the definition of a term shall be resolved in favor of a meaning that permits compliance with HIPAA.
              </p>
            </Typography>
          </div>
          
          <div>
            <Typography className="mt-20 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
              4. Permitted Uses and Disclosures of PHI
            </Typography>
          </div>
          
          <div className="w-full">
            <Typography
              className="mt-12 sm:text-2xl text-left tracking-tight"
              color="text.secondary"
            >
              <p className="mb-12">
              Except as otherwise limited in this BAA or the Agreement, Business Associate may do any or all of the following:
              </p>
              
              <p className="mb-12">
              1. Use or Disclosure Under the Agreement. Use or disclose PHI to perform functions, activities, or services for, or on behalf of Covered Entity, to the extent permitted in the Agreement, provided that such use or disclosure would not violate the Privacy Rule or any applicable state law if done by Covered Entity. Notwithstanding the above, Business Associate may also use and disclose PHI for the purposes identified in the paragraphs below.
              </p>
              <p className="mb-12">
              2. Use for Administration or Legal Responsibilities. Use PHI for the proper management and administration of Business Associate or to carry out the legal responsibilities of Business Associate.
              </p>        
              <p className="mb-12">
              3. Disclosure for Administration or Legal Responsibilities. Disclose PHI for the proper management and administration of Business Associate or to carry out the legal responsibilities of Business Associate, provided that:
              </p>
              <p className="mb-12 ml-24">
              a. The disclosures are Required by Law; or
              </p>              
              <p className="mb-12 ml-24">
              b. Business Associate obtains reasonable assurances from the third party to whom the PHI is disclosed that such information shall remain confidential and shall be used or further disclosed only as Required By Law or for the purpose for which it was disclosed to the person, and such person agrees to promptly notify Business Associate of any instance of which it is aware in which the confidentiality of the information has been breached.
              </p>
              <p className="mb-12">
              4. Use for Reporting of Violations. Use PHI to report violations of law to appropriate federal, state, and local authorities, consistent with 45 C.F.R. § 164.502(j).
              </p>
              <p className="mb-12">
              5. Use for Data Aggregation Services. Use PHI to provide Data Aggregation services relating to the health care operations of Covered Entity, as permitted by 45 C.F.R. §164.504(e)(2)(i)(B).
              </p>
              <p className="mb-12">
              6. De-identified Information. Use PHI to create de-identified information in accordance with 45 C.F.R. §§ 164.502(d) and 164.514(a)-(c). Business Associate may use de-identified information for any purpose so long as it complies with applicable law. 
              </p>
              
            </Typography>
          </div>
          
          <div>
            <Typography className="mt-20 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
              5. Obligations and Activities of Business Associate
            </Typography>
          </div>
          
          <div className="w-full">
            <Typography
              className="mt-12 sm:text-2xl text-left tracking-tight"
              color="text.secondary"
            >
              <p className="mb-12">
              1. Limited by Agreement and Law. Business Associate may not use or disclose PHI other than as permitted or required by this BAA and the Agreement or as Required by Law.
              </p>
              <p className="mb-12">
              2. Compliance with HIPAA. To the extent that Business Associate is responsible for carrying out an obligation of Covered Entity under HIPAA pursuant to this BAA or the Agreement, Business Associate shall comply with the requirements of HIPAA that apply to Covered Entity in the performance of such obligation.
              </p>        
              <p className="mb-12">
              3. Appropriate Safeguards. Business Associate shall use appropriate safeguards and, where applicable, comply with the Security Rule and HITECH with respect to ePHI, to prevent use or disclosure of PHI other than as provided for by this BAA.
              </p>
              <p className="mb-12">
              4. Reportable Events.
              </p>
              <p className="mb-12 ml-24">
              a. Business Associate shall report to Covered Entity, by e-mail or telephone, any Reportable Event of which it becomes aware. All such reports shall be made without unreasonable delay and in no case later than fifteen (15) business days after Business Associate’s discovery of a Reportable Event.
              </p>              
              <p className="mb-12 ml-24">
              b. The notification required hereunder shall include, to the extent possible: (i) the identification of each Individual whose PHI has been, or is reasonably believed by Business Associate to have been, accessed, acquired, used, lost, modified, destroyed, or disclosed during the Reportable Event; (ii) a brief description of what happened, including the date of the Reportable Event and the date of the discovery of the Reportable Event; (iii) a description of the types of PHI involved; (iv) any steps Individuals should take to protect themselves from potential harm resulting from the Reportable Event; (v) a brief description of what Business Associate is doing to investigate, remediate, and respond to the Reportable Event, mitigate harm to Individuals, and protect against further Reportable Events; and (vi) such other information that is reasonably available to Business Associate that Covered Entity would reasonably be expected to need to fulfill its notification obligations with respect thereto. Business Associate shall supplement its initial notification as additional information is obtained.
              </p>
              <p className="mb-12 ml-24">
              c. Business Associate shall cooperate with Covered Entity in investigating a Reportable Event and assist Covered Entity in determining whether a Reportable Event constitutes a Breach of Unsecured PHI.
              </p>  
              <p className="mb-12 ml-24">
              d. Business Associate shall mitigate, to the extent practicable, any harmful effect that is known to Business Associate of a Reportable Event.
              </p>              
              <p className="mb-12 ml-24">
              e. The parties acknowledge and agree that this Section 5.4.e constitutes notice by Business Associate to Covered Entity of the ongoing existence and occurrence of attempted but unsuccessful Security Incidents that do not result in unauthorized access to, or use, loss, modification, destruction, or disclosure of, PHI, such as pings and other broadcast attacks on Business Associate’s firewall, port scans, unsuccessful log-on attempts, unsuccessful denial of service attacks, or any combination thereof.
              </p>
              <p className="mb-12">
              5. Subcontractors. If Business Associate discloses PHI to a Subcontractor or allows a Subcontractor to create, receive, maintain, or transmit PHI on its behalf, Business Associate shall require the Subcontractor to agree to the substantially similar restrictions, conditions, and requirements that apply to Business Associate with respect to such information by entering into a written arrangement with Subcontractor that complies with 45 C.F.R. §§ 164.314(a) and 164.504(e).  
              </p>
              <p className="mb-12">
              6. Access to PHI. Business Associate agrees to provide access via in-app export, to Protected Health Information in a Designated Record Set to Covered Entity or, as directed by Covered Entity, to an Individual, in order to meet Covered Entity’s requirements under 45 CFR § 164.524. Business Associate further agrees, in the case where Business Associate controls access to Protected Health Information in an Electronic Health Record, or controls access to Protected Health Information stored electronically in any format, to provide similar access in order for Covered Entity to meet its requirements of the HIPAA Rules and under Section 13405(c) of the HITECH Act. These provisions do not apply if Business Associate and its employees or Subcontractors have no Protected Health Information in a Designated Record Set of Covered Entity.
              </p>
              <p className="mb-12">
              7. Amendment of PHI. To the extent that Business Associate maintains PHI in a Designated Record Set, Business Associate shall make amendment(s) to such PHI in a Designated Record Set that Covered Entity directs or agrees to in a time and manner that meets the requirements of 45 C.F.R. § 164.526. This provision does not apply if Business Associate and its employees or Subcontractors have no Protected Health Information from a Designated Record Set of Covered Entity.
              </p>
              <p className="mb-12">
              8. Accounting of Disclosures. Business Associate shall provide to Covered Entity an accounting of the disclosures of an Individual’s PHI in a time and manner that meets the requirements of 45 C.F.R. § 164.528 and, as of the applicable effective date, Section 13405(c) of HITECH and any regulations promulgated thereunder. Business Associate shall have a reasonable time within which to comply with such a request from Covered Entity and in no case shall Business Associate be required to provide such documentation in less than ten (10) business days after Business Associate's receipt of such request.
              </p>
              <p className="mb-12">
              9. Response to Requests from Individuals. Except as this BAA or any other agreement between Covered Entity or Business Associate may otherwise provide, in the event Business Associate receives an access, amendment, accounting of disclosure, or other similar request directly from an Individual, Business Associate will redirect the Individual to Covered Entity.
              </p>
              <p className="mb-12">
              10. Governmental Access to Records. Business Associate shall make its internal policies, practices, books, and records relating to the use and disclosure of PHI that is received from, or created or received by Business Associate on behalf of, Covered Entity available to the Secretary for purposes of determining compliance with HIPAA. No attorney-client, accountant-client, or other legal privilege shall be deemed to have been waived by Business Associate by virtue of Business Associate’s compliance with this provision.
              </p>
              <p className="mb-12">
              11. Minimum Necessary. Business Associate agrees that it shall comply with HIPAA’s minimum necessary requirements.
              </p>
              <p className="mb-12">
              12. Communication with Other Business Associates. In connection with the performance of its services, activities, and/or functions to or on behalf of Covered Entity, Business Associate may disclose information, including PHI, to other business associates of Covered Entity. Likewise, Business Associate may use and disclose information, including PHI, received from other business associates of Covered Entity, as if this information was received from, or originated with, Covered Entity. The parties agree that it is the responsibility of Covered Entity to secure and maintain business associate agreements with its other business associates.
              </p>
              
            </Typography>
          </div>
          
          <div>
            <Typography className="mt-20 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
              6. Obligations of Covered Entity
            </Typography>
          </div>
          
          <div className="w-full">
            <Typography
              className="mt-12 sm:text-2xl text-left tracking-tight"
              color="text.secondary"
            >
              <p className="mb-12">
              1. Notice of Privacy Practices. Covered Entity shall notify Business Associate in writing of any limitations in its notice of privacy practices, to the extent that such limitations may affect Business Associate’s use or disclosure of PHI.
              </p>
              <p className="mb-12">
              2. Notification of Revocations. Covered Entity shall notify Business Associate in writing of any changes in, or revocation of, authorization by an Individual to use or disclose PHI, to the extent that such changes or revocation may affect Business Associate’s use or disclosure of PHI.
              </p>        
              <p className="mb-12">
              3. Notification of Restrictions. Covered Entity shall notify Business Associate in writing of any restriction to the use or disclosure of PHI that Covered Entity has agreed to or is required to abide by in accordance with 45 C.F.R. § 164.522, to the extent that such restriction may affect Business Associate’s use or disclosure of PHI.
              </p>
              <p className="mb-12">
              4. Notification of Modifications. Covered Entity shall notify Business Associate in writing of any modifications to accounting disclosures of PHI under 45 CFR § 164.528, made applicable under Section 13405(c) of the HITECH Act, to the extent that such restrictions may affect Business Associate’s use or disclosure of Protected Health Information.
              </p>
              <p className="mb-12">
              5. Permissible Requests. Covered Entity shall not request that Business Associate use or disclose PHI in any manner that would not be permissible under HIPAA or other applicable federal or state law if done by Covered Entity.
              </p>
              <p className="mb-12">
              6. Minimum Necessary. Covered Entity agrees that it shall comply with HIPAA’s minimum necessary requirements and only provide Business Associate the minimum PHI necessary in order for Business Associate to provide the services.
              </p>
              
            </Typography>
          </div>
          




          <div>
            <Typography className="mt-20 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
              7. Term and Termination
            </Typography>
          </div>
          
          <div className="w-full">
            <Typography
              className="mt-12 sm:text-2xl text-left tracking-tight"
              color="text.secondary"
            >
              <p className="mb-12">
              1. Term. The term of this BAA shall commence as of the Effective Date, be coterminous with the Agreement, and continue in full force and effect from year to year, but shall terminate as of the earliest occurrence of any of the following:
              </p>
              <p className="mb-12 ml-24">
              a. The Agreement expires or is terminated with or without cause;
              </p>  
              <p className="mb-12 ml-24">
              b. This BAA is terminated for cause as described in paragraph (2) below;
              </p>  
              <p className="mb-12 ml-24">
              c. The parties mutually agree to terminate this BAA; or
              </p>  
              <p className="mb-12 ml-24">
              d. This BAA is terminated under applicable federal, state, or local law.
              </p>  
              <p className="mb-12">
              2. Termination for Cause.
              </p>        
              <p className="mb-12 ml-24">
              a. Upon Covered Entity’s determination of a breach of a material term of this BAA by Business Associate, Covered Entity shall provide Business Associate written notice of that breach in sufficient detail to enable Business Associate to understand the specific nature of that breach and afford Business Associate an opportunity to cure the breach; provided, however, that if Business Associate fails to cure the breach within thirty (30) days of receipt of such notice, Covered Entity may terminate this BAA and the Agreement.
              </p>  
              <p className="mb-12 ml-24">
              b. Upon Business Associate’s determination of a breach of a material term of this BAA by Covered Entity, Business Associate shall provide Covered Entity written notice of that breach in sufficient detail to enable Covered Entity to understand the specific nature of that breach and afford Covered Entity an opportunity to cure the breach; provided, however, that if Covered Entity fails to cure the breach within thirty (30) days of receipt of such notice, Business Associate may terminate this BAA and the Agreement.
              </p>  
              <p className="mb-12">
              3. Effect of Termination
              </p>
              <p className="mb-12 ml-24">
              a. Subject to paragraph (b) below, upon termination of this BAA for any reason, Business Associate shall return or destroy all PHI that Business Associate still maintains in any form. Business Associate shall retain no copies of such PHI.
              </p>  
              <p className="mb-12 ml-24">
              b. If return or destruction of any or all PHI is not feasible, Business Associate shall:
              </p> 
              <p className="mb-12 ml-48">
              i. Retain only that PHI for which return or destruction is not feasible;
              </p>  
              <p className="mb-12 ml-48">
              ii. Return to Covered Entity or destroy the remaining PHI that Business Associate still maintains in any form;
              </p> 
              <p className="mb-12 ml-48">
              iii. Extend the protections of this BAA to any retained PHI, continue to use appropriate safeguards, and comply with the Security Rule and HITECH with respect to ePHI, in order to prevent use or disclosure of the retained PHI other than as provided for in this BAA for as long as Business Associate retains the PHI;
              </p>  
              <p className="mb-12 ml-48">
              iv. Not use or disclose the PHI retained by Business Associate other than for the purposes for which such PHI was retained and subject to the same conditions set forth in this BAA that applied prior to termination; and
              </p> 
              <p className="mb-12 ml-48">
              v. Return to Covered Entity or destroy the PHI retained by Business Associate if and when it becomes feasible to do so.
              </p>
              <p className="mb-12 ml-24">
              c. This Section 7.3 shall survive termination of this Agreement.
              </p>
              
            </Typography>
          </div>
          

           <div>
            <Typography className="mt-20 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
              8. Miscellaneous
            </Typography>
          </div>
          
          <div className="w-full">
            <Typography
              className="mt-12 sm:text-2xl text-left tracking-tight"
              color="text.secondary"
            >
              <p className="mb-12">
              1. Regulatory References. A reference in this BAA to a section in HIPAA means the section as in effect or as amended at the time this BAA is executed or amended.
              </p>
            
              <p className="mb-12">
              2. Amendment; No Waiver. Upon the effective date of any federal statute amending or expanding HIPAA, any guidance or temporary, interim final or final regulations promulgated under HIPAA, or under any federal statute amending or expanding HIPAA (collectively, the “Regulations”) that are applicable to this BAA or any amendments to the Regulations, this BAA shall be automatically amended, such that the obligations imposed on Covered Entity and Business Associate shall remain in compliance with such requirements, unless the parties agree otherwise by mutual consent. The parties shall take all necessary action to expressly reflect such automatic amendments to this BAA from time to time. Except as provided otherwise in this paragraph (B), no waiver, change, modification, or amendment of any provision of this BAA shall be made unless it is in writing and is signed by the parties hereto. The failure of either party at any time to insist upon strict performance of any condition, promise, agreement, or understanding set forth herein shall not be construed as a waiver or relinquishment of the right to insist upon strict performance of the same condition, promise, agreement, or understanding at a future time.
              </p>        
              
              <p className="mb-12">
              3. Interpretation. Any ambiguity in this BAA shall be resolved in favor of a meaning that permits compliance with HIPAA. The titles and headings set forth at the beginning of each section hereof are inserted for convenience of reference only and shall in no way be construed as a part of this BAA or as a limitation on the scope of the particular provision to which it refers. In the event of an inconsistency between the provisions of this BAA and the mandatory terms of HIPAA, as may be expressly amended from time-to-time by the Secretary, or as a result of interpretations by the Secretary, a court, or another regulatory agency with authority over the parties, the interpretation of the Secretary, such court, or regulatory agency shall prevail.
              </p>
              
              <p className="mb-12">
              4. Entire Agreement; Effect on the Agreement. This BAA, together with the Agreement, sets forth the entire understanding between the parties and supersedes any previous or contemporaneous understandings, commitments, representations, warranties, or agreements, written or oral, regarding the subject matter hereof. No representations, agreements, or understandings of any kind, either written or oral, except as set forth or incorporated by reference into this BAA or the Agreement, have been relied upon in entering into this BAA, nor shall any such representations, agreements, or understandings be binding upon the parties unless expressly contained herein or therein. Notwithstanding any provision to the contrary in this BAA or the Agreement, to the extent that any term in this BAA is directly contradictory to a term in the Agreement, the term in this BAA shall supersede such contradictory term to the extent necessary to permit compliance with HIPAA.
              </p>
              <p className="mb-12">
              5. Relationship of Parties. The parties to this BAA are independent contractors. None of the provisions of this BAA are intended to create, nor shall they be interpreted or construed to create, any relationship between Covered Entity and Business Associate other than that of independent contractors. Except as otherwise expressly set forth herein, neither party, nor any of its representatives, shall be deemed to be the agent, employee, or representative of the other party.
              </p>
              <p className="mb-12">
              6. No Third Party Beneficiaries. This BAA is between the parties hereto. Nothing express or implied in this BAA is intended to confer, nor shall anything herein confer, any rights, remedies, obligations, or liabilities whatsoever upon any person other than Covered Entity and Business Associate and any respective successors and assigns.
              </p>
              <p className="mb-12">
              7. Invalid or Unenforceable Provision. The provisions of this BAA shall be severable. The invalidity or unenforceability of any particular provision or portion of such provision of this BAA shall be construed, in all respects, as if such invalid or unenforceable provision or portion of such provision had been omitted, and shall not affect the validity and enforceability of the other provisions hereof or portions of that provision.
              </p>
              <p className="mb-12">
              8. Assignment. The parties’ rights and obligations with respect to assignment of this BAA shall be subject to the assignment provision set forth in the Agreement. This BAA shall be binding upon, and shall inure to the benefit of, the parties hereto and their respective successors.
              </p>
              <p className="mb-12">
              9. Applicable Law. This BAA shall be construed, administered, and governed by the governing law set forth in the Agreement, except to the extent preempted by applicable federal law.  
              </p>
              <p className="mb-12">
              10. Disputes. In the event of a dispute between the parties, the parties shall follow the dispute resolution procedures outlined in the Agreement. 
              </p>
              <p className="mb-12">
              11. Notices. All notices to Business Associate shall be in writing, and either delivered by hand, or sent by mail, or delivered in such other manner as the parties may agree upon, to Halespring PBC, c/o Compliance Manager, 4654 Stagecoach Road – 73, Copperopolis, CA 95228. All notices to Covered Entity shall be by email at the email address provided upon account creation. Each party reserves the right to change address for receiving notice during the term of this BAA upon written notice to the other parties.
              </p>
              
            </Typography>
          </div>
        </div>
        </div>
  );
}

export default BAAPageContent;







    
        