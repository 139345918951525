import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios';

/**
 * @template T
 * @typedef {import('./strapi').SingleApiResponseWrapper<T>} SingleApiResponseWrapper
 */

/**
 * @typedef {{
 *   'community-welcome': import('./marketing').CommunityWelcome;
 * }} SingleTypes
 */

/**
 * @typedef {SingleTypes[keyof SingleTypes]} SingleType
 */

/** @type {import('@reduxjs/toolkit').EntityAdapter<SingleType, string>} */
const singleTypesAdaptor = createEntityAdapter({
  selectId: (singleType) => singleType.slug
});

const initialState = singleTypesAdaptor.getInitialState({
  isLoading: false
});

/** @type {import('@reduxjs/toolkit').AsyncThunk<SingleType, { slug: string, params?: any}, {}>}  */
export const fetchSingleTypeBySlug = createAsyncThunk(
  'marketing/singleTypes/fetchSingleTypeBySlug',
  async ({ slug, params }) => {
    /** @type {import('axios').AxiosResponse<SingleApiResponseWrapper<SingleType>>} */
    const response = await axios({
      method: 'GET',
      url: slug,
      params: params || { populate: '*' }
    });

    return {
      slug,
      ...response.data.data
    };
  }
);

const singleTypesSlice = createSlice({
  name: 'marketing/singleTypes',

  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleTypeBySlug.fulfilled, (state, action) => {
        if (action.payload) {
          singleTypesAdaptor.upsertOne(state, action.payload);
        }
      });
  }
});

export const selectSingleTypeBySlug = (/** @type {keyof SingleTypes} */ slug) =>
  (/** @type {import('./index').RootState} */ state) =>
    state.marketing.singleTypes.entities[slug]

export default singleTypesSlice.reducer;
