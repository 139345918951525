import { useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  searchBlogPosts,
  fetchFeaturedBlogPost,
  selectSearchedBlogPosts,
  selectBlogPostPagination,
  selectFeaturedBlogPost,
} from 'app/store/marketing/blogPostsSlice';
import { fetchTags, selectTags } from 'app/store/marketing/tagsSlice';
import BlogPostPreview from 'app/shared-components/marketing/BlogPostPreview';
import sanitizeHtml from 'sanitize-html';
import EastIcon from '@mui/icons-material/East';
import classNames from 'classnames';
import Input from 'app/shared-components/marketing/Input';
import Button from 'app/shared-components/marketing/Button';
import Breadcrumbs from 'app/shared-components/marketing/Breadcrumbs';

const POSTS_PER_PAGE = 9;

export default function Blog() {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);

  const params = useMemo(() => ({
    s: searchParams.get('s') || '',
    tags: searchParams.get('tags') ? searchParams.get('tags').split(',') : []
  }), [searchParams]);

  useEffect(() => {
    dispatch(fetchFeaturedBlogPost());
    dispatch(fetchTags());
  }, []);

  useEffect(() => {
    dispatch(searchBlogPosts({
      tags: params.tags,
      s: params.s,
      pagination: {
        start: POSTS_PER_PAGE * (page - 1),
        limit: POSTS_PER_PAGE,
        withCount: true
      },
      loadMore: page > 1
    }));
  }, [params, page]);

  const featured = useSelector(selectFeaturedBlogPost());
  const searchedBlogPosts = useSelector(selectSearchedBlogPosts());
  const blogPostPagination = useSelector(selectBlogPostPagination());
  const tags = useSelector(selectTags());

  const toggleTag = (slug) => {
    if (params.tags.includes(slug)) {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        tags: params.tags.length === 1
          ? '' 
          : [...params.tags]
            .filter((tag) => tag !== slug)
            .join(',')
      });
    }
    else {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        tags: [...params.tags, slug]
          .join(',')
      });
    }
    setPage(1);
  }

  const onSearchSubmit = (e) => {
    e.preventDefault();
    
    const data = new FormData(e.target);
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      s: data.get('s')
    });
    setPage(1);
  }

  const showMore = () => {
    setPage(page + 1);
  }

  return (
    <div>
      {/* Breadcrumbs */}
      <section>
        <div className="container py-28">
          <Breadcrumbs
            trail={[
              { label: 'Home', to: '/' },
              { label: 'Blog', to: '/blog' }
            ]}
          />
        </div>
      </section>

      {/* Featured post */}
      <section className="hidden lg:block mb-24">
        {featured && (
          <article
            className="relative bg-hs-white"
          >
            <div className="container">
              <div className="grid grid-cols-2 py-40 pr-40">
                <div>
                  <div className="flex flex-wrap gap-8">
                    {featured.tags?.map((tag) => (
                      <Link
                        key={tag.id}
                        className="block text-12 text-hs-blue bg-hs-grey-light px-10 py-4 font-medium rounded-full"
                      >
                        {tag.name}
                      </Link>
                    ))}
                  </div>

                  <div className="mt-12 text-16 text-hs-grey-black">
                    {featured.author} | {new Date(featured.createdAt).toLocaleString('en-US', {
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric'
                    })}
                  </div>

                  <h2 className="text-hs-grey-black text-48 leading-none font-medium mt-12">
                    {featured.title}
                  </h2>

                  <div
                    className="text-hs-grey-black text-18 font-medium mt-28"
                    dangerouslySetInnerHTML={{ __html: sanitizeHtml(featured.excerpt) }}
                  />

                  <div className="mt-40">
                    <Link
                      className="text-hs-blue text-20 font-semibold"
                      to={`/blog/${featured.slug}`}
                    >
                      Read
                      <EastIcon className="ml-2" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="absolute top-0 left-0 w-full h-full grid grid-cols-2 pointer-events-none">
              <div />
              <div className="relative">
                <img
                  src={featured.image.url}
                  alt={featured.image.alternativeText}
                  className="object-cover w-full h-full absolute"
                />
              </div>
            </div>
          </article>
        )}
      </section>

      {/* Posts */}
      <section>
        <div className="container pb-80">
          <h2 className="text-48 text-hs-grey-black leading-none font-medium">
            Recent articles
          </h2>

          <div className="flex flex-wrap lg:flex-nowrap gap-y-20 w-full mt-40">
            <div className="flex flex-wrap gap-8 w-fill lg:w-initial">
              {tags?.map((tag) => (
                <button
                  key={tag.id}
                  className={classNames([
                    'block text-12 border-2 border-hs-blue px-10 py-4 font-medium rounded-full',
                    params.tags.includes(tag.slug) ? 'text-hs-white bg-hs-blue' : 'text-hs-blue bg-hs-white'
                  ])}
                  onClick={() => toggleTag(tag.slug)}
                >
                  {tag.name}
                </button>
              ))}
            </div>

            <form
              className="-order-1 lg:order-1 w-full lg:w-initial lg:ml-8"
              onSubmit={onSearchSubmit}
            >
              <Input
                placeholder="Search Blog"
                name="s"
              />
            </form>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-3 gap-20 mt-40">
            {featured && <BlogPostPreview post={featured} className="block lg:hidden" />}
            {searchedBlogPosts?.map((post) => <BlogPostPreview key={post.id} post={post} />)}
          </div>

          <div className="mt-40">
            {blogPostPagination.total !== searchedBlogPosts.length && <Button
              className="text-hs-blue outline-hs-blue"
              onClick={showMore}
            >
              Show More <EastIcon className="ml-2" />
            </Button>}
          </div>
        </div>
      </section>
    </div>
  );
}
