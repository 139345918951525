import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion'


  const item = {
    hidden: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 },
  };



function MissingReferralItem() {
  
  return (

      <Card component={motion.div} style={{height:'100%'}} variants={item} className="mb-10">
       
          <CardContent className="px-20">  
          
              <Typography>
                 This referral is no longer available.
              </Typography>
              
          </CardContent>
          
		</Card>
    
  );
}


export default MissingReferralItem;
