import { createEntityAdapter, createSelector, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios';

/**
 * @template T
 * @typedef {import('./strapi').CollectionApiResponseWrapper<T>} CollectionApiResponseWrapper
 */
/** @typedef {import('./marketing').Testimonial} Testimonial */

/** @type {import('@reduxjs/toolkit').EntityAdapter<Testimonial, string>} */
const testimonialsAdaptor = createEntityAdapter({
  selectId: (testimonial) => testimonial.slug
});

const initialState = testimonialsAdaptor.getInitialState({
  isLoading: false
});

/** @type {import('@reduxjs/toolkit').AsyncThunk<Testimonial, string, {}>}  */
export const fetchTestimonialBySlug = createAsyncThunk(
  'marketing/testimonials/fetchTestimonialBySlug',
  async (slug) => {
    /** @type {import('axios').AxiosResponse<CollectionApiResponseWrapper<Testimonial>>} */
    const response = await axios({
      method: 'GET',
      url: 'testimonials',
      params: {
        slug,
        populate: '*'
      }
    });

    return response.data.data[0];
  }
);

/** @type {import('@reduxjs/toolkit').AsyncThunk<Testimonial[], any, {}>}  */
export const fetchTestimonials = createAsyncThunk(
  'marketing/testimonials/fetchTestimonials',
  async ({ filters, sort, pagination }) => {
    /** @type {import('axios').AxiosResponse<CollectionApiResponseWrapper<Testimonial>>} */
    const response = await axios({
      method: 'GET',
      url: 'testimonials',
      params: {
        populate: '*',
        filters,
        sort,
        pagination
      }
    });

    return response.data.data;
  }
);

const testimonialsSlice = createSlice({
  name: 'marketing/testimonials',

  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchTestimonialBySlug.fulfilled, (state, action) => {
        if (action.payload) {
          testimonialsAdaptor.upsertOne(state, action.payload);
        }
      })
      .addCase(fetchTestimonials.fulfilled, (state, action) => {
        if (action.payload) {
          testimonialsAdaptor.upsertMany(state, action.payload);
        }
      });
  }
});

/** @typedef {import('./index').RootState} RootState */

const selectTestimonialsState = (/** @type {RootState} */ state) => state.marketing.testimonials;

export const selectTestimonials = () => createSelector(
  [selectTestimonialsState],
  (testimonials) => testimonials.ids.map((id) => testimonials.entities[id])
);

export const selectTestimonialBySlug = (/** @type {string} */ slug) =>
  (/** @type {import('./index').RootState } */ state) =>
    state.marketing.testimonials.entities[slug];

export default testimonialsSlice.reducer;
