import { Suspense } from 'react';
import lazyLoad from 'app/shared-components/util/lazyLoad';
import { memo } from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

const ReferralsAppToggleButton = lazyLoad(() => import('src/app/main/apps/referrals/ReferralsAppToggleButton'));
const SearchProviderButton = lazyLoad(() => import('app/theme-layouts/shared-components/SearchProviderButton'));
const ChannelSelectorButton = lazyLoad(() => import('app/shared-components/channel/ChannelSelectorButton'));
const ChatAppToggleButton = lazyLoad(() => import('src/app/main/apps/chat/ChatAppToggleButton'));
const NotificationPanelToggleButton = lazyLoad(() => import('app/shared-components/layout/notificationPanel/NotificationPanelToggleButton'));
const FullScreenToggle = lazyLoad(() => import('../../shared-components/FullScreenToggle'));
const AdjustFontSize = lazyLoad(() => import('../../shared-components/AdjustFontSize'));
const UserMenu = lazyLoad(() => import('../../shared-components/UserMenu'));


function UserToolbar({isMobile, user}) {

  return (
      <>
        <AdjustFontSize />

        <SearchProviderButton isPublic={false}/>
        
		<FullScreenToggle />
		
		<ReferralsAppToggleButton /> 
		
        <ChannelSelectorButton type="team" title="Teams" 
                         icon={<FuseSvgIcon>material-outline:people_outline</FuseSvgIcon>}
                     />

        <ChannelSelectorButton type="group" title="Groups" 
                         icon={<FuseSvgIcon>heroicons-outline:user-group</FuseSvgIcon>}
                     />
        
        <ChatAppToggleButton /> 

        <NotificationPanelToggleButton />

        <UserMenu />
      </>
  );
}

export default memo(UserToolbar);
