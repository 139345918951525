import _ from '@lodash';
import { convertToPlain, truncateText } from 'app/shared-components/utils'


const LinkMetaModel = (data) => {
	let out= { ... data}; 
	out.description = truncateText(convertToPlain(data.description), 200);
	out.title = data.title ? data.title : '';
	out.image = data.image ? data.image : '';
    out.hostname = data.hostName ? data.hostName : '';
    out.siteName = data.siteName ? data.siteName : data.hostName ? data.hostName : '';
    return out; 
}

export default LinkMetaModel;
