import { Icon } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

function getFeatureBgClasses(i, total) {
  if (total <= 2) {
    switch (i % 4) {
      case 0: return { bg: 'bg-hs-blue-pale', icon: 'bg-hs-blue-light' };
      case 1: return { bg: 'bg-hs-green-pale', icon: 'bg-hs-green-light' };
    }
  }

  switch (i % 4) {
    case 0: return { bg: 'bg-hs-blue-pale', icon: 'bg-hs-blue-light' };
    case 1: return { bg: 'bg-hs-grey-light', icon: 'bg-hs-grey' };
    case 2: return { bg: 'bg-hs-green-pale lg:bg-hs-grey-light', icon: 'bg-hs-green-light lg:bg-hs-grey' };
    case 3: return { bg: 'bg-hs-grey-light lg:bg-hs-green-pale', icon: 'bg-hs-grey lg:bg-hs-green-light' };
  }
}

/**
 * @param {{
 *   features: {
 *     title: string;
 *     body: string;
 *     icong: string;
 *   }[];
 *   className?: string;
 * }} 
 */
export default function FeatureGrid({ features, className, ...props }) {
  return (
    <div
      className={twMerge(classNames([
        'grid grid-cols-1 lg:grid-cols-2 gap-12 mt-40',
        className
      ]))}
    >
      {features.map((feature, i) => {
        const bgClasses = getFeatureBgClasses(i, features.length);

        return (
          <article
            key={i}
            className={classNames([
              'p-20 lg:p-40 pb-72 lg:pb-120 rounded-20',
              bgClasses.bg
            ])}
          >
            <div className="flex justify-between items-start">
              <h3 className="h3 lg:h2">
                {feature.title}
              </h3>

              <div className={classNames([
                'w-60 h-60 lg:w-80 lg:h-80 flex justify-center items-center rounded-full text-hs-white text-32 lg:text-40 flex-none ml-20',
                bgClasses.icon
              ])}>
                {feature.icon.includes("material") ?
                  <FuseSvgIcon size='40px'>{feature.icon}</FuseSvgIcon>
                                  : 
                  <Icon fontSize='inherit'>{feature.icon}</Icon>
                }
              </div>
            </div>

            <p className="body-md lg:body-lg mt-28">
              {feature.body}
            </p>
          </article>
        );
      })}
    </div>
  )
}