// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { useMemo, useState } from 'react';
import Badge from '@mui/material/Badge';
import Divider from '@mui/material/Divider';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import NestedMenuItem from './NestedMenuItem';
import DefaultButton from './DefaultButton';
import { usePopper } from "react-popper";
import { ClickAwayListener } from '@mui/material';


const Popover = ({ isOpen, content, children }) => {
  
  return (
    <div>
      {children}
      {isOpen && content}
    </div>
  );
};

function DialogSafeIconMenu({text, icon, iconOnly, iconSize, items, unread, onItemClick, placement }) {
  const [open, setOpen]= useState(false);
  const size= iconSize ? iconSize : 30;
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const usePlacement= placement ? placement : "top-start";

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: usePlacement,
    modifiers: [{ name: "offset", options: { offset: [0, 5] } }]
  });
  
  const handleClick = (event) => {
    setOpen(!open);
  };
  
  const handleClose = () => {
    setOpen(false);
  };

  function handleItemClick(item){
	  item.onClick ? item.onClick(item) : onItemClick(item);
	  handleClose();
  }
  
  
  return (
    <Popover
        isOpen={open}
        content={
         <ClickAwayListener onClickAway={handleClose}>
          <div ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            className="popover-content bg-white rounded"
          >
            {items && items.map((item) => (
          			item.type === 'divider' ?
          			     <Divider key={item.key} />
          			       :
          			item.items ? 
          				<NestedMenuItem key={item.key} 
          				                icon={item.icon} 
          				                text={item.text} 
          				                unread={item.unread} 
          				                items={item.items}
          				                onItemClick={onItemClick}/> 
          				    :
          				<MenuItem key={item.key} onClick={() => handleItemClick(item)}>
          				  {item.icon &&
          				  <ListItemIcon className="min-w-40">
          		            <Badge color="secondary" variant="dot" invisible={!item.unread}>
          		                <FuseSvgIcon>{item.icon}</FuseSvgIcon>
          			        </Badge>
          		          </ListItemIcon>
          				  }
          		          <ListItemText primary={item.text} />
          		        </MenuItem>	 
          	))}
          </div>
         </ClickAwayListener>
        }
      >
        <div
          className="btn-container"
          ref={setReferenceElement}
          onClick={handleClick}
        >
          {iconOnly &&
              <IconButton >
                  <FuseSvgIcon size={size}>{icon}</FuseSvgIcon>
              </IconButton>
            }
            
            {!iconOnly &&
            <DefaultButton iconRightSize={size}
                           iconRight={icon}
                           text={text}
                           />
            }
        </div>
      </Popover>
  );
};




export default DialogSafeIconMenu;