import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import { startLoading, stopLoading } from './utilSlice';
import uploadFile from 'app/shared-components/uploadFunction';


export const getItems = createAsyncThunk(
  "fileItems/getItems",
  async (folderId, { getState }) => {
	  if (!folderId) return null;
      const apiName = 'CoreAPI';
	  const path = '/fileitem/byparent/' + folderId;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	 let data= await apiGet(apiName, path, options);
	 return data;
  }
);

export const getFolder = createAsyncThunk(
  "fileItems/getFolder",
  async (id, { getState }) => {
	    if (!id) return null;
      const apiName = 'CoreAPI';
	    const path = '/fileitem/' + id;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	 let data= await apiGet(apiName, path, options);
	 return data;
  }
);


export const addFileItem = createAsyncThunk(
  "fileItems/addFileItem",
  async (params, {dispatch}) => {
	  const {folderId, fileId, name, description, type, size, knowledgeBase}= params;
      const apiName = 'CoreAPI';
	  const path = '/fileitem';
      const options = {
		  body: { parentId: folderId,
		             objectId: fileId,
		             type: type,
		             size: size,
		             name: name,
		             knowledgeBase: knowledgeBase,
		             description: description},
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	 try{
		const data= await apiPut(apiName, path, options);
	    return data; 
	 }finally{
		dispatch(stopLoading());
	 }
	 
  }
);

export const uploadFileItem = createAsyncThunk(
  "fileItems/uploadFileItem",
  async (params, {dispatch}) => {
	  const {file, folderId, name, description, knowledgeBase}= params;
	  
	  dispatch(startLoading());
	  try{
		  //Upload file and update user data
		  const fileId= await uploadFile(file, folderId, 'file_item');
		  const resp= await dispatch(addFileItem({fileId: fileId, 
			                       folderId: folderId,
			                       type: file.type,
			                       size: file.size,  
			                       name: name, 
			                       knowledgeBase: knowledgeBase,
			                       description: description}));
	      return resp.payload;
      }finally{
		dispatch(stopLoading());
	  }
  }
);


export const deleteFileItem = createAsyncThunk(
  "fileItems/deleteFileItem",
  async (params, {dispatch}) => {
	  const {folderId, fileId}= params;
      const apiName = 'CoreAPI';
	  const path = '/fileitem/' + fileId;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	 try{
		const data= await apiDel(apiName, path, options);
	    return fileId; 
	 }finally{
		dispatch(stopLoading());
	 }
	 
  }
);

export const updateFileItem = createAsyncThunk(
  "fileItems/updateFileItem",
  async (params, {dispatch}) => {
	  const {fileId, name, description, knowledgeBase}= params;
      const apiName = 'CoreAPI';
	  const path = '/fileitem/' + fileId;
      const options = {
		  headers: {},
		  body: {  name: name,
	              knowledgeBase: knowledgeBase,
	              description: description},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	 try{
		const data= await apiPut(apiName, path, options);
	    return data; 
	 }finally{
		dispatch(stopLoading());
	 }
	 
  }
);

export const updateStorageLimit = createAsyncThunk(
  "fileItems/updateStorageLimit",
  async ({ folderId }, { dispatch, getState }) => {
      const apiName = 'CoreAPI';
	    const path = '/fileitem/' + folderId + '/updatestorage';
      const options = {
		  body: {},
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  
	  //Store channel member data
	  const data= await apiPut(apiName, path, options);
    return data;
  }
);

const itemsAdapter = createEntityAdapter({});

export const {
  selectAll: selectItems,
  selectEntities: selectItemsEntities,
  selectById: selectItemById,
} = itemsAdapter.getSelectors((state) => state.fileItems.currentItems);

export const {
  selectById: selectFolderById,
} = itemsAdapter.getSelectors((state) => state.fileItems.allFolders);

export const selectFolder = (folderId) => state => {
	if (folderId) return selectFolderById(state, folderId);
	return null;
}

const itemsSlice = createSlice({
  name: 'fileItems',
  initialState: {
    rootFolderId: null,
    currentFolderId: null,
    selectedItemId: null,
    currentItems: itemsAdapter.getInitialState(),
    allFolders: itemsAdapter.getInitialState(),
  },
  reducers: {
    setSelectedItem: (state, action) => {
      state.selectedItemId = action.payload;
    },
    setCurrentFolder: (state, action) => {
      state.currentFolderId = action.payload;
    },
    setRootFolder: (state, action) => {
	  const {id, name}= action.payload;
	  const rootItem= {id: id, name: name};
      state.rootFolderId = id;
      itemsAdapter.addOne(state.allFolders, rootItem);
    },
  },
  extraReducers: builder => {
    builder.addCase(getItems.fulfilled, (state, action) => {
	  const items= action.payload;
	  if (!items) return;
      itemsAdapter.setAll(state.currentItems, items);
      state.currentFolderId = action.meta.arg;
      state.selectedItemId = null;
      items.filter((item) => item.type === 'folder').map((item) => itemsAdapter.setOne(state.allFolders, item));
    })
    .addCase(getFolder.fulfilled, (state, action) => {
	    const item= action.payload;
	    if (!item) return;
	    if (item.type != 'folder') return;
      itemsAdapter.setOne(state.allFolders, item);
    })
    .addCase(addFileItem.fulfilled, (state, action) => {
	  const item= action.payload;
	  if (!item) return;
      itemsAdapter.addOne(state.currentItems, item);
      if (item.type === 'folder') itemsAdapter.addOne(state.allFolders, item);
    })
    .addCase(updateFileItem.fulfilled, (state, action) => {
	  const item= action.payload;
	  if (!item) return;
      itemsAdapter.setOne(state.currentItems, item);
      if (item.type === 'folder') itemsAdapter.setOne(state.allFolders, item);
    })
    .addCase(updateStorageLimit.fulfilled, (state, action) => {
	    const item= action.payload;
	    if (!item) return;
	    if (item.type != 'folder') return;
      itemsAdapter.setOne(state.allFolders, item);
    })
    .addCase(deleteFileItem.fulfilled, (state, action) => {
	  const itemId= action.payload;
      itemsAdapter.removeOne(state.currentItems, itemId);
      itemsAdapter.removeOne(state.allFolders, itemId);
    })
  },
});

export const selectFolders = createSelector([selectItems], (items) => {
  return items.filter((item) => item.type === 'folder');
});

export const selectFiles = createSelector([selectItems], (items) => {
  return items.filter((item) => item.type !== 'folder');
});

export const selectSelectedItem = ({ fileItems }) => fileItems.selectedItemId;
export const selectCurrentFolder = ({ fileItems }) => fileItems.currentFolderId;
export const selectRootFolder = ({ fileItems }) => fileItems.rootFolderId;
export const selectPath = ({ fileItems }) => {
	let path=[];
	let currentFolder= fileItems.allFolders.entities[fileItems.currentFolderId];
	console.log("Current Folder:");
	console.log(currentFolder);
	while(currentFolder){
		path.push({name: currentFolder.name, id: currentFolder.id});
		if (currentFolder.parentId){
			currentFolder= fileItems.allFolders.entities[currentFolder.parentId];
		}else currentFolder= null;
	}
	return path.reverse();
}

export const { setSelectedItem, setRootFolder, setCurrentFolder} = itemsSlice.actions;

export default itemsSlice.reducer;
