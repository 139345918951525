import { useState, useEffect, useRef, Suspense } from 'react';
import lazyLoad from 'app/shared-components/util/lazyLoad';
import { useSelector, useDispatch } from 'react-redux';
import { selectCallDetails, setCallDetails } from 'app/store/videoCallSlice';
import { submitUpdatePostStatus, STATUS_VIDEO_CALL_ENDED} from 'app/store/postSlice';
import MinimizableDialog from '../layout/MinimizableDialog';
import CloseableDrawer from '../layout/CloseableDrawer';
const DMChat = lazyLoad(() => import('../chat/DMChat'));
const CommentPanel = lazyLoad(() => import('../chat/CommentPanel'));
const VideoCallContainer = lazyLoad(() => import('./VideoCallContainer'));


function VideoCallModal(props) {
  const dispatch= useDispatch();
  const callDetails = useSelector(selectCallDetails);
  const open= Boolean(callDetails);
  
  if (!open) return null;
  const {channelId, postId, meetingId, userName, 
         dmChannelId, title, isOwner} = callDetails;
  if (!meetingId) return null;


  
  function handleClose(isCallEnded){
	  dispatch(setCallDetails(null));
	  if (isCallEnded){
		  dispatch(submitUpdatePostStatus({postId: postId, status: STATUS_VIDEO_CALL_ENDED}));
	  }
  }
  

  return (
    <>
      <MinimizableDialog open={open} 
                        title={title} 
                        handleClose={handleClose}
                        fullScreen>

            <Suspense fallback={<h2> Loading...</h2>}>
    	        <VideoCallContainer channelId={channelId} 
    	                    postId={postId} 
    	                    meetingId={meetingId}
    	                    isHost={isOwner}
    	                    userName={userName}
    	                    onExit={handleClose}
    	                    dmChannelId={dmChannelId}
    	                    />
    	      </Suspense>
    	      
      </MinimizableDialog>
      
    </>
  );
  
}

export default VideoCallModal;
