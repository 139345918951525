import { useState, useEffect, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setAiDialogDetails, selectAiDialogDetails, getAiChats, selectAiChats, addAiChat, deleteAiChat } from 'app/store/aiChatSlice';
import { selectUser } from 'app/store/userSlice';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import MinimizableDialog from '../layout/MinimizableDialog';
import MultiChatPanel from '../chat/MultiChatPanel'
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import ControlledTextField from 'app/shared-components/form/ControlledTextField';
import dispatchSubmit from 'app/shared-components/util/dispatchSubmit';
import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import NativeDialog from 'app/shared-components/layout/NativeDialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DefaultButton from '../layout/DefaultButton';
import confirmationDialog from '../util/confirmationDialog';


const schema = yup.object().shape({
  title: yup.string().required(),
});

function AIChatModal(props) {
  const dispatch= useDispatch();
  const user= useSelector(selectUser);
  const dmDetails = useSelector(selectAiDialogDetails);
  const [channelId, setChannelId]= useState(dmDetails?.channelId);
  const [folderId, setFolderId]= useState(dmDetails?.folderId);
  const [fileId, setFileId]= useState(dmDetails?.fileId);
  const aiChats = useSelector(selectAiChats);
  const [chatItems, setChatItems]= useState([]);
  const [selectedItem, setSelectedItem]= useState(null);
  const [dialogOpen, setDialogOpen]= useState(false);
  const fullScreen = useThemeMediaQuery((theme) => theme.breakpoints.down('sm'));
  const userId= user?.id;
  const open= Boolean(dmDetails);
  const height= fullScreen ? "100%" : "700px";
  const width= fullScreen ? "100%" : "600px";
  const dialogWidth= fullScreen ? "100%" : "600px";
  const dialogMaxWidth= dialogWidth;
  const title= selectedItem ? "AI Chat: " + selectedItem.title : "AI Chat List";
  const emptyMessage = "Create a new chat to begin.";
  

  function handleClose(){
	  dispatch(setAiDialogDetails(null));
	  setChannelId(null);
	  setFolderId(null);
	  setFileId(null);
	  setSelectedItem(null);
  }
  
  useEffect(() => {
    if (dmDetails){
        if (dmDetails.channelId) setChannelId(dmDetails.channelId);
        else if (dmDetails.folderId) setFolderId(dmDetails.folderId);
        else if (dmDetails.fileId) setFileId(dmDetails.fileId);
    }
  }, [dmDetails]);
  
  
  useEffect(() => {
    if (folderId) dispatch(getAiChats({folderId: folderId}));
    else if (fileId) dispatch(getAiChats({fileId: fileId}));
    else if (channelId) dispatch(getAiChats({channelId: channelId}));
  }, [folderId, fileId, channelId]);
  
  useEffect(() => {
    if (aiChats.length > 0){
        const items = aiChats.map((botchan) => {
          return { ...botchan, botchanId: botchan.id, id: "AI-BOT-" + botchan.id, firstName: "AI", lastName: "Assistant", showTitle: true }
        })
        setChatItems(items);
        setSelectedItem(items[0]);
    }else {
      setChatItems([]);
      setSelectedItem(null);
    }
  }, [aiChats]);
  
  if (!open) return null;
  
  
  function handleSelectItem(item){
	  setSelectedItem(item);
  }

  function handleCreateItem(){
	  setDialogOpen(true);
  }
  
  function handleDeleteItem(){
	  if (selectedItem) confirmationDialog(dispatch, {
				title: "Delete Selected Chat?",
				content: "Do you want to delete the currently selected AI Chat?",
				onOk: deleteChat,
				onCancel: () => {},
				okText: "Delete Chat",
				cancelText: "Cancel"
			});
  }


  function createNewChat(data){
	  dispatchSubmit({dispatch: dispatch, 
      	             submitFunction: addAiChat({channelId: channelId, folderId: folderId, fileId: fileId, title: data.title}), 
      	             successMessage:  "Chat Created!"});
  }
  
  function deleteChat(){
	   dispatchSubmit({dispatch: dispatch, 
      	             submitFunction: deleteAiChat({id: selectedItem.botchanId}), 
      	             successMessage:  "Chat Deleted"});
  }
  
  function processPost(post){
     post.type= "bot_conversation";
     //Different values if this directly on file folder
     if (folderId){
       post.foreignModelKey= "FileItem:" + folderId;
       if (!post.messageText.startsWith("@ai #files")) {
         if (post.messageText.startsWith("#files")) post.messageText= "@ai " + post.messageText;
         else post.messageText= "@ai #files " + post.messageText;
       }
     }else if (fileId){
       if (!post.messageText.startsWith("@ai")) post.messageText= "@ai " + post.messageText;
       post.foreignModelKey= "FileItem:" + fileId;
     }else{
  	   if (!post.messageText.startsWith("@ai")) post.messageText= "@ai " + post.messageText;
  	   post.foreignModelKey= "Channel:" + channelId;
     }
  }


  return (
    <>
     <MinimizableDialog open={open} 
                        title={title} 
                        handleClose={handleClose}
                        showClose={true}
                        fullScreen={fullScreen}
                        width={dialogWidth}
                        maxWidth={dialogMaxWidth}>
        <Suspense fallback={<h2> Loading...</h2>}>
           {(channelId || folderId || fileId) ?
            <div style={{display: 'flex', flexDirection: 'column', height: height, width: width}}>
              
               <MultiChatPanel items={chatItems} selectedItem={selectedItem} 
                    onCreate={handleCreateItem} onDelete={handleDeleteItem} emptyMessage={emptyMessage}
	                  onClose={handleClose} onSelectItem={handleSelectItem} 
	                  processPost={processPost}/>
	                  
            </div>
                      :
            <h2> Loading...</h2>          
           }
	      </Suspense>
     	
     </MinimizableDialog>
     
     <CreateAIChatDialog open={dialogOpen} onClose={() => setDialogOpen(false)} onSubmit={createNewChat}/>
     
    </>

  );
  
}

function CreateAIChatDialog({open, onClose, onSubmit}) {
   
  const { handleSubmit, formState, control, reset } = useForm({
    mode: 'onChange',
    defaultValues: {
      title: '',
    },
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  function onSubmitInternal(data){
     onSubmit(data);
     onClose();
  }


  return (
    <NativeDialog
      onClose={onClose}
      open={open}
      width="400px"
    >
      <AppBar position="static" color="secondary" elevation={0}>
	      <Toolbar className="flex w-full">
	        <Typography variant="subtitle1" color="inherit">
	          New AI Chat
	        </Typography>
	      </Toolbar>
	    </AppBar>
	  
	    <form noValidate onSubmit={handleSubmit(onSubmitInternal)} className="flex flex-col">
          <DialogContent classes={{ root: 'p-32 pb-0' }}>

				         <ControlledTextField name="title" label="Chat Title" width="100%"
                                      control={control} errors={errors} />
	  

          </DialogContent>

          <DialogActions className="flex flex-col sm:flex-row sm:items-center justify-between py-16 sm:py-24 px-24">
            <div className="flex items-center space-x-8 mt-16 sm:mt-0">
			        <DefaultButton text="Cancel" onClick={onClose} />
              <DefaultButton text="Create"
	              disabled={_.isEmpty(dirtyFields) || !isValid}
	              submit />
            </div>
          </DialogActions>
        </form>
          
    </NativeDialog>
  );
}

export default AIChatModal;
