import { lighten, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { TYPE_VIDEO_CALL, deletePost, selectPostById } from 'app/store/postSlice';
import { startLoading } from 'app/store/utilSlice';
import { LinkPreview } from '@dhaiwat10/react-link-preview';
import { confirmationDialog, dispatchResultMessage } from '../utils';
import {getMetadata} from 'app/shared-components/linkMetadataFunction';
import LinkMetaModel from '../post/LinkMetaModel';
import FileItem from '../file/FileItem';
import VideoCallItem from '../chime/VideoCallItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import ChatEdit from './ChatEdit';
import ReferralShareItem from 'app/shared-components/referral/ReferralShareItem';
import MissingReferralItem from 'app/shared-components/referral/MissingReferralItem';
import CitationsDialog from 'app/shared-components/chat/CitationsDialog';



const StyledMessageRow = styled('div')(({ theme }) => ({
  '&.contact': {
    '& .bubble': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.secondary.contrastText,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
    },
    '& .time': {
        marginLeft: 5,
    },
    '& .attachment': {
	  marginTop: 5,
    },
    '& .controls': {
	  marginTop: 2,
    },
    '&.first-of-group': {
      '& .bubble': {
        borderTopLeftRadius: 20,
      },
    },
    '&.last-of-group': {
      '& .bubble': {
        borderBottomLeftRadius: 20,
      },
    },
    '&.last-of-chat': {
      '& .bubble': {
        borderBottomLeftRadius: 20,
      },
    },
  },
  '&.bot': {
    '& .bubble': {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.secondary.contrastText,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
    },
    '& .time': {
        marginLeft: 5,
    },
    '& .attachment': {
	  marginTop: 5,
    },
    '& .controls': {
	  marginTop: 2,
    },
    '&.first-of-group': {
      '& .bubble': {
        borderTopLeftRadius: 20,
      },
    },
    '&.last-of-group': {
      '& .bubble': {
        borderBottomLeftRadius: 20,
      },
    },
    '&.last-of-chat': {
      '& .bubble': {
        borderBottomLeftRadius: 20,
      },
    },
  },
  '&.me': {
    paddingLeft: 40,
    '& .bubble': {
      marginLeft: 'auto',
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
      borderTopLeftRadius: 20,
      borderBottomLeftRadius: 20,
      borderTopRightRadius: 5,
      borderBottomRightRadius: 5,
    },
    '& .time': {
		marginLeft: 'auto',
        marginRight: 5,
        justifyContent: 'flex-end',
    },
    '& .attachment': {
      marginLeft: 'auto',
      marginTop: 5,
    },
    '& .controls': {
      marginLeft: 'auto',
      marginTop: 5,
    },
    '&.first-of-group': {
      '& .bubble': {
        borderTopRightRadius: 20,
      },
    },

    '&.last-of-group': {
      '& .bubble': {
        borderBottomRightRadius: 20,
      },
    },
    '&.last-of-chat': {
      '& .bubble': {
        borderBottomRightRadius: 20,
      },
    },
  },
  '&.contact + .me, &.me + .contact': {
    paddingTop: 10,
    marginTop: 10,
  },
  '&.first-of-group': {
	 paddingTop: 8,
    '& .bubble': {
      borderTopLeftRadius: 20,
    },
  },
  '&.last-of-group': {
	paddingBottom: 8,
    '& .bubble': {
      borderBottomLeftRadius: 20, 
    },
    '& .time': {
        display: 'flex',
    },
  },
  '&.last-of-chat': {
	paddingBottom: 8,
    '& .bubble': {
      borderBottomLeftRadius: 20, 
    },
    '& .time': {
        display: 'flex',
    },
  },
}));

const getMetadataFetcher= (data) => async () =>
{
  if (data.linkMetadata) return LinkMetaModel(data.linkMetadata);
  else return await getMetadata(data.linkUrl);
}


function DMChatEntry({postId, prevPostId, nextPostId, user, channelId, chatLength, suppressVideoCall, i}) {
  const dispatch = useDispatch();
  const [isClicked, setIsClicked] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showCitations, setShowCitations] = useState(false);
  const item= useSelector(selectPostById(postId));
  const prevItem= useSelector(selectPostById(prevPostId));
  const nextItem= useSelector(selectPostById(nextPostId));
  const isVideoCall= item?.type === TYPE_VIDEO_CALL;
  const isAuthor= item?.author?.id === user.id;
  const isBot= item?.author?.id && item.author.id.includes("AI-BOT");
  const isFirstMessageOfGroup= i === 0 || (item && prevItem && prevItem.author.id !== item.author.id);
  const isLastMessageOfGroup= i === chatLength - 1 || (item && nextItem && nextItem.author.id !== item.author.id);
  const isLastMessageOfChat= i + 1 === chatLength;
  const hasReferral= item?.foreignModelKey && item.foreignModelKey.startsWith('OutboundReferral'); 
  const referralExists= Boolean(item?.referral);
  const important= item.important;
  const hasCitations= item?.citations && item.citations.length > 0;
   

  function handleRemovePost(){
	 confirmationDialog(dispatch, "Delete message?", 
	                   "Are you sure you want to permanently remove this message?",
	 (confirmed) => {
		  if (confirmed){
			 dispatch(startLoading());
			 dispatch(deletePost({ channelId: channelId, postId: postId })).then((resp) =>
			 {
				dispatchResultMessage(dispatch, resp, "Deleted message");
			 });
	      }
	  }
	);  
  };
  
  if (!item) return null;
  if (isVideoCall && suppressVideoCall) return null;
  
  return (
      <>
        <StyledMessageRow
          onClick={() => setIsClicked(!isClicked)}
          className={clsx(
            'flex flex-col grow-0 shrink-0 items-start justify-end relative px-16 pb-4',
            isAuthor ? 'me' : isBot ? 'bot' : 'contact',
            { 'first-of-group': isFirstMessageOfGroup },
            { 'last-of-group': !isLastMessageOfChat && isLastMessageOfGroup },
			{ 'last-of-chat': isLastMessageOfChat },
          )}
        >
          {item.text && !isEditing &&
          <div className="bubble flex relative items-center justify-center p-10 pb-6 pt-6 max-w-full">
            <div className="leading-tight whitespace-pre-wrap flex flex-row" style={{fontWeight: important ? '600' : undefined}}>
              {important && 
		          <FuseSvgIcon size={16} color="red" className="mr-4">
                heroicons-solid:exclamation-circle
              </FuseSvgIcon>
		       }
		       {hasCitations && 
		          <FuseSvgIcon size={16} color="blue" className="mr-4" onClick={() => setShowCitations(true)}>
                heroicons-outline:information-circle
              </FuseSvgIcon>
		       }
              {item.text}
            </div>
            
          </div>
          }
          
          {isEditing && 
		    <ChatEdit postId={item.id} 
		            rootChannelId={channelId} 
		            originalText={item.text}
		            closeEdit={() => { setIsEditing(false); setIsClicked(false); }} />
		  }
            {isVideoCall && <Box className="attachment max-w-xs max-h-xs"><VideoCallItem post={item} user={user} isDM isOwner={isAuthor}/></Box>}
            {item.mediaURL && <Box className="attachment max-w-xs max-h-xs"><img src={item.mediaURL} alt="post" className="rounded-8" /></Box>}
            {item.attachment && <Box className="attachment max-w-xs max-h-xs"><FileItem item={item.attachment}/></Box>}
            {item.linkUrl && <Box className="attachment max-w-xs"><LinkPreview url={item.linkUrl} width='100%' fetcher={getMetadataFetcher(item)}
              fallback={<a href={item.linkUrl}><Typography>{item.linkUrl}</Typography></a>} 
             /></Box>}
            {hasReferral &&  <Box className="attachment max-w-sm max-h-sm">{referralExists ? <ReferralShareItem referral={item.referral} isOwner={isAuthor} enableInterest /> : <MissingReferralItem />}</Box>}

             
          {isClicked && isAuthor &&
		     <Box display="flex" className="controls" flexDirection="row" alignItems="center" justifyItems="left">
                {!isVideoCall && 
            	<Button aria-label="Edit" onClick={() => setIsEditing(true)}>
                  <FuseSvgIcon size={16}>heroicons-outline:pencil</FuseSvgIcon>
                  <Typography className="mx-4">Edit</Typography>
                </Button>
                }
                <Button aria-label="Delete" onClick={handleRemovePost}>
                  <FuseSvgIcon size={16}>heroicons-outline:trash</FuseSvgIcon>
                  <Typography className="mx-4">Delete</Typography>
                </Button>
          </Box>}
			  
		      <div className="hidden time relative ve max-w-full">
            <Typography
              className="time whitespace-nowrap"
              color="text.secondary"
            >
              {formatDistanceToNow(new Date(item.createdAt), { addSuffix: true })}
            </Typography>
          </div>
          
        </StyledMessageRow>
        
        {hasCitations &&
           <CitationsDialog open={showCitations} onClose={() => setShowCitations(false)} citations={item.citations} />
        }
     </>
  );
}

export default DMChatEntry;
