import lazyLoad from 'app/shared-components/util/lazyLoad';
const HelpCenterHome= lazyLoad(() => import('./home/HelpCenterHome'));
const HelpCenterFaqs= lazyLoad(() => import('./faqs/HelpCenterFaqs'));
const HelpCenterGuides= lazyLoad(() => import('./guides/HelpCenterGuides'));
const HelpCenterSupport= lazyLoad(() => import('./support/HelpCenterSupport'));
const HelpCenterApp= lazyLoad(() => import('./HelpCenterApp'));
const GuideCategory= lazyLoad(() => import('./guides/GuideCategory'));
const GuideCategories= lazyLoad(() => import('./guides/GuideCategories'));
const HelpCenterGuide= lazyLoad(() => import('./guide/HelpCenterGuide'));
const GettingStarted= lazyLoad(() => import('./getting-started/GettingStarted'));
const SupportCaseChat= lazyLoad(() => import('./support/SupportCaseChat'));
const SupportCasesApp= lazyLoad(() => import('./support/SupportCasesApp'));
const HelpCenterSearchResults= lazyLoad(() => import('./home/HelpCenterSearchResults'));

const HelpCenterAppConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'apps/help-center',
      element: <HelpCenterApp />,
      children: [
        {
          path: '',
          element: <HelpCenterHome />,
        },
        {
          path: 'search',
          element: <HelpCenterSearchResults />,
        },
        {
          path: 'getting-started',
          element: <GettingStarted />,
        },
        {
          path: 'faqs',
          element: <HelpCenterFaqs />,
        },
        {
          path: 'guides',
          element: <HelpCenterGuides />,
          children: [
            {
              path: '',
              element: <GuideCategories />,
            },
            {
              path: ':categorySlug',
              element: <GuideCategory />,
            },
            {
              path: ':categorySlug/:guideSlug',
              element: <HelpCenterGuide />,
            },
          ],
        },
        {
          path: 'support',
          element: <SupportCasesApp />,
          children: [
            {
              path: 'chat/:id',
              element: <SupportCaseChat />,
            },
          ],
        },
      ],
    },
  ],
};

export default HelpCenterAppConfig;
