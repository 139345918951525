import React from 'react';


import { Helmet } from 'react-helmet-async';

const HeaderTags = ({ title, description, type, name, image, keywords, schema, canonicalURL, favicon }) => {
  const useType = type ? type : 'website';
  const useImage = image ? image : '';
  const isIPhone = navigator.userAgent.indexOf('iPhone') > -1;
  const siteName= "Halespring";
  const host = window.location.protocol + '//' + window.location.host;
  const url = host + window.location.pathname;
  const useUrl= canonicalURL ? canonicalURL : url;

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={useUrl} />
      {favicon && <link rel="shortcut icon" type="image/png" sizes="128x128" href={favicon}></link>}
      <meta name="description" content={description} />
      {keywords && (  <meta name="keywords" content={keywords} /> )}
      <meta property="og:type" content={useType} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={useImage} />
      <meta property="og:url" content={useUrl} />
      {name && (  <meta name="twitter:creator" content={name} /> )}
      <meta name="twitter:card" content={useType} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {isIPhone && <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" /> }
      {schema && 
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      }
    </Helmet>
  );
};

export default HeaderTags;