import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { darken } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import './terms.css';


function TermsPageContent() {
  const [pageHtml, setPageHtml] = useState("");
  
  useEffect(() => {
   	window.fetch('/assets/static/terms.txt').then((response) => {
   	  if (response){
   	    response.text().then((val) => setPageHtml(val));
   	  }
   	});
  }, []);

  return (
        <div className="flex flex-col items-center w-full">
          <div className="terms max-w-md" dangerouslySetInnerHTML={{ __html: pageHtml }} />
        </div>
  );
}

export default TermsPageContent;
