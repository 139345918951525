import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

function StyledList({items}) {
   if (!items) return null;
   
   return (
	<List disablePadding sx={{ listStyleType: 'disc', pl: 4}}>
	    {items.map((item, i) => 
            <ListItem key={i} sx={{ display: 'list-item', pt: 1, pb: 1}}>
                <Typography>{item}</Typography>
            </ListItem>
        )}
    </List>
   );
}
   

export default StyledList;
