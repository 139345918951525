import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import ItemIcon from './ItemIcon';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';



function FileItem(props) {
  const { item, onClick } = props;
  const navigate= useNavigate();
  const itemPath = item?.id ? 'view/' + item.id : undefined;
  const itemDownload= item?.objectUrl ? item.objectUrl : undefined;

  if (!item) {
    return null;
  }

  return (
	 <Box
      sx={{ backgroundColor: 'background.paper' }}
      className="relative w-full sm:w-160 h-160 m-8 p-16 shadow rounded-16"
      onClick={onClick}
    >
      {itemPath && 
      <IconButton
        className="absolute z-20 top-0 right-0 m-6 w-32 h-32 min-h-32"
        onClick={() => navigate(itemPath)}
      >
        <FuseSvgIcon size={20}>heroicons-solid:information-circle</FuseSvgIcon>
      </IconButton>}
      
      {itemPath && 
      <NavLinkAdapter
        className="flex flex-col h-full w-full"
        to={itemPath}
        role="button"
      >
         <FileDisplay item={item} />
      </NavLinkAdapter>}
      
      {!itemPath && <FileDisplay item={item}  />}
      
    </Box>
  );
  
  function FileDisplay({item}){
	  return (
		<>
	  	  <div className="flex flex-auto w-full items-center justify-center">
          <ItemIcon className="" type={item.type} />
        </div>
        <div className="flex shrink flex-col justify-center text-center">
          <Typography className="truncate text-12 font-medium">{item.name}</Typography>
          {item.description && (
            <Typography className="truncate text-12 font-medium" color="text.secondary">
              {item.description}
            </Typography>
          )}
        </div>
        </>
	  )
  }
}

export default FileItem;
