import FusePageSimple from '@fuse/core/FusePageSimple';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectIsLoading, startLoading, stopLoading} from 'app/store/utilSlice';
import { useSelector, useDispatch } from 'react-redux';
import CropImageDialog from 'app/shared-components/CropImageDialog';
import React, { useRef, useEffect} from "react";
import FileSelect from 'app/shared-components/controls/FileSelect';
import { showMessage } from 'app/store/fuse/messageSlice';
import { confirmationDialog, dispatchResultMessage } from 'app/shared-components/utils';
import { errorMessage } from 'app/shared-components/util/message';

const validImageExtensions = ["jpg", "jpeg", "bmp", "gif", "png"];
const validVideoExtensions = ["mp4", "mov", "avi", "quicktime"];
const MB= 1048576;
const maxImageSize= 5 * MB;
const maxVideoSize= 100 * MB;
const maxVideoSizeText= "100 MB";
const maxImageSizeText= "5 MB";


function MediaFileSelect({aspectRatio, resize, dialogTitle, fileInputRef, onSaveImage, onSaveVideo, onlyImages}) {
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState();  
  const accept= onlyImages ? "image/*" : "image/*,video/*";

  const onSelectFile= (file) => {
    const validation= validateMediaFile(file, onlyImages);
    if (validation.isValid){
      setSelectedFile(file);
      if (validation.isImage){
        setOpenDialog(true);
      }else{
        const dataUrl= URL.createObjectURL(file)
        onSaveVideo(dataUrl, file);
      }
    }else{
      errorMessage(dispatch, validation.message);
    }
  };
  
  function saveImage(dataUrl, file){
    handleCloseDialog();
	   onSaveImage(dataUrl, file);
  }
  
  function handleCloseDialog() {
    setOpenDialog(false);
    setSelectedFile({})
  }
  

  return (
      <>
          <FileSelect fileInputRef={fileInputRef} accept={accept} onSelectFile={onSelectFile} />
          <CropImageDialog aspectRatio={aspectRatio} resize={resize} dialogTitle={dialogTitle}
                           file={selectedFile} open={openDialog}
                           onSaveCrop={saveImage} onCancel={handleCloseDialog}/>
     </>
  );
}

function validateMediaFile(file, onlyImages) 
{
	const out= {};
	const fileType= file?.type;
    out.isImage=  fileType ? validImageExtensions.some(ext => fileType.endsWith(ext) || fileType.endsWith(ext.toUpperCase())) : false;
    out.isVideo=  fileType ? validVideoExtensions.some(ext => fileType.endsWith(ext) || fileType.endsWith(ext.toUpperCase())) : false;
    out.size= file?.size ? file.size : 0;
    out.type= fileType ? fileType : "NONE";
    out.maxSize= out.isImage ? maxImageSize : out.isVideo ? maxVideoSize : 0;
    out.maxSizeText= out.isImage ? maxImageSizeText : out.isVideo ? maxVideoSizeText : "";
    out.validType= out.isImage || (out.isVideo && !onlyImages);
    out.validSize= out.size < out.maxSize;
    out.isValid= out.validType && out.validSize;
    out.message= !out.validType ? "File type is not allowed: " + fileType :
                 !out.validSize ? "Maximum size for type " + fileType + " is " + out.maxSizeText :
                 "";
    return out;
}

export default MediaFileSelect;
