import React from 'react';
import { Helmet } from 'react-helmet-async';

const HeaderTags404 = (props) => {
  const useType = 'website';
  const title= "Page Not Found";
  const description= "The requested page does not exist.";
  const isIPhone = navigator.userAgent.indexOf('iPhone') > -1;
  const siteName= "Halespring";
  const host = window.location.protocol + '//' + window.location.host;
  const useUrl = host + window.location.pathname;

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={useUrl} />
      <meta name="prerender-status-code" content="404"></meta>
      <meta name="description" content={description} />
      <meta property="og:type" content={useType} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={useUrl} />
      <meta name="twitter:card" content={useType} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {isIPhone && <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" /> }
    </Helmet>
  );
};

export default HeaderTags404;