import {useRef, useEffect, forwardRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MinimizeIcon from '@mui/icons-material/Minimize';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Slide from '@mui/material/Slide';
import Draggable from 'react-draggable';
import { isIOSMobile } from '../util/videoUtils';


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function MinimizableDialog({open, title, children, handleClose, showClose, fullScreen, width, maxWidth, height, maxHeight, zIndex}) {
  const [minimized, setMinimized] = useState(false);
  const useTouch= isIOSMobile();
  const ref = useRef();
  const useWidth= fullScreen ? '100%' : width ? width : "300px";
  const useHeight= fullScreen ? '100%' : height ? height : undefined;
  const useMaxHeight= fullScreen ? '100%' : maxHeight ? maxHeight : undefined;
  const useMaxWidth= fullScreen ? '100%' : maxWidth ? maxWidth : undefined;

  useEffect(() => {
    if (open){
      if (!minimized) {
        ref.current?.showModal();
      } else {
        ref.current?.close();
      }
    }
  }, [minimized, open]);
  

  function minimize(){
	  setMinimized(true);
  }
  
  function restore(){
    console.log("RESTORE");
	  setMinimized(false);
  }
  
  const onClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
      minimize();
    else
      handleClose();
  }
  
  
  function DragDialog({children}) {
    return (
      fullScreen ? children :
      <Draggable
        handle="#draggable-dialog-title"
        cancel={'[class*="dialog-content-root"]'}
      >
        {children}
      </Draggable>
    );
  }

  return (
    open &&
      <>
      
      <dialog ref={ref}
              onCancel={minimize}
              style={{width: useWidth,
                      height: useHeight,
                      maxHeight: useMaxHeight,
                      maxWidth: useMaxWidth,
                      borderRadius: '5px',
                      overflow: 'auto'}}>
      <div className="flex flex-col w-full h-full">
        <AppBar id="draggable-dialog-title" sx={{ position: 'relative',
                                               zIndex: 1,
                                            '& .MuiToolbar-root': {
                                              minHeight: '48px',
                                              paddingLeft: '12px',
                                              paddingRight: '12px',
                                              height: '48px',
                                              width: '100%',
					                                    }}}>
          <Toolbar>
            <div className="flex flex-row w-full">
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {title}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={minimize}
                aria-label="minimize"
              >
                <MinimizeIcon />
              </IconButton>
              {showClose &&
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
              </IconButton>
              }
            </div>
          </Toolbar>
        </AppBar>
        <div className="flex grow min-h-0 w-full dialog-content-root">
          {children}
        </div>
      </div>
      </dialog>
        
       <Draggable>
       <AppBar position="fixed" sx={{ display: !minimized ? 'none' : undefined, 
                                      width: '300px', 
                                      zIndex: 1300,
                                      top: 'auto',
                                      bottom: 0, 
                                      left: 0,
                                      '& .MuiToolbar-root': {
										paddingLeft: '10px',
                                        paddingRight: '10px',
                                        minHeight: '48px',
									  }}}>
          <Toolbar>
            <Typography noWrap sx={{ marginLeft: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={!useTouch ? restore : undefined}
              onTouchStart={useTouch ? restore : undefined}
              aria-label="restore"
            >
              <FuseSvgIcon>heroicons-outline:window</FuseSvgIcon>
            </IconButton>
            {showClose &&
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
            </IconButton>
            }
          </Toolbar>
        </AppBar>
        </Draggable>
      </>
  );
}
