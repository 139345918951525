import HeaderTags from 'app/shared-components/helmet/HeaderTags';



const UserProfileHeaderTags = ({ id, name, screenName, image, description, keywords }) => {
  const host = window.location.protocol + '//' + window.location.host;
  const useType = 'profile';
  const url = host + window.location.pathname;
  const baseUrl= host + "/public/profile/";
  const screenNameUrl= baseUrl + screenName;
  const idUrl= baseUrl + id;
  
  const schema=
     {
      "@context": "https://schema.org",
      "@type": "ProfilePage",
      "mainEntity": {
        "@type": "Person",
        "name": name,
        "alternateName": screenName,
        "identifier": id,
        "description": description,
        "image": image,
        "sameAs": [
          screenNameUrl,
          idUrl
        ]
      }
    }
  
  return (
    <HeaderTags title={name} description={description} keywords={keywords} type={useType} 
                image={image} schema={schema} canonicalURL={screenNameUrl} />
  );
};

export default UserProfileHeaderTags;