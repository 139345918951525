import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import styled from '@emotion/styled';

const BreadcrumbUl = styled('ul')`
  li {
    white-space: nowrap;
  }

  li::before {
    content: '•';
    margin-right: 0.8rem;
  }

  @media (min-width: 1024px) {
    li::before {
      margin-left: 0.8rem;
    }

    li:first-of-type::before {
      content: '';
    }
  }
`;

/**
 * @param {{
 *   trail: {
 *     label: string;
 *     to: string;
 *   }[];
 *   className?: string;
 * }} props
 */
export default function Breadcrumbs({ trail = [], className, ...props }) {
  return (
    <BreadcrumbUl
      className={twMerge(classNames([
        'text-hs-grey-black text-16 flex',
        className
      ]))}
      {...props}
    >
      {trail.map((item, i) =>
        <li
          key={item.to}
        >
          <Link
            className={i >= (trail.length - 1) ? 'font-normal' : 'font-semibold' }
            to={item.to}
          >
            {item.label}
          </Link>
        </li>
      )}
    </BreadcrumbUl>
  )
}