import lazyLoad from 'app/shared-components/util/lazyLoad';

const FindCareApp = lazyLoad(() => import('./FindCareApp'));
const RequestCareApp = lazyLoad(() => import('./RequestCareApp'));


const FindCareConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/find',
      element: <FindCareApp />,
    },
    {
      path: '/request',
      element: <RequestCareApp />,
    },
  ],
};

export default FindCareConfig;


