import { createEntityAdapter, createSelector, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios';

/**
 * @template T
 * @typedef {import('./strapi').CollectionApiResponseWrapper<T>} CollectionApiResponseWrapper
 */
/** @typedef {import('./marketing').Person} Person */

/** @type {import('@reduxjs/toolkit').EntityAdapter<Person, number>} */
const peopleAdaptor = createEntityAdapter({
  selectId: (person) => person.slug,
});

const initialState = peopleAdaptor.getInitialState({
  isLoading: false
});

/** @type {import('@reduxjs/toolkit').AsyncThunk<Person, string, {}>}  */
export const fetchPersonBySlug = createAsyncThunk(
  'marketing/people/fetchPersonBySlug',
  async (slug) => {
    /** @type {import('axios').AxiosResponse<CollectionApiResponseWrapper<Person>>} */
    const response = await axios({
      method: 'GET',
      url: 'people',
      params: {
        populate: '*',
        filters: { slug }
      }
    });

    return response.data.data;
  }
);

/** @type {import('@reduxjs/toolkit').AsyncThunk<Person[], any, {}>}  */
export const fetchPeople = createAsyncThunk(
  'marketing/people/fetchPeople',
  async ({ filters, sort, pagination }) => {
    /** @type {import('axios').AxiosResponse<CollectionApiResponseWrapper<Person>>} */
    const response = await axios({
      method: 'GET',
      url: 'people',
      params: {
        populate: '*',
        filters,
        sort,
        pagination
      }
    });

    return response.data.data;
  }
);

const peopleSlice = createSlice({
  name: 'marketing/people',

  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchPersonBySlug.fulfilled, (state, action) => {
        if (action.payload) {
          peopleAdaptor.upsertOne(state, action.payload);
        }
      })
      .addCase(fetchPeople.fulfilled, (state, action) => {
        if (action.payload) {
          peopleAdaptor.upsertMany(state, action.payload);
        }
      });
  }
});

/** @typedef {import('./index').RootState} RootState */

const selectPeopleState = (/** @type {RootState} */ state) => state.marketing.people;

export const selectPeople = () => createSelector(
  [selectPeopleState],
  (people) => people.ids.map((slug) => people.entities[slug])
);

export const selectPersonBySlug = (/** @type {number} */ slug) =>
  (/** @type {import('./index').RootState } */ state) =>
    state.marketing.people.entities[slug];

export default peopleSlice.reducer;
