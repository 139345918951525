import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { stopLoading, startLoading} from 'app/store/utilSlice';
import { fetchUsers, fetchInvites, getUserStatus, addUserFollow, getProviderInfo, getUserByScreenName, fetchEmailInvites, setUserTags,
        removeUserFollow, createInvite, addUserConnection, getUser, removeUserConnection, fetchOutgoingInvites, removeEmailInvite,
        removeConnectionInvite, adminUpdateUser, createEmailInvite, declineInvite, adminGetUser, setFavoriteStatus} from './contactFunctions';
import {getConnections} from './connectionsSlice';
import {setRefreshFeed} from 'app/store/homeSlice';


export const getContactsByType= createAsyncThunk('contacts/getContactsByType',
 async (params, { dispatch, getState }) => {
	 try{
		  dispatch(startLoading());
		  const {type, refresh}= params;
		  const { user, contacts } = getState();
		  console.log("Inside getContactsByType " + type + ": " + user.id);
		  const contactData= contacts[type];
		  console.log(contactData);
		  if (!refresh && contactData.ids.length > 0 && type != 'search' && type != 'tagged') return null;
		  else{
			  dispatch(startLoading());
			  let data= await fetchUsers(user.id, params);
			  return { data };
		  }
	}finally{
		dispatch(stopLoading());
	}
});

export const getContactStatus= createAsyncThunk('contacts/getContactStatus',
 async ({contact, refresh}, { getState }) => {
	  const { user, contacts } = getState();
	  console.log("Inside getContactStatus " + contact.id + ": " + user.id);
	  if(!refresh){
		  const status= selectContactById(contacts.contactStatus, contact.id);
		  if (status)return status;
	  } 
	  const data= await getUserStatus(user.id, contact.id);
	  return data;
});

export const updateFavoriteStatus= createAsyncThunk('contacts/updateFavoriteStatus',
 async ({contactId, isFavorite, notes}, { dispatch, getState }) => {
    try{
		  dispatch(startLoading());
		  const { user } = getState();
    	  const data= await setFavoriteStatus(user.id, contactId, isFavorite, notes);
    	  return data;
	}finally{
		dispatch(stopLoading());
	}
});

export const updateUserTags= createAsyncThunk('contacts/updateUserTags',
 async ({contactId, tags}, { dispatch, getState }) => {
    try{
		  dispatch(startLoading());
		  const { user } = getState();
    	  const data= await setUserTags(user.id, contactId, tags);
    	  dispatch(getContactStatus({refresh: true, contact: {id: contactId}}));
    	  return data;
	}finally{
		dispatch(stopLoading());
	}
});

export const loadProviderInfo= createAsyncThunk('contacts/loadProviderInfo',
 async (params, { getState }) => {
	  const { providerInfoId } = params;
	  const data= await getProviderInfo(providerInfoId);
	  return data;
});

export const loadUserById= createAsyncThunk('contacts/loadUserById',
 async (params, { getState }) => {
	  const { userId } = params;
	  const data= await getUser(userId);
	  return data;
});

export const adminLoadUserById= createAsyncThunk('contacts/adminLoadUserById',
 async (params, { getState }) => {
	  const { userId } = params;
	  const data= await adminGetUser(userId);
	  return data;
});

export const loadUserByScreenName= createAsyncThunk('contacts/loadUserByScreenName',
 async (params, { getState }) => {
	  const { screenName } = params;
	  const data= await getUserByScreenName(screenName);
	  return data;
});

export const updateContactStatus= createAsyncThunk('contacts/updateContactStatus',
 	async (params, { getState }) => {
  
  const { contacts, user } = getState();
  console.log("Inside updateContactStatus for " + params.contactId + ": " + params.add + ", " + params.remove);
  
  //Fetch status if it hasn't been fetched yet
  let existing= selectContactById(contacts.contactStatus, params.contactId);
  if (!existing){
	  existing= await getUserStatus(user.id, params.contactId);
  }
  
  //Copy object
  let status= { id: existing.id,  details: [...existing.details]};
  
  //Add the status in 'add'
  if (params.add){
	if (!status.details.includes(params.add)){
		status.details.push(params.add);
	}
  }
  
  //Remove the status in 'remove'
  if (params.remove){
	const index = status.details.indexOf(params.remove);
	if (index > -1) { 
	  status.details.splice(index, 1); 
	}
  }
  
  //Return the new status object
  return status;
});

export const followUser= createAsyncThunk('contacts/followUser',
 	async (params, { dispatch, getState }) => {
  
  const { user, contacts } = getState();
  console.log("Inside followUser: " + params.contactId);
  const contactEntity= selectContactById(contacts.all, params.contactId);
  await addUserFollow(user.id, params.contactId);
  
  //Update contact status 
  dispatch(updateContactStatus({contactId: params.contactId, add: 'following'}));
  dispatch(setRefreshFeed());
  return contactEntity;
});

export const unfollowUser= createAsyncThunk('contacts/unfollowUser',
 	async (params, { dispatch, getState }) => {
  
  const { user } = getState();
  console.log("Inside unfollowUser: " + params.contactId);
  
  await removeUserFollow(user.id, params.contactId);
  
  //Update contact status 
  dispatch(updateContactStatus({contactId: params.contactId, remove: 'following'}));
  dispatch(setRefreshFeed());
  return params.contactId;
});

export const removeConnection= createAsyncThunk('contacts/removeConnection',
 	async (params, { dispatch, getState }) => {
  
  const { user } = getState();
  console.log("Inside removeConnection: " + params.contactId);
  
  await removeUserConnection(user.id, params.contactId);
  
  //Update contact status 
  dispatch(updateContactStatus({contactId: params.contactId, remove: 'connected'}));
  
  //Update contact list in chat panel
  dispatch(getConnections());
  
  return params.contactId;
});

export const removeInvitation= createAsyncThunk('contacts/removeInvitation',
 	async (params, { dispatch, getState }) => {
  
  const { user } = getState();
  console.log("Inside removeInvitation: " + params.contactId);
  
  await removeConnectionInvite(user.id, params.contactId);
  
  //Update contact status 
  dispatch(updateContactStatus({contactId: params.contactId, remove: 'invited'}));
  return params.contactId;
});

export const removeEmailInvitation= createAsyncThunk('contacts/removeEmailInvitation',
 	async (params, { dispatch, getState }) => {
  
  const { user } = getState();
  console.log("Inside removeEmailInvitation: " + params.id);
  await removeEmailInvite(params.id);
  return params.id;
});

export const getInvites= createAsyncThunk('contacts/getInvites',
 async (params, { dispatch, getState }) => {
	 try{
		  const { user, contacts } = getState();
		  const {refresh }= params;
		  console.log("Inside getInvites: " + user.id);
		  const contactData= contacts.invites;
		  if (!refresh && !contactData.refresh && contactData.ids.length > 0) return null;
		  else {
			  dispatch(startLoading());
		  	  let data= await fetchInvites(user.id, params);
		      return { data };
		  }
	}finally{
		dispatch(stopLoading());
	}
});

export const getOutgoingInvites= createAsyncThunk('contacts/getOutgoingInvites',
 async (params, { dispatch, getState }) => {
	 try{
		  const { user, contacts } = getState();
		  const { refresh }= params;
		  console.log("Inside getOutgoingInvites: " + user.id);
		  const contactData= contacts.outgoingInvites;
		  if (!refresh && !contactData.refresh && contactData.ids.length > 0) return null;
		  else {
			  dispatch(startLoading());
		  	  let data= await fetchOutgoingInvites(user.id, params);
		      return { data };
		  }
	}finally{
		dispatch(stopLoading());
	}
});

export const getEmailInvites= createAsyncThunk('contacts/getEmailnvites',
 async (params, { dispatch, getState }) => {
	 try{
		  const { user, contacts } = getState();
		  const { refresh }= params;
		  console.log("Inside getEmailnvites: " + user.id);
		  const contactData= contacts.emailInvites;
		  if (!refresh && !contactData.refresh && contactData.ids.length > 0) return null;
		  else {
			  dispatch(startLoading());
			  //Need qualified user key since email invite owner can also be a channel
		  	  let data= await fetchEmailInvites("User:" + user.id, params);
		      return { data };
		  }
	}finally{
		dispatch(stopLoading());
	}
});

export const inviteViaEmail= createAsyncThunk('contacts/inviteViaEmail',
 	async (params, { dispatch, getState }) => {
  
  await createEmailInvite(params);
  
  dispatch(stopLoading());
  return params.contactId;
});

export const inviteConnection= createAsyncThunk('contacts/inviteConnection',
 	async (params, { dispatch, getState }) => {
  
  const { user } = getState();
  console.log("Inside inviteConnection: " + user.id + ", " + params.contactId);
  await createInvite(user.id, params.contactId, params.message);
  
  //Update contact status 
  dispatch(updateContactStatus({contactId: params.contactId, add: 'invited'}));
  dispatch(stopLoading());
  return params.contactId;
});

export const acceptInvite= createAsyncThunk('contacts/acceptInvite',
 	async (params, { dispatch, getState }) => {
  
  const { user } = getState();
  console.log("Inside acceptInvite: " + params.contactId);
  await addUserConnection(user.id, params.contactId);
  
  //Update contact status 
  dispatch(updateContactStatus({contactId: params.contactId, add: 'connected'}));
  
  //Add the user to connections list
  dispatch(addContactToConnections(params.contactId));
  
  //Update contact list in chat panel
  dispatch(getConnections({}));
  
  return params.contactId;
});

export const rejectInvite= createAsyncThunk('contacts/rejectInvite',
 	async (params, { dispatch, getState }) => {
  const { user } = getState();
  
  console.log("Inside rejectInvite: " + params.contactId);
  await declineInvite(user.id, params.contactId);
  return params.contactId;
});

export const addContactToConnections= createAsyncThunk('contacts/addContactToConnections',
 async (contactId, { getState }) => {
	  const { user } = getState();
	  console.log("Inside addContactToConnections " + contactId + ": " + user.id);
	  const data= await getUser(contactId);
	  return data;
});

export const adminUpdateContact= createAsyncThunk('contacts/adminUpdateContact',
 async (params, { getState }) => {
	  const {userId, data} = params;
	  console.log("Inside adminUpdateContact: " + userId);
	  const resp= await adminUpdateUser(userId, data);
	  return resp;
});

const contactsAdapter = createEntityAdapter({});
const providerInfoAdapter = createEntityAdapter({});


export const { selectAll: selectContacts, selectById: selectContactById } =
  contactsAdapter.getSelectors((state) => state);
  
const { selectById: selectProviderInfo } =
  providerInfoAdapter.getSelectors((state) => state);
  
export const selectContactsByType = (type) => state => {
	console.log("Selecting contacts for type: " + type);
    const contacts= state.contacts[type];
    return selectContacts(contacts);
}

export const selectContactByTypeAndId = (type, id) => state => {
	console.log("Selecting contact for type: " + type + " and id " + id);
	if (!id) return null;
    const contacts= state.contacts[type];
    return selectContactById(contacts, id);
}

export const selectUserById = (id) => state => {
	console.log("Selecting user with id " + id);
	if (!id) return null;
    return selectContactById(state.contacts.all, id);
}

export const selectProviderInfoById = (id) => state => {
	if (!id) return null;
    return selectProviderInfo(state.contacts.providerInfo, id);
}

export const selectUserByScreenName = (name) => state => {
	console.log("Selecting user with screenName " + name);
	if (!name) return null;
	const id= state.contacts.screenNameMap[name];
    return selectContactById(state.contacts.all, id);
}

export const selectSearchTextByType = (type) => state => {
	console.log("Selecting contacts for type: " + type);
    const contacts= state.contacts[type];
    return contacts.searchText;
}

export const selectIsLoadedByType = (type) => state => {
    const contacts= state.contacts[type];
    return contacts.isLoaded;
}

/*export const selectFilteredContacts = createSelector(
  [selectContacts, selectSearchText],
  (contacts, searchText) => {
    if (searchText.length === 0) {
      return contacts;
    }
    return FuseUtils.filterArrayByString(contacts, searchText);
  }
);

export const selectGroupedFilteredContacts = createSelector(
  [selectFilteredContacts],
  (contacts) => {
    return contacts
      .sort((a, b) => a.name.localeCompare(b.name, 'es', { sensitivity: 'base' }))
      .reduce((r, e) => {
        // get first letter of name of current element
        const group = e.name[0];
        // if there is no property in accumulator with this letter create it
        if (!r[group]) r[group] = { group, children: [e] };
        // if there is push current element to children array for that letter
        else r[group].children.push(e);
        // return accumulator
        return r;
      }, {});
  }
);*/


const contactsSlice = createSlice({
  name: 'contacts',
  initialState:{
	  connections: contactsAdapter.getInitialState({
				    searchText: '',
				    isLoaded: false,
				   }),
	  followers: contactsAdapter.getInitialState({
				    searchText: '',
				    isLoaded: false,
				   }),
	  following: contactsAdapter.getInitialState({
				    searchText: '',
				    isLoaded: false,
				   }),
	  favorite: contactsAdapter.getInitialState({
				    searchText: '',
				    isLoaded: false,
				   }),
	  tagged: contactsAdapter.getInitialState({
				    searchText: '',
				    isLoaded: false,
				   }),
	  search: contactsAdapter.getInitialState({
				    searchText: '',
				    isLoaded: false,
				   }),
	  suggested: contactsAdapter.getInitialState({
				    searchText: '',
				    isLoaded: false,
				   }),
	  all: contactsAdapter.getInitialState({
				    searchText: '',
				   }),
	  contactStatus: contactsAdapter.getInitialState(),
	  providerInfo: providerInfoAdapter.getInitialState(),
	  invites: contactsAdapter.getInitialState({refresh: false}),
	  outgoingInvites: contactsAdapter.getInitialState({refresh: false}),
	  emailInvites: contactsAdapter.getInitialState({refresh: false}),
	  screenNameMap: {},
	  inviteContact: null,
	  emailInvite: null,
	  userViewDialogId: null,
	  userNotesDialogId: null,
	  userTagsDialogId: null,
	  userRecommendDialogId: null,
	  dmUserDialogDetails: null,
  }, 
  reducers: {
	resetSearchResults: {
      reducer: (state, action) => {
        state.search = contactsAdapter.getInitialState({
									    searchText: '',
									    isLoaded: false,
									   });
      }
    },
    setInviteContact: {
      reducer: (state, action) => {
        state.inviteContact = action.payload;
      }
    },
    setEmailInvite: {
      reducer: (state, action) => {
        state.emailInvite = action.payload;
      }
    },
    setUserViewDialogId: {
      reducer: (state, action) => {
        state.userViewDialogId = action.payload;
      }
    },
    setUserNotesDialogId: {
      reducer: (state, action) => {
        state.userNotesDialogId = action.payload;
      }
    },
    setUserTagsDialogId: {
      reducer: (state, action) => {
        state.userTagsDialogId = action.payload;
      }
    },
    setUserRecommendDialogId: {
      reducer: (state, action) => {
        state.userRecommendDialogId = action.payload;
      }
    },
    setDMUserDialogDetails: {
      reducer: (state, action) => {
        state.dmUserDialogDetails = action.payload;
      }
    },
    addUserToAll: {
      reducer: (state, action) => {
		contactsAdapter.setOne(state.all, action.payload);
		state.screenNameMap[action.payload.screenName] = action.payload.id;
      }
    },
    addUsersToAll: {
      reducer: (state, action) => {
		contactsAdapter.setMany(state.all, action.payload);
      }
    },
  },
    extraReducers: builder => {
    builder.addCase(followUser.fulfilled, (state, action) => {
		contactsAdapter.addOne(state.following, action.payload);
		contactsAdapter.addOne(state.all, action.payload);
    })
    .addCase(getContactStatus.fulfilled, (state, action) => {
		contactsAdapter.setOne(state.contactStatus, action.payload);
    })
    .addCase(updateFavoriteStatus.fulfilled, (state, action) => {
		contactsAdapter.setOne(state.contactStatus, action.payload);
		if (action.payload.isFavorite) {
		    const contact= state.all.entities[action.payload.id];
		    if (contact) contactsAdapter.addOne(state.favorite, contact);
		}
		else contactsAdapter.removeOne(state.favorite, action.payload.id);
    })
    .addCase(updateContactStatus.fulfilled, (state, action) => {
        contactsAdapter.setOne(state.contactStatus, action.payload);
    })
    .addCase(inviteConnection.fulfilled, (state, action) => {
        const contactId= action.meta.arg.contactId;
        state.invites.refresh= true;
    })
    .addCase(inviteViaEmail.fulfilled, (state, action) => {
        state.emailInvites.refresh= true;
    })
    .addCase(loadProviderInfo.fulfilled, (state, action) => {
        providerInfoAdapter.setOne(state.providerInfo, action.payload);
    })
    .addCase(loadUserById.fulfilled, (state, action) => {
        contactsAdapter.setOne(state.all, action.payload);
        state.screenNameMap[action.payload.screenName] = action.payload.id;
    })
    .addCase(adminLoadUserById.fulfilled, (state, action) => {
        contactsAdapter.setOne(state.all, action.payload);
        state.screenNameMap[action.payload.screenName] = action.payload.id;
    })
    .addCase(loadUserByScreenName.fulfilled, (state, action) => {
        contactsAdapter.setOne(state.all, action.payload);
        state.screenNameMap[action.payload.screenName] = action.payload.id;
    })
    .addCase(acceptInvite.fulfilled, (state, action) => {
        const contactId= action.meta.arg.contactId;
		contactsAdapter.removeOne(state.invites, contactId);
    })
    .addCase(rejectInvite.fulfilled, (state, action) => {
        const contactId= action.meta.arg.contactId;
		contactsAdapter.removeOne(state.invites, contactId);
    })
    .addCase(addContactToConnections.fulfilled, (state, action) => {
		contactsAdapter.addOne(state.connections, action.payload);
		contactsAdapter.addOne(state.all, action.payload);
    })
    .addCase(adminUpdateContact.fulfilled, (state, action) => {
		contactsAdapter.setOne(state.all, action.payload);
    })
    .addCase(unfollowUser.fulfilled, (state, action) => {
		let contactId= action.payload;
		contactsAdapter.removeOne(state.following, contactId);
    })
    .addCase(removeInvitation.fulfilled, (state, action) => {
		let contactId= action.payload;
		contactsAdapter.removeOne(state.outgoingInvites, contactId);
    })
    .addCase(removeEmailInvitation.fulfilled, (state, action) => {
		let id= action.payload;
		contactsAdapter.removeOne(state.emailInvites, id);
    })
    .addCase(removeConnection.fulfilled, (state, action) => {
		let contactId= action.payload;
		contactsAdapter.removeOne(state.connections, contactId);
    })
    .addCase(getContactsByType.fulfilled, (state, action) => {
        if (action.payload?.data){
			const type = action.meta.arg.type;
        	contactsAdapter.setAll(state[type], action.payload.data);
        	contactsAdapter.setMany(state.all, action.payload.data);
        	state[type].searchText = '';
        	state[type].isLoaded = true;	
		}
    })
    .addCase(getInvites.fulfilled, (state, action) => {
        if (action.payload?.data){
        	const contacts= state.invites;
        	contactsAdapter.setAll(contacts, action.payload.data);	
        	contacts.refresh= false;
		}
    })
    .addCase(getOutgoingInvites.fulfilled, (state, action) => {
        if (action.payload?.data){
        	const contacts= state.outgoingInvites;
        	contactsAdapter.setAll(contacts, action.payload.data);	
        	contacts.refresh= false;
		}
    })
    .addCase(getEmailInvites.fulfilled, (state, action) => {
        if (action.payload?.data){
        	const contacts= state.emailInvites;
        	contactsAdapter.setAll(contacts, action.payload.data);
        	contacts.refresh= false;
		}
    })
  }
})

export const { setInviteContact, setEmailInvite, setUserViewDialogId, setUserNotesDialogId, setUserTagsDialogId, setUserRecommendDialogId, 
               setDMUserDialogDetails, addUserToAll, addUsersToAll, resetSearchResults} = contactsSlice.actions;

export const selectInviteContact = ({ contacts }) => contacts.inviteContact;
export const selectEmailInvite = ({ contacts }) => contacts.emailInvite;
export const selectUserViewDialogId = ({ contacts }) => contacts.userViewDialogId;
export const selectUserNotesDialogId = ({ contacts }) => contacts.userNotesDialogId;
export const selectUserTagsDialogId = ({ contacts }) => contacts.userTagsDialogId;
export const selectUserRecommendDialogId = ({ contacts }) => contacts.userRecommendDialogId;
export const selectDMUserDialogDetails = ({ contacts }) => contacts.dmUserDialogDetails;
export default contactsSlice.reducer;
