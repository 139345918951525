import React, { Fragment, useRef, useState } from 'react';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import getCaretCoordinates from 'textarea-caret';
import { getActiveToken, replaceAt } from 'app/shared-components/utils.js';
import { selectConnections } from 'app/store/connectionsSlice';
import { selectAllMembersByChannelId } from 'app/store/channelsSlice';
import { useSelector } from 'react-redux';
import UserAvatar from '../UserAvatar';
import UserName from '../UserName';
import './css/UserAutocomplete.css';



export function UserAutocomplete( props ) 
{
  const inputRef = useRef(null)
  const { mode, value, setTextValue, setCursorPosition, onPaste, onFocus, onBlur, channelId, onEnterPress, id }= props;
  const userList= mode === 'channel' ? useSelector(selectAllMembersByChannelId(channelId)) : useSelector(selectConnections)
  const [filteredList, setFilteredList] = useState([]);
  const [listOpen, setListOpen] = useState(false);
  const [loadingState, setLoadingState] = useState("completed");
  
  const onSelectItem = (item) => {
	  const cursorPosition = inputRef.current?.selectionEnd || 0;
      const activeToken= getActiveToken(value, cursorPosition);
      const [index] = activeToken.range;
      const replacement = `@${item.screenName} `;
      const newValue = replaceAt(
        value,
        replacement,
        index,
        activeToken.word.length
      );

      setTextValue(newValue);
      console.log(newValue);

      if (inputRef.current) {
        inputRef.current.selectionEnd = index + replacement.length;
        inputRef.current.focus();
      }
      
      setListOpen(false);
  }
  
  const handleOnChange = (event) => {
	  setTextValue(event.target.value);
      onInputNavigate();
  };
  
  const handleOnFocus = (event) => {
	  onFocus(event);
  };
  
  const handleOnBlur = (event) => {
	  onBlur(event);
  };
 
  function onInputNavigate() {
    const cursorPosition = inputRef.current?.selectionEnd || 0;
    const val = inputRef.current?.value || '';
    const activeToken= getActiveToken(val, cursorPosition);
    let shouldOpen= false;
    if (activeToken?.word && isPotentialScreenName(activeToken?.word)) {
		  setFilteredList(filterUsers(userList, activeToken.word.slice(1)));
		  shouldOpen= true;
      }
	setListOpen(shouldOpen);
	setCursorPosition(cursorPosition);
  }

  const isPotentialScreenName= (username) => {
	  if (!username) return false;
	  if (!(username[0] == '@')) return false;
	  if (username.length < 4) return false;
	  return true;
  }
  
  const { top, left, height } = inputRef.current
    ? getCaretCoordinates(inputRef.current, inputRef.current?.selectionEnd)
    : { top: 0, height: 0 };

  return (
		<div style={{position: 'relative'}} className="w-full">
          <TextareaAutosize style={{ resize: 'none' }}
              ref={inputRef}
            id={id}
	          className="p-10 w-full rounded-full"
	          placeholder="Write something.."
	          rows="1"
	          maxRows="3"
	          margin="none"
	          value={value}
	          onChange={handleOnChange}
	          onClick={(event) => {
	              onInputNavigate();
	            }}
	          onKeyUp={(event) => {
	              if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
	                onInputNavigate();
	              }
	            }}
	          onKeyDown={(event) => {
	              if (event.key === "Enter") {
				  	if (onEnterPress) onEnterPress(event);
				  }
	            }}
	          onPaste={onPaste}
	      />
        
        <div className="autocomplete-panel" style={{ top: top + 'px', left: left + 'px' }} >
          {loadingState === 'stalled' && listOpen && (
            <div className="autocomplete-loading">
              <svg
                className="autocomplete-loading-icon"
                viewBox="0 0 100 100"
                fill="currentColor"
              >
                <circle
                  cx="50"
                  cy="50"
                  r="35"
                  fill="none"
                  stroke="currentColor"
                  strokeDasharray="164.93361431346415 56.97787143782138"
                  strokeWidth="6"
                >
                  <animateTransform
                    attributeName="transform"
                    dur="1s"
                    keyTimes="0;0.40;0.65;1"
                    repeatCount="indefinite"
                    type="rotate"
                    values="0 50 50;90 50 50;180 50 50;360 50 50"
                  ></animateTransform>
                </circle>
              </svg>
            </div>
          )}
          {loadingState === 'completed' && listOpen &&
               filteredList.length > 0 && (
				 <div>
                    <ul
                      className="autocomplete-items"
                    >
                      {filteredList.map((item) => {
                        return (
                          <li key={item.screenName} onClick={(e) => onSelectItem(item)}>
                            <div
                              className='autocomplete-item'   
                            >
                              <AccountItem user={item}/>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                 </div>
           )}  
        </div>
  </div>

  );
}


function AccountItem({ user }) {
  return (
    <div className="account-body">
      <UserAvatar user={user} disableClick={true}/>
      <div>
        <div className="account-name">
          <UserName user={user}/>
        </div>
        <div className="account-handle">
          @{user.screenName}
        </div>
      </div>
    </div>
  );
}

const filterUsers = (userList, query) => {
	console.log('Query: ' + query);
	const q= query.toLowerCase();
	return userList.filter(user => user.firstName.toLowerCase().includes(q) ||
	                               user.lastName.toLowerCase().includes(q) ||
	                               user.screenName.toLowerCase().includes(q));
}
