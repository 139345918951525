import lazyLoad from 'app/shared-components/util/lazyLoad';

const AdminApp = lazyLoad(() => import('./AdminApp'));


const AdminAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/admin',
      element: <AdminApp />,
      children: [
		{
          path: 'reports',
          element: <div />,
        },
        {
          path: 'config',
          element: <div />,
        },
        {
          path: 'users',
          element: <div />,
        },
        {
          path: 'plans',
          element: <div />,
        },
        {
          path: 'plan/:planId',
          element: <div />,
        },
        {
          path: 'helpcontent',
          element: <div />,
        },
        {
          path: 'helpcontent/:contentId',
          element: <div />,
        },
        {
          path: 'stats',
          element: <div />,
        },
        {
          path: 'user/:userId',
          element: <div />,
        },
        
        {
          path: 'support/chat/:chatId',
          element: <div />,
        }
      ],
    },
  ],
};

export default AdminAppConfig;
