import authRoles from '../../../auth/authRoles';
import Blog from './Blog';
import BlogPost from './BlogPost';
import { Navigate } from 'react-router-dom';

const HomeConfig = {
  settings: {
    layout: {
      style: 'layoutMarketing',

      config: {
        header: {
          display: true
        },

        footer: {
          display: true
        }
      },
    },
  },

  auth: null, //Anyone can access regardless of logged in status

  routes: [
    {
      path: 'blog',
      element: <Blog />,
    },
    {
      path: 'blog/:slug',
      element: <BlogPost />,
    },
  ],
};

export default HomeConfig;
