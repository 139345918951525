import Button from '@mui/material/Button';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import UserAvatar from './UserAvatar'; 
import UserName from './UserName';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Box from '@mui/system/Box';
import Stack from '@mui/system/Stack';
import format from 'date-fns/format';
import ProviderInfo from 'app/shared-components/user/ProviderInfo';
import DefaultButton from '../layout/DefaultButton';
import _ from '@lodash';
import history from '@history';
import { selectUserById, selectContactByTypeAndId, getContactStatus, followUser,
         selectProviderInfoById, loadProviderInfo, loadUserById,
         unfollowUser, acceptInvite, setInviteContact, removeConnection, removeInvitation} from 'app/store/contactsSlice';
import { dispatchResultMessage, confirmationDialog } from '../utils';
import { fetchGivenRecommendation } from 'app/store/userRecommendationSlice';
import { selectUser} from 'app/store/userSlice';
import DisplayUserHtml from 'app/shared-components/layout/DisplayUserHtml';
import UserProfileViewHeader from 'app/shared-components/user/UserProfileViewHeader';


const UserView = (props) => {
  const dispatch = useDispatch();
  const { userId, returnPath, actions, chips } = props;
  const user = useSelector(selectUserById(userId));
  const isProvider= user?.type === 'provider';
  const currentUser = useSelector(selectUser);
  const providerInfo= useSelector(selectProviderInfoById(userId));
  let isCurrentUser= currentUser.id == userId;
  console.log("userview");
  console.log(user);

  useEffect(() => {
    if (userId){
    	if (!user) {
    	   dispatch(loadUserById({userId: userId}));
    	}else{
    	   if (!isCurrentUser) {
    	     dispatch(getContactStatus({contact: user }));
    	     dispatch(fetchGivenRecommendation({contactId: userId}));
    	   }
    	   if (isProvider && !providerInfo){
    		    dispatch(loadProviderInfo({providerInfoId: user.id}))
    	   }
    	}
    }
  }, [userId, user]);
  
  
  if (!user) return null;
 
  return (
    <>
        <UserProfileViewHeader user={user} actions={actions} chips={chips} forceNarrow />
      
        <Divider className="mt-16 mb-24" />
        
        {isProvider && (
        <div className="mr-12 pb-12 flex flex-grow">
	        <ProviderInfo user={user} providerInfo={providerInfo} forceNarrow />
	      </div>
	      )}
		  
		   {!isProvider && (
        <div className="flex flex-col space-y-32 mx-24">
          {user.about && (
            <div className="flex items-center">
              <DisplayUserHtml id="user-about" html={user.about} maxHeight="400px"/>
            </div>
          )}


          {user.city && (
            <div className="flex items-center">
              <FuseSvgIcon>heroicons-outline:location-marker</FuseSvgIcon>
              <div className="ml-24 leading-6">{user.city + ", " + user.stateProvince}</div>
            </div>
          )}

        </div>
        )}
          
    </>
  );
};

export default UserView;
