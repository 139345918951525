import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import DefaultButton from 'app/shared-components/layout/DefaultButton';
import { darken } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';


function CookiePageContent() {

  const cookieSettingsStyle = {
      padding: "8px 30px",
      backgroundColor: "#F8F9FA",
      color: "#858A8F",
      border: "1px solid #DEE2E6",
      boxSizing: "border-box",
      borderRadius: "2px",
      cursor: "pointer"
  };
  
  return (
    
        <div className="flex flex-col items-center">
        
        <div className="flex flex-col items-center max-w-md"> 
        
          <div>
            <Typography className="mt-4 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
              1. What are cookies?
            </Typography>
          </div>

          <div className="w-full">
            <Typography
              className="mt-12 sm:text-2xl text-left tracking-tight"
              color="text.secondary"
            >
              <p className="mb-12">This Cookie Policy explains what cookies are and how we use them, the types of cookies we use (i.e. the information we collect using cookies and how that information is used), and how to manage the cookie settings.</p>
              <p>Cookies are small text files that are used to store small pieces of information. They are stored on your device when the website is loaded on your browser. These cookies help us make the website function properly, make it more secure, provide better user experience, and understand how the website performs and to analyze what works and where it needs improvement.</p>
            </Typography>
          </div>
          
          <div>
            <Typography className="mt-20 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
              2. How do we use cookies?
            </Typography>
          </div>

          <div className="w-full">
            <Typography
              className="mt-12 sm:text-2xl text-left tracking-tight"
              color="text.secondary"
            >
              <p className="mb-12">As most of the online services, our website uses first-party and third-party cookies for several purposes. First-party cookies are mostly necessary for the website to function the right way, and they do not collect any of your personally identifiable data.</p> 
              <p>The third-party cookies used on our website are mainly for understanding how the website performs, how you interact with our website, keeping our services secure, providing advertisements that are relevant to you, and all in all providing you with a better and improved user experience and help speed up your future interactions with our website.</p>
            </Typography>
          </div>
          
          <div>
            <Typography className="mt-20 text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
              3. Types of Cookies we use
            </Typography>
          </div>

          {/*Auto-generated by CookieYes using the className cky-audit-table-element*/}
          <div className="mt-12 w-full" color="text.secondary">
              <div className="cky-audit-table-element"></div>
          </div>
          
          <div>
            <Typography className="mt-20  text-4xl sm:text-7xl font-extrabold tracking-tight leading-tight text-center">
              4. Manage cookie preferences
            </Typography>
          </div>
          
          {/*Auto-generated by CookieYes using the className cky-banner-element*/}
          <div className="mt-12" color="text.secondary">
              <a className="cky-banner-element" style={cookieSettingsStyle}>Cookie Settings</a>
          </div>
          
          <div className="w-full">
            <Typography
              className="mt-12 sm:text-2xl text-left tracking-tight"
              color="text.secondary"
            >
              <p className="mb-12">You can change your cookie preferences any time by clicking the above button. This will let you revisit the cookie consent banner and change your preferences or withdraw your consent right away. </p>
              <p className="mb-12">In addition to this, different browsers provide different methods to block and delete cookies used by websites. You can change the settings of your browser to block/delete the cookies. Listed below are the links to the support documents on how to manage and delete cookies from the major web browsers.</p> 
              <p className="mb-5">Chrome: <a href="https://support.google.com/accounts/answer/32050" target="_blank">https://support.google.com/accounts/answer/32050</a></p>
              <p className="mb-5">Safari: <a href="https://support.apple.com/en-in/guide/safari/sfri11471/mac" target="_blank">https://support.apple.com/en-in/guide/safari/sfri11471/mac</a></p>
              <p className="mb-5">Firefox: <a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US" target="_blank">https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US</a></p>
              <p className="mb-12">Internet Explorer: <a href="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc" target="_blank">https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc</a></p>
              <p>If you are using any other web browser, please visit your browser’s official support documents.</p>
            </Typography>
          </div>
        </div>
        </div>
  );
}

export default CookiePageContent;

