import { Box } from '@mui/system';
import IconButton from '@mui/material/IconButton';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import ItemIcon from 'app/shared-components/file/ItemIcon';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';



function FileItem({link, type}) {

  return (
	 <Box
      sx={{ backgroundColor: 'background.paper' }}
      className="relative w-full sm:w-160 h-160 m-8 p-16 shadow rounded-16"
    >

      <NavLinkAdapter
        className="flex flex-col h-full w-full"
        to={link}
        target="_blank"
        role="button"
        
      >
         <div className="flex flex-auto w-full items-center justify-center">
          <ItemIcon className="" type={type} />
        </div>

      </NavLinkAdapter>
      
      
    </Box>
  );

}

export default FileItem;
