import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import NativeDialog from 'app/shared-components/layout/NativeDialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {   } from 'app/store/contactsSlice';
import { selectUserRecommendDialogId, setUserRecommendDialogId, selectUserById, selectContactByTypeAndId, updateFavoriteStatus} from 'app/store/contactsSlice';
import { selectIsLoading, startLoading, stopLoading} from 'app/store/utilSlice';
import { useForm } from 'react-hook-form';
import { useThemeMediaQuery } from '@fuse/hooks';
import { selectGivenRecommendationByContactId, createRecommendation, updateRecommendation, deleteRecommendation } from 'app/store/userRecommendationSlice';
import DefaultButton from '../layout/DefaultButton';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import ControlledWYSIWYG from 'app/shared-components/form/ControlledWYSIWYG';
import { dispatchResultMessage } from 'app/shared-components/utils';

const schema = yup.object().shape({
  text: yup.string().required("Recommendation text is required."),
});

function UserRecommendDialog({}) {
  const dispatch= useDispatch();
  const userId = useSelector(selectUserRecommendDialogId);
  const user = useSelector(selectUserById(userId));
  const givenRecommendation = useSelector(selectGivenRecommendationByContactId(userId));
  const hasRecommendation= givenRecommendation && !givenRecommendation.notFound;
  const title= "Recommendation For " + user?.firstName + " " + user?.lastName;
  
  const defaultValues = {
	  text: hasRecommendation ? givenRecommendation.text : "",
	};
  
  const { handleSubmit, formState, control, reset } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  
  useEffect(() => {
     if (hasRecommendation) reset({text: givenRecommendation.text});
  }, [givenRecommendation, hasRecommendation]);

  if (!userId) {
    return null;
  }


  function onSubmit(data) {
    //set the loading flag
	  dispatch(startLoading());
	   
    //Close the dialog
    onClose();
     if (hasRecommendation)
       dispatch(updateRecommendation({contactId: userId, text: data.text})).then((resp) =>
  		 {
  			   dispatchResultMessage(dispatch, resp, "Updated Recommendation");
  		 });
  	else
  	  dispatch(createRecommendation({contactId: userId, text: data.text})).then((resp) =>
  		 {
  			   dispatchResultMessage(dispatch, resp, "Added Recommendation");
  		 });
  }
  


  function onClose() {
    dispatch(setUserRecommendDialogId(null));
  }

  return (
    <NativeDialog
      onClose={onClose}
      open={Boolean(userId)}
      width="400px"
    >
      <AppBar position="static" color="secondary" elevation={0}>
	      <Toolbar className="flex w-full">
	        <Typography variant="subtitle1" color="inherit">
	          {title}
	        </Typography>
	      </Toolbar>
	    </AppBar>
	      <form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <DialogContent classes={{ root: 'p-16 pb-0' }}>
            <div className="mb-32">

               <ControlledWYSIWYG name="text" initialContent={defaultValues.text} 
			                   control={control} errors={errors}/>    
            
	          </div>

          </DialogContent>

          <DialogActions className="flex flex-col sm:flex-row sm:items-center justify-between py-16 sm:py-24 px-24">
            <div className="flex items-center space-x-8 mt-16 sm:mt-0">
              <DefaultButton text="Cancel" onClick={onClose} />
              <DefaultButton text={hasRecommendation ? "Update" : "Create"}
	              disabled={_.isEmpty(dirtyFields) || !isValid}
	              submit />
            </div>
          </DialogActions>
          
        </form>
        
    </NativeDialog>
  );
}

export default UserRecommendDialog;
