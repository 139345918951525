


function FileSelect({fileInputRef, onSelectFile, accept}) {

  const selectFile = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    onSelectFile(files[0]);
  };
  
  return (
      <input type="file" ref={fileInputRef} accept={accept} hidden onChange={selectFile} onClick={(event) => event.target.value= ''}/>
  );
}

export default FileSelect;