import { startLoading, stopLoading } from "app/store/utilSlice";
import { dispatchResultMessage } from "../utils";
import { showMessage } from "app/store/fuse/messageSlice";

import history from '@history';

const dispatchSubmit= ({dispatch, submitFunction, onSuccess, returnPath, successMessage, noLoading}) => {
	try{
	   if (!noLoading) dispatch(startLoading());
	   if (returnPath) history.push({pathname: returnPath});
	   dispatch(submitFunction).then((resp) => {
		  if (!noLoading) dispatch(stopLoading());
		  dispatchResultMessage(dispatch, resp, successMessage);
		  if (onSuccess) onSuccess();
	   });
	}catch(error){
		if (!noLoading) dispatch(stopLoading());
		dispatch(
	        showMessage({
	            message     : error.message,
	            autoHideDuration: 6000,
	            anchorOrigin: {
	                vertical  : 'top',//top bottom
	                horizontal: 'center'//left center right
	            },
	            variant: 'error'//success error info warning null
	        }))
	}
  }
  
export default dispatchSubmit;
