import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { memo, useMemo, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectConnections, getConnections  } from 'app/store/connectionsSlice';
import { openChatPanel, selectSelectedContactId, setSelectedContactId } from './store/stateSlice';
import ContactButton from './ContactButton';

const Root = styled(FuseScrollbars)(({ theme }) => ({
  background: theme.palette.background.paper,
}));

function ContactList(props) {
  const dispatch = useDispatch();
  const connections = useSelector(selectConnections);
  const selectedContactId = useSelector(selectSelectedContactId);
  const contactListScroll = useRef(null);

  const scrollToTop = () => {
    contactListScroll.current.scrollTop = 0;
  };
  
/*  useEffect(() => {
	dispatch(getConnections())
  }, [dispatch]);*/

  return (
    <Root
      className="flex shrink-0 flex-col overflow-y-auto py-8 overscroll-contain"
      ref={contactListScroll}
      option={{ suppressScrollX: true, wheelPropagation: false }}
    >
      {useMemo(() => {
		  
        const handleContactClick = (contactId) => {
          dispatch(openChatPanel());
          dispatch(setSelectedContactId(contactId));
          scrollToTop();
        };

        const container = {
          show: {
            transition: {
              staggerChildren: 0.05,
            },
          },
        };

        const item = {
          hidden: { opacity: 0, scale: 0.6 },
          show: { opacity: 1, scale: 1 },
        };

        return (
         (connections.length > 0) && (
            <>
              <motion.div
                variants={container}
                initial="hidden"
                animate="show"
                className="flex flex-col shrink-0"
              >
                {connections.map((connection) => {
                    return (
                      <motion.div variants={item} key={connection.targetUserId}>
                        <ContactButton
                          connection={connection}
                          selectedContactId={selectedContactId}
                          onClick={handleContactClick}
                        />
                      </motion.div>
                    );
                  })}
               </motion.div>
            </>
          )
        );
      }, [connections, dispatch, selectedContactId])}
    </Root>
  );
}

export default memo(ContactList);
