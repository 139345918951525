import { Link } from 'react-router-dom';
import Button from 'app/shared-components/marketing/Button';
import { useSelector } from 'react-redux';
import { selectMenuBySlug } from 'app/store/marketing/menusSlice';

// TODO: Fetch from Strapi

const NAV_LINKS = [
  { id: 0, title: 'For Providers', url: '/', target: null },
  { id: 1, title: 'For Patients', url: '/', target: null },
  { id: 2, title: 'Company', url: '/', target: null },
  { id: 3, title: 'Blog', url: '/', target: null },
  { id: 4, title: 'Terms of Service', url: '/', target: null, mobile: true },
  { id: 5, title: 'Privacy Policy', url: '/', target: null, mobile: true }
];

const SOCIAL_LINKS = [
  { id: 0, title: 'Linkedin', url: '/', target: '_blank', icon: 'linkedin' },
  { id: 1, title: 'Instagram', url: '/', target: '_blank', icon: 'instagram' },
  { id: 2, title: 'Facebook', url: '/', target: '_blank', icon: 'facebook' }
];

function FooterLayoutPage() {
  const headerMenu = useSelector(selectMenuBySlug('header'));
  const footerMenu = useSelector(selectMenuBySlug('footer'));
  const socialMenu = useSelector(selectMenuBySlug('social'));

  const menuItems = [
    ...(headerMenu?.items || []),
    ...(footerMenu?.items || []).map((link) => ({ ...link, mobile: true }))
  ];

  return (
    <footer className="bg-hs-blue">
      <div className="container px-40 py-20 md:py-36 flex flex-col md:flex-row gap-y-8 md:justify-between items-center md:items-start">
        <div className="hidden md:block">
          <Link
            to="/"
            className="block w-160"
          >
            <img
              src="assets/images/logo/HALESPRING-WHITE.png"
              className="w-full"
            />
          </Link>
        </div>

        <nav>
          <ul className="grid md:grid-rows-4 grid-flow-row md:grid-flow-col gap-x-32 gap-y-8">
            {menuItems.map((item) =>
              <li
                key={item.id}
                className={item.mobile ? '' : 'hidden md:block'}
              >
                <Link
                  to={item.url}
                  className="text-white font-medium text-16 uppercase"
                >
                  {item.title}
                </Link>
              </li>
            )}
          </ul>
        </nav>

        <div className="flex flex-col items-center gap-y-12 order-first md:order-none">
          <ul className="flex gap-x-16">
            {socialMenu?.items?.map((item) => 
              <li
                key={item.id}
              >
                <a
                  href={item.url}
                  target={item.target}
                >
                  <img
                    src={`assets/images/social/${item.title.toLowerCase()}.svg`}
                    alt={item.title}
                    className="w-60 h-60"
                  />
                </a>
              </li>
            )}
          </ul>

          <a
            className="text-hs-green text-24 font-medium"
            href="mailto:info@halespring.com"
            target="_blank"
          >
            info@halespring.com
          </a>
        </div>

        <div className="hidden md:flex flex-col gap-y-12 w-200">
          <Button
            to="/sign-up"
            className="bg-hs-blue-light hover:bg-hs-blue-pale text-hs-blue"
            dense
          >
            Sign Up
          </Button>

          <Button
            to="/sign-in"
            className="bg-hs-blue hover:bg-hs-blue-dark text-hs-blue-light outline-hs-blue-light"
            dense
          >
            Log In
          </Button>
        </div>
      </div>

      <div className="bg-hs-blue-dark text-white text-16">
        <div className="container px-40 flex flex-col md:flex-row gap-y-8 md:justify-between items-center py-10 md:py-24">
          <div>
            ©{new Date().getFullYear()} Halespring Public Benefit Corporation All rights reserved
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterLayoutPage;
