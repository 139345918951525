import React, { Component } from 'react';
import * as Sentry from "@sentry/react";
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import DefaultButton from 'app/shared-components/layout/DefaultButton';

const isNetworkError= (error) => {
  const name= error.name;
  if (name && (name.includes('Network') || name.includes('network') || name.includes('Chunk'))) return true;
  return false;
}

export default class ErrorBoundary extends Component 
{
  constructor(props) {
    super(props);
    this.state = {
      details: false,
    };
  }
  
  render() {
	  return (
	    <Sentry.ErrorBoundary 
	      showDialog={false}
	      fallback={({ error, componentStack, resetError }) => (
           <Card className="flex flex-col w-full">
        		 <CardHeader
                    className="p-16 text-center"
                    title={isNetworkError(error) ? "There was a network error while loading this page." : "There was a problem loading this page."}
                  /> 
                  <CardContent className="px-20 pt-0 h-full flex flex-col">  
              			<div className="py-12 text-center">
              			{ isNetworkError(error) ? "Please check your network connection and try again." :
              			"Our support team has been sent a report of this problem, and we will work to fix it as soon as possible!" }
              			</div>
              			<div className="py-12 text-center">
              			  <DefaultButton text="Show Details" onClick={() => { this.setState({ details: !this.state.details }); }} />
              			  <DefaultButton text="Reload Page" onClick={() => { window.location.reload(); }} />
              			</div>
              			{this.state.details &&
              			  <div>
              			     <div>{error.toString()}</div>
                         <div>{componentStack}</div>
              			  </div>
              			}
              			
                </CardContent>
        		</Card>
        )}
      >
                                		
           {this.props.children}
           
      </Sentry.ErrorBoundary>
	  );
	}

}