import sanitizeHtml from 'sanitize-html';
import { Link } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

/**
 * @param {{ post: import('../../store/marketing/marketing').BlogPost }}
 */
export default function BlogPostPreview({ post, className, ...props }) {
  return (
    <article
      className={twMerge(classNames([
        'bg-hs-white rounded-20 px-28 pt-20 pb-60',
        className
      ]))}
      {...props}
    >
      <div className="aspect-w-3 aspect-h-2">
        <img
          src={post.image?.url}
          alt={post.image?.alternativeText}
          className="object-cover rounded-20"
        />
      </div>

      <div className="mt-20 flex flex-wrap gap-8">
        {post.tags.map((tag) =>
          <Link
            key={tag.id}
            className="block text-12 text-hs-blue bg-hs-grey-light px-10 py-4 font-medium rounded-full"
          >
            {tag.name}
          </Link>
        )}
      </div>

      <h3 className="mt-12 text-hs-grey-black text-28 font-semibold">
        {post.title}
      </h3>

      <div
        className="mt-12 text-hs-grey-black text-16"
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(post.excerpt) }}
      />

      <div className="mt-20">
        <Link
          className="text-hs-blue text-20 font-semibold"
          to={`/blog/${post.slug}`}
        >
          Read
          <EastIcon className="ml-2" />
        </Link>
      </div>
    </article>
  )
}
