import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import { joinChannel } from 'app/store/channelsSlice';


export const getChannelInvites = createAsyncThunk('channelInvites/getChannelInvites', 
  async (params, { getState, dispatch }) => {
	  const { user } = getState();
	  const url= '/user/' + user.id + '/channelinvite';
	  const apiName = 'CoreAPI';
	  const path = url;
	  const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	
	  let data= await apiGet(apiName, path, options);
	  return data;
});

export const removeChannelInvite = createAsyncThunk('channelInvites/removeChannelInvite', 
  async (params, { getState, dispatch }) => {
	  const { user } = getState();
	  const { channelId, userId } = params;
	  const inviteUserId= userId ? userId : user.id;
	  const url= '/channel/' + channelId + '/channelinvite/' + inviteUserId;
	  const apiName = 'CoreAPI';
	  const path = url;
	  const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	
	  let data= await apiDel(apiName, path, options);
	  return data;
});

const invitesAdapter = createEntityAdapter({
	selectId: (invite) => invite.channelId,
});

export const { selectAll: selectInvites, selectById: selectById } =
  invitesAdapter.getSelectors((state) => state.channelInvites);

const channelInvitesSlice = createSlice({
  name: 'channelInvites',
  initialState: invitesAdapter.getInitialState(),
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(getChannelInvites.fulfilled, (state, action) => {
      invitesAdapter.setAll(state, action.payload);
    })
    .addCase(removeChannelInvite.fulfilled, (state, action) => {
	  if (!action.meta.arg.userId){ //If userId exists then this invite was not TO the current user
		 invitesAdapter.removeOne(state, action.meta.arg.channelId);
	  }
    })
    .addCase(joinChannel.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      invitesAdapter.removeOne(state, channelId);
    })
   },
});


export const selectInvitesByChannelType = (channelType) => state => {
	if (!channelType) return null;
	const invites= selectInvites(state);
    return invites.filter(item => item.channelType === channelType);
}

export default channelInvitesSlice.reducer;
