import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios';

/**
 * @template T
 * @typedef {import('./strapi').CollectionApiResponseWrapper<T>} CollectionApiResponseWrapper
 */
/** @typedef {import('./marketing').Page} Page */

/** @type {import('@reduxjs/toolkit').EntityAdapter<Page, string>} */
const pagesAdaptor = createEntityAdapter({
  selectId: (page) => page.slug,
  sortComparer: (a, b) => new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime()
});

const initialState = pagesAdaptor.getInitialState({
  isLoading: false
});

/** @type {import('@reduxjs/toolkit').AsyncThunk<Page, string, {}>}  */
export const fetchPageBySlug = createAsyncThunk(
  'marketing/pages/fetchPageBySlug',
  async (slug, { dispatch }) => {
    /** @type {import('axios').AxiosResponse<CollectionApiResponseWrapper<Page>>} */
    const response = await axios({
      method: 'GET',
      url: 'pages',
      params: {
        populate: '*',
        filters: { slug }
      }
    });

    return response.data.data[0];
  }
);

const pagesSlice = createSlice({
  name: 'marketing/pages',

  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchPageBySlug.fulfilled, (state, action) => {
        if (action.payload) {
          pagesAdaptor.upsertOne(state, action.payload);
        }
      });
  }
});

export const selectPageBySlug = (/** @type {string} */ slug) =>
  (/** @type {import('./index').RootState} */ state) =>
    state.marketing.pages.entities[slug]

export default pagesSlice.reducer;
