import FuseScrollbars from '@fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { memo, useMemo, useRef } from 'react';
import MultiChatItemButton from './MultiChatItemButton';

const Root = styled(FuseScrollbars)(({ theme }) => ({
  background: theme.palette.background.paper,
}));

function MultiChatUserList(props) {
  const {onSelectItem, onCreate, onDelete, selectedItem, items}= props;
  const contactListScroll = useRef(null);
  const hasCreate= Boolean(onCreate);
  const hasDelete= Boolean(onDelete) && selectedItem;

  const scrollToTop = () => {
    contactListScroll.current.scrollTop = 0;
  };
  
  if (!items) return null;
  
  return (
    <Root
      className="flex shrink-0 flex-col overflow-y-auto py-8 overscroll-contain"
      ref={contactListScroll}
      option={{ suppressScrollX: true, wheelPropagation: false }}
    >
      {useMemo(() => {
		  
        const handleContactClick = (item) => {
          onSelectItem(item);
          scrollToTop();
        };

        const container = {
          show: {
            transition: {
              staggerChildren: 0.05,
            },
          },
        };

        const item = {
          hidden: { opacity: 0, scale: 0.6 },
          show: { opacity: 1, scale: 1 },
        };

        return (
         (items.length > 0 || hasCreate) && (
            <>
              <motion.div
                variants={container}
                initial="hidden"
                animate="show"
                className="flex flex-col shrink-0"
              >
                {items.map((chatItem) => {
                    return (
                      <motion.div variants={item} key={chatItem.id}>
                        <MultiChatItemButton
                          item={chatItem}
                          selectedId={selectedItem?.id}
                          onClick={handleContactClick}
                        />
                      </motion.div>
                    );
                  })}
                {hasCreate && 
                    <motion.div variants={item} key="create-item">
                      <MultiChatItemButton
                        isCreate
                        selectedId="create-item"
                        onClick={onCreate}
                      />
                    </motion.div>
                }
                {hasDelete && 
                    <motion.div variants={item} key="delete-item">
                      <MultiChatItemButton
                        isDelete
                        selectedId="delete-item"
                        onClick={onDelete}
                      />
                    </motion.div>
                }
               </motion.div>
            </>
          )
        );
      }, [items, selectedItem])}
    </Root>
  );
}

export default memo(MultiChatUserList);
