import * as React from 'react';
import { DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import {openDialog, closeDialog } from 'app/store/fuse/dialogSlice';


export default function notificationDialog(dispatch, 
									       {
									        title,
									        content,
									        onOk,
									        okText
									       })
{
	const buttonText= okText ? okText : "OK";
	
	dispatch(openDialog({
        children: (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> {dispatch(closeDialog()); if (onOk) onOk(true);}} color="primary" autoFocus>
                        {buttonText}
                    </Button>
                </DialogActions>
            </React.Fragment>
             )
    }));
}
