

export const resizeImage= async (imgToCompress, maxWidth, maxHeight) => {
  // Get canvas
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  
  //Determine resizing factor based on max dimensions
  const originalWidth = imgToCompress.width;
  const originalHeight = imgToCompress.height;
  let newWidth = maxWidth
  let newHeight = maxHeight;
  let heightFactor= 1;
  if (originalWidth > maxWidth){
	  heightFactor= maxWidth / originalWidth;
  }
  let widthFactor= 1;
  if (originalHeight > maxHeight){
	  widthFactor= maxHeight / originalHeight;
  }
  
  //The larger dimension is fixed at max value, and other dimension is scaled 
  //proportionally 
  if (heightFactor < widthFactor){
	  newHeight = originalHeight * heightFactor;
  }else{
	  newWidth = originalWidth * widthFactor;
  }
  
  //Set the canvas size
  canvas.width = newWidth;
  canvas.height = newHeight;
  
  //Draw the image on the canvas
  context.drawImage(
    imgToCompress,
    0,
    0,
    newWidth,
    newHeight
  );
  
  const blob= await new Promise(resolve => 
    canvas.toBlob(resolve, "image/jpeg", 1)
  );
  return blob;
}

export const blobToImage = (blob) => {
  return new Promise(resolve => {
    const url = URL.createObjectURL(blob)
    let img = new Image()
    img.onload = () => {
      URL.revokeObjectURL(url)
      resolve(img)
    }
    img.src = url
  })
}
