import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

export default function Input(props) {
  const { className, ...inputProps } = props;

  return <input
    {...inputProps}
    className={twMerge(classNames([
      'placeholder:text-hs-grey-dark text-hs-black border border-[#7A9CC6] px-20 py-10 text-24 rounded-full w-full lg:w-initial',
      className
    ]))}
  />
}
