import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import { stopLoading } from './utilSlice';
import { updateUserInfo } from './userSlice';


export const getExternalMessages = createAsyncThunk(
  "externalMessages/getExternalMessages",
  async ({bulk, refresh}, { getState }) => {
	  const {user, externalMessages} = getState();
	  let data= bulk;
	  if (!data){
		  if (!refresh && externalMessages.status === 'succeeded') data= selectAllMessages(getState());
		  else{
			  const apiName = 'CoreAPI';
			  const path = '/externalmessage/byowner/' + user.id;
		      const options = {
				  headers: {},
				  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
			  };
			  data= await apiGet(apiName, path, options);
		  }
	  }
	  return {lastRead: externalMessages.lastRead ? externalMessages.lastRead : user.raw.lastReadExternalMessages,
	          messages: data};
  }
);


export const createExternalMessage = createAsyncThunk(
  "externalMessages/createExternalMessage",
  async (params, {dispatch}) => {
	  const {targetId, name, message, email}= params;
      const apiName = 'PublicAPI';
	    const path = '/externalmessage/public/create';
      const options = {
			  body: { targetId: targetId,
			          message: message,
			          name: name,
			          email: email},
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	 try{
		const data= await apiPut(apiName, path, options);
	    return data; 
	 }finally{
		dispatch(stopLoading());
	 }
	 
  }
);


export const deleteExternalMessage = createAsyncThunk(
  "externalMessages/deleteExternalMessage",
  async (params, {dispatch}) => {
	  const {messageId}= params;
      const apiName = 'CoreAPI';
	  const path = '/externalmessage/' + messageId;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	 try{
		const data= await apiDel(apiName, path, options);
	    return messageId; 
	 }finally{
		dispatch(stopLoading());
	 }
	 
  }
);

export const setExternalMessagesRead = createAsyncThunk('externalMessages/setExternalMessagesRead', 
   async (params, { dispatch, getState }) => {
	  console.log("Setting external messages read timestamp.");
	  await dispatch(updateUserInfo({lastReadExternalMessages: Date.now()}));
});

const messagesAdapter = createEntityAdapter({});

export const {
  selectAll: selectAllMessages,
  selectById: selectById,
} = messagesAdapter.getSelectors((state) => state.externalMessages);

const messagesSlice = createSlice({
  name: 'externalMessages',
  initialState: messagesAdapter.getInitialState({unread: false, lastRead: null, userToMessage: {},
                                                 viewMessage: {}, status: 'idle'}),
  reducers: {
    setUserToMessage: (state, action) => {
      state.userToMessage = action.payload;
    },
    setViewMessage: (state, action) => {
      state.viewMessage = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getExternalMessages.fulfilled, (state, action) => {
	  if (!action.payload) return;
	  state.status= "succeeded";
	  const messages= action.payload.messages;
	  const lastRead= action.payload.lastRead;
      if (messages.length > 0){
		    messagesAdapter.setMany(state, messages);
			const lastMessage= messages[messages.length - 1];
			if (!lastRead) state.unread= true;
			else if (lastRead < lastMessage.createdAt) state.unread= true;
		}
    })
    .addCase(deleteExternalMessage.fulfilled, (state, action) => {
      messagesAdapter.removeOne(state, action.payload);
    })
    .addCase(setExternalMessagesRead.fulfilled, (state, action) => {
      state.unread= false;
      state.lastRead= Date.now();
    })
  },
});

export const selectMessageById = (id) => state => {
	if (id) return selectById(state, id);
	return null;
}

export const selectViewMessage = ({ externalMessages }) => externalMessages.viewMessage;
export const selectUserToMessage = ({ externalMessages }) => externalMessages.userToMessage;
export const selectMessagesUnread= ({ externalMessages }) => externalMessages.unread;

export const { setUserToMessage, setViewMessage} = messagesSlice.actions;

export default messagesSlice.reducer;
