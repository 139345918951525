import lazyLoad from 'app/shared-components/util/lazyLoad';

const PostView = lazyLoad(() => import('./PostView'));


const PostViewConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'post/:id',
      element: <PostView />,
    },
  ],
};

export default PostViewConfig;


