import HeaderTags from 'app/shared-components/helmet/HeaderTags';
import _ from '@lodash';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import states from 'app/shared-components/controls/states.json';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';

const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 },
  };

export default function ProviderStates() {

  const host = window.location.protocol + '//' + window.location.host;
  const orgImage=  host + '/assets/images/logo/HALESPRING-HEADER-191.jpg';
  const basePath= '/public/search/providers/state/';

  return (
    <>
      <HeaderTags title="Provider Directory By State" 
                  description="Find provider profiles by state of practice." 
                  keywords="Directory,Search,Therapist,Therapy,Referrals,Pyschologist,Psychiatrist"
                  image={orgImage}
      />
      
    <div className="pt-48">
      {/* Hero */}
      <section className="mb-48">
        <div className="container">
          <h1 className="h3 lg:h1 text-center">
            Provider Directory By State
          </h1>
        </div>
      </section>
      
      {/* States */}
      <section className="bg-white">
        <div className="container bg-white pt-40 pb-40">
          <Grid container spacing={2} alignItems="stretch" className="w-full m-0 p-0">
              {states.map((state) => 
				        <Grid key={state.value} item xs={6} sm={4} md={3}>
               		 <Card component={motion.div} variants={item} >
                     <CardActionArea component={NavLinkAdapter} to={basePath + state.value}>
                          <CardContent>
                             <Typography color="secondary">
                               {state.name}
                             </Typography>
                          </CardContent>
        		         </CardActionArea>
            		   </Card>
                </Grid>
              )}
          </Grid>

        </div>
      </section>

    </div>
    </>
  );
}