import lazyLoad from 'app/shared-components/util/lazyLoad';

const CheckoutApp = lazyLoad(() => import('./CheckoutApp'));

const CheckoutAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/checkout',
      element: <CheckoutApp />,
    },
    {
      path: 'apps/checkout/return',
      element: <CheckoutApp />,
    },
  ],
};

export default CheckoutAppConfig;
