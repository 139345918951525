import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import _ from '@lodash';
import LinkMetaModel from "./post/LinkMetaModel";



async function fetchMetadata(link) 
{
	//console.log("getting metadata: " + link);
	
    const apiName = 'CoreAPI';
	  const path = '/linkmeta/';
	  const options = {
	  headers: {},
	  body: {
		  url: link
	  },
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	};

    //Request the upload link from the server
	let metadata= await apiPut(apiName, path, options);
	//console.log("LINK METADATA");
	//console.log(metadata);
	return LinkMetaModel(metadata);
}

//export const getMetadata = _.debounce(async (link) => {return await fetchMetadata(link)}, 2000, {'leading': true,'trailing': false} );
export const getMetadata = _.memoize(async (link) => {return await fetchMetadata(link)});

export const isValidUrl = (urlString) => {
  try { 
  	return Boolean(new URL(urlString)); 
  }
  catch(e){ 
  	return false; 
  }
}


  export const getGoogleDocType = (urlString) => {
  	if (urlString && urlString.startsWith("https://docs.google.com/")){
  		 if (urlString.includes("/document/d")) return "document";
  		 else if (urlString.includes("/presentation/d")) return "presentation";
  		 else if (urlString.includes("/spreadsheets/d")) return "spreadsheets";
  	}
  	return null;
  }
