import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';

export const getChannelApplications = createAsyncThunk('channelApplications/getChannelApplications', 
  async (params, { getState, dispatch }) => {
	  const { user } = getState();
	  const url= '/user/' + user.id + '/channelapplication';
	  const apiName = 'CoreAPI';
	  const path = url;
	  const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	
	  let data= await apiGet(apiName, path, options);
	  return data;
});

export const applyToChannel = createAsyncThunk(
  "channelApplications/applyToChannel",
  async (params, { dispatch, getState }) => {
	  const { user } = getState();
	  const { channelId, type, message} = params;
      const apiName = 'CoreAPI';
	  const path = '/user/' + user.id + '/channelapplication';
      const options = {
		  body: {channelId: channelId, message: message},
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  
	  //Store channel member data
	  const data= await apiPut(apiName, path, options);
      return data;
  }
);

export const removeChannelApplication = createAsyncThunk('channelApplications/removeChannelApplication', 
  async (params, { getState, dispatch }) => {
	  const { user } = getState();
	  const { channelId } = params;
	  const url= '/user/' + user.id + '/channelapplication/' + channelId;
	  const apiName = 'CoreAPI';
	  const path = url;
	  const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	
	  let data= await apiDel(apiName, path, options);
	  return data;
});

const applicationsAdapter = createEntityAdapter({
	selectId: (application) => application.channelId,
});

export const { selectAll: selectApplications, selectById: selectById } =
  applicationsAdapter.getSelectors((state) => state.channelApplications);

const channelApplicationsSlice = createSlice({
  name: 'channelApplications',
  initialState: applicationsAdapter.getInitialState(),
  reducers: {
  },
  extraReducers: builder => {
    builder.addCase(getChannelApplications.fulfilled, (state, action) => {
      applicationsAdapter.setAll(state, action.payload);
    })
    .addCase(removeChannelApplication.fulfilled, (state, action) => {
	  applicationsAdapter.removeOne(state, action.meta.arg.channelId);
    })
    .addCase(applyToChannel.fulfilled, (state, action) => {
      applicationsAdapter.addOne(state, action.payload);
    })
   },
});


export const selectApplicationsByChannelType = (channelType) => state => {
	if (!channelType) return null;
	const apps= selectApplications(state);
    return apps.filter(item => item.channelType === channelType);
}

export default channelApplicationsSlice.reducer;
