import {useRef, useEffect, forwardRef, useState} from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MinimizeIcon from '@mui/icons-material/Minimize';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Draggable from 'react-draggable';
import { isIOSMobile } from '../util/videoUtils';


export default function NativeDialog({open, title, children, onClose, fullScreen, width, maxWidth, height, maxHeight, zIndex}) {
  const ref = useRef();
  const useWidth= fullScreen ? '100%' : width ? width : "300px";
  const useHeight= fullScreen ? '100%' : height ? height : undefined;
  const useMaxHeight= fullScreen ? '100%' : maxHeight ? maxHeight : undefined;
  const useMaxWidth= fullScreen ? '100%' : maxWidth ? maxWidth : undefined;

  useEffect(() => {
    if (open){
        ref.current?.showModal();
    } else {
        ref.current?.close();
    }
  }, [open]);
  

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") 
      onClose();
    else
      onClose();
  }
  
  
  return (
    open &&
      <dialog ref={ref}
              onCancel={handleClose}
              style={{width: useWidth,
                      height: useHeight,
                      maxHeight: useMaxHeight,
                      maxWidth: useMaxWidth,
                      borderRadius: '5px',
                      overflow: 'auto'}}>
         <div className="flex flex-col">
            {children}
        </div>
      </dialog>
  );
}
