import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { stopLoading, startLoading} from 'app/store/utilSlice';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';

export const TYPE_POST = "post";
export const TYPE_TIMELINE_POST = "timelinepost";
export const TYPE_CHAT = "chat";
export const TYPE_DM = "dm";
export const TYPE_NOTE = "note";
export const TYPE_VIDEO_CALL = "videocall";
export const TYPE_POST_COMMENT = "postcomment";
export const TYPE_CHAT_COMMENT = "chatcomment";
export const TYPE_NOTE_COMMENT = "notecomment";
export const TYPE_TIMELINE_POST_COMMENT = "timelinepostcomment";
export const STATUS_VIDEO_CALL_ACTIVE = "call_active";
export const STATUS_VIDEO_CALL_ENDED = "call_ended";

const meetingAdapter = createEntityAdapter({
	sortComparer: (a, b) => b.createdAt - a.createdAt
});


export const fetchMeeting = createAsyncThunk(
  "chimeMeeting/fetchMeeting",
  async (id, { dispatch }) => {
	dispatch(startLoading());
	try{
      const apiName = 'CoreAPI2';
	  const path = '/x2/chime/meeting/' + id;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	  const data= await apiGet(apiName, path, options);
	  return data;
	}finally{
	  dispatch(stopLoading());
	}
  }
);



export const createMeeting = createAsyncThunk(
  "chimeMeeting/createMeeting",
  async (params, { dispatch, getState }) => {
	  try{
	      const {channelId, title } = params;
	      const apiName = 'CoreAPI2';
		  const path = '/x2/chime/meeting/';
		  let body= { channelId: channelId,
		              title: title};
		  
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPost(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const joinMeeting = createAsyncThunk(
  "chimeMeeting/joinMeeting",
  async (params, { dispatch, getState }) => {
	  try{
	      const {meetingId } = params;
	      const apiName = 'CoreAPI2';
		  const path = '/x2/chime/meeting/' + meetingId + '/attendee';
		  let body= { };
		  
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPost(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const leaveMeeting = createAsyncThunk(
  "chimeMeeting/leaveMeeting",
  async (params, { dispatch, getState }) => {
	  try{
	      const {meetingId, attendeeId } = params;
	      const apiName = 'CoreAPI2';
		  const path = '/x2/chime/meeting/' + meetingId + '/attendee/' + attendeeId;
		  
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 await apiDel(apiName, path, options);
		 return attendeeId;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const endMeeting = createAsyncThunk(
  "chimeMeeting/endMeeting",
  async (params, { dispatch, getState }) => {
	  try{
	      const {id } = params;
	      const apiName = 'CoreAPI';
		  const path = '/x2/chime/meeting/' + id;
		  
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 await apiDel(apiName, path, options);
		 return id;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

const chimeMeetingSlice = createSlice({
  name: "chimeMeeting",
  initialState: meetingAdapter.getInitialState({viewingMeetingId: null}),
  reducers: {
    setViewingMeetingId: (state, action) => {
      state.viewingMeetingId= action.payload;
    },
    setMeetingEnded: (state, action) => {
    	const meetingId= action.payload;
      const meetingEntry= state.entities[meetingId];
      if (meetingEntry){
      	meetingEntry.status= "Ended";
      }
    },
  },
  extraReducers: builder => {
    builder
    .addCase(fetchMeeting.fulfilled, (state, action) => {
        meetingAdapter.setOne(state, action.payload);
    })
    .addCase(createMeeting.fulfilled, (state, action) => {
        meetingAdapter.addOne(state, action.payload);
    })
    .addCase(endMeeting.fulfilled, (state, action) => {
      const meetingId = action.meta.arg.id;
      meetingAdapter.removeOne(state, meetingId);
    })
  }
})

const { selectById } = meetingAdapter.getSelectors(state => state.chimeMeeting);

export const selectMeetingById = (id) => state => {
	if (id) return selectById(state, id);
	return null;
}


export const selectViewingMeetingId = ({ chimeMeeting }) => chimeMeeting.viewingMeetingId;


export const { setViewingMeetingId, setMeetingEnded } = chimeMeetingSlice.actions;

export default chimeMeetingSlice.reducer;
