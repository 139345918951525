import lazyLoad from 'app/shared-components/util/lazyLoad';

const Chat = lazyLoad(() => import('./chat/Chat'));
const ChatFirstScreen = lazyLoad(() => import('./ChatFirstScreen'));
const ExternalMessages = lazyLoad(() => import('./chat/ExternalMessages'));
const ChatApp = lazyLoad(() => import('./ChatApp'));

const ChatAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/chat',
      element: <ChatApp />,
      children: [
        {
          path: '',
          element: <ChatFirstScreen />,
        },
         {
          path: 'external',
          element: <ExternalMessages />,
        },
        {
          path: ':id',
          element: <Chat />,
        },
      ],
    },
  ],
};

export default ChatAppConfig;
