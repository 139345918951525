import aboutConfig from './about/aboutConfig';
import blogConfig from './blog/blogConfig';
import homeConfig from './home/homeConfig';
import pageConfig from './page/pageConfig';
import providersConfig from './providers/providersConfig';
import patientsConfig from './patients/patientsConfig';
import investConfig from './invest/investConfig';
import directoryConfig from './directory/directoryConfig';

const marketingConfig = [
  aboutConfig,
  blogConfig,
  homeConfig,
  pageConfig,
  providersConfig,
  patientsConfig,
  investConfig,
  directoryConfig
];

export default marketingConfig;
