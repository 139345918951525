import VideoPlayer from './VideoPlayer';


export const PostVideoPlayer = ({mediaUrl, mediaType, onVideoSize}) => {
  const useMediaType = mediaType === 'video/quicktime' ? 'video/mp4' : mediaType;
  const options= {
      autoplay: false,
      preload: 'auto',
      controls: true,
      responsive: true,
      fluid: true,
      sources: [{
        src: mediaUrl,
        type: useMediaType
      }]
   };

 function handleReady(player){
    player.on('click', (e) => { e.preventDefault(); e.stopPropagation(); });
    player.on('loadedmetadata', function(){ handleLoadedMetadata(player); })
 }
 
 function handleLoadedMetadata(player){
   const width= getComputedStyle(player.el()).width;
   const height= getComputedStyle(player.el()).height;
   if (onVideoSize) onVideoSize({width: width, height: height});
 }
  
  return (
      <div className="rounded-md overflow-hidden">
        <VideoPlayer options={options} onReady={handleReady} />
      </div>
  );
}

export default PostVideoPlayer;