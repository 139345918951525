import { lighten } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import UserAvatar from 'app/shared-components/user/UserAvatar';
import UserName from 'app/shared-components/user/UserName';
import DMChat from 'app/shared-components/chat/DMChat';
import MultiChatUserList from './MultiChatUserList';


function MultiChatPanel({initialTitle, title, emptyMessage, onClose, processPost,
                         items, onSelectItem, selectedItem, onCreate, onDelete}) {
  
  if (!items) {
    return null;
  }
  
  function handleCloseChat(){
	 if (onClose) onClose();
  }

  return (
    <>
      {(title || initialTitle) && 
      <Box
        className="w-full border-b-1"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
        }}
      >
        <Toolbar className="flex items-center justify-between px-16 w-full">
          <div className="flex items-center flexGrow">
           {!selectedItem && (
      			<div className='flex flex-col'>
      				<Typography className="text-16 font-semibold px-4">{initialTitle}</Typography>
      				{title && <Typography noWrap={true}>{title}</Typography>}
      			</div>
            )}
           {selectedItem && (
		      <>
            <UserAvatar className="relative mx-8" user={selectedItem} />
            <div className='flex flex-col'>
               <UserName color="inherit" className="text-16 font-semibold px-4" user={selectedItem} />
	           {title && <Typography noWrap={true}>{title}</Typography>}
	        </div>
           </>
            )}   
          </div>
          <IconButton
              aria-label="Close chat"
              onClick={() => handleCloseChat(true)}
              className="flex "
              size="large"
            >
              <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
          </IconButton>
        </Toolbar>
      </Box>
      }
      
        <Paper className="flex flex-1 flex-row min-h-px shadow-0 w-full">
          <MultiChatUserList onSelectItem={onSelectItem} selectedItem={selectedItem} onCreate={onCreate} onDelete={onDelete}
                             items={items} className="flex shrink-0" />

    		  <Paper
    		      className='flex flex-col relative shadow w-full'
    		      sx={{ background: (theme) => theme.palette.background.default }}
    		    >
              {selectedItem ? (
                  <DMChat channelId={selectedItem.channelId} className="flex flex-1 z-10" processPost={processPost} />
              ) : (
                <div className="flex flex-col flex-1 items-center justify-center p-24">
                  <FuseSvgIcon size={128} color="disabled">
                    heroicons-outline:chat
                  </FuseSvgIcon>
                  <Typography className="px-16 pb-24 mt-24 text-center" color="text.secondary">
                    {emptyMessage}
                  </Typography>
                </div>
              )}
           </Paper>
        </Paper>
    </>
  );
}

export default MultiChatPanel;
