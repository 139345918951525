const config = {
  title: 'Layout Marketing',

  defaults: {
    mode: 'container',

    containerWidth: 1360,

    disableGlobalFuseStyles: true,

    header: {
      display: true
    },
  
    footer: {
      display: true
    }
  }
};

export default config;
