import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import { fetchChannels, setChannelRead } from 'app/store/channelsSlice';
import { submitPost } from './postSlice';
import sortComparer from 'app/shared-components/util/lastMessageSortComparer';


const dmChannelAdapter = createEntityAdapter({
});

const { selectAll, selectById } =
  dmChannelAdapter.getSelectors((state) => state.dmChannels);
  
const newDmChannel= (data) =>
{
	return {...data, id: data.channelId}
}

const dmChannelSlice = createSlice({
  name: 'dmChannels',
  initialState: dmChannelAdapter.getInitialState(),
  reducers: {
    addDmChannel:  (state, action) => {
        dmChannelAdapter.addOne(state, newDmChannel(action.payload));
	},
	addDmChannels:  (state, action) => {
        dmChannelAdapter.addMany(state, action.payload.map((data) => newDmChannel(data)));
	},
	removeDmChannel:  (state, action) => {
		const dmChannel= findChannelWithForeignId(state, action.payload);
		if (dmChannel) dmChannelAdapter.removeOne(state, dmChannel.id);
	},
  },
});

const findChannelWithForeignId= (state, id) => {
	for (var i = 0; i < state.ids.length; i++){
	  const id= state.ids[i];
	  const dmChannel= state.entities[id];
	  if (dmChannel && dmChannel.foreignId === id) 
		   	  return dmChannel;
	}
    return null;
}


export const { addDmChannel, addDmChannels, removeDmChannel } = dmChannelSlice.actions;


export const selectDmChannelById = (id) => state => {
	if (!id) return null;
    return selectById(state, id);
}

export default dmChannelSlice.reducer;
