import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMenuBySlug } from 'app/store/marketing/menusSlice';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Button from 'app/shared-components/marketing/Button';
import CloseIcon from '@mui/icons-material/Close'
import styled from '@emotion/styled';

const MainNavLink = styled(NavLink)`
  position: relative;

  /*&.active::after {
    content: '•';
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
    left: 0;
    bottom: -0.8rem;
    pointer-events: none;
  }*/
  
  &.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: #22B721;
    pointer-events: none;
  }
`;

function HeaderLayoutPage() {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const menu = useSelector(selectMenuBySlug('header'));
  const socialLinks = useSelector(selectMenuBySlug('social'));
  const location = useLocation();

  useEffect(() => {
    setDrawerIsOpen(false);
  }, [location]);

  return (
    <>
      <header className="container lg:px-40">
        <div
          className="flex justify-between items-center w-full px-16 lg:px-40 py-4 lg:py-18 bg-hs-white lg:rounded-full"
        >
          <Link
            className="flex items-center flex-none"
            to="/"
          >
            <img className="h-40 lg:h-60" src="assets/images/logo/HALESPRING-LOGO-100.png" alt="logo" />
            <img className="h-40 lg:h-40" src="assets/images/logo/HALESPRING-TEXT.png" alt="halespring" />
          </Link>

          <div className="flex items-center">
            <nav className="hidden lg:block">
              <ul className="flex space-x-28 items-center">
                {menu?.items?.map((item) => 
                  <li
                    key={item.id}
                  >
                    <MainNavLink
                      to={item.url}
                      className="block p-8 text-hs-blue text-18 font-medium whitespace-nowrap"
                    >
                      {item.title}
                    </MainNavLink>
                  </li>
                )}

                <li>
                  <Button
                    to="/sign-up"
                    className="bg-hs-blue hover:bg-hs-blue-dark text-hs-white"
                    dense
                  >
                    Provider Sign-Up
                  </Button>
                </li>

                <li>
                  <Button
                    to="/sign-in"
                    className="bg-hs-blue hover:bg-hs-blue-dark text-hs-white"
                    dense
                  >
                    Login
                  </Button>
                </li>
              </ul>
            </nav>

            <button
              className="lg:hidden flex justify-center items-center w-40 h-40 bg-hs-blue text-hs-white rounded-full"
              aria-label="Open Menu"
              onClick={() => setDrawerIsOpen(true)}
            >
              <MenuIcon />
            </button>
          </div>
        </div>
      </header>

      <SwipeableDrawer
        anchor="right"
        open={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
        onOpen={() => setDrawerIsOpen(true)}
      >
        <div className="flex flex-col p-20 pt-56 h-full">
          <button
            className="absolute top-8 right-8 flex justify-center items-center w-40 h-40 bg-hs-blue text-hs-white rounded-full"
            aria-label="Close Menu"
            onClick={() => setDrawerIsOpen(false)}
          >
            <CloseIcon />
          </button>

          <nav className="flex-auto">
            <ul className="flex flex-col gap-y-36 h-full">
              {menu?.items?.map((item) => (
                <li
                  key={item.id}
                >
                  <Link
                    to={item.url}
                    className="block text-hs-grey-black text-48 leading-none font-medium"
                  >
                    {item.title}
                  </Link>
                </li>
              ))}

              <li className="mt-auto">
                <Button
                  to="/sign-up"
                  className="bg-hs-blue hover:bg-hs-blue-dark text-hs-white"
                >
                  Provider Sign-Up
                </Button>
              </li>

              <li>
                <Button
                  to="/sign-in"
                  className="bg-hs-blue hover:bg-hs-blue-dark text-hs-white"
                >
                  Login
                </Button>
              </li>
            </ul>
          </nav>

          <ul className="flex-none flex justify-center gap-x-16 mt-40">
            {socialLinks?.items?.map((item) => 
              <li
                key={item.id}
              >
                <a
                  href={item.url}
                  target={item.target}
                >
                  <img
                    src={`assets/images/social/${item.title.toLowerCase()}-blue.svg`}
                    alt={item.title}
                    className="w-60 h-60"
                  />
                </a>
              </li>
            )}
          </ul>
        </div>
      </SwipeableDrawer>
    </>
  );
}

export default HeaderLayoutPage;
