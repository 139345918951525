import { createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios';

/**
 * @template T
 * @typedef {import('./strapi').CollectionApiResponseWrapper<T>} CollectionApiResponseWrapper
 */
/** @typedef {import('./marketing').Menu} Menu */

/** @type {import('@reduxjs/toolkit').EntityAdapter<Menu, string>} */
const menusAdaptor = createEntityAdapter({
  selectId: (menu) => menu.slug,
  sortComparer: (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
});

const initialState = menusAdaptor.getInitialState({
  isLoading: false
});

/** @type {import('@reduxjs/toolkit').AsyncThunk<Menu, string, {}>}  */
export const fetchMenus = createAsyncThunk(
  'marketing/menus/fetchMenus',
  async (_, { dispatch }) => {
    /** @type {import('axios').AxiosResponse<CollectionApiResponseWrapper<Menu>>} */
    const response = await axios({
      method: 'GET',
      url: 'menus',
      params: {
        populate: '*',
        nested: true 
      }
    });

    return response.data.data;
  }
);

const menusSlice = createSlice({
  name: 'marketing/menus',

  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchMenus.fulfilled, (state, action) => {
        if (action.payload) {
          menusAdaptor.upsertMany(state, action.payload);
        }
      });
  }
});

export const selectMenuBySlug = (/** @type {string} */ slug) =>
  (/** @type {import('./index').RootState} */ state) =>
    state.marketing.menus.entities[slug]

export default menusSlice.reducer;
