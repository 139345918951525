import Divider from '@mui/material/Divider';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import UserAvatar from '../user/UserAvatar';
import UserName from '../user/UserName';
import DefaultButton from '../layout/DefaultButton';
import { motion } from 'framer-motion'
import { convertToPlain, truncateText } from 'app/shared-components/utils'
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setCreateInterest, selectInterestById, fetchInterest } from 'app/store/referralInterestSlice';
import { setDMUserDialogDetails } from 'app/store/contactsSlice';



const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 },
  };





function ReferralShareItem({ referral, isOwner, enableInterest }) {
  const dispatch= useDispatch();
  const processInterest= enableInterest && !isOwner;
  const referralId= referral?.id;
  const interest= processInterest ? useSelector(selectInterestById(referralId)) : null;
  const chatChannelId= interest ? interest.dmChannelId : null;
  const chatUser=interest?.provider;
	const chatTitle= "Referral: " + interest?.summary;
  let location = "";
  if (referral?.city) location= referral.city;
  if (referral?.stateProvince) location= location + " " + referral.stateProvince;
  const plainDescription= truncateText(convertToPlain(referral?.description), 200);
  const isClosed = referral?.status && referral.status.startsWith('closed');
  
  function openInterestDialog(){
	  dispatch(setCreateInterest(referral));
  }
  
  function openDMDialog(){
	  dispatch(setDMUserDialogDetails({targetUser: chatUser, title: chatTitle, channelId: chatChannelId}));
  }
  
  useEffect(() => {
    //Double check if there is an existing interest
    if (referralId && !interest){
       dispatch(fetchInterest({referralId: referralId}));
    }
  }, [referralId]);
  
  if (!referral) return null;
  
  return (

      <Card component={motion.div} style={{width: '100%', height:'100%'}} variants={item} key={referral.id}  className="mb-10">
          <div className="mx-24 mb-5 mt-20">
            <div className="flex flex-row items-center">
              <FuseSvgIcon>material-outline:outbound</FuseSvgIcon>
              <Typography color="secondary" className="text-large font-semibold ml-5" >{referral.summary}</Typography>
             </div>
             <div>
               <Typography className="" >{referral.clientType + " in " + location}</Typography>
             </div>
          </div>
           
          <CardContent className="px-20 pt-0">  
          
           {referral.description && (
			      <>
              <Divider style={{width:'100%', margin:'5px'}} />
              <Typography
                className="inline"
                component="span"
                variant="body2"
                color="text.secondary"
                sx={{
        				  width:'100%',
        			      overflow: 'hidden',
        			      textOverflow: 'ellipsis',
        			      display: '-webkit-box',
        			      WebkitLineClamp: '3',
        			      WebkitBoxOrient: 'vertical',
        			    }}
              >
                 {plainDescription}
              </Typography>
              </>
              )}
              
              {!!referral.specializations && referral.specializations.length > 0  && (
			  <>
			  <Divider style={{width:'100%', margin:'5px'}} />
              <Typography
                display="block"
                className="font-semibold"
                variant="body2"
                color="text.secondary"
                sx={{
      				    width:'100%',
      			      overflow: 'hidden',
      			      textOverflow: 'ellipsis',
      			      display: '-webkit-box',
      			      WebkitLineClamp: '2',
      			      WebkitBoxOrient: 'vertical',
      			    }}
              >
                 {referral.specializations.join()}
              </Typography>
              </>
            )}

          </CardContent>
          
          {enableInterest && !isOwner && !isClosed &&
          <CardActions disableSpacing className="px-24 justify-end -mt-10 mb-10">
            {interest ?
                <DefaultButton size="small" text="View Chat" onClick={openDMDialog} />
                       :
                <DefaultButton size="small" text="Express Interest" onClick={openInterestDialog} />
            }
          </CardActions>
          }
      
		</Card>
    
  );
}


export default ReferralShareItem;
