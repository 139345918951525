import lazyLoad from 'app/shared-components/util/lazyLoad';

const ProgramProfile = lazyLoad(() => import('app/shared-components/organization/ProgramProfile'));

const ProgramProfileAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/program/:programId',
      element: <ProgramProfile />,
    },
  ],
};

export default ProgramProfileAppConfig;
