import AppBar from '@mui/material/AppBar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createInterest, selectCreateInterest, setCreateInterest } from 'app/store/referralInterestSlice';
import { useForm } from 'react-hook-form';
import { showMessage } from 'app/store/fuse/messageSlice';
import { dispatchResultMessage } from '../utils';
import ControlledTextField from '../form/ControlledTextField';
import DefaultButton from '../layout/DefaultButton';

const schema = yup.object().shape({
  message: yup.string().required('You must enter a message'),
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ReferralInterestDialog(props) {
  const dispatch = useDispatch();  
  const interest = useSelector(selectCreateInterest);
  const { id, summary } = interest ? interest : {};
  const referralId= id;
  const title = 'Express Interest: ' + summary;
  
  const { handleSubmit, formState, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  

  function handleCloseDialog() {
    
  }

  function handleDiscard() {
	  dispatch(setCreateInterest({}));
  }

  function onSubmit(data) {
    dispatch(createInterest({referralId: referralId, referral: interest, message: data.message})).then((resp) =>
	{
		dispatchResultMessage(dispatch, resp, "Interest Submitted!", errorMessageMapper);
	});
	dispatch(setCreateInterest({}));
  }
 
  function errorMessageMapper(message){
	  if (message && (message.includes("409") || message.includes("Duplicate"))) return "You have already expressed interest in this referral!";
	  else return message;
  }

  return (
    <Dialog
      classes={{
        paper: 'w-full m-24',
      }}
      TransitionComponent={Transition}
      onClose={handleCloseDialog}
      open={Boolean(referralId)}
    >
      <AppBar position="static" color="secondary" elevation={0}>
	      <Toolbar className="flex w-full">
	        <Typography variant="subtitle1" color="inherit">
	          {title}
	        </Typography>
	      </Toolbar>
	  </AppBar>
	  
      <form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <DialogContent classes={{ root: 'p-16 pb-0 sm:p-32 sm:pb-0' }}>
				<ControlledTextField name="message" label="Message"
                             control={control} errors={errors}
             				 required fullWidth multiline
             				 minRows={4} maxRows={8} />
          </DialogContent>

          <DialogActions className="flex flex-col sm:flex-row sm:items-center justify-between py-16 sm:py-24 px-24">
            <div className="flex items-center space-x-8 mt-16 sm:mt-0">
              <DefaultButton text="Cancel" onClick={handleDiscard} />
              <DefaultButton text="Submit"
	              disabled={_.isEmpty(dirtyFields) || !isValid}
	              submit />
            </div>
          </DialogActions>
        </form>
        
    </Dialog>
  );
}

export default ReferralInterestDialog;
