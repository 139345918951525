import lazyLoad from 'app/shared-components/util/lazyLoad';

const SearchApp = lazyLoad(() => import('./SearchApp'));
const ProvidersByState = lazyLoad(() => import('./ProvidersByState'));


const SearchProvidersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/search',
      element: <SearchApp />,
    },
    {
      path: '/search/providers',
      element: <SearchApp />,
    },
    {
      path: '/search/programs',
      element: <SearchApp />,
    },
    {
      path: '/search/posts',
      element: <SearchApp />,
    },
    {
      path: '/search/providers/state/:state',
      element: <ProvidersByState />,
    },
  ],
};

export default SearchProvidersConfig;


