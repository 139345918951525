import _ from '@lodash';
import ChannelAccessSettingsModel, { MEMBERSHIP_SETTING_INVITE, MEMBERSHIP_SETTING_OPEN } from './ChannelAccessSettingsModel';
import {MEMBERSHIP_SETTING_NONE, ACCESS_LEVEL_NONE, ACCESS_LEVEL_INHERIT, MEMBERSHIP_SETTING_INHERIT} from './ChannelAccessSettingsModel';


function GroupModel(data) {
  let channel = {};
  channel.id= data?.id ? data.id : undefined;
  channel.name= data?.name ? data.name : '';
  channel.description= data?.description ? data.description : '';
  channel.type= data?.type ? data.type : '';
  channel.applicationType= data?.publicAccess ? data.publicAccess : "NONE";
  channel.joinType= data?.joinType ? data.joinType : "NONE";
  channel.inheritMembers= (data?.type === 'topic') ? true : false;
  channel.parentChannelId= data?.parentChannelId;
  channel.accessSettings= data?.joinType ? getAccessSettings(data) : '';
  if (data?.type === 'group'){
		  channel.specializations= data?.specializations ? data.specializations : [];
		  channel.postalCode= data?.postalCode ? data.postalCode : '';
		  channel.states= data?.states ? data.states : [];
		  channel.credentials= data?.credentials ? data.credentials : [];
		  channel.postalCodes= data?.postalCodes ? data.postalCodes : '';
  }
  return channel;
}


export function APIModel(data) {
  let channel = {};
  channel.id= data.id;
  channel.name= data.name;
  channel.description= data.description;
  channel.type= data.type;
  channel.inheritMembers= data.type === 'topic' ? data.inheritMembers : undefined;
  channel.accessSettings= data.accessSettings;
  channel.parentChannelId= data.parentChannelId;
  channel.headerImageId= data.headerImageId;
  channel.logoImageId= data.logoImageId;
  if (data?.type === 'group'){
		  channel.specializations= data.specializations;
		  channel.postalCode= data.postalCode;
		  channel.requirements= {};
		  channel.requirements.states= data.states;
		  channel.requirements.credentials= data.credentials;
		  channel.requirements.postalCodes= parsePostalCodes(data.postalCodes);
  }
  
  return channel;
}

export function loadChannelModel(data) {
  if (!data) return GroupModel();
  let channel = {...data, joinType: "NONE", applicationType: "NONE"};
  const settingsObj= parseAccessSettings(data.accessSettings);
  for (const property in settingsObj) {
	  if (settingsObj[property].membershipSetting == MEMBERSHIP_SETTING_OPEN) channel.joinType= property;
	  if (settingsObj[property].membershipSetting == MEMBERSHIP_SETTING_INVITE) channel.applicationType= property;
  }
  if (data.requirements){
  	 channel.states= data.requirements.states;
     channel.credentials= data.requirements.credentials;
     channel.postalCodes= data.requirements.postalCodes ? data.requirements.postalCodes.join() : "";
  }
  if (data.type === 'group'){
  	if (!channel.states) channel.states= [];
  	if (!channel.credentials) channel.credentials= [];
  	if (!channel.specializations) channel.specializations= [];
    if (!channel.postalCodes) channel.postalCodes= "";
  }
  return channel;
}

function getAccessSettings(data){
	//New access settings object with default no access for all user types
	let access= ChannelAccessSettingsModel();
	
	//Set access based on form selections
	if (data.joinType && data.joinType != "NONE") {
		access[data.joinType].membershipSetting= MEMBERSHIP_SETTING_OPEN;
	}
	if (data.applicationType && data.applicationType != "NONE") {
		access[data.applicationType].membershipSetting= MEMBERSHIP_SETTING_INVITE;
	}

    //If this is a topic, currently we just set everything to inherit
	if (data.type === 'topic'){
		for (const property in access) {
		  access[property].defaultAccess= ACCESS_LEVEL_INHERIT;
		  access[property].membershipSetting= MEMBERSHIP_SETTING_INHERIT;
		}
	}
	//If this is a team, currently set so anyone can be invited but nobody has any other access
	else if (data.type === 'team' || data.type === 'organization'){
		 access.public.defaultAccess= ACCESS_LEVEL_NONE;
	   access.public.membershipSetting= MEMBERSHIP_SETTING_INVITE;
	}
	
	//Turn object into array 
	let out= [];
	for (const property in access) {  
	  if (access[property].defaultAccess != ACCESS_LEVEL_NONE ||
	      access[property].membershipSetting != MEMBERSHIP_SETTING_NONE)
	  {
		 out.push(access[property]);
	  } 
	}
	
	// validate there is some membership access
	if (out.length == 0)
	{
		throw new Error('No access or membership provided for any user type!');
	}
	
	//Return JSON string 
	return JSON.stringify(out);
}


function parseAccessSettings(data){
	const access= JSON.parse(data);
	const out= ChannelAccessSettingsModel();
	for (let i in access) {
		const setting= access[i];
		const settingOut= out[setting.userType];
		settingOut.defaultAccess= setting.defaultAccess;
		settingOut.membershipSetting= setting.membershipSetting;
	}
	return out;
}

function parsePostalCodes(text){
	  let codes;
  	try{
  		if (text){
  			let val= text.replaceAll(" ",",");
  			val= val.replaceAll(",,",",");
  			codes= val.split(",");
   		}
  		else return [];
  	}catch(error){
  		throw new Error("Postal codes must be seperated by commas or spaces.");
  	}
  	
  	for (let i in codes){
			const code= codes[i];
			if (!(/^[0-9]{5}$/.test(code))) throw new Error('Postal codes must be seperated by only commas or spaces. Invalid postal code: ' + code);
		}
		
		return codes;
  }

export default GroupModel;
