import authRoles from '../../../auth/authRoles';
import Home from './Home';
import { Navigate } from 'react-router-dom';

const HomeConfig = {
  settings: {
    layout: {
      style: 'layoutMarketing',

      config: {
        header: {
          display: true
        },

        footer: {
          display: true
        }
      },
    },
  },

  auth: authRoles.onlyGuest,

  routes: [
    // {
    //   path: 'marketing/',
    //   element: <Navigate to="/marketing/home" replace={true} />,
    // },
    // {
    //   path: 'marketing/home',
    //   element: <Home />,
    // },
    {
      path: '/',
      element: <Home />,
    },
  ],
};

export default HomeConfig;
