import { DatePicker } from '@mui/x-date-pickers';
import { Controller } from 'react-hook-form';


function ControlledDatePicker({control, name, label, errors, className, required}) {
   const useClasses = className ? className : "mt-8 mb-16 w-full";
   
   return (
	   
	 <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            className={useClasses}
            value={new Date(value)}
            onChange={onChange}
            slotProps={{
              textField: {
                label: label,
                required: required,
                variant: 'outlined',
                error: !!errors[name],
                helperText: errors[name]?.message
              },
            }}
          />
        )}
      />
   );
}

export default ControlledDatePicker;
