import FuseUtils from '@fuse/utils/FuseUtils';
import jwtDecode from 'jwt-decode';
import jwtServiceConfig from './jwtServiceConfig';
import generateUUID from 'app/shared-components/util/generateUUID';
import { getProviderInfo, getUserByScreenName } from 'app/store/contactFunctions';
import { generateRandomHex } from 'app/shared-components/util/randomUtils';
import { defaultUserCover } from 'app/configs/themesConfig';
import { signUp, signIn, confirmSignUp, confirmSignIn, resendSignUpCode, getCurrentUser, 
         fetchAuthSession, fetchUserAttributes, fetchMFAPreference, rememberDevice, 
         resetPassword, confirmResetPassword, updatePassword, signOut } from 'aws-amplify/auth';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.handleAuthentication();
  }

  handleAuthentication = async () => {
	  console.log("Inside handleAuthentication");
	  try{
	  	await getCurrentUser();
	  	this.emit('onAutoLogin', true);
	  }catch(error){
	  	this.emit('onNoAccessToken');
	  }
  };


  createUser = (data) => {
		console.log(data);
		const email= data.email.toLowerCase();
		const uuid= generateUUID();
		const screenName= data.screenName ? data.screenName : createScreenName(data.firstName, data.lastName);
	    return new Promise((resolve, reject) => {
		  signUp({
	        username: email,
	        password: data.password,
	        options: {
	          userAttributes: {
		          email: email,
		          given_name: data.firstName,
		          family_name: data.lastName,
		          preferred_username: screenName,
		          birthdate: data.birthdate,
		          phone_number: data.phone,
		          'custom:type': data.type,
		          'custom:uuid': uuid
	          }
	        },
	      }).then((response) => {
	         resolve({userId: uuid, response});
	         this.emit('onSignup', {email: email, channelId: data.channelId, referralRequest: data.referralRequest});
	      }).catch(err => {
			 console.log(err.message);
			 reject(err);
		  })
    });
  }; 

  signInWithEmailAndPassword = (emailIn, password, channelId) => {
    return new Promise((resolve, reject) => {
		(async () => {
	    try {
		    console.log("Inside signInWithEmailAndPassword.");
 	      const email= emailIn.toLowerCase();

			  //Log in with Cognito
	      const {isSignedIn, nextStep}= await signIn({ username: email, password: password });
	      
	      //Check for MFA
	      if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE') {	    
				   //Resolve with the new user 
				    resolve(nextStep.signInStep);
			  }else{
			  	  //Use cognito user to get rest of user information
	          let user= await this.finalizeLogin(nextStep.signInStep, channelId);

				    //Resolve with the new user 
				    resolve(user);
			  }
	    } catch (err) {
		      console.log(err);
		      signOut();
          console.log("Signed out of Cognito!");
	        reject(err);
	    }
	    })()
    });
  };
  
  signInWithMfaCode = (code, remember) => {
    return new Promise((resolve, reject) => {
		(async () => {
	    try {
		     console.log("Inside signInWithMfaCode.");
 	       await confirmSignIn({ challengeResponse: code });
	       
         //Use cognito user to get rest of user information
	       let user= await this.finalizeLogin();
	  
	  		 //Remeber device as needed
	       if (remember) await rememberDevice(); 

				 //Resolve with the new user 
				 resolve(user);
	    } catch (err) {
		      console.log(err);
		      signOut();
          console.log("Signed out of Cognito!");
	        reject(err);
	    }
	    })()
    });
  };
  
  finalizeLogin = async (signInStep, channelId) => {
  	  //Use cognito user to get rest of user information
      let user= await this.getStoreUserData();
  
	    //Set the JWT token
	    const { idToken } = (await fetchAuthSession()).tokens ?? {};
	    
	    //Check for challenge
      if (signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {	    
			   user.requireNewPassword= true;
		  }
	    
	    //Emit login event for UserAuth
		  this.emit('onLogin', {user: user, channelId: channelId});
		  
		  return user;
  };
  
  confirmSignupWithCode = (emailIn, code, channelId) => {
    return new Promise((resolve, reject) => {
		(async () => {
	      try {
		      console.log("Inside confirmSignupWithCode.");
 	        const email= emailIn.toLowerCase();

	        await confirmSignUp({username: email,
                               confirmationCode: code});
	        
	        //Resolve 
		      resolve();
		    
		      //Emit login event for UserAuth
		  	  this.emit('onConfirmSignup', {email: email, channelId: channelId });
	      } catch (err) {
		    console.log(err);
	        reject(err);
	      }
	    })()
    });
  };
  
  resendConfirmationCode = (emailIn) => {
    return new Promise((resolve, reject) => {
		(async () => {
	      try {
		    console.log("Inside resendConfirmationCode.");
 	      const email= emailIn.toLowerCase();

			  await resendSignUpCode({username: email});
	        
	      //Resolve 
		    resolve();
		    
		    //Emit login event for UserAuth
			this.emit('onResendConfirmationCode', {email: email});
	      } catch (err) {
		    console.log(err);
	        reject(err);
	      }
	    })()
    });
  };


  forgotPassword = (emailIn) => {
    return new Promise((resolve, reject) => {
		(async () => {
	      try {
		    console.log("Inside forgotPassword.");
 	        const email= emailIn.toLowerCase();

	        await resetPassword({username: email});
	        
	        //Resolve 
		      resolve();
		    
		      //Emit login event for UserAuth
			    this.emit('onForgotPassword', {email: email});
	      } catch (err) {
		    console.log(err);
	        reject(err);
	      }
	    })()
    });
  };
  
  forgotPasswordReset = (emailIn, code, password) => {
    return new Promise((resolve, reject) => {
		(async () => {
	      try {
		    console.log("Inside forgotPasswordReset.");
 	      const email= emailIn.toLowerCase();

	      await confirmResetPassword({username: email,newPassword: password, confirmationCode: code});
	        
	        //Resolve 
		    resolve();
		    
		    //Emit login event for UserAuth
			  this.emit('onForgotPasswordReset', {email: email});
	      } catch (err) {
		    console.log(err);
	        reject(err);
	      }
	    })()
    });
  };
  
  changePassword = (oldPassword, newPassword) => {
    return new Promise((resolve, reject) => {
		(async () => {
	      try {
    		const data = await updatePassword({oldPassword, newPassword});
	        
	        //Resolve 
		    resolve();
		    
		    //Emit login event for UserAuth
			  this.emit('onChangePassword', {});
	      } catch (err) {
		    console.log(err);
	        reject(err);
	      }
	    })()
    });
  };

  newPassword = (newPassword) => {
    return new Promise((resolve, reject) => {
		(async () => {
	      try {
		    console.log("Inside newPassword.");

        await confirmSignIn({ challengeResponse: newPassword });
	        
		    //Resolve
		    resolve();
		    
		    //Emit login event for UserAuth
			  this.emit('onNewPassword');
	      } catch (err) {
		    console.log(err);
	        reject(err);
	      }
	    })()
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
		(async () => {
	      try {
			    console.log("Inside signInWithToken");

	        //Use cognito user to get rest of user information
	        let user= await this.getStoreUserData();
	        console.log(user);
		    
			    //Resolve with the new user 
			    resolve(user);
	      } catch (err) {
			console.log(err);
	        reject(err);
	      }
	    })()
    });
  };
  
  refreshLogin = () => {
    return new Promise((resolve, reject) => {
		(async () => {
	      try {
			    console.log("Inside refreshLogin");
			    //refresh user session with Cognito
	        await fetchAuthSession();
	      } catch (err) {
			    console.log(err);
	      }
		  finally{
			  resolve();
		  }
	    })()
    });
  };
  
  updateUserImage = async (userId, fileId, imageType) => {
	  const apiName = 'CoreAPI';
	  const path = '/user/' + userId;
	  let body = {};
	  switch(imageType) {
		  case 'user_profile':
			body.profileImageId= fileId;
		    break;
		  case 'user_header':
			body.headerImageId= fileId;
		    break;
		  default:
	  }
    const options = {
		  headers: {},
		  body: body,
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

   let newUserObj= await apiPut(apiName, path, options);
	 return newUserObj;
  };
  
  
  getStoreUserData = async () => {
  	//Get cogniton attributes
	  const userAttributes = await fetchUserAttributes();
	  
	  //Get user data from platform
    const userId= userAttributes["custom:uuid"];
    if (!userId) throw new Error("No userId found for logged-in user.");
    console.log("Inside getStoreUserData for " + userId);
    const userObj= await this.validateLogin(userId);
    console.log(userObj);
    const updatedTermsMessage= userObj.updatedTermsMessage;
    const requireAcceptTerms= Boolean(updatedTermsMessage);
    const { preferred } = await fetchMFAPreference();
    const mfaEnabled= preferred === 'SMS';
    
    //Get user roles
    const { idToken } = (await fetchAuthSession()).tokens ?? {};
    const groups= idToken.payload['cognito:groups'];
    let roles= ["user"];
    if (groups){
		  roles= roles.concat(groups);
	  }
	  
	  //get attributes (for some reason the included attributes can be stale)
	  const attributes= userAttributes;
	  console.log(attributes);
    
    //Create redux user data object for store
    const data = {screenName: userObj.screenName ? userObj.screenName : userObj.firstName,
                  firstName: userObj.firstName,
                  lastName: userObj.lastName,
                  email: attributes.email, 
                  phone: attributes.phone_number,
                  phoneVerified: attributes.phone_number_verified,
                  mfaEnabled: mfaEnabled,
                  birthdate: attributes.birthdate,
                  status: userObj.status ? userObj.status : '', 
                  city: userObj.city ? userObj.city : '',
                  stateProvince: userObj.stateProvince ? userObj.stateProvince : '',
                  postalCode: userObj.postalCode ? userObj.postalCode : '', 
                  about: userObj.about ? userObj.about : '', 
                  gender: userObj.gender ? userObj.gender : '', 
                  type: userObj.type, providerInfoId: userId,
                  featuredPosts: userObj.featuredPosts,
                  followerCount: userObj.followerCount,
                  followingCount: userObj.followingCount,
                  connectionCount: userObj.connectionCount,
                  };
    const images = {
		          profileImageUrl: userObj.profileImageUrl,
              photoURL: userObj.profileImageUrl,
              headerImageURL: userObj.headerImageUrl ? userObj.headerImageUrl : defaultUserCover,
          };
    const timelineId= userObj.publicTimelineId;
    
    let providerInfo= userObj.providerInfo;
    return {id: userId, timelineId: timelineId, role: roles, data: data, 
            images: images, requireNewPassword: false, providerInfo: providerInfo, 
            requireAcceptTerms: requireAcceptTerms, updatedTermsMessage: updatedTermsMessage,
            raw: userObj, initialized: false, subscriptionStatus: {}, identificationStatus: {}};
  }
  
  validateLogin = async (userId) => {
	  console.log("Inside validateLogin for " + userId);
	  const apiName = 'CoreAPI2';
	  const path = '/x2/userx/' + userId + '/validatelogin';
    const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

   let userObj= await apiPost(apiName, path, options);
	 return userObj;
  };
  


  logout = () => {
	  signOut();
    console.log("Signed out of Cognito!");
    this.emit('onLogout', 'Logged out');
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
      console.warn('access token expired');
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem('jwt_access_token');
  };
}

const createScreenName= (firstName, lastName) => {
	const hexString= generateRandomHex(16);
	return (firstName.charAt(0) + lastName.charAt(0) + '_' + hexString).toLowerCase();
}

const instance = new JwtService();

export default instance;
