import _ from '@lodash';
import FuseUtils from '@fuse/utils';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

function NotificationModel(notification) {
  let data = {};
  
  data.id= notification.source + "-" + notification.createdAt;
  data.type= notification.event?.type;
  data.source= notification.source;
  data.createdAt= notification.createdAt;
  data.time= formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true });
  data.image= notification.event?.sourceUserProfileImageURL;
  if (!data.image) data.icon= 'heroicons-solid:star';
  data.read= notification.read;
  data.variant= 'default';
  enrichNotification(data, notification.event);
  return data;
}

function enrichNotification(data, event){
	if (!event) return;
	if (event.type === 'Follow') enrichFollow(data, event);
	else if (event.type === 'Post') enrichPost(data, event);
	else if (event.type === 'ImportantPost') enrichImportantPost(data, event);
	else if (event.type === 'UserRecommendation') enrichUserRecommendation(data, event);
	else if (event.type === 'ConnectionInvite') enrichInvite(data, event);
	else if (event.type === 'PostInteraction') enrichInteraction(data, event);
	else if (event.type === 'PostWarning') enrichPostWarning(data, event);
	else if (event.type === 'ChannelInvite') enrichChannelInvite(data, event);
	else if (event.type === 'Mention') enrichMention(data, event);
	else if (event.type === 'ReferralInterest') enrichReferralInterest(data, event);
	else if (event.type === 'ReferralNotification') enrichReferralNotification(data, event);
	else if (event.type === 'ChannelApplicationAccepted') enrichApplicationApproved(data, event);
	else if (event.type === 'ChannelApplicationDeclined') enrichApplicationDeclined(data, event);
	else if (event.type === 'ChannelMemberRemoved') enrichRemovedFromChannel(data, event);
	else if (event.type === 'ChannelApplication') enrichChannelApplication(data, event);
	else if (event.type === 'ChannelInviteAccepted') enrichChannelInviteAccepted(data, event);
	else if (event.type === 'ChannelJoined') enrichChannelJoined(data, event);
	else if (event.type === 'ConnectionAccepted') enrichConnectionAccepted(data, event);
	else if (event.type === 'ExternalMessage') enrichExternalMessage(data, event);
	else if (event.type === 'ClientAuthorizationPending') enrichClientAuthPending(data, event);
	else if (event.type === 'ClientAuthorizationStatus') enrichClientAuthStatus(data, event);
}

function enrichFollow(data, event){
	data.title= "New Follower";
    data.description= event.sourceUserName + " followed you.";
    data.useRouter= true;
    data.link = "/profile/" + event.sourceUserId;
}

function enrichUserRecommendation(data, event){
	data.title= "New Recommendation";
    data.description= event.sourceUserName + " recommended you.";
    data.useRouter= true;
    data.link = "/apps/profile";
}

function enrichPost(data, event){
	if (event.threadPostId){
		data.title= "New Comment";
        data.description= event.sourceUserName + " commented on your post.";
        data.postId= event.threadPostId;
	}
	data.useRouter= true;
	if (event.contentPostType === 'chat' || event.postType === 'chatcomment')
    	data.link = "/chatpost/" + data.postId;
    else
    	data.link= "/post/" + data.postId;

}

function enrichImportantPost(data, event){
  data.title= "Important Message!";
  data.description= "From " + event.sourceUserName + (event.channelType === 'dm' ? "" : " in " + event.channelName);
  data.postId= event.contentPostId;

	if (event.channelType === 'dm' || event.channelType === 'client_dm' || event.channelType === 'interest_dm'){
		data.onClick= 'dmModal';
		data.dmChannelId= event.channelId;
		data.dmTitle= event.channelName;
		data.dmUserId= event.sourceUserId;
	}else{
		data.useRouter= true;
		if (event.contentPostType === 'chat' || event.postType === 'chatcomment')
	    	data.link = "/chatpost/" + data.postId;
	    else
	    	data.link= "/post/" + data.postId;
	}
}

function enrichInvite(data, event){
	data.title= "New Invite";
    data.description= event.sourceUserName + " invited you to connect.";
    data.useRouter= true;
    data.link = "/apps/network/invites";
}

function enrichChannelInvite(data, event){
	data.title= "Invite to " + event.channelType;
    data.description= event.sourceUserName + " invited you to join: " + event.channelName;
    data.useRouter= true;
    data.link= "/apps/" + event.channelType + "s/invites";
}

function enrichInteraction(data, event){
	data.title= "New Interaction";
    data.description= event.sourceUserName + " liked your post. ";
    data.useRouter= true;
    data.postId = event.contentPostId;
    data.link = "/post/" + event.contentPostId;
}

function enrichPostWarning(data, event){
	  data.title= "Recieved Warning for Post";
    data.description= event.warningType + ": " + event.message;
    data.useRouter= true;
    data.postId = event.contentPostId;
    data.link = "/post/" + event.contentPostId;
    data.icon= 'heroicons-solid:exclamation'
}

function enrichMention(data, event){
	data.title= "New Mention";
    data.description= event.sourceUserName + " mentioned you.";
    data.useRouter= true;
    if (event.postType === 'chat' || event.postType === 'chatcomment')
    	data.link = "/chatpost/" + event.postId;
    else
    	data.link= "/post/" + event.postId;
}

function enrichReferralInterest(data, event){
	data.title= "Referral Interest";
    data.description= event.sourceUserName + " is interested in " + event.referralSummary;
    data.useRouter= true;
    data.referralId = event.referralId;
    data.link = "/apps/referrals/chat/" + event.referralId;
}

function enrichReferralNotification(data, event){
	  data.title= "New Referral";
    data.description= event.sourceUserName + " posted " + event.referralSummary;
    data.useRouter= true;
    data.referralId = event.referralId;
    data.link = "/apps/referrals/notifications";
}

function enrichConnectionAccepted(data, event){
	data.title= "Connection Accepted!";
    data.description= event.sourceUserName + " accepted your connection request.";
    data.useRouter= true;
    data.userId = event.sourceUserId;
    data.link = "/profile/" + event.sourceUserId;
}

function enrichChannelInviteAccepted(data, event){
	const label= event.channelType == 'group' ? "Group" : "Team";
	data.title= label + " Invite Accepted!";
    data.description= event.sourceUserName + " accepted your invitation to join '" + event.channelName + "'";
    data.useRouter= true;
    data.channelId = event.channelId;
    data.channelType= event.channelType;
    data.link = "/apps/" + event.channelType + "/" + event.channelId;
}

function enrichChannelApplication(data, event){
	data.title= "New Application!";
    data.description= event.sourceUserName + " applied to join '" + event.channelName + "'";
    data.useRouter= true;
    data.channelId = event.channelId;
    data.channelType= event.channelType;
    data.link = "/apps/" + event.channelType + "/" + event.channelId + "/members?showApplications=true";
}

function enrichApplicationApproved(data, event){
	  data.title= "Application Approved!";
    data.description= event.sourceUserName + " approved your application to '" + event.channelName + "'";
    data.useRouter= true;
    data.channelId = event.channelId;
    data.channelType= event.channelType;
    data.link = "/apps/" + event.channelType + "/" + event.channelId;
}

function enrichApplicationDeclined(data, event){
	 data.title= "Application Declined";
	  data.channelId = event.channelId;
    data.channelType= event.channelType;
    data.description= event.sourceUserName + " declined your application to '" + event.channelName + "'";
    data.useRouter= true;
    data.link = "/" + event.channelType + "/" + event.channelId;
}

function enrichRemovedFromChannel(data, event){
	  data.title= "Removed from " + event.channelType;
	  data.channelType= event.channelType;
    data.channelId = event.channelId;
    data.channelType= event.channelType;
    data.description= event.sourceUserName + " removed you from '" + event.channelName + "'";
    data.useRouter= true;
    data.link = "/" + event.channelType + "/" + event.channelId;
}



function enrichChannelJoined(data, event){
	data.title= "New Member!";
    data.description= event.sourceUserName + " joined '" + event.channelName + "'";
    data.useRouter= true;
    data.channelId = event.channelId;
    data.channelType= event.channelType;
    data.link = "/apps/" + event.channelType + "/" + event.channelId;
}

function enrichExternalMessage(data, event){
	data.title= "New External Message";
    data.description= event.fromName + " sent you a message.";
    data.useRouter= true;
    data.link = "/apps/chat/external";
}

function enrichClientAuthPending(data, event){
	data.title= "New Client Authorization!";
    data.description= event.sourceUserName + " authorized you to discuss a client.";
    data.useRouter= true;
    data.clientId= event.clientId;
    data.link = "/apps/clients/pending";
}

function enrichClientAuthStatus(data, event){
	if (event.status === 'Approved'){
		data.title= "Client Authorization Accepted";
	    data.description= event.sourceUserName + " approved your authorization discuss a client.";
	}else{
		data.title= "Client Authorization Declined";
	    data.description= event.sourceUserName + " declined your authorization discuss a client.";
	}
    data.useRouter= true;
    data.clientId= event.clientId;
    data.link = "/apps/client/" + event.clientId + "/users";
}

export default NotificationModel;
