import { Typography } from '@mui/material';


function UserLocation(props) {
  let { user, className, style, sx, component, to, color, paragraph, noPronouns, providerView} = props;
  if (!user) return null;
  if (user.raw) user= user.raw;
  const isProvider= user.type === 'provider';
  const location= isProvider ? getLocationString(user.providerInfo?.city, user.providerInfo?.stateProvince):
                               getLocationString(user.city, user.stateProvince);
  const pronouns= !noPronouns && user.pronouns ? user.pronouns : "";
  const text= pronouns && location ? pronouns + ", " + location : pronouns + location;
                                     

  function getLocationString(city, state) {
    if (city && state) return  city + ", " + state;
    if (city) return city;
    if (state) return state;
    return "";
  }
  

  return (
	
		  <Typography
	              sx={sx}
	              className={className}
	              style={style}
	              component={component}
	              to={to}
	              color={color}
	              paragraph={paragraph}
	            >
	              {text} 
	      </Typography> 
	      
  );
  
}


export default UserLocation;