import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import {  startLoading, stopLoading} from 'app/store/utilSlice';
import {  setPost } from 'app/store/postSlice';
import queryString from 'app/shared-components/util/queryString';

export const STATUS_OPEN = "open";
export const STATUS_THRESHOLD = "threshold";
export const STATUS_HIDDEN = "hidden";
export const STATUS_REMOVED = "removed";
export const STATUS_CLOSED = "closed";

const statusSort= {};
statusSort[STATUS_REMOVED]= "01";
statusSort[STATUS_CLOSED]= "02";
statusSort[STATUS_HIDDEN]= "03";
statusSort[STATUS_OPEN]= "04";
statusSort[STATUS_THRESHOLD]= "05";

const itemsAdapter = createEntityAdapter({
	sortComparer: (a, b) => b.sortKey.localeCompare(a.sortKey),
});


export const getReportCounts = createAsyncThunk(
  "report/getReportCounts",
  async ({channelId, status, startFromId }, { dispatch, getState }) => {
    dispatch(startLoading());
    
    console.log("Status: " + status);
    console.log("Sort: " + statusSort[status]);
    console.log(statusSort);
    
      const apiName = 'CoreAPI';
      const basePath=  channelId ?  '/postreportcount/bychannel/' + channelId : '/postreportcount/bystatus/' + status;
      const queryStatus= (channelId && status) ? "createdAt=STARTS:" + statusSort[status] : undefined;
      const queryStart= startFromId ? "startFromId=" + startFromId : undefined;
      const path = basePath + queryString(queryStatus, queryStart);
      const options = {
  		  headers: {},
  		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  	  };

      try{
  	    let data= await apiGet(apiName, path, options);
  	    
  	    //Add each post to the postSlice store so it can be displayed in PostEntry component
  	    for (let i in data) {
      		const report= data[i];
      		if (report.post) dispatch(setPost({post: report.post}));
      	}
      	
        return data;
      }finally{
        dispatch(stopLoading());
      }
  }
);

export const updateReportCount = createAsyncThunk(
  "report/updateReportCount",
    async (params, {dispatch}) => {
	  const {postId, status}= params;
    const apiName = 'CoreAPI';
	  const path = '/postreportcount/' + postId;
      const options = {
		  headers: {},
		  body: {status: status},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  try{
		  const data= await apiPut(apiName, path, options);
	    return data; 
	  }finally{
		  dispatch(stopLoading());
	  }
  }
);

export const deleteReportCount = createAsyncThunk(
  "report/deleteReportCount",
    async (params, {dispatch}) => {
	  const {postId}= params;
    const apiName = 'CoreAPI';
	  const path = '/postreportcount/' + postId;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  try{
		  const data= await apiDel(apiName, path, options);
	    return postId; 
	  }finally{
		  dispatch(stopLoading());
	  }
  }
);


export const {
  selectAll: selectReportCounts,
  selectById: selectItemById,
} = itemsAdapter.getSelectors((state) => state.report.currentReportCounts);


const reportSlice = createSlice({
  name: 'report',
  initialState: {
	  reportPostId: null, 
	  currentChannelId: null, 
	  currentStatus: null, 
	  lastFetchedId: null, 
	  currentReportCounts:  itemsAdapter.getInitialState(),
  },
  reducers: {
    setReportPostId: {
      reducer: (state, action) => {
        state.reportPostId = action.payload;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getReportCounts.fulfilled, (state, action) => {
	    const items= action.payload;
      state.currentChannelId = action.meta.arg.channelId;
      state.currentStatus = action.meta.arg.status;
      itemsAdapter.setAll(state.currentReportCounts, items);
      if (items.length > 0){
        const last= items[items.length -1];
        state.lastFetchedId= last.id;
      }
    })
    .addCase(updateReportCount.fulfilled, (state, action) => {
        const item= action.payload;
        const status= action.meta.arg.status;
        if (status != state.currentStatus) itemsAdapter.removeOne(state.currentReportCounts, item.id);
        else itemsAdapter.upsertOne(state.currentReportCounts, item);
    })
    .addCase(deleteReportCount.fulfilled, (state, action) => {
	      const postId= action.payload;
        itemsAdapter.removeOne(state.currentReportCounts, postId);
    })
  },
});



export const selectReportPostId = ({ report }) => report.reportPostId;
export const { setReportPostId } = reportSlice.actions;
export default reportSlice.reducer;
