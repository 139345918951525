import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import {  startLoading, stopLoading} from 'app/store/utilSlice';


export const getPlans = createAsyncThunk(
  'subscriptionPlan/getPlans',
  async ({type}, { dispatch, getState }) => {
	try{
      dispatch(startLoading());
	 
		  const apiName = 'CoreAPI2';
		  const path = '/x2/subscriptionplan/bytype/' + type;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  const out = await apiGet(apiName, path, options);
		  return out;
	  
   }finally{
	   dispatch(stopLoading());
   }
  }
)


export const createPlan = createAsyncThunk(
  'subscriptionPlan/createPlan',
  async (params, { dispatch, getState }) => {
	  const apiName = 'CoreAPI2';
	  const path = '/x2/subscriptionplan/';
      const options = {
		  body: params,
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  const out = await apiPut(apiName, path, options);
	  return out;
  }
);


export const deletePlan = createAsyncThunk(
  'subscriptionPlan/deletePlan',
  async ({id}, { dispatch, getState }) => {
	  const apiName = 'CoreAPI2';
	  const path = '/x2/subscriptionplan/' + id;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  const resp = await apiDel(apiName, path, options);
	  return id;
  }
);
 
export const updatePlan = createAsyncThunk(
  'subscriptionPlan/updatePlan',
  async (params, { dispatch, getState }) => {
	  const apiName = 'CoreAPI2';
	  const path = '/x2/subscriptionplan/' + params.id;
      const options = {
		  body: params,
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  const out = await apiPut(apiName, path, options);
	  return out;
  }
);



const planAdapter = createEntityAdapter({});


const subscriptionPlanSlice = createSlice({
  name: 'subscriptionPlan',
  initialState: {
	  plans: planAdapter.getInitialState(),
	  subscriptionExceededMessage: null,
	  selectedId: null,
	  selectedTab: null,
  },
  reducers: {
    setSelectedPlanId: {
      reducer: (state, action) => {
        state.selectedId = action.payload;
      }
    },
    setSelectedPlanTab: {
      reducer: (state, action) => {
        state.selectedTab = action.payload;
      }
    },
    setSubscriptionExceededMessage: {
      reducer: (state, action) => {
        state.subscriptionExceededMessage = action.payload;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(updatePlan.fulfilled, (state, action) => {
	  planAdapter.upsertOne(state.plans, action.payload);
    })
    .addCase(createPlan.fulfilled, (state, action) => {
      planAdapter.addOne(state.plans, action.payload);
    })
 
    .addCase(deletePlan.fulfilled, (state, action) => {
      planAdapter.removeOne(state.plans, action.payload);
      state.selectedId= null;
    })
    .addCase(getPlans.fulfilled, (state, action) => {
      planAdapter.setAll(state.plans, action.payload);
    })
  },
});


const { selectAll, selectById } = planAdapter.getSelectors();


//Get the objects for the specified type
export const selectPlans= (state) => {
	return selectAll(state.subscriptionPlan.plans);
} 
export const selectPlanById= (id) => (state) => {
	return selectById(state.subscriptionPlan.plans, id);
} 


export const selectSelectedPlanId = ({ subscriptionPlan }) => subscriptionPlan.selectedId;
export const selectSelectedPlanTab = ({ subscriptionPlan }) => subscriptionPlan.selectedTab;
export const selectSubscriptionExceededMessage = ({ subscriptionPlan }) => subscriptionPlan.subscriptionExceededMessage;
export const { setSelectedPlanId, setSelectedPlanTab, setSubscriptionExceededMessage} = subscriptionPlanSlice.actions;
export default subscriptionPlanSlice.reducer;
