import { useEffect } from "react";
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { clientVersion } from 'app/shared-components/util/restAPI';

const isDev= process.env.NODE_ENV === 'development';
const isProd= (!isDev && document.location.hostname.search("halespring.com") !== -1 && document.location.hostname.search("dev") === -1);
const isFeature= (!isDev && document.location.hostname.search("halespring.com") === -1);
const isEnabled= !isDev;

Sentry.init({
  dsn: "https://7488409071898c10f2f7a445e94f5eaf@o4505889870905344.ingest.sentry.io/4505889872216064",
  normalizeDepth: 10,
  initialScope: {
    tags: { "client-version": clientVersion },
  },
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: .05, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.0, // This sets the sample rate at 0%. 
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: isEnabled,
  environment: isDev ? "development" : isProd ? "production" : isFeature ? "feature" : "staging",
});
