import {  createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit';
import { startLoading, stopLoading } from './utilSlice';
import { addDmChannel, addDmChannels, removeDmChannel } from './dmChannelSlice';
import { setChannelRead, setChannelUnread } from './channelsSlice';
import { submitPost } from './postSlice';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import sortComparer from 'app/shared-components/util/lastMessageOrCreatedSortComparer';

const botChatAdapter = createEntityAdapter({sortComparer: sortComparer});

export const {
  selectAll: selectAiChats,
  selectById: selectAiChatById,
} = botChatAdapter.getSelectors((state) => state.aiChat.botChats);

export const getAiChats = createAsyncThunk(
  "aiChat/getAiChats",
  async ({channelId, folderId, fileId}, { dispatch, getState }) => {
	    if (!channelId && !folderId && !fileId) return null;
	    const { user } = getState();
      const apiName = 'CoreAPI2';
      const key= user.id + (channelId ? ("-Channel:" + channelId) : ("-FileItem:" + (folderId ? folderId : fileId)));
	    const path = '/x2/botuserchannel/bykey/' + key;
      const options = {
  		  headers: {},
  		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  	  };
  	 let data= await apiGet(apiName, path, options);
  	 dispatch(addDmChannels(data.map((botchan) => ({type: 'bot', foreignId: botchan.id, channelId: botchan.channelId, data: botchan}))));

  	 return data;
  }
);


export const addAiChat = createAsyncThunk(
  "aiChat/addAiChat",
  async (params, {dispatch, getState}) => {
	    const {title, channelId, folderId, fileId}= params;
      const apiName = 'CoreAPI2';
      const key= channelId ? ("Channel:" + channelId) : ("FileItem:" + (folderId ? folderId : fileId));
	    const path = '/x2/botuserchannel';
      const options = {
		  body: { title: title,
		          foreignKey: key},
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
  	 try{
  		  const data= await apiPut(apiName, path, options);
  	    return data; 
  	 }finally{
  		 dispatch(stopLoading());
  	 }
	 
  }
);


export const deleteAiChat = createAsyncThunk(
  "deleteAiChat/deleteAiChat",
  async (params, {dispatch}) => {
	    const {id}= params;
      const apiName = 'CoreAPI2';
      const path = '/x2/botuserchannel/' + id;
      const options = {
  		  headers: {},
  		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  	  };
  	 try{
  		  const data= await apiDel(apiName, path, options);
  	    return id; 
  	 }finally{
  		 dispatch(stopLoading());
  	 }
  }
);


const findChatWithChannelId= (state, channelId) => {
	let out= null;
	state.botChats.ids.map((id) => {
		const chat= state.botChats.entities[id];
		if (chat && chat.channelId == channelId) out= chat;
	})
	return out;
}

const initialState = {
  aiDialogDetails: null,
  botChats: botChatAdapter.getInitialState(),
};

const aiChatSlice = createSlice({
  name: 'aiChat',
  initialState,
  reducers: {
    setAiDialogDetails: (state, action) => {
      state.aiDialogDetails = action.payload;
      botChatAdapter.removeAll(state.botChats);
    },
  },
  extraReducers: builder => {
    builder.addCase(getAiChats.fulfilled, (state, action) => {
  	  const items= action.payload;
  	  if (!items) return;
      botChatAdapter.setAll(state.botChats, items);
    })
    .addCase(addAiChat.fulfilled, (state, action) => {
  	  const item= action.payload;
  	  if (!item) return;
      botChatAdapter.addOne(state.botChats, item);
    })
    .addCase(deleteAiChat.fulfilled, (state, action) => {
	    const id= action.payload;
      botChatAdapter.removeOne(state.botChats, id);
    })
    .addCase(setChannelRead.fulfilled, (state, action) =>  {
        const channelId = action.payload;
        const chat= findChatWithChannelId(state, channelId);
        if (chat) chat.unread= false;
    })
    .addCase(setChannelUnread, (state, action) =>  {
        const channelId = action.payload;
        const chat= findChatWithChannelId(state, channelId);
        if (chat) chat.unread= true;
    })
    .addCase(submitPost.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const chat= findChatWithChannelId(state, channelId);
      //Update with adapter to trigger sort
      if (chat) botChatAdapter.upsertOne(state.botChats, {id: chat.id, lastMessageTimestamp: action.payload.createdAt});
    })
  },
});

export const { setAiDialogDetails } = aiChatSlice.actions;
export const selectAiDialogDetails = ({ aiChat }) => aiChat.aiDialogDetails;
export default aiChatSlice.reducer;
