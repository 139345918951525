import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import UserAvatar from 'app/shared-components/user/UserAvatar';
import UserName from 'app/shared-components/user/UserName';
import { styled } from '@mui/material/styles';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';

const Root = styled(Tooltip)(({ theme, active }) => ({
  width: 70,
  minWidth: 70,
  flex: '0 0 auto',
  ...(active && {
    '&:after': {
      position: 'absolute',
      top: 8,
      right: 0,
      bottom: 8,
      content: "''",
      width: 4,
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
      backgroundColor: theme.palette.primary.main,
    },
  }),
}));

const StyledUreadBadge = styled('div')(({ theme, value }) => ({
  position: 'absolute',
  minWidth: 18,
  height: 18,
  top: 4,
  left: 10,
  borderRadius: 9,
  padding: '0 5px',
  fontSize: 11,
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.35)',
  zIndex: 10,
}));


const MultiChatItemButton = ({ item, selectedId, onClick, isCreate, isDelete }) => {
  const contact= item;
  const isUnread = item ? item.unread : false;
  const isSelected= selectedId === contact?.id;
  
  return (
    <Root title={isCreate ? "New" : isDelete ? "Delete Selected" : item?.showTitle ? item.title : <UserName user={contact}/>} placement="left" active={selectedId === contact?.id ? 1 : 0}>
      <Button
        onClick={() => onClick(item)}
        className={clsx(
          'contactButton rounded-0 py-4 h-auto min-h-auto max-h-none',
          isSelected && 'active'
        )}
      >
        {isUnread && <StyledUreadBadge></StyledUreadBadge>}

        {!isCreate && !isDelete && <UserAvatar user={contact} disableClick={true}/>}
        {isCreate && <AddCircleOutline color="success"/>}
        {isDelete && <RemoveCircleOutline color="error" />}
        
      </Button>
    </Root>
  );
};

export default MultiChatItemButton;
