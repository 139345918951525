import lazyLoad from 'app/shared-components/util/lazyLoad';
import { memo } from 'react';
import Button from '@mui/material/Button';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { Typography } from '@mui/material';


const SearchProviderButton = lazyLoad(() => import('app/theme-layouts/shared-components/SearchProviderButton'));
const AdjustFontSize = lazyLoad(() => import('../../shared-components/AdjustFontSize'));
const UserMenu = lazyLoad(() => import('../../shared-components/UserMenu'));


function GuestToolbar({isMobile, user}) {

  return (
      <>
        <AdjustFontSize />

        <SearchProviderButton isPublic={true}/>
        
        <NavLinkAdapter to="/sign-in"><Button className="h-40"  >
	        <Typography noWrap className="mx-4 font-semibold " color="secondary">
	          Sign In
	        </Typography>
	    </Button></NavLinkAdapter>
      </>
  );
}

export default memo(GuestToolbar);
