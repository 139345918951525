import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { motion } from 'framer-motion';
import Divider from '@mui/material/Divider';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import StyledList from 'app/shared-components/layout/StyledList';
import DisplayUserHtml from 'app/shared-components/layout/DisplayUserHtml';
import getDisplayValue from 'app/shared-components/util/getDisplayValue';
import getListDisplayValue from 'app/shared-components/util/getListDisplayValue';
import genders from 'app/shared-components/controls/genders.json';
import pronouns from 'app/shared-components/controls/pronouns.json';
import namePreferences from 'app/shared-components/controls/namePreferences.json';
import specializations from 'app/shared-components/controls/specializations.json';
import credentials from 'app/shared-components/controls/credentials.json';
import clientTypes from 'app/shared-components/controls/clientTypes.json';
import insurance from 'app/shared-components/controls/insurance.json';

const container = {
    show: {
      transition: {
        staggerChildren: 0.05,
      },
    },
  };

const item = {
    hidden: { opacity: 0, y: 40 },
    show: { opacity: 1, y: 0 },
  };

const ProviderInfo = ({providerInfo, user, forceNarrow}) => {
  
  if (!providerInfo) return null;
  let isEnhanced= Boolean(providerInfo.enhancedProfile);
  let hasCertifications = providerInfo.certifications && providerInfo.certifications.length > 0;
  let hasEducation = providerInfo.education && providerInfo.education.length > 0;
  let hasAffiliations = providerInfo.affiliations && providerInfo.affiliations.length > 0;
  let hasFees = providerInfo.fees && providerInfo.fees.length > 0;

  
  // const certifications= ["AADMAMD", "HFSKS", "Trauma Stuff", "EMDR"];
  // const education= ["BA, University of Chicago", "MSW, Purdue University"];
  // const affiliations= ["American Psychology Association", "Mental Health of Western CT", "Trauma Things of Stuff"];
  // const fees= [{ name: "Intake Assesment (1.5 hours)", fee: "$400" }, { name: "Therapy (45 Minutes)", fee: "$300" }];
  // hasCertifications = true;
  // hasEducation = true;
  // hasAffiliations = true;
  // hasFees = true;
  // isEnhanced= true;
  
  return (
    <motion.div variants={container} initial="hidden" animate="show" className="w-full">
        <div className="flex flex-col w-full pr-16 md:pr-0">
          <Grid container spacing={2} alignItems="stretch" className="w-full m-0 p-0 ">
           
          <Grid item xs={12} sm={12} md={12} > 
          <Card component={motion.div} variants={item} className="w-full h-full ">
            <div className="relative px-24 pt-24">
              <Typography color="secondary" className="text-2xl font-semibold leading-tight">
                About {user.firstName}
              </Typography>
            </div>

            <CardContent className="px-24 py-24">
              <DisplayUserHtml id="user-about" html={user.about} className="mb-24" maxHeight="400px"/>
            </CardContent>
            
          </Card>
          </Grid>
          
          <Grid item xs={12} sm={12} md={forceNarrow ? 12 : 6} > 
          <Card component={motion.div} variants={item} className="w-full h-full">
            <div className="relative px-24 pt-24">
              <Typography color="secondary" className="text-2xl font-semibold leading-tight">
                Professional Profile
              </Typography>

            </div>

            <CardContent className="px-24 py-24">
              <div className="mb-24">
                <Typography className="font-semibold mb-4 text-15">Credentials</Typography>
                <Typography>{getListDisplayValue(providerInfo.credentials, credentials)}</Typography>
              </div> 
              <div className="mb-24">
                <Typography className="font-semibold mb-4 text-15">Client Types</Typography>
                <Typography>{getListDisplayValue(providerInfo.clientTypes, clientTypes) }</Typography>
              </div>

              <div className="mb-24">
                <Typography className="font-semibold mb-4 text-15">Specializations</Typography>
                <Typography>{getListDisplayValue(providerInfo.specializations, specializations)}</Typography>
              </div>
              
            </CardContent>
            
          </Card>
          </Grid>
          
          <Grid item xs={12} sm={12} md={forceNarrow ? 12 : 6} > 
          <Card component={motion.div} variants={item} className="w-full h-full">
            <div className="relative px-24 pt-24">
              <Typography color="secondary" className="text-2xl font-semibold leading-tight">
                Practice Information
              </Typography>

            </div>

            <CardContent className="px-24 py-24">
              
              <div className="mb-24">
                <Typography className="font-semibold mb-4 text-15">Practices in States</Typography>
                <Typography>{providerInfo.practiceStates ? providerInfo.practiceStates.join() : ""}</Typography>
              </div> 
              
              <div className="flex flex-row mb-24">
                <div className="w-1/2">
                  <Typography className="font-semibold mb-4 text-15">Offers In-Person?</Typography>
                  <Typography>{providerInfo.providesInPerson ? 'Yes' : 'No'}</Typography>
                </div>                
                
                <div className="w-1/2">
                  <Typography className="font-semibold mb-4 text-15">Offers Telehealth?</Typography>
                  <Typography>{providerInfo.providesTelehealth ? 'Yes' : 'No'}</Typography>
                </div>
              </div>
              
              <div className="flex flex-row mb-24">
                <div className="w-1/2">
                  <Typography className="font-semibold mb-4 text-15">Accepting Clients?</Typography>
                  <Typography>{providerInfo.acceptingClients ? 'Yes' : 'No'}</Typography>
                </div>
                <div className="w-1/2">
                  <Typography className="font-semibold mb-4 text-15">Accepts Insurance?</Typography>
                  <Typography>{providerInfo.acceptsInsurance ? 'Yes' : 'No'}</Typography>
                </div>
              </div>
			        
			         {providerInfo.acceptsInsurance && providerInfo.acceptedInsurance && (providerInfo.acceptedInsurance.length > 0) &&
				        <div className="mb-24">
                  <Typography className="font-semibold mb-4 text-15">Insurance Plans</Typography>
                  
                  <Typography>{getListDisplayValue(providerInfo.acceptedInsurance, insurance)}</Typography>
                </div>   
			        }
	
            </CardContent>
            
          </Card>
          </Grid>
          
          {isEnhanced && hasEducation &&
          <Grid item xs={12} sm={12} md={forceNarrow ? 12 : 6}  > 
          <Card component={motion.div} variants={item} className="w-full h-full">
            <div className="relative px-24 pt-24">
              <Typography color="secondary" className="text-2xl font-semibold leading-tight">
                Education
              </Typography>

            </div>

            <CardContent className="px-24 ">

                <StyledList items={providerInfo.education} />

            </CardContent>
            
          </Card>
          </Grid>
          }
          
          {isEnhanced && hasCertifications &&
          <Grid item xs={12} sm={12} md={forceNarrow ? 12 : 6}  > 
          <Card component={motion.div} variants={item} className="w-full h-full">
            <div className="relative px-24 pt-24">
              <Typography color="secondary" className="text-2xl font-semibold leading-tight">
                Professional Certifications
              </Typography>
            </div>

            <CardContent className="px-24 ">

                <StyledList items={providerInfo.certifications} />

            </CardContent>
            
          </Card>
          </Grid>
          }
          
           {isEnhanced && hasAffiliations &&
          <Grid item xs={12} sm={12} md={forceNarrow ? 12 : 6}  > 
          <Card component={motion.div} variants={item} className="w-full h-full">
            <div className="relative px-24 pt-24">
              <Typography color="secondary" className="text-2xl font-semibold leading-tight">
                Professional Affiliations
              </Typography>
            </div>

            <CardContent className="px-24 ">

                <StyledList items={providerInfo.affiliations} />
              
            </CardContent>
            
          </Card>
          </Grid>
          }
          
           {isEnhanced && hasFees &&
          <Grid item xs={12} sm={12} md={forceNarrow ? 12 : 6}  > 
          <Card component={motion.div} variants={item} className="w-full h-full">
            <div className="relative px-24 pt-24">
              <Typography color="secondary" className="text-2xl font-semibold leading-tight">
                Service Fees
              </Typography>
            </div>

            <CardContent className="px-24">
              <StyledList items={providerInfo.fees.map((fee) => fee.name + ": " + fee.fee )} />
            </CardContent>
            
          </Card>
          </Grid>
          }

          <Grid item xs={12} sm={12} md={12}  > 
          <Card component={motion.div} variants={item} className="w-full h-full">

            <CardContent className="px-24 py-24">
              
              <div className={forceNarrow ? "flex flex-col items-start " : "flex flex-col md:flex-row items-start md:items-center md:justify-center"} >
                <div className={forceNarrow ? "flex items-center" : "flex items-center mt-24 md:mt-0 mr-0 md:mr-24"} >
                  <FuseSvgIcon>heroicons-outline:location-marker</FuseSvgIcon>
                  <div className="flexflex-row items-center mr-0 md:mr-24"> 
                    <div className="ml-12 leading-6">{providerInfo.address}</div>
                    {providerInfo.city && 
                    <div className="ml-12 leading-6">{providerInfo.city + ", " + providerInfo.stateProvince + " " + providerInfo.postalCode}</div>
                    }
                  </div>
                </div>
                
                <div className={forceNarrow ? "flex items-center mt-24" : "flex items-center mt-24 md:mt-0 mr-0 md:mr-24"} >
                  <FuseSvgIcon>heroicons-outline:phone</FuseSvgIcon>
                  <Typography className="ml-12 leading-6">{providerInfo.phone}</Typography>
                </div>
                
                <div className={forceNarrow ? "flex items-center mt-24" : "flex items-center mt-24 md:mt-0 mr-0 md:mr-24"} >
                  <FuseSvgIcon>heroicons-outline:mail</FuseSvgIcon>
                  <Typography className="ml-12 leading-6">{providerInfo.email}</Typography>
                </div>
                
              </div>

            </CardContent>
          </Card>
          </Grid>
          
        </Grid>
      </div>
    </motion.div>
    
  );
};

export default ProviderInfo;
