import Quill from 'quill';
var Link = Quill.import('formats/link');

class TargetLink extends Link {
  static create(value) {
    let node = super.create(value);
    value = this.sanitize(value);
    node.setAttribute('href', value);
    if(value.startsWith("/")) {
      node.removeAttribute('target');
    }
    return node;
  }
}

Quill.register(TargetLink);
export default TargetLink;