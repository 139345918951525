import FuseUtils from '@fuse/utils';
import AppContext from 'app/AppContext';
import { Component } from 'react';
import { matchRoutes } from 'react-router-dom';
import withRouter from '@fuse/core/withRouter';
import history from '@history';
import { FEATURE_CONFIG } from "app/configs/aws.ts";

let loginRedirectUrl = null;
let loginRedirectSearch = "";
let isProd= (document.location.hostname.search("halespring.com") !== -1 && document.location.hostname.search("dev") === -1);

class FuseAuthorization extends Component {
  constructor(props, context) {
    super(props);
    const { routes } = context;
    this.state = {
      accessGranted: true,
      routes,
    };
    this.defaultLoginRedirectUrl = props.loginRedirectUrl || '/home';
  }

  componentDidMount() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return nextState.accessGranted !== this.state.accessGranted;
  }

  componentDidUpdate() {
    if (!this.state.accessGranted) {
      this.redirectRoute();
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { location, userRole } = props;
    const { pathname } = location;

    const matchedRoutes = matchRoutes(state.routes, pathname);
    const matched = matchedRoutes ? matchedRoutes[0] : false;
    return {
      accessGranted: matched ? FuseUtils.hasPermission(matched.route.auth, userRole) : true,
    };
  }

  redirectRoute() {
    const { location, userRole, navigate } = this.props;
    const { pathname, search } = location;
    const redirectUrl = loginRedirectUrl || this.defaultLoginRedirectUrl;
    

    /*
        User is guest
        Redirect to Login Page
        */
    if (!userRole || userRole.length === 0) {
	    const redirectPage= '/sign-in?redirect=true';
	    console.log("Redirect from " + pathname + " to " + redirectPage);
	    console.log("Search params: " + search);
      setTimeout(() => navigate(redirectPage), 0);
      loginRedirectUrl = pathname;
      loginRedirectSearch= search;
    } else {
      /*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to home or loginRedirectUrl
        */
      const targetURL= redirectUrl + loginRedirectSearch;
      console.log("Redirect from " + pathname + " to " + targetURL);
      setTimeout(() => navigate(targetURL), 0);
      loginRedirectUrl = this.defaultLoginRedirectUrl;
      loginRedirectSearch= "";
    }
  }

  render() {
    // console.info('Fuse Authorization rendered', this.state.accessGranted);
    return this.state.accessGranted ? <>{this.props.children}</> : null;
  }
}

FuseAuthorization.contextType = AppContext;

export default withRouter(FuseAuthorization);
