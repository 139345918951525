import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { stopLoading, startLoading} from 'app/store/utilSlice';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';

export const TYPE_POST = "post";
export const TYPE_TIMELINE_POST = "timelinepost";
export const TYPE_CHAT = "chat";
export const TYPE_DM = "dm";
export const TYPE_NOTE = "note";
export const TYPE_VIDEO_CALL = "videocall";
export const TYPE_POST_COMMENT = "postcomment";
export const TYPE_CHAT_COMMENT = "chatcomment";
export const TYPE_NOTE_COMMENT = "notecomment";
export const TYPE_TIMELINE_POST_COMMENT = "timelinepostcomment";
export const STATUS_VIDEO_CALL_ACTIVE = "call_active";
export const STATUS_VIDEO_CALL_ENDED = "call_ended";

const postsAdapter = createEntityAdapter({
	sortComparer: (a, b) => b.createdAt - a.createdAt
});


export const fetchPost = createAsyncThunk(
  "post/fetchPost",
  async (id, { dispatch }) => {
	dispatch(startLoading());
	try{
      const apiName = 'CoreAPI';
	  const path = '/contentpost/' + id;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	  const data= await apiGet(apiName, path, options);
	  return data;
	}finally{
	  dispatch(stopLoading());
	}
  }
);

export const fetchPublicPost = createAsyncThunk(
  "post/fetchPublicPost",
  async (id, { dispatch }) => {
	dispatch(startLoading());
	try{
      const apiName = 'PublicAPI';
	  const path = '/contentpost/' + id + '/public';
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	  const data= await apiGet(apiName, path, options);
	  return data;
	}finally{
	  dispatch(stopLoading());
	}
  }
);


export const submitPost = createAsyncThunk(
  "post/submitPost",
  async (params, { dispatch, getState }) => {
	  try{
	      const {channelId, messageText, fileId, fileType, fileSize, attachment, linkUrl, parentPostId, 
	             sharedPostId, sharedReferralId, chimeMeetingId, type, important, foreignModelKey } = params;
	      const apiName = 'CoreAPI';
		  const path = '/contentpost/';
		  if (!type) throw new Error("No type specified for post.");
		  let body= { channelId: channelId,
		              parentPostId: parentPostId,
				          text: messageText,
				          type: type,
		  	          important: important,
		  };
		  if(linkUrl){
			  body.linkUrl= linkUrl;
		  }else if(fileId){
			  body.mediaId= fileId;
			  body.mediaType= fileType;
			  body.mediaSize= fileSize;
		  }else if(attachment){
			  body.attachment= attachment;
		  }else if(sharedPostId) {
			  body.foreignModelKey= "ContentPost:" + sharedPostId;
		  }else if(sharedReferralId) {
			  body.foreignModelKey= "OutboundReferral:" + sharedReferralId;
		  }else if(chimeMeetingId) {
			  body.foreignModelKey= "ChimeMeeting:" + chimeMeetingId;
		  }else if(foreignModelKey) {
			  body.foreignModelKey= foreignModelKey;
		  }
		  
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)


export const submitUpdatePost = createAsyncThunk(
  "post/submitUpdatePost",
  async (params, { dispatch, getState }) => {
	  try{
	      const {postId, messageText } = params;
	      const apiName = 'CoreAPI';
		  const path = '/contentpost/' + postId + '/updatetext';
		  let body= { id: postId,
				      text: messageText};
		  
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const submitUpdatePostStatus = createAsyncThunk(
  "post/submitUpdatePostStatus",
  async (params, { dispatch, getState }) => {
	  try{
	      const {postId, status } = params;
	      const apiName = 'CoreAPI';
		  const path = '/contentpost/' + postId + '/updatestatus';
		  let body= { id: postId,
				      status: status};
		  
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const hidePost = createAsyncThunk(
  "post/hidePost",
  async (params, { dispatch, getState }) => {
	  try{
	      const {postId, channelId } = params;
	      
	      const apiName = 'CoreAPI';
		  const path = '/contentpost/' + postId + '/updatestatus';
		  let body= { id: postId,
				      status: 'hidden'};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const unhidePost = createAsyncThunk(
  "post/unhidePost",
  async (params, { dispatch, getState }) => {
	  try{
	      const {postId, channelId } = params;
	      
	      const apiName = 'CoreAPI';
		  const path = '/contentpost/' + postId + '/updatestatus';
		  let body= { id: postId,
				      status: 'initial'};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)


export const submitUpdatePostUsage = createAsyncThunk(
  "post/submitUpdatePostUsage",
  async (params, { dispatch, getState }) => {
      const {postId, value } = params;
      const apiName = 'CoreAPI';
	  const path = '/contentpost/' + postId + '/updateusage?value=' + value;
	  
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	 let data= await apiPut(apiName, path, options);
	 return data;
  }
)


export const submitPostReport = createAsyncThunk(
  "post/submitPostReport",
  async (params, { dispatch, getState }) => {
	  try{
		  dispatch(startLoading());
		  const { user } = getState();
	      const { postId, caseType, description } = params;
	      const apiName = 'CoreAPI';
		  const path = '/user/' + user.id + "/postreport/";
	      const options = {
			  body: {postId: postId, type: caseType, description: description},
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const deletePostReport = createAsyncThunk(
  "post/deletePostReport",
  async (params, { dispatch, getState }) => {
	  try{
		  dispatch(startLoading());
		  const { user } = getState();
	      const { postId } = params;
	      const apiName = 'CoreAPI';
		  const path = '/user/' + user.id + "/postreport/" + postId;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiDel(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const deletePost = createAsyncThunk(
  "post/deletePost",
  async (params, { dispatch }) => {
	  try{
	      const { postId } = params;
	      const apiName = 'CoreAPI';
		  const path = '/contentpost/' + postId;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiDel(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const submitPostInteraction = createAsyncThunk(
  "post/submitPostInteraction",
  async (params, { dispatch, getState }) => {
	  try{
		  dispatch(startLoading());
		  const { user } = getState();
	      const { postId, interaction } = params;
	      const apiName = 'CoreAPI';
		  const path = '/user/' + user.id + "/postinteraction/";
	      const options = {
			  body: {postId: postId, type: interaction},
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const deletePostInteraction = createAsyncThunk(
  "post/deletePostInteraction",
  async (params, { dispatch, getState }) => {
	  try{
		  dispatch(startLoading());
		  const { user } = getState();
	      const { postId } = params;
	      const apiName = 'CoreAPI';
		  const path = '/user/' + user.id + "/postinteraction/" + postId;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiDel(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const fetchPostsByChannelId = createAsyncThunk(
  "post/fetchPostsByChannelId",
  async (params) => {
	  const {channelId, after, before }= params;
      const apiName = 'CoreAPI';
      const query= after ? "?createdAt=GT:" + after : before ? "?createdAt=LT:" + before : "";
	    const path = '/contentpost/bychannel/' + channelId + query;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	 let data= await apiGet(apiName, path, options);
	 processPosts(data);
     return data;
  }
)

export const searchPosts = createAsyncThunk(
  "post/searchPosts",
  async (query, { dispatch, rejectWithValue }) => {
     try{
		  dispatch(startLoading());
		  const apiName = 'CoreAPI';
		  const path = '/search/posts';
	      const options = {
			  body: query,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  
		  try {
		     const data= await apiPut(apiName, path, options);
	      	 processPosts(data);
	         return data;
		  } catch (err) {
		      if (!err.response) {
		        throw err;
		      }
		      return rejectWithValue(err.response.data);
		  }
		  
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const searchPostsPublic = createAsyncThunk(
  "post/searchPostsPublic",
  async (query, { dispatch, rejectWithValue }) => {
     try{
		  dispatch(startLoading());
		  const apiName = 'PublicAPI';
		  const path = '/search/public/posts';
	      const options = {
			  body: query,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  
		  try {
		     const data= await apiPut(apiName, path, options);
	      	 processPosts(data);
	         return data;
		  } catch (err) {
		      if (!err.response) {
		        throw err;
		      }
		      return rejectWithValue(err.response.data);
		  }
		  
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

const processPosts = (data) => {
	for (let i in data) {
		let post= data[i];
		if (!post.callerInteraction){
			post.callerInteraction= ""; //Needed to use immer mutation in reducer
		}
	 }
}

const postSlice = createSlice({
  name: "post",
  initialState: postsAdapter.getInitialState({viewingPostId: null, deletedPostIds: {}}),
  reducers: {
	addPost:  (state, action) => {
		const {post}= action.payload;
		const exists= Boolean(state.entities[post.id]);
    postsAdapter.setOne(state, post);
    if (post.parentPostId && !exists){
			  let parentPost = state.entities[post.parentPostId];
			  if (parentPost){
				 parentPost.commentCount++;
			  } 
		}
	},
	setPost:  (state, action) => { //Used for adding a post shared by another post being displayed (or in a post report item) to make sure it is in the store
		const {post}= action.payload;
		
		//Check to see if it was deleted during this client session so we don't add it back into store
		if (!state.deletedPostIds[post.id]) postsAdapter.addOne(state, post);
	},
	updatePost:  (state, action) => {
		const {post}= action.payload;
        postsAdapter.setOne(state, post);
	},
	removePost:  (state, action) => {
		const {post}= action.payload;
        postsAdapter.removeOne(state, post.id);
	},
	addPostInteraction:  (state, action) => {
		const {interaction}= action.payload;
        const post= state.entities[interaction.postId];
	    if (post){
			post.callerInteraction= interaction.type;
			post.interactionCount++;
	  	}
	},
	removePostInteraction:  (state, action) => {
		const {interaction}= action.payload;
    const post= state.entities[interaction.postId];
	  if (post){
			post.callerInteraction= null;
			if (post.interactionCount > 0) post.interactionCount--;
	  }	
	},
  setViewingPostId: (state, action) => {
      state.viewingPostId= action.payload;
  },
  addFeedEntries: (state, action) => {
	  for (let i in action.payload) {
		  let post= action.payload[i];
        postsAdapter.setOne(state, post);
    }
  },
  },
  extraReducers: builder => {
    builder
    .addCase(fetchPost.fulfilled, (state, action) => {
        postsAdapter.setOne(state, action.payload);
    })
    .addCase(fetchPublicPost.fulfilled, (state, action) => {
        postsAdapter.setOne(state, action.payload);
    })
    .addCase(fetchPostsByChannelId.fulfilled, (state, action) => {
        postsAdapter.setMany(state, action.payload);
    })
    .addCase(searchPosts.fulfilled, (state, action) => {
        postsAdapter.setMany(state, action.payload);
    })
    .addCase(searchPostsPublic.fulfilled, (state, action) => {
        postsAdapter.setMany(state, action.payload);
    })
    .addCase(submitPost.fulfilled, (state, action) => {
		    const post= action.payload;
        postsAdapter.addOne(state, post);
        if (post.parentPostId){
		  let parentPost = state.entities[post.parentPostId];
		  if (parentPost){
			 parentPost.commentCount++;
		  } 
		}
		if (action.meta.arg.sharedPostId){
		  let sharedPost = state.entities[action.meta.arg.sharedPostId];
		  if (sharedPost){
			 sharedPost.shareCount++;
		  } 
		}
    })
    .addCase(submitUpdatePost.fulfilled, (state, action) => {
		const post= action.payload;
        postsAdapter.upsertOne(state, post);
    })
    .addCase(submitUpdatePostStatus.fulfilled, (state, action) => {
		const post= action.payload;
		postsAdapter.upsertOne(state, post);
    })
    .addCase(hidePost.fulfilled, (state, action) => {
    	const post= action.payload;
		postsAdapter.upsertOne(state, post);
    })
    .addCase(unhidePost.fulfilled, (state, action) => {
    	const post= action.payload;
		postsAdapter.upsertOne(state, post);
    })
    .addCase(deletePost.fulfilled, (state, action) => {
      const postId = action.meta.arg.postId;
      postsAdapter.removeOne(state, postId);
      state.deletedPostIds[postId]= true; //Needed to prevent post from being re-added to store (if it is a sharedPost inside another post)
    })
    .addCase(submitPostInteraction.fulfilled, (state, action) => {
      const postId = action.meta.arg.postId;
      const interaction= action.meta.arg.interaction;
	  let post= state.entities[postId];
      if (post){
		post.callerInteraction= interaction;
		post.interactionCount++;
  	  }
    })
    .addCase(deletePostInteraction.fulfilled, (state, action) => {
      const postId = action.meta.arg.postId;
	  let post= state.entities[postId];
      if (post){
		post.callerInteraction= null;
	    if (post.interactionCount > 0) post.interactionCount--;
  	  }
    })
  }
})

const { selectById } = postsAdapter.getSelectors(state => state.post);

export const selectPostById = (postId) => state => {
	if (postId) return selectById(state, postId);
	return null;
}

export const selectIsPostDeleted = (postId) => state => {
	if (postId) return state.post.deletedPostIds[postId];
	return false;
}

export const selectAllPosts = (state) => {
	return state.post.entities;
}

export const selectViewingPostId = ({ post }) => post.viewingPostId;


export const { setViewingPostId, addFeedEntries, setPost, addPost, removePost, updatePost, addPostInteraction, removePostInteraction } = postSlice.actions;

export default postSlice.reducer;
