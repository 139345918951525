import lazyLoad from 'app/shared-components/util/lazyLoad';
const LocationProfile = lazyLoad(() => import('app/shared-components/organization/LocationProfile'));

const PublicLocationProfileConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null, //anyone
  routes: [
    {
      path: '/public/location/:locationId',
      element: <LocationProfile />,
    }
  ],
};

export default PublicLocationProfileConfig;


