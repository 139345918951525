import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchPageBySlug, selectPageBySlug } from 'app/store/marketing/pagesSlice';

export default function Page() {
  const routeParams = useParams();
  const dispatch = useDispatch();

  const { slug } = routeParams;
  const page = useSelector(selectPageBySlug(slug));

  useEffect(() => {
    dispatch(fetchPageBySlug(slug))
  }, [slug])
  
  return (
    <div>
      {page ? page.title : slug }
    </div>
  );
}
