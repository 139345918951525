import HeaderTags from 'app/shared-components/helmet/HeaderTags';



const BlogHeaderTags = ({ title, description, author, image, datePublished, keywords }) => {
  const publisher= "Halespring";
  const host = window.location.protocol + '//' + window.location.host;
  const publisherLogo=  host + '/assets/images/logo/HALESPRING-LOGO-ONLY-1500.png'
  const useType = 'blog';
  const url = host + window.location.pathname;
  
  const schema=
    {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": url
      },
      "headline": title,
      "description": description,
      "image": image,  
      "author": {
        "@type": "Person",
        "name": author
      },  
      "publisher": {
        "@type": "Organization",
        "name": publisher,
        "logo": {
          "@type": "ImageObject",
          "url": publisherLogo
        }
      },
      "datePublished": datePublished
    };

  
  return (
    <HeaderTags title={title} description={description} keywords={keywords} type={useType} image={image} schema={schema} />
  );
};

export default BlogHeaderTags;