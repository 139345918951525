import lazyLoad from 'app/shared-components/util/lazyLoad';

const ChatPostView = lazyLoad(() => import('./ChatPostView'));


const ChatPostViewConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'chatpost/:id',
      element: <ChatPostView />,
    },
  ],
};

export default ChatPostViewConfig;


