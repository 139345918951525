import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import {  startLoading, stopLoading} from 'app/store/utilSlice';


export const getContent = createAsyncThunk(
  'helpContent/getContent',
  async ({type, tag}, { dispatch, getState }) => {
	try{
      dispatch(startLoading());
	 
		  const apiName = 'CoreAPI2';
		  const path = '/x2/helpcontent/bytype/' + type;
		  const params = tag ? "?tag=STARTS:" + tag : "";
	    const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  const out = await apiGet(apiName, path + params, options);
		  return out;
   }finally{
	   dispatch(stopLoading());
   }
  }
)


export const createContent = createAsyncThunk(
  'helpContent/createContent',
  async (params, { dispatch, getState }) => {
	  const apiName = 'CoreAPI2';
	  const path = '/x2/helpcontent/';
    const options = {
		  body: params,
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  const out = await apiPut(apiName, path, options);
	  return out;
  }
);


export const deleteContent = createAsyncThunk(
  'helpContent/deleteContent',
  async ({id}, { dispatch, getState }) => {
	  const apiName = 'CoreAPI2';
	  const path = '/x2/helpcontent/' + id;
    const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  const resp = await apiDel(apiName, path, options);
	  return id;
  }
);
 
export const updateContent = createAsyncThunk(
  'helpContent/updateContent',
  async (params, { dispatch, getState }) => {
	  const apiName = 'CoreAPI2';
	  const path = '/x2/helpcontent/' + params.id;
    const options = {
		  body: params,
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  const out = await apiPut(apiName, path, options);
	  return out;
  }
);

export const searchContent = createAsyncThunk(
  'helpContent/searchContent',
  async ({query}, { dispatch, getState }) => {
	try{
      dispatch(startLoading());
	 
		  const apiName = 'CoreAPI';
		  const path = '/search/help?query=' + query;
	    const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  const out = await apiGet(apiName, path, options);
		  return out;
   }finally{
	   dispatch(stopLoading());
   }
  }
)


const helpAdapter = createEntityAdapter({});


const helpContentSlice = createSlice({
  name: 'helpContent',
  initialState: {
	  userfaq: helpAdapter.getInitialState(),
	  publicfaq: helpAdapter.getInitialState(),
	  guide: helpAdapter.getInitialState(),
	  search: helpAdapter.getInitialState(),
	  selectedId: null,
	  selectedTab: null,
  },
  reducers: {
    setSelectedId: {
      reducer: (state, action) => {
        state.selectedId = action.payload;
      }
    },
    setSelectedTab: {
      reducer: (state, action) => {
        state.selectedTab = action.payload;
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(updateContent.fulfilled, (state, action) => {
       const type= action.meta.arg.type;
	     helpAdapter.upsertOne(state[type], action.payload);
    })
    .addCase(createContent.fulfilled, (state, action) => {
       const type= action.meta.arg.type;
       helpAdapter.addOne(state[type], action.payload);
    })
    .addCase(deleteContent.fulfilled, (state, action) => {
      helpAdapter.removeOne(state.publicfaq, action.payload);
      helpAdapter.removeOne(state.userfaq, action.payload);
      helpAdapter.removeOne(state.guide, action.payload);
      state.selectedId= null;
    })
    .addCase(getContent.fulfilled, (state, action) => {
       const type= action.meta.arg.type;
       helpAdapter.setAll(state[type], action.payload);
    })
    .addCase(searchContent.fulfilled, (state, action) => {
       helpAdapter.setAll(state.search, action.payload);
    })
  },
});


const { selectAll, selectById } = helpAdapter.getSelectors();

export const selectContentById = (contentId) => state => {
    const entry= findEntry(state.helpContent, contentId);
	  return entry.content ? entry.content : null;
}

const findEntry= (state, contentId) => {
	  let item= state.userfaq.entities[contentId];
	  if (item){
	  	  return { entities: state.userfaq, content: item };
	  }
	  item= state.publicfaq.entities[contentId];
	  if (item){
	  	  return { entities: state.publicfaq, content: item };
	  }
	  item= state.guide.entities[contentId];
	  if (item){
	  	  return { entities: state.guide, content: item };
	  }
    return {};
}


//Get the objects for the specified type
export const selectAllByType= (type) => (state) => {
	return selectAll(state.helpContent[type]);
} 

export const selectSearchResults= (state) => {
	return selectAll(state.helpContent.search);
} 


export const selectSelectedId = ({ helpContent }) => helpContent.selectedId;
export const selectSelectedTab = ({ helpContent }) => helpContent.selectedTab;
export const { setSelectedId, setSelectedTab} = helpContentSlice.actions;
export default helpContentSlice.reducer;
