import ChatAppConfig from './chat/ChatAppConfig';
import ContactsAppConfig from './contacts/ContactsAppConfig';
import ClientsAppConfig from './clients/ClientsAppConfig';
import HelpCenterAppConfig from './help-center/HelpCenterAppConfig';
import ProfileAppConfig from './profile/profileAppConfig';
import HomeAppConfig from './home/HomeAppConfig';
import GroupsAppConfig from './groups/GroupsAppConfig';
import TeamsAppConfig from './teams/TeamsAppConfig';
import AdminAppConfig from './admin/AdminAppConfig';
import AccountAppConfig from './account/AccountAppConfig';
import AcceptTermsAppConfig from './accept-terms/AcceptTermsAppConfig';
import AccountReturnAppConfig from './account/AccountReturnAppConfig';
import ReferralsAppConfig from './referrals/ReferralsAppConfig';
import OrganizationsAppConfig from './organizations/OrganizationsAppConfig';

const appsConfigs = [
  ContactsAppConfig,
  ChatAppConfig,
  ClientsAppConfig,
  HelpCenterAppConfig,
  ProfileAppConfig,
  HomeAppConfig,
  GroupsAppConfig,
  TeamsAppConfig,
  AdminAppConfig,
  AccountAppConfig,
  AcceptTermsAppConfig,
  AccountReturnAppConfig,
  ReferralsAppConfig,
  OrganizationsAppConfig
];

export default appsConfigs;
