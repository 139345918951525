import lazyLoad from 'app/shared-components/util/lazyLoad';

const AccountApp = lazyLoad(() => import('./AccountApp'));

const AccountAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/account',
      element: <AccountApp />,
    },
    {
      path: 'apps/account/security',
      element: <AccountApp />,
    },
    {
      path: 'apps/account/identity',
      element: <AccountApp />,
    },
    {
      path: 'apps/account/communication',
      element: <AccountApp />,
    },
    {
      path: 'apps/account/usage',
      element: <AccountApp />,
    },
  ],
};

export default AccountAppConfig;
