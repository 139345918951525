import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
import { authRoles } from '../auth';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);

const navigationConfig = [
  {
    id: 'admin',
    title: 'Admin',
    type: 'item',
    icon: 'material-outline:miscellaneous_services',
    auth: authRoles.admin,
    url: '/apps/admin',
  },
  {
    id: 'home',
    title: 'Home',
    type: 'item',
    icon: 'heroicons-outline:home',
    url: '/home',
  },
  {
    id: 'profile',
    title: 'Profile',
    type: 'item',
    icon: 'heroicons-outline:user-circle',
    url: '/apps/profile',
  },
  {
    id: 'groups',
    title: 'Groups',
    type: 'item',
    icon: 'heroicons-outline:user-group',
    url: '/apps/groups',
  },
  {
    id: 'teams',
    title: 'Teams',
    type: 'item',
    icon: 'material-outline:people_outline',
    url: '/apps/teams',
  },
  {
    id: 'clients',
    title: 'Clients',
    type: 'item',
    icon: 'material-outline:portrait',
    url: '/apps/clients',
  },
  {
    id: 'referrals',
    title: 'Referrals',
    type: 'item',
    icon: 'material-outline:outbound',
    url: '/apps/referrals',
  },
  {
    id: 'organizations',
    title: 'Organizations',
    type: 'item',
    icon: 'heroicons-outline:building-office',
    url: '/apps/organizations',
  },
  {
    id: 'apps.chat',
    title: 'Direct Messages',
    type: 'item',
    icon: 'heroicons-outline:chat-alt',
    url: '/apps/chat',
  },
  {
    id: 'apps.network',
    title: 'Your Network',
    type: 'item',
    icon: 'material-outline:share',
    url: '/apps/network',
  },
  {
    id: 'apps.help-center',
    title: 'Help Center',
    type: 'item',
    icon: 'heroicons-outline:support',
    url: '/apps/help-center',
  },
];

export default navigationConfig;
