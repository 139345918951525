import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import sanitizeHtml from 'sanitize-html';

/**
 * @param {{
 *   person: import('../../store/marketing/marketing').Person,
 *   className?: string
 * }} props
 */
export default function Person({ person, className, ...props}) {
  return (
    <article
      className={twMerge(classNames([
        'px-16 lg:px-28 py-20 rounded-20',
        className
      ]))}
      {...props}
    >
      <div className="aspect-w-1 aspect-h-1 rounded-20 overflow-hidden">
        <img
          src={person.image?.url}
          alt={person.image?.alternativeText}
          className="object-cover object-top"
        />
      </div>

      <h3 className="h4 mt-20">
        {person.name}
      </h3>

      {person.title && (
        <div className="mt-12 flex">
          <div className="body-xs text-hs-white bg-hs-grey-dark px-10 py-4 rounded-full">
            {person.title}
          </div>
        </div>
      )}

      <div
        className="mt-12 body-sm"
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(person.bio) }}
      />
    </article>
  );
}
