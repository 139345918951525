import { createSelector, createEntityAdapter, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios';

/**
 * @template T
 * @typedef {import('./strapi').CollectionApiResponseWrapper<T>} CollectionApiResponseWrapper
 */
/** @typedef {import('./marketing').BlogPost} BlogPost */

/** @type {import('@reduxjs/toolkit').EntityAdapter<BlogPost, string>} */
const blogPostsAdaptor = createEntityAdapter({
  selectId: (blogPost) => blogPost.slug
});

const initialState = blogPostsAdaptor.getInitialState({
  /** @type {string|null} */
  featuredSlug: null,
  /** @type {string[]} */
  newest3Slugs: [],
  /** @type {string[]} */
  searchedSlugs: [],
  pagination: {
    page: 1,
    pageSize: 0,
    pageCount: 0,
    total: 0
  }
});

/** @type {import('@reduxjs/toolkit').AsyncThunk<BlogPost, string, {}>}  */
export const fetchBlogPostBySlug = createAsyncThunk(
  'marketing/blogPosts/fetchBlogPostBySlug',
  async (slug) => {
    /** @type {import('axios').AxiosResponse<CollectionApiResponseWrapper<BlogPost>>} */
    const response = await axios({
      method: 'GET',
      url: 'blog-posts',
      params: {
        filters: { slug },
        populate: '*'
      }
    });

    return response.data.data[0];
  }
);

/** @type {import('@reduxjs/toolkit').AsyncThunk<BlogPost, string, {}>}  */
export const fetchFeaturedBlogPost= createAsyncThunk(
  'marketing/blogPosts/fetchFeaturedBlogPost',
  async (slug) => {
    /** @type {import('axios').AxiosResponse<CollectionApiResponseWrapper<BlogPost>>} */
    const response = await axios({
      method: 'GET',
      url: 'blog-posts',
      params: {
        filters: {
          featured: true
        },
        populate: '*'
      }
    });

    return response.data.data[0];
  }
);

/** @type {import('@reduxjs/toolkit').AsyncThunk<BlogPost[], any, {}>}  */
export const fetchBlogPosts = createAsyncThunk(
  'marketing/blogPosts/fetchBlogPosts',
  async ({ filters, sort, pagination }) => {
    /** @type {import('axios').AxiosResponse<CollectionApiResponseWrapper<BlogPost>>} */
    const response = await axios({
      method: 'GET',
      url: 'blog-posts',
      params: {
        populate: '*',
        filters,
        sort,
        pagination
      }
    });

    return response.data.data;
  }
);

/** @type {import('@reduxjs/toolkit').AsyncThunk<CollectionApiResponseWrapper<BlogPost>, any, {}>}  */
export const searchBlogPosts = createAsyncThunk(
  'marketing/blogPosts/searchBlogPosts',
  async ({
    tags = [],
    s = '',
    pagination,
    loadMore = false
  } = {}) => {
    /** @type {import('axios').AxiosResponse<CollectionApiResponseWrapper<BlogPost>>} */
    const response = await axios({
      method: 'GET',
      url: 'blog-posts',
      params: {
        populate: '*',
        filters: {
          $or: [
            { title: { $containsi: s} },
            { body: { $containsi: s} }
          ],
          tags: {
            slug: { $in: tags }
          },
          featured: false
        },
        sort: 'publishedAt:desc',
        pagination
      }
    });

    return response.data;
  }
);

/** @type {import('@reduxjs/toolkit').AsyncThunk<CollectionApiResponseWrapper<BlogPost>, any, {}>}  */
export const fetchNewest3BlogPosts = createAsyncThunk(
  'marketing/blogPosts/fetchNewest3BlogPosts',
  async () => {
    /** @type {import('axios').AxiosResponse<CollectionApiResponseWrapper<BlogPost>>} */
    const response = await axios({
      method: 'GET',
      url: 'blog-posts',
      params: {
        populate: '*',
        sort: 'publishedAt:desc',
        pagination: {
          start: 0,
          limit: 3
        }
      }
    });

    return response.data.data;
  }
);

const blogPostsSlice = createSlice({
  name: 'marketing/blogPosts',

  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogPostBySlug.fulfilled, (state, action) => {
        if (action.payload) {
          blogPostsAdaptor.upsertOne(state, action.payload);
        }
      })
      .addCase(fetchFeaturedBlogPost.fulfilled, (state, action) => {
        if (action.payload) {
          blogPostsAdaptor.upsertOne(state, action.payload);
          state.featuredSlug = action.payload.slug;
        }
      })
      .addCase(fetchBlogPosts.fulfilled, (state, action) => {
        if (action.payload) {
          blogPostsAdaptor.upsertMany(state, action.payload);
        }
      })
      .addCase(fetchNewest3BlogPosts.fulfilled, (state, action) => {
        if (action.payload) {
          blogPostsAdaptor.upsertMany(state, action.payload);
          state.newest3Slugs = action.payload.map((post) => post.slug);
        }
      })
      .addCase(searchBlogPosts.fulfilled, (state, action) => {
        if (action.payload) {
          blogPostsAdaptor.upsertMany(state, action.payload.data);
          state.pagination = action.payload.meta.pagination;
          if (action.meta.arg.loadMore) {
            state.searchedSlugs = Array.from(new Set([...state.searchedSlugs, ...action.payload.data.map((post) => post.slug)]));
          }
          else {
            state.searchedSlugs = action.payload.data.map((post) => post.slug);
          }
        }
      });
  }
});

/** @typedef {import('./index').RootState} RootState */

const selectBlogPostState = (/** @type {RootState} */ state) => state.marketing.blogPosts;

export const selectBlogPosts = () => createSelector(
  [selectBlogPostState],
  (blogPosts) => blogPosts.ids.map((id) => blogPosts.entities[id])
);

export const selectNewest3BlogPosts = () => createSelector(
  [selectBlogPostState],
  (blogPosts) => blogPosts.newest3Slugs.map((id) => blogPosts.entities[id])
);

export const selectSearchedBlogPosts = () => createSelector(
  [selectBlogPostState],
  (blogPosts) => blogPosts.searchedSlugs.map((id) => blogPosts.entities[id])
);

export const selectBlogPostPagination = () =>
  (/** @type {RootState} */ state) =>
    state.marketing.blogPosts.pagination;

export const selectBlogPostBySlug = (/** @type {string} */ slug) =>
  (/** @type {RootState} */ state) =>
    state.marketing.blogPosts.entities[slug];

export const selectFeaturedBlogPost = () =>
  (/** @type {RootState} */ state) =>
    state.marketing.blogPosts.entities[state.marketing.blogPosts.featuredSlug];

export default blogPostsSlice.reducer;
