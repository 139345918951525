import { useCallback, useEffect, useRef, } from 'react';


const useInterval = (fn, ms) => {
  const timeout = useRef();
  const mountedRef = useRef(false);
  
  const run = useCallback(() => {
    if (mountedRef.current) {
	  fn();
      timeout.current = window.setTimeout(run, ms);
    }
  }, [fn, ms]);
  
  useEffect(() => {
	if (ms){
		mountedRef.current = true;
	    return () => {
		  console.log("Interval unmounted.");
	      mountedRef.current = false;
	      window.clearTimeout(timeout.current);
	    };
	}
	    
  }, []);
  
  const stop= ()=>{
	if(ms){
		console.log("Stopping interval.")
		mountedRef.current = false;
		window.clearTimeout(timeout.current);
    }
  }
  
  const start= (initialDelay)=>{
	if(ms){
		console.log("Starting interval.")
		mountedRef.current = true;
		if (initialDelay) timeout.current= window.setTimeout(run, initialDelay);
		else run();
	}
  }
  
  return [start, stop];
};

export default useInterval;