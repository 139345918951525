import {Button} from '@mui/material/';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';


function DefaultButton({icon, iconColor, iconRight, iconRightSize, text, link, onClick, disabled, children, className,
                        submit, primary, warning, size, id, iconSize}) 
{
							
  const useClasses= className ? className : "mx-8 whitespace-nowrap";
  
  const mainProps={
	  className: useClasses,
	  disabled: disabled,
	  component: link ? NavLinkAdapter : undefined,
	  to: link ? link : undefined,
	  onClick: onClick,
	  type: submit ? "submit" : "button",
	  size: size,
	  id: id
  }

 
  function renderContents(){
	 return (
		<>
          {icon && <FuseSvgIcon color={iconColor} size={iconSize ?  iconSize : 20}>{icon}</FuseSvgIcon>}
          {text && <span className="mx-8">{text}</span>}
          {iconRight && <FuseSvgIcon size={iconRightSize ?  iconRightSize : 20}>{iconRight}</FuseSvgIcon>}
          {children}
        </>
      )
  }
  
  if (primary) return (
	 <Button
      variant="contained"
      color="primary"
      {...mainProps}
     >
      {renderContents()}
     </Button>
  )
  else if (warning) return (
	 <Button
      variant="contained"
      color="warning"
      {...mainProps}
     >
      {renderContents()}
     </Button>
  )
  else return (
	 <Button
      variant="contained"
      color="secondary"
      {...mainProps}
     >
      {renderContents()}
     </Button>
  )
   
}

export default DefaultButton;
