import lazyLoad from 'app/shared-components/util/lazyLoad';

const FindCareApp = lazyLoad(() => import('./FindCareApp'));
const RequestCareApp = lazyLoad(() => import('./RequestCareApp'));


const PublicFindCareConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null, //anyone
  routes: [
    {
      path: 'public/find',
      element: <FindCareApp />,
    },
    {
      path: 'public/request',
      element: <RequestCareApp />,
    },
  ],
};

export default PublicFindCareConfig;


