const retry = (fn, retriesLeft = 3, interval=1500) => {
    return new Promise((resolve, reject) => {
        fn()
          .then(resolve)
          .catch((error) => {
              setTimeout(() => {
                  console.warn("Error in call, " + (retriesLeft - 1) + " attempts remaining.");
                  console.warn(error);
                  if (retriesLeft === 1) {
                      // reject('maximum retries exceeded');
                      reject(error);
                      return;
                   }
                   //Retry
                   retry(fn, retriesLeft - 1, interval).then(resolve, reject);
              }, interval);
           });
    });
}

export default retry;