import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { darken } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import clsx from 'clsx';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import TermsPageContent from './TermsPageContent';

function TermsPage() {


  return (
    <div className="relative flex flex-col flex-auto min-w-0 overflow-hidden">
    
      <Box
        sx={{ backgroundColor: 'primary.main', color: 'primary.contrastText' }}
        className="px-24 pt-20 pb-40"
      >
        <div className="flex flex-col items-center w-full max-w-7xl mx-auto text-center">
          <img className="" src="assets/images/logo/HALESPRING2.png" alt="logo" />
          <Typography className="mt-20 text-3xl sm:text-4xl sm:text-5xl font-extrabold leading-6 sm:leading-10">
            Terms and Conditions
          </Typography>
        </div>
      </Box>
      
      <div className="relative pt-32 pb-48 sm:pt-80 sm:pb-96 px-24 sm:px-64 overflow-hidden">
        <svg
          className="-z-1 absolute inset-0 pointer-events-none"
          viewBox="0 0 960 540"
          width="100%"
          height="100%"
          preserveAspectRatio="xMidYMax slice"
          xmlns="http://www.w3.org/2000/svg"
        >
          <Box
            component="g"
            sx={{ color: 'divider' }}
            className="opacity-20"
            fill="none"
            stroke="currentColor"
            strokeWidth="100"
          >
            <circle r="234" cx="196" cy="23" />
            <circle r="234" cx="790" cy="491" />
          </Box>
        </svg>
        
        <TermsPageContent/>
        
      </div>

    </div>
  );
}

export default TermsPage;
