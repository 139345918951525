import SingleSelect from '../controls/SingleSelect';
import { Controller } from 'react-hook-form';



function ControlledSingleSelect({control, name, items, label, errors, className, maxHeight, required, emptyChoice, infoText}) {
   const useClasses = className ? className : "mt-8 mb-16";
   
   return (
	   
	<Controller
       control={control}
       name={name}
       render={({ field }) => <SingleSelect 
                              {...field}
                              required={required}
                              emptyChoice={emptyChoice}
                              infoText={infoText}
                              items={items}
                              className={useClasses}
                              id={name} 
                              label={label}
                              errors={errors}
                              maxHeight={maxHeight}
                            />}
    />
   );
}

export default ControlledSingleSelect;
