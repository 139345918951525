const sortComparer= (a, b) => {
    const aVal= a.createdAt;
    const bVal= b.createdAt;
    // null timestamp after anything else
    if (!aVal && !bVal) return 0;
    if (!aVal) return 1;
    if (!bVal) return -1;
    // highest sorts first
    return aVal < bVal ? 1 : -1;
}

export default sortComparer;