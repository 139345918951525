import QuillEditor from './quill/QuillEditor';
import { Controller } from 'react-hook-form';



function ControlledWYSIWYG({control, name, label, errors, className, initialContent}) {
   const useClasses = className ? className : "items-center mb-10";
   
   return (
	<Controller
      className={useClasses}
      render={({ field }) => <QuillEditor initialContent={initialContent} {...field} />}
      name={name}
      control={control}
    /> 
   );
}

export default ControlledWYSIWYG;
