import { CONNECTION_STATE_CHANGE } from 'aws-amplify/api';
import { Hub } from 'aws-amplify/utils';


Hub.listen("api", (data) => {
  const { payload } = data;
  console.log(data);
  if (payload.event === CONNECTION_STATE_CHANGE) {
    SubscriptionManager.connectionState= payload.data.connectionState;
    if (payload.data.connectionState === "Connected") {
  	  if (SubscriptionManager.priorDisconnection) SubscriptionManager.notifyReconnect();
  	  SubscriptionManager.priorDisconnection= false;
    }else if (payload.data.connectionState === "ConnectedPendingNetwork" ||
              payload.data.connectionState === "ConnectionDisrupted" ||
              payload.data.connectionState === "ConnectionDisruptedPendingNetwork" ||
              payload.data.connectionState === "Disconnected") {
  	  console.log("Setting priorDisconnection to TRUE.")
  	  SubscriptionManager.priorDisconnection= true;
    }
  }
});

const SubscriptionManager = {
  subscriptionMap: new Map(),
  priorDisconnection: false,
  connectionState: null,
  init: function(){
  	console.log("Initializing subscription manager.");
  	this.subscriptionMap.clear();
  	this.priorDisconnection= false;
  },

  addSubscription: function(key, sub) {
    this.subscriptionMap.set(key, sub);
  },
  
  removeSubscription: function(key) {
	const obj= this.subscriptionMap.get(key);
	if (obj){
		console.log("Removing subscription with key " + key);
		if (obj.subscription) obj.subscription.unsubscribe();
		this.subscriptionMap.delete(key);
	}
  },
  
  getSubscription: function(key) {
    return this.subscriptionMap.get(key);
  },
  
  hasSubscription: function(key) {
    return this.subscriptionMap.has(key);
  },
  
  allSubscriptions: function(){
	  return this.subscriptionMap.values();
  },
  
  unsubscribeAll: function(){
	for (let [key, value] of this.subscriptionMap) {
		console.log("Unsubscribing key: " + key);
		try{
		   if (value.subscription) value.subscription.unsubscribe();
		}catch(e){
		   console.log(e);
		}
	}
  },
  
  notifyReconnect: function(){
	for (let [key, value] of this.subscriptionMap) {
		console.log("Notifying reconnect for key: " + key);
		if (value.reconnect) value.reconnect();
	}
  },
  
};


export default SubscriptionManager;