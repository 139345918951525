import { useState, useEffect, Suspense } from 'react';
import lazyLoad from 'app/shared-components/util/lazyLoad';
import { useSelector, useDispatch } from 'react-redux';
import { selectDMUserDialogDetails, setDMUserDialogDetails, selectUserById, loadUserById } from 'app/store/contactsSlice';
import { selectUser } from 'app/store/userSlice';
import { getUserConnection, getUser } from 'app/store/contactFunctions';
import { submitUpdatePostStatus, STATUS_VIDEO_CALL_ENDED} from 'app/store/postSlice';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import MinimizableDialog from '../layout/MinimizableDialog';
import SingleChatPanel from './SingleChatPanel'



function DMChatModal(props) {
  const dispatch= useDispatch();
  const user= useSelector(selectUser);
  const dmDetails = useSelector(selectDMUserDialogDetails);
  const [channelId, setChannelId]= useState(dmDetails?.channelId);
  const fullScreen = useThemeMediaQuery((theme) => theme.breakpoints.down('sm'));
  const selectedTargetUser= useSelector(selectUserById(dmDetails?.targetUserId));
  console.log("Inside chat modal: " + channelId);
  console.log(selectedTargetUser);
  const targetUser= dmDetails?.targetUser ? dmDetails.targetUser : selectedTargetUser;
  console.log(targetUser);
  const userId= user?.id;
  const open= Boolean(dmDetails);
  const height= fullScreen ? "100%" : "700px";
  const width= fullScreen ? "100%" : "600px";
  const dialogWidth= fullScreen ? "100%" : "600px";
  const dialogMaxWidth= dialogWidth;

  function handleClose(){
	  dispatch(setDMUserDialogDetails(null));
	  setChannelId(null);
  }
  
  useEffect(() => {
    if (dmDetails){
      if (dmDetails.channelId) 
          setChannelId(dmDetails.channelId);
      else if (dmDetails.targetUser){
      	  getUserConnection(userId, dmDetails.targetUser.id).then(connection => {
      	     if (connection) setChannelId(connection.dmChannelId);
      	  });
      }
      
      if (!dmDetails.targetUser && dmDetails.targetUserId && !targetUser){
		     dispatch(loadUserById({userId: dmDetails.targetUserId}));
      }
    }
  }, [dmDetails]);
  
  if (!open) return null;

  return (
     <MinimizableDialog open={open} 
                        title={dmDetails.title} 
                        handleClose={handleClose}
                        showClose
                        fullScreen={fullScreen}
                        width={dialogWidth}
                        maxWidth={dialogMaxWidth}>
        <Suspense fallback={<h2> Loading...</h2>}>
           {channelId ?
            <div style={{display: 'flex', height: height, width:width}}>
             <SingleChatPanel onClose={handleClose} 
                         channelId={channelId}
                         targetUser={targetUser}/>
            </div>
                      :
            <h2> Loading...</h2>          
           }
	      </Suspense>
     	
     </MinimizableDialog>

  );
  
}

export default DMChatModal;
