import Badge from '@mui/material/Badge';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { useState } from 'react';


function CardActionsBaseMenu(props) {
  const { items, unread, children  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = (event) => {
    if (event){
      event.stopPropagation();
      event.preventDefault();
    }
    setAnchorEl(null);
  };

  function handleItemClick(event, item){
    event.stopPropagation();
    event.preventDefault();
    if (item.onClick) item.onClick();
    else if (item.handler) item.handler();
	  handleClose();
  }

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        onMouseDown={(event) => event.stopPropagation()}
      >
         <Badge color="secondary" variant="dot" invisible={!unread}>
             <FuseSvgIcon>heroicons-outline:dots-vertical</FuseSvgIcon>
         </Badge>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
      
        {items && items.map((item) => (
		    <MenuItem key={item.key} onClick={(event) => handleItemClick(event, item)}>
          <ListItemIcon className="min-w-40">
            <Badge color="secondary" variant="dot" invisible={!item.unread}>
                <FuseSvgIcon>{item.icon}</FuseSvgIcon>
	        </Badge>
          </ListItemIcon>
          <ListItemText primary={item.text} />
        </MenuItem>	 
		 ))}
		 
		 {children}
		  
      </Menu>
    </div>
  );
}

export default CardActionsBaseMenu;
