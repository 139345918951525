import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import NativeDialog from 'app/shared-components/layout/NativeDialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {   } from 'app/store/contactsSlice';
import { selectUserNotesDialogId, setUserNotesDialogId, selectUserById, selectContactByTypeAndId, updateFavoriteStatus} from 'app/store/contactsSlice';
import { selectIsLoading, startLoading, stopLoading} from 'app/store/utilSlice';
import { useForm } from 'react-hook-form';
import { useThemeMediaQuery } from '@fuse/hooks';
import ControlledSingleSelect from 'app/shared-components/form/ControlledSingleSelect';
import DefaultButton from '../layout/DefaultButton';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import ControlledWYSIWYG from 'app/shared-components/form/ControlledWYSIWYG';
import { dispatchResultMessage } from 'app/shared-components/utils';

const schema = yup.object().shape({
  notes: yup.string(),
});

function UserNotesDialog({}) {
  const dispatch= useDispatch();
  const userId = useSelector(selectUserNotesDialogId);
  const user = useSelector(selectUserById(userId));
  const contactStatus = useSelector(selectContactByTypeAndId('contactStatus', userId));
  const isStatusLoaded= contactStatus ? true : false;
  const isFavorite= isStatusLoaded ? contactStatus.isFavorite : false;
  const notes= isStatusLoaded ? contactStatus.notes : "";
  const title= "Notes For " + user?.firstName + " " + user?.lastName;
  
  const defaultValues = {
	  notes: notes,
	};
  
  const { handleSubmit, formState, control, reset, watch } = useForm({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  
  useEffect(() => {
  	 reset({notes: notes});
  }, [contactStatus]);

  if (!userId) {
    return null;
  }


  function onSubmit(data) {
    //set the loading flag
	  dispatch(startLoading());
	   
    //Close the dialog
    onClose();
    
     dispatch(updateFavoriteStatus({contactId: userId, isFavorite: isFavorite, notes: data.notes})).then((resp) =>
		 {
			   dispatchResultMessage(dispatch, resp, "Updated Notes");
		 });
  }
  


  function onClose() {
    dispatch(setUserNotesDialogId(null))
  }

  return (
    <NativeDialog
      onClose={onClose}
      open={Boolean(userId)}
      width="400px"
    >
      <AppBar position="static" color="secondary" elevation={0}>
	      <Toolbar className="flex w-full">
	        <Typography variant="subtitle1" color="inherit">
	          {title}
	        </Typography>
	      </Toolbar>
	    </AppBar>
	      <form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <DialogContent classes={{ root: 'p-16 pb-0' }}>
            <div className="mb-32">

               <ControlledWYSIWYG name="notes" initialContent={notes} 
			                   control={control} errors={errors}/>    
            
	          </div>

          </DialogContent>

          <DialogActions className="flex flex-col sm:flex-row sm:items-center justify-between py-16 sm:py-24 px-24">
            <div className="flex items-center space-x-8 mt-16 sm:mt-0">
              <DefaultButton text="Cancel" onClick={onClose} />
              <DefaultButton text="Save"
	              disabled={_.isEmpty(dirtyFields) || !isValid}
	              submit />
            </div>
          </DialogActions>
          
        </form>
        
    </NativeDialog>
  );
}

export default UserNotesDialog;
