import * as React from 'react';
import { DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import {setNativeDialog } from 'app/store/utilSlice';


export default function confirmationDialog(dispatch, 
									       {
									        title,
									        content,
									        onOk,
									        onCancel,
									        okText,
									        cancelText,
									        width,
									        height,
									        maxWidth,
									        maxHeight,
									       })
{
	dispatch(setNativeDialog({
	    width: width,
	    height: height,
	    maxWidth: maxWidth,
	    maxHeight: maxHeight,
        children: (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> {dispatch(setNativeDialog(null)); onCancel(false)}} color="primary">
                        {cancelText}
                    </Button>
                    <Button onClick={()=> {dispatch(setNativeDialog(null)); onOk(true)}} color="primary" autoFocus>
                        {okText}
                    </Button>
                </DialogActions>
            </React.Fragment>
             )
    }));
}
