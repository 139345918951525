import lazyLoad from 'app/shared-components/util/lazyLoad';

const ContactsApp = lazyLoad(() => import('./ContactsApp'));

const ContactsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/network',
      element: <ContactsApp />,
      children: [
        {
          path: 'view/:id',
          element: <div/>,
        },
      ],
    },
    {
      path: 'apps/network/:filter',
      element: <ContactsApp />,
      children: [
        {
          path: 'view/:id',
          element: <div/>,
        },
      ],
    }
  ],
};

export default ContactsAppConfig;
