import HomePage from './HomePage';
import ProviderHomePage from './ProviderHomePage';
import UserHomePage from './UserHomePage';
import { Navigate } from 'react-router-dom';
import { authRoles } from 'src/app/auth';


const homePageConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: 'pages/home-old',
      element: <Navigate to="/" />,
    },
    {
      path: 'pages/home/providers',
     element: <Navigate to="/" />,
    },
    {
      path: 'pages/home/users',
      element: <Navigate to="/" />,
    },
  ],
};

export default homePageConfig;
