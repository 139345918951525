import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import NativeDialog from 'app/shared-components/layout/NativeDialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import DefaultButton from '../layout/DefaultButton';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import DialogSafeIconMenu from '../layout/DialogSafeIconMenu';
import Button from '@mui/material/Button';
import NavLinkAdapter from '@fuse/core/NavLinkAdapter';
import { truncateText } from 'app/shared-components/utils'



function CitationsDialog({open, onClose, title, citations}) {
   const useTitle= title ? title : "Document References";
   const hasCitations= citations && citations.length > 0;

  return (
    <NativeDialog
      onClose={onClose}
      open={open}
      width="500px"
    >
      <AppBar position="static" color="secondary" elevation={0}>
	      <Toolbar className="flex flex-row w-full">
	        <Typography  sx={{ ml: 2, flex: 1 }} variant="subtitle1" component="div" color="inherit">
	          {useTitle}
	        </Typography>
	        <IconButton
                edge="start"
                color="inherit"
                onClick={onClose}
                aria-label="close"
              >
                <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
              </IconButton>
	      </Toolbar>
	    </AppBar>
	  
          <DialogContent classes={{ root: 'p-12 pb-0' }}>

            {hasCitations  ?
              <div className="">
        		      {citations.map((citation) => 
        		         <div className="flex flex-col align-center justify-center items-center p-6 my-12 w-full">
        		           <div className="flex grow">
          		           <Typography className="" color="success">
              	          {citation.generatedText}
              	         </Typography>
            	        </div>
            	        {citation.references.map((ref) => 
            	          <div className="flex flex-col border-2 rounded-md align-center justify-center items-center p-6 my-12 w-full">
            		           <div className="flex flex-row w-full">
            		             <div className="flex" style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: '75%'}}>
                		           <Typography className="font-semibold" >
                    	          {ref.name}
                    	         </Typography>
                  	         </div>
                  	         <div className="flex grow"></div>
                  	         <Button component={NavLinkAdapter} to={ref.objectUrl} target="_blank" 
                                    className="mx-8 whitespace-nowrap"
                                variant="contained"
                                color="secondary" >
                                Download
                            </Button>
                	        </div>
            		           <div className="flex">
              		           <Typography className="" color="secondary">
                  	          {truncateText(ref.sourceText, 1000)}
                  	         </Typography>
                	        </div>
                	        
                        </div>
            	        )}
        		         </div>
        		      )}
	            </div>
	                        :
	             <div className="px-32">
				         <Typography className="text-xl" >
      	          This entry has no document references.
      	        </Typography>
	            </div>
            }

          </DialogContent>

          <DialogActions className="flex flex-col sm:flex-row sm:items-center justify-between py-16 sm:py-24 px-24">
            <div className="flex items-center space-x-8 mt-16 sm:mt-0">
			        <DefaultButton text="Close" onClick={onClose} />
            </div>
          </DialogActions>
    </NativeDialog>
  );
}

export default CitationsDialog;
