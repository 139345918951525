import { Box } from '@mui/system';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Typography from '@mui/material/Typography';
import DefaultButton from '../layout/DefaultButton';
import { useDispatch, useSelector } from 'react-redux';
import notificationDialog from '../util/notificationDialog';
import { setCallDetails, selectCallDetails } from 'app/store/videoCallSlice';
import { fetchMeeting, selectMeetingById } from 'app/store/chimeMeetingSlice';
import { STATUS_VIDEO_CALL_ACTIVE } from 'app/store/postSlice';
import { useEffect } from 'react';


function VideoCallItem({user, post, isDM, isOwner}) {
  const dispatch= useDispatch();
  const existingCall= useSelector(selectCallDetails);
  
  const { id, channelId, status, foreignModelKey} = post;
  const meetingId= foreignModelKey ? foreignModelKey.substring(foreignModelKey.indexOf(":") + 1) : undefined;
  const meeting= useSelector(selectMeetingById(meetingId));
  const title= isDM ? "Video Call" : "Video Call";
  const isMeetingEnded= meeting && meeting.status === "Ended";
  const isRecordActive= status == STATUS_VIDEO_CALL_ACTIVE;
  const isActive=  isRecordActive && meetingId && !isMeetingEnded;
  const userName= user?.data ? (user.data.firstName + " " + user.data.lastName) : "";
  const isExistingCall= Boolean(existingCall);
  
  useEffect(() => {
     if(meetingId && isRecordActive) dispatch(fetchMeeting(meetingId));
  }, [meetingId]);

  if (!post) {
    return null;
  }
  
  function onClickJoin(event){
	  event.stopPropagation();
	  event.preventDefault();
	  if (isExistingCall){
		 notificationDialog(dispatch, {title: "Already In Call", content: "Please leave existing call before trying to join."})
	  }else{
		 dispatch(setCallDetails({
			       title,
             postId: id,
             channelId,
             meetingId,
             userName,
             isOwner,
             dmChannelId: isDM ? channelId : undefined,
		 }));
	  }
	  
  }

  return (
	 <Box
      sx={{ backgroundColor: 'background.paper' }}
      className="relative w-[150px] m-8 p-16 shadow rounded-16"
    >
      {isActive &&
      <div className="flex flex-col w-full items-center justify-center">
         
          <FuseSvgIcon className="" size={64} color="primary" >
	        heroicons-solid:video-camera
	      </FuseSvgIcon>
	      <DefaultButton text="Join Call" onClick={onClickJoin}/>

      </div>     
      }
      
      {!isActive &&
      <div className="flex flex-row w-full items-center justify-center">
        
          <FuseSvgIcon className="" size={24} color="primary" >
	        heroicons-solid:video-camera
	      </FuseSvgIcon>

	      <Typography>Call Ended</Typography>
	 
      </div>     
      }
    </Box>
    
  );
  
}

export default VideoCallItem;
