import React, { useRef } from 'react';
import ClassNames from 'classnames';
import RemoveComponent from './RemoveComponent';
const ItemTypes = { TAG: 'tag' };



const SingleTag = (props) => {
  const tagRef = useRef(null);
  const {
    readOnly = false,
    tag,
    classNames,
    index,
    labelField = 'text',
    tags
  } = props;


  const label = props.tag[labelField];
  const { className = '' } = tag;
  const opacity = 1;
  return (
    <span
      ref={tagRef}
      className={ClassNames('tag-wrapper rounded-full', classNames.tag, className)}
      style={{
        opacity,
        cursor: 'auto',
        backgroundColor: 'darkgrey',
        paddingTop: '4px',
        paddingBottom: '4px',
        paddingLeft: '6px',
        paddingRight: '6px',
        marginLeft: '4px',
        marginRight: '4px',
      }}
      data-testid="tag"
      onClick={props.onTagClicked}
      onTouchStart={props.onTagClicked}>
      {label}
      <RemoveComponent
        tag={props.tag}
        className={classNames.remove}
        removeComponent={props.removeComponent}
        onRemove={props.onDelete}
        readOnly={readOnly}
        index={index}
      />
    </span>
  );
};

export { SingleTag };
