import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { startLoading } from 'app/store/utilSlice';
import { dispatchResultMessage, confirmationDialog } from 'app/shared-components/utils';
import { followUser, setUserNotesDialogId, setUserTagsDialogId, setUserRecommendDialogId,
         unfollowUser, acceptInvite, setInviteContact, removeConnection, removeInvitation, setDMUserDialogDetails, updateFavoriteStatus } from 'app/store/contactsSlice';
import CardActionsBaseMenu from 'app/shared-components/util/CardActionsBaseMenu';
import DefaultButton from 'app/shared-components/layout/DefaultButton';
import Tooltip from '@mui/material/Tooltip';



function UserProfileActions(props) {
  const { user, userId, isFollowing, followsYou, isConnected, isInvited, invitedYou, isFavorite, hasNotes, hasRecommendation, hasTags, actions, maxButtons} = props;
  const dispatch = useDispatch();
  const max= maxButtons ? maxButtons : 5;
  
  //Actions (IN order of display priority)
  const allActions= [];
  if (actions){
     for (let i in actions) {
			allActions.push(actions[i]);
     }
  }
  
  allActions.push({icon: isFavorite ? "heroicons-solid:star" : "heroicons-outline:star", text: isFavorite ? "Remove Favorite" : "Add Favorite", key: "favorite", handler: toggleFavorite});
  allActions.push({icon: hasNotes ? "heroicons-solid:pencil-square" : "heroicons-outline:pencil-square", text: "User Notes", key: "notes", handler: handleOpenNotes});
  allActions.push({icon: hasTags ? "heroicons-solid:tag" : "heroicons-outline:tag", text: "User Tags", key: "tags", handler: handleOpenTags});
  allActions.push({icon: hasRecommendation ? "material-solid:recommend"  : "material-outline:recommend", text: "Recommend User", key: "recommend", handler: handleOpenRecommend});
  
  if (isConnected){
    allActions.push({icon: "heroicons-outline:chat-alt", text: "Direct Message", key: "message", handler: handleOpenDM});
  }
  
  if (!isConnected && !invitedYou && !isInvited){
    allActions.push({icon: "material-outline:person_add", text: "Invite To Connect", key: "connect",  handler: handleInviteConnection});
  }
  
  if (isConnected){
    allActions.push({icon: "material-outline:person_remove", text: "Remove Connection", key: "removeConnection", handler: handleRemoveConnection});
  }
  
  if (!isConnected && invitedYou){
     allActions.push({icon: "material-outline:how_to_reg", text: "Accept Connection", key: "acceptInvite", handler: handleAcceptInvite});
  }
  
  if (!isFollowing){
    allActions.push({icon: "material-outline:account_circle", text: "Follow User", key: "follow", handler: handleFollow});
  }
  
  
  if (!isConnected && isInvited){
     allActions.push({icon: "material-outline:person_add_disabled", text: "Delete Invitation To Connect", key: "uninviteConnection",  handler: handleUninviteConnection});
  }
  
  
  if (isFollowing){
    allActions.push({icon: "material-outline:no_accounts", text: "Unfollow User", key: "unfollow",  handler: handleRemoveFollow});
  }
  
  
  
  //Split between buttons and menu
  let buttonActions;
  let menuActions;
  if (allActions.length <= max){
      buttonActions= allActions;
  }else{
      buttonActions= allActions.slice(0, max);
      menuActions= allActions.slice(max);
  }

  function handleOpenDM(){
	  dispatch(setDMUserDialogDetails({targetUser: user, title: "DM " + user.firstName + " " + user.lastName}));
  }
  
  function handleOpenNotes(){
	  dispatch(setUserNotesDialogId(userId));
  }
  
  function handleOpenRecommend(){
	  dispatch(setUserRecommendDialogId(userId));
  }
  
  function handleOpenTags(){
	  dispatch(setUserTagsDialogId(userId));
  }
    
  
  function handleFollow(){
	dispatch(followUser({contactId: userId})).then((resp) =>
	{
		dispatchResultMessage(dispatch, resp, "Followed User!");
	});
  }
  
  function handleRemoveFollow(){
	 confirmationDialog(dispatch, "Unfollow User?", 
	                   "Are you sure you want to unfollow this user?",
	 (confirmed) => {
		  if (confirmed){
			 dispatch(unfollowUser({contactId: userId})).then((resp) =>
		 	 {
				dispatchResultMessage(dispatch, resp, "Unfollowed User");
			 });
	      }
	  }
	);  
  }
  
  function handleInviteConnection(){
	  dispatch(setInviteContact(user));
  }
 
  
  function handleUninviteConnection(){
	 confirmationDialog(dispatch, "Delete Invitation?", 
	                   "Are you sure you want to delete the invitation to connect with this user?",
	 (confirmed) => {
		  if (confirmed){
			 dispatch(removeInvitation({contactId: userId})).then((resp) =>
			 {
				dispatchResultMessage(dispatch, resp, "Deleted Invitation");
			 });
	      }
	  }
	);  
  }
  
  function handleAcceptInvite(){
	 dispatch(acceptInvite({ contactId: userId})).then((resp) =>
	{
		dispatchResultMessage(dispatch, resp, "Connected!");
	});
  }
  
  function handleRemoveConnection(){
	 confirmationDialog(dispatch, "Remove Connection?", 
	                   "Are you sure you want to remove this user as a connection?",
	 (confirmed) => {
		  if (confirmed){
			 dispatch(removeConnection({contactId: userId})).then((resp) =>
			 {
				dispatchResultMessage(dispatch, resp, "Removed Connection");
			 });
	      }
	  }
	);  
  }

 function toggleFavorite(){
   
	 confirmationDialog(dispatch, isFavorite ? "Remove Favorite?" : "Add Favorite?", 
	                   "Are you sure you want to " + (isFavorite ? "remove" : "add") + " this user as a favorite?",
	 (confirmed) => {
		  if (confirmed){
			 dispatch(updateFavoriteStatus({contactId: userId, isFavorite: !isFavorite})).then((resp) =>
			 {
				  dispatchResultMessage(dispatch, resp, (isFavorite ? "Removed" : "Added") + " Favorite");
			 });
	      }
	  }
	);  
  }


  return (
      <>
        
       {buttonActions.map((action) => (
          action.icon ?
          <Tooltip title={action.text} >
          <IconButton aria-label={action.text} onClick={() => action.handler(userId) }>
            <FuseSvgIcon size={20}>{action.icon}</FuseSvgIcon>
          </IconButton>
          </Tooltip>
                      : 
		      <DefaultButton key={action.key} className="whitespace-nowrap" size="small" text={action.text}  onClick={() => action.handler(userId) } />
       ))} 	
          
       {menuActions && 
        <CardActionsBaseMenu items={menuActions}  />
	   }
	  </>
  );
  
}

export default UserProfileActions;
