/* eslint import/no-extraneous-dependencies: off */
import {  createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';


export const handleRejectedAction = createAsyncThunk(
  'util/handleRejectedAction',
  async (action, { dispatch, getState, extra }) => {
	const subManager= extra;
    console.log("Handling rejected action for logged out user: " + action.type);
    if (action.type === 'subscriptions/addSubscriptionObject/fulfilled'){
		const key= action.meta.arg.key;
		subManager.removeSubscription(key);
	}
  }
);

export const fetchCurrentLocation = createAsyncThunk(
  'util/fetchCurrentLocation',
  async (params, { dispatch, getState }) => {
	
	//Get the current user state
    const { util } = getState();
    
    //Threshold for refreshing
    const currentTime = Date.now();
    const threshold= currentTime - (60000 * 5);
    if (util.currentLocationRetrieved && (util.currentLocationRetrieved > threshold)){
		return { cached: true, location: util.currentLocation};
	}
	
	const url= '/search/public/currentlocation';
    const apiName = 'PublicAPI';
    const path = url;
    const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    };

    const data= await apiGet(apiName, path, options);
    return { cached: false, location: data};
  }
);


const initialState = {
  isLoading: false,
  nativeDialog: null,
  aiDialogDetails: null,
  currentLocation: null,
  currentLocationRetrieved: null,
};

const utilSlice = createSlice({
  name: 'util',
  initialState,
  reducers: {
    startLoading: (state, action) => {
      state.isLoading = true;
    },
    stopLoading: (state, action) => {
      state.isLoading = false;
    },
    setNativeDialog: (state, action) => {
      state.nativeDialog = action.payload;
    },
    setAiDialogDetails: (state, action) => {
      state.aiDialogDetails = action.payload;
    },
  },
  extraReducers: builder => {
    builder
    .addCase(fetchCurrentLocation.fulfilled, (state, action) => {
		if (action.payload.cached) return;
		state.currentLocation= action.payload.location;
		state.currentLocationRetrieved= Date.now();
    })
  },
});

export const { startLoading, stopLoading, setNativeDialog } = utilSlice.actions;
export const selectIsLoading = ({ util }) => util.isLoading;
export const selectNativeDialog = ({ util }) => util.nativeDialog;
export const selectAiDialogDetails = ({ util }) => util.aiDialogDetails;
export const selectCurrentLocation = ({ util }) => util.currentLocation;

export default utilSlice.reducer;
