import { Children, useRef } from 'react';
import Slider from 'react-slick';
import Button from 'app/shared-components/marketing/Button';
import EastIcon from '@mui/icons-material/East';
import styled from '@emotion/styled';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SLIDER_PROPS = {
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  arrows: false,
  dots: false,
  draggable: false,
  swipe: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        draggable: true,
        swipe: true,
      }
    }
  ]
};

const PreviewSliderContainer = styled('div')`
  .slick-slider {
    .slick-track {
      display: flex;
      align-items: stretch;
    }
    .slick-slide {
      display: flex;
      align-self: stretch;
      height: unset;
      > div {
        display: flex;
        align-self: stretch;
        width: 100%;
      }
    }
  }
`;

const SlideWrapper = styled('div')`
  height: 100%;
  > * {
    height: 100%;
  }
`;

/**
 * @param {{
 *   title: string;
 *   moreBtn: {
 *     title: string;
 *     to: string;
 *   };
 * }}
 */
export default function PreviewSlider({ title, moreBtn, children, useArrows, sliderProps, ...props }) {
  const slider = useRef(null);
  const useSliderProps= sliderProps ? { ...SLIDER_PROPS, ...sliderProps } : SLIDER_PROPS;

  return (
    <PreviewSliderContainer {...props}>
      <div className="flex justify-between items-end ">
        <h2 className="text-hs-grey-black text-36 lg:text-48 leading-none font-semibold">
          {title}
        </h2>

        {moreBtn && <div className="hidden lg:block">
          <Button
            className="text-hs-blue outline-hs-blue"
            to={moreBtn.to}
            onClick={moreBtn.onClick}
          >
            {moreBtn.title}
            <EastIcon className="ml-2" />
          </Button>
        </div>}

        <div className={"flex-none flex gap-x-12" + (!useArrows ? " lg:hidden" : "")}>
          <button onClick={() => slider.current?.slickPrev()}>
            <img
              src="/assets/images/marketing/prev.svg"
              alt="Previous"
              className="w-40 h-40"
            />
          </button>
          <button onClick={() => slider.current?.slickNext()}>
            <img
              src="/assets/images/marketing/next.svg"
              alt="Next"
              className="w-40 h-40"
            />
          </button>
        </div>
      </div>

      <Slider
        className="mt-20 lg:mt-40 -mr-20"
        ref={slider}
        {...useSliderProps}
      >
        {Children.map(children, (slide) =>
          <SlideWrapper className="pr-20">
            {slide}
          </SlideWrapper>
        )}
      </Slider>

      {moreBtn && <div className="mt-20 lg:hidden">
        <Button
          className="text-hs-blue outline-hs-blue"
          to={moreBtn.to}
        >
          {moreBtn.title}
          <EastIcon className="ml-2" />
        </Button>
      </div>}
    </PreviewSliderContainer>
  );
}