import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { stopLoading, startLoading} from 'app/store/utilSlice';
import sortComparer from 'app/shared-components/util/lastMessageSortComparer';
import { bulkClientListCalls } from 'app/shared-components/util/bulkApi';
import { isUnread } from 'app/shared-components/utils';
import { setChannelRead, setChannelUnread, addChannel } from './channelsSlice';
import { submitPost } from './postSlice';
import { getClients } from './groupsSlice';
import { addDmChannel, addDmChannels, removeDmChannel } from './dmChannelSlice';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';

const emptyArray= [];
const clientAdapter = createEntityAdapter({
	sortComparer: (a, b) => b.createdAt - a.createdAt
});

const clientTeamAdapter = createEntityAdapter({
	sortComparer: sortComparer
});

const authUserAdapter = createEntityAdapter({
	sortComparer: sortComparer
});

export const fetchClient = createAsyncThunk(
  "clients/fetchClient",
  async (id, { dispatch }) => {
	dispatch(startLoading());
	try{
      const apiName = 'CoreAPI';
	  const path = '/client/' + id;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	  const data= await apiGet(apiName, path, options);
	  return data;
	}finally{
	  dispatch(stopLoading());
	}
  }
);

export const fetchClients = createAsyncThunk(
  "clients/fetchClients",
  async ({bulk}, { dispatch, getState }) => {
	  const {user} = getState();
	  let data= bulk;
	  if (!data){ //pre-fetched data via bulk api
	      const apiName = 'CoreAPI';
		    const path = '/client/byowner/' + user.id;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  data= await apiGet(apiName, path, options);
	 }
	 
	 //Use bulk load to get topics and members for the groups
	 //Do this async so it doesn't block the return of this thunk
	 bulkClientListCalls({clients: data}).then((bulk) => {
		 const bulkData= bulk.items;
		 for (let i in data) {
			const client= data[i];
			if (client.notesChannelId) dispatch(addChannel({channel: {id: client.notesChannelId, rootChannelId: client.notesChannelId, name: "Client Notes Channel", reverseSort: true}}))
			const teams= bulkData[client.id+'teams'];
			dispatch(fetchClientTeams({clientId: client.id, bulk: teams.code == 200 ? teams.body : undefined}));
			const users= bulkData[client.id+'users'];
			dispatch(fetchAuthorizedUsers({clientId: client.id, bulk: users.code == 200 ? users.body : undefined}));
		 }
	 });
	
     return data;
  }
)

export const fetchClientTeams = createAsyncThunk(
  "clients/fetchClientTeams",
  async ({clientId, bulk}, { dispatch }) => {
	dispatch(startLoading());
	try{
	  let data= bulk;
	  if (!data){
	      const apiName = 'CoreAPI';
		  const path = '/client/' + clientId + '/clientteam/pairs';
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  data= await apiGet(apiName, path, options);
	  }
	  
	  return data.map((pair) => {
		  return {...pair.channel, ...pair.clientTeam };
	  });
	}finally{
	  dispatch(stopLoading());
	}
  }
);


export const fetchAuthorizedUsers = createAsyncThunk(
  "clients/fetchAuthorizedUsers",
  async ({clientId, bulk}, { dispatch }) => {
	dispatch(startLoading());
	try{
	  let data= bulk;
	  if (!data){
	      const apiName = 'CoreAPI';
		  const path = '/client/' + clientId + '/clientauthorizeduser/pairs';
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  data= await apiGet(apiName, path, options);
	  }
	  
	  const out= data.map((pair) => ({...pair.user, ...pair.clientAuthorizedUser }));
	  dispatch(addDmChannels(out.map((dm) => ({type: 'clientAuthUser', foreignId: dm.clientId +':' + dm.userId, channelId: dm.channelId, data: dm}))));
	  return out;
	}finally{
	  dispatch(stopLoading());
	}
  }
);

export const createAuthorizedUser = createAsyncThunk(
  "clients/createAuthorizedUser",
  async ({clientId, user}, { dispatch, getState }) => {
	  dispatch(startLoading());
	  try{
	      const apiName = 'CoreAPI';
	      const path = '/client/' + clientId + '/clientauthorizeduser';
		  const body= {clientId: clientId, userId: user.id};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 return {...user, ...data };
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const deleteAuthorizedUser = createAsyncThunk(
  "clients/deleteAuthorizedUser",
  async ({clientId, userId}, { dispatch, getState }) => {
	  dispatch(startLoading());
	  try{
	      const apiName = 'CoreAPI';
	      const path = '/client/' + clientId + '/clientauthorizeduser/' + userId;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiDel(apiName, path, options);
		 dispatch(removeDmChannel(clientId +':' + userId));
		 return userId;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const updateAuthorizedUser = createAsyncThunk(
  "clients/updateAuthorizedUser",
  async ({clientId, userId, fileId}, { dispatch, getState }) => {
	  dispatch(startLoading());
	  try{
	      const apiName = 'CoreAPI';
	      const path = '/client/' + clientId + '/clientauthorizeduser/' + userId;
	      const options = {
			  body: {releaseFileId: fileId },
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 return {id: userId, ...data}; //Needs id to upsert the authorized user record
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const deleteClientTeam = createAsyncThunk(
  "clients/deleteClientTeam",
  async ({clientId, teamId}, { dispatch, getState }) => {
	  dispatch(startLoading());
	  try{
		  const { clients }= getState();
		  const client= clients.entities[clientId];
		  let channelId= null; //Chat channel id for this client team (needed to clean up team in groupSlice)
		  if (client){
			  const team= client.teams.entities[teamId];
			  channelId= team?.channelId;
		  }
	      const apiName = 'CoreAPI';
	      const path = '/client/' + clientId + '/clientteam/' + teamId;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiDel(apiName, path, options);
		 return {teamId: teamId,  clientId: clientId, channelId: channelId };
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const createClientTeam = createAsyncThunk(
  "clients/createClientTeam",
  async ({clientId, channel}, { dispatch, getState }) => {
	  dispatch(startLoading());
	  try{
	      const apiName = 'CoreAPI';
	      const path = '/client/' + clientId + '/clientteam';
		  const body= {clientId: clientId, teamId: channel.id};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 dispatch(getClients({groupId: channel.id, type: 'team'}));
		 return {...channel, ...data.clientteam };
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const createClient = createAsyncThunk(
  "clients/createClient",
  async (params, { dispatch, getState }) => {
	  try{
	      const apiName = 'CoreAPI';
		  const path = '/client/';
		  let body= { ...params};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let client= await apiPut(apiName, path, options);
		 if (client.notesChannelId) dispatch(addChannel({channel: {id: client.notesChannelId, rootChannelId: client.notesChannelId, name: "Client Notes Channel", reverseSort: true}}))
		 return client;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const updateClient = createAsyncThunk(
  "clients/updateClient",
  async (params, { dispatch, getState }) => {
	  try{
	      const apiName = 'CoreAPI';
		  const path = '/client/' + params.id;
		  let body= { ...params};
	      const options = {
			  headers: {},
			  body: body,
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)


export const deleteClient = createAsyncThunk(
  "clients/deleteClient",
  async (params, { dispatch }) => {
	  try{
	      const { clientId } = params;
	      const apiName = 'CoreAPI';
		  const path = '/client/' + clientId;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiDel(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

const findClientWithChannelId= (state, channelId) => {
	for (var i = 0; i < state.ids.length; i++){
	  const id= state.ids[i];
	  const client= state.entities[id];
      if (client?.users){
				for (var j = 0; j < client.users.ids.length; j++){
		       const userId= client.users.ids[j];
			     const user= client.users.entities[userId];
			     if (user && user.channelId == channelId) 
			   	    return {user: user, client: client};
				}
	  }
	}
    return {};
}

const newClient= (data) => {
	return {...data, unread: false,
	        teams: clientTeamAdapter.getInitialState({status: "idle"}),
	        users: authUserAdapter.getInitialState()};
}

const clientsSlice = createSlice({
  name: "clients",
  initialState: clientAdapter.getInitialState({viewingClientId: null, selectedClientsTab: 0, unread: false}),
  reducers: {
    setViewingClientId: (state, action) => {
      state.viewingClientId= action.payload;
    },
    setSelectedClientsTab: {
      reducer: (state, action) => {
        state.selectedClientsTab = action.payload;
      }
    },
    updateClientAuthUser: (state, action) => {
      const clientId = action.payload.clientId;
      const clientEntry = state.entities[clientId];
      if (clientEntry) {
			  authUserAdapter.upsertOne(clientEntry.users, action.payload);  
			  clientEntry.unread= isUnread(clientEntry.users.entities); 
			  state.unread= isUnread(state.entities);
      }
    },
  },
  extraReducers: builder => {
    builder
    .addCase(fetchClient.fulfilled, (state, action) => {
        clientAdapter.setOne(state, newClient(action.payload));
    })
    .addCase(fetchClients.fulfilled, (state, action) => {
		const clientEntries = action.payload.map(client => {
           return newClient(client);
        });
        clientAdapter.setMany(state, clientEntries);
        state.unread= isUnread(state.entities);
    })
    .addCase(createClient.fulfilled, (state, action) => {
		const client= action.payload;
        clientAdapter.addOne(state, newClient(client));
    })
    .addCase(updateClient.fulfilled, (state, action) => {
        clientAdapter.upsertOne(state, action.payload);
    })
    .addCase(deleteClient.fulfilled, (state, action) => {
      const clientId = action.meta.arg.clientId;
      clientAdapter.removeOne(state, clientId);
      state.unread= isUnread(state.entities);
    })
    .addCase(fetchClientTeams.fulfilled, (state, action) => {
      const clientId = action.meta.arg.clientId;
      const clientEntry = state.entities[clientId];
      if (clientEntry) {
		  clientEntry.teams.status= 'suceeded';
		  clientTeamAdapter.setAll(clientEntry.teams, action.payload);  
      }
    })
    .addCase(fetchAuthorizedUsers.fulfilled, (state, action) => {
      const clientId = action.meta.arg.clientId;
      const clientEntry = state.entities[clientId];
      if (clientEntry) {
			  clientEntry.users.status= 'suceeded';
			  authUserAdapter.setAll(clientEntry.users, action.payload); 
			  clientEntry.unread= isUnread(clientEntry.users.entities); 
			  state.unread= isUnread(state.entities);
      }
    })
    .addCase(createClientTeam.fulfilled, (state, action) => {
      const clientId = action.meta.arg.clientId;
      const clientEntry = state.entities[clientId];
      if (clientEntry) {
		     clientTeamAdapter.addOne(clientEntry.teams, action.payload);  
      }
    })
    .addCase(deleteClientTeam.fulfilled, (state, action) => {
      const {clientId, teamId} = action.meta.arg;
      const clientEntry = state.entities[clientId];
      if (clientEntry) {
		     clientTeamAdapter.removeOne(clientEntry.teams, teamId);  
      }
    })
    .addCase(createAuthorizedUser.fulfilled, (state, action) => {
      const clientId = action.meta.arg.clientId;
      const clientEntry = state.entities[clientId];
      if (clientEntry) {
		    authUserAdapter.addOne(clientEntry.users, action.payload);  
      }
    })
    .addCase(updateAuthorizedUser.fulfilled, (state, action) => {
      const clientId = action.meta.arg.clientId;
      const clientEntry = state.entities[clientId];
      if (clientEntry) {
		    authUserAdapter.upsertOne(clientEntry.users, action.payload);  
      }
    })
    .addCase(deleteAuthorizedUser.fulfilled, (state, action) => {
      const clientId = action.meta.arg.clientId;
      const clientEntry = state.entities[clientId];
      if (clientEntry) {
		    authUserAdapter.removeOne(clientEntry.users, action.payload);
		    state.unread= isUnread(state.entities);
      }
    })
    .addCase(setChannelRead.fulfilled, (state, action) =>  {
        const channelId = action.payload;
        const {user, client} = findClientWithChannelId(state, channelId);
        if (user) user.unread= false;
        if (client) {
        	client.unread= isUnread(client.users.entities);
          state.unread= isUnread(state.entities);
        }
    })
    .addCase(setChannelUnread, (state, action) =>  {
        const channelId = action.payload;
        const {user, client} = findClientWithChannelId(state, channelId);
        if (user) user.unread= true;
        if (client) {
        	client.unread= true;
        	state.unread= true;
        }
    })
    .addCase(submitPost.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const {user, client} = findClientWithChannelId(state, channelId);
      if (user){
			  user.lastMessageTimestamp= action.payload.createdAt;
		  }
    })
  }
})

const { selectById, selectAll } = clientAdapter.getSelectors(state => state.clients);
const { selectById: selectTeamById, selectAll: selectAllTeams } = clientTeamAdapter.getSelectors(state => state.teams);
const { selectById: selectUserById, selectAll: selectAllUsers } = authUserAdapter.getSelectors(state => state.users);


export const selectClientById = (clientId) => state => {
	if (clientId) return selectById(state, clientId);
	return null;
}

export const selectAuthUserByIds = (clientId, userId) => state => {
	const client= clientId ? selectById(state, clientId) : null;
	if (client && userId) return selectUserById(client, userId);
	else return null;
}

export const selectAllClients = () => state => {
	return selectAll(state);
}

export const selectTeamsByClientId = (clientId) => state => {
    if (clientId){
		const client= selectById(state, clientId);
		if (client){
			const teams= selectAllTeams(client);
		    return teams;
		}
	}
	return emptyArray;
}

export const selectAuthUsersByClientId = (clientId) => state => {
    if (clientId){
		const client= selectById(state, clientId);
		if (client){
			const users= selectAllUsers(client);
		    return users;
		}
	}
	return emptyArray;
}

export const selectSelectedClientsTab = () => state => {
	return state.clients.selectedClientsTab;
}
export const selectClientsUnread = ({ clients }) => clients.unread;
export const selectViewingClientId = ({ clients }) => clients.selectViewingClientId;
export const { setViewingPostId, updateClientAuthUser, setSelectedClientsTab } = clientsSlice.actions;

export default clientsSlice.reducer;
