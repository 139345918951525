import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserViewDialogId, setUserViewDialogId } from 'app/store/contactsSlice';
import { selectIsLoading, startLoading, stopLoading} from 'app/store/utilSlice';
import { dispatchResultMessage } from '../utils';
import { Controller, useForm } from 'react-hook-form';
import ControlledTextField from '../form/ControlledTextField';
import DefaultButton from '../layout/DefaultButton';
import UserView from 'app/shared-components/user/UserView';



const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function UserViewDialog({actions, chips}) {
  const dispatch= useDispatch();
  const userId = useSelector(selectUserViewDialogId);

  if (!userId) {
    return null;
  }
  

  function handleCloseDialog() {
    
  }

  function handleDiscard() {
    dispatch(setUserViewDialogId(null))
  }

 

  return (
    <Dialog
      classes={{
        paper: 'w-full m-12 sm:m-24'
      }}
      sx={{
        '.MuiDialogContent-root': {
          padding: 0,
        },
      }}
      TransitionComponent={Transition}
      onClose={handleCloseDialog}
      open={Boolean(userId)}
    >

          <DialogContent>
             <UserView userId={userId} actions={actions} chips={chips}/>
             <div className="pb-12" />
          </DialogContent>

          <DialogActions className="flex flex-col sm:flex-row sm:items-center justify-between py-16 sm:py-24 px-24">
            <div className="flex items-center space-x-8 mt-16 sm:mt-0">
              <DefaultButton text="Close" onClick={handleDiscard} />
            </div>
          </DialogActions>
        
    </Dialog>
  );
}

export default UserViewDialog;
