import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { generateClient } from 'aws-amplify/api';
import { fetchAuthSession } from 'aws-amplify/auth';
import { onAddChannelUpdate, onUpdateChatStatus, onAddPostInteraction, onAddNotification } from 'src/graphql/subscriptions';
import { addPost, removePost, updatePost, addPostInteraction, removePostInteraction, fetchPostsByChannelId} from 'app/store/postSlice';
import { saveChannelUser, setChannelRefresh, refreshChannelPosts, setChannelUnread, setChannelRead, selectChannelById } from 'app/store/channelsSlice';
import MessageTemplate from 'app/shared-components/chat/MessageTemplate';
import NotificationTemplate from 'app/shared-components/layout/notificationPanel/NotificationTemplate';
import NotificationModel from 'app/shared-components/layout/notificationPanel/model/NotificationModel';
import { updateConnection, getConnections } from 'app/store/connectionsSlice';
import { addNotification, getNotifications } from 'app/store/notificationsSlice';
import { getContactsByType, getInvites, getContactStatus } from './contactsSlice';
import { getChannelApplications} from './channelApplicationsSlice';
import { fetchPost } from 'app/store/postSlice';
import { fetchReferral, updateReferralInterest, fetchReferrals } from './referralsSlice';
import { updateInterest, fetchInterests } from './referralInterestSlice';
import { getGroup, getGroups } from './groupsSlice';
import { getExternalMessages } from './externalMessageSlice';
import { fetchAuthorizedClients, updateAuthorizedClient } from './clientsAuthorizedSlice';
import { fetchClients, updateClientAuthUser } from './clientsSlice';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';


const MAX_SUBSCRIPTIONS= 50;
const TYPE_ADD = "add";
const TYPE_REMOVE = "remove";
const TYPE_MODIFY = "modify";

const client = generateClient();


const initialState = {
  subscriptions: {},
  connectionState: null,
  previousConnectionState: null,
  isDisconnected: false,
  count: 0
};

const subscriptionSlice = createSlice({
  name: 'subscriptions',
  initialState,
  reducers: {
    addSubscriptionKey: (state, action) => {
      state.subscriptions[action.payload] = true;
      state.count= state.count + 1;
    },
    removeSubscriptionKey: (state, action) => {
	  if (state.subscriptions[action.payload]){
		  state.subscriptions[action.payload] = undefined;
	      state.count= state.count - 1;
	  }  
    },
  },
  extraReducers: builder => {
    builder.addCase(checkConnectionStatus.fulfilled, (state, action) => {
    	state.previousConnectionState= state.connectionState;
		  state.connectionState= action.payload;
		  if (state.previousConnectionState !== "Connected" && state.connectionState === "Connected"){
	    	 state.isDisconnected= false;
	    }
	    else if (state.previousConnectionState === "Connected" && state.connectionState && state.connectionState !== "Connected"){
	    	 state.isDisconnected= true;
	    }
    })
  },
});

export const selectConnectionStatus = ({ subscriptions }) => subscriptions.connectionState;
export const selectPreviousConnectionStatus = ({ subscriptions }) => subscriptions.previousConnectionState;
export const selectIsDisconnected = ({ subscriptions }) => subscriptions.isDisconnected;


function checkCapacity(getState, dispatch, subManager){
	const {subscriptions, channels}= getState();
	console.log("Current subscription count " + subscriptions.count);
	if (subscriptions.count < MAX_SUBSCRIPTIONS) return;
	
	//Otherwise, remove the subscription for channel with least recent activity
	//Channel ids should be sorted by most recent message, so iterate from the end
	for (var i = channels.ids.length - 1; i >= 0; i--) {
	 const channelId= channels.ids[i];
   	 if (subscriptions.subscriptions[channelId]){
			console.log("Removing subscription for channel with id " + channelId);
			subManager.removeSubscription(channelId);
			dispatch(removeSubscriptionKey(channelId));
			return;
		}
	}
}


export const subscribeToChannel = createAsyncThunk('subscriptions/subscribeToChannel', 
  async (params, { getState, dispatch, extra }) => {
	  const { user } = getState();
	  const { channelId, parentPostChannelId, closeSnackbar, enqueueSnackbar} = params;
	  const subManager= extra;
	  
	  //if there is a parentId, this is a comment channel and we don't need an actual subscription 
	  //since those are published to the parent channel id, however we do need to ensure it gets refreshed
	  //if there is a disconnect
	  if (!parentPostChannelId){
		    if (subManager.hasSubscription(channelId)) {
			  console.log("Subscription already exists for channel: " + channelId);
			  return;
		    }
		    
		    checkCapacity(getState, dispatch, subManager);
		  
		    //Initialize channel user, otherwise subscription could fail if it is the first time a user is
		    //viewing the channel. 
		    console.log("Initializing channel user for channel: " + channelId);
		    const chanUser= await saveChannelUser(channelId, user.id, false);
		    
		    // Subscribe to new messages
			  const channelSub = await createChannelSubscription(params, user.id, getState, dispatch);
		    dispatch(addSubscriptionObject({key: channelId, sub: channelSub, reconnect: () => reconnectChannel({channelId: channelId,
		                                                                                  dispatch: dispatch,
		                                                                                  getState: getState})}));
	  }else{
		    dispatch(addSubscriptionObject({key: channelId, reconnect: () => reconnectChannel({channelId: channelId,
		                                                                                  dispatch: dispatch,
		                                                                                  getState: getState})}));
	  }
	  
});

export const fetchChannelUpdates = createAsyncThunk(
  "post/fetchChannelUpdates",
  async (params, { getState, dispatch, extra }) => {
	  const {channelId, fromTimestamp }= params;
	  const { user } = getState();
      const apiName = 'CoreAPI';
      const query= "?publishedAt=GT:" + fromTimestamp;
	  const path = '/channelupdaterecord/bychannel/' + channelId + query;
      const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };

	 let data= await apiGet(apiName, path, options);
	 for (let i in data) {
		processUpdateRecord(data[i], user.id, dispatch);
	 }
     return data;
  }
)

export const checkConnectionStatus = createAsyncThunk('subscriptions/checkConnectionStatus', 
  async (params, { getState, dispatch, extra }) => {
	  const subManager= extra;
	  return subManager.connectionState;
});

const processUpdateRecord = (record, userId, dispatch) => {
	const publishType= record.updateType;
	const model= JSON.parse(record.content);
	
	if (record.contentType === 'ContentPost'){
		//If user is author we can just return
		if (model.author.id == userId ) return;
		
		if (publishType === TYPE_ADD){
	  	  	dispatch(addPost({post: model, 
			                    subscriptionChannelId: record.channelId,
		  	  	              publishedAt: record.publishedAt}));
		  }else if (publishType === TYPE_MODIFY){
		    dispatch(updatePost({post: model, 
		    		  	  	     subscriptionChannelId: record.channelId,
	  	  	                     publishedAt: record.publishedAt}));
		  }else if (publishType === TYPE_REMOVE){
		    dispatch(removePost({post: model, 
		    		  	  	     subscriptionChannelId: record.channelId,
	  	  	                     publishedAt: record.publishedAt}));
		  }
	}else if (record.contentType === 'PostInteraction'){
		  //If user is author we can just return
		  if (model.userId == userId ) return;
		  
		  if (publishType === TYPE_ADD){
	  	  	dispatch(addPostInteraction({interaction: model, 
	  	  	                             subscriptionChannelId: record.channelId,
	  	  	                             publishedAt: record.publishedAt} ));
		  }else if (publishType === TYPE_REMOVE){
		    dispatch(removePostInteraction({interaction: model, 
		                                    subscriptionChannelId: record.channelId,
	  	  	                                publishedAt: record.publishedAt}));
		  }
	
	}else{
		console.log("Unknown type for update record: " + record.contentType);
	}
	
}

const createChannelSubscription = async (params, userId, getState, dispatch) => {
    const { channelId, closeSnackbar, enqueueSnackbar} = params;
  
    // Subscribe to messages
    console.log("Creating new subscription for channel: " + channelId);
    const authToken= (await fetchAuthSession()).tokens?.idToken?.toString();
    const sub = client.graphql({ query: onAddChannelUpdate, variables: {channelId: channelId}, authToken })
										  .subscribe({
											  next: ({ data }) => {
												  try{
													  processChannelUpdate(dispatch, getState, userId, closeSnackbar, enqueueSnackbar,
													                       data.onAddChannelUpdate);
												  }catch(e){
													  console.warn(e);
												  }  
											  },
											  error: (error) => console.warn(error)
											});
		return sub;
}


export const subscribeToConnections = createAsyncThunk('subscriptions/subscribeToConnections', 
  async (params, { getState, dispatch, extra }) => {
	  const { user } = getState();
	  const subManager= extra;
	  const subKey= "connections";
	  if (subManager.hasSubscription(subKey)) {
		  console.log("Subscription already exists for connections.");
		  return;
	  }
	  
		// Subscribe to updates of connections
	  console.log("Creating new subscription for chat status updates.");
	  const authToken= (await fetchAuthSession()).tokens?.idToken?.toString();
	  const sub = client.graphql({ query: onUpdateChatStatus, variables: {sourceUserId: user.id}, authToken })
										  .subscribe({
											  next: ({ data }) => {
												  try{
													  processChatStatusUpdate(dispatch, getState, data.onUpdateChatStatus);	
												  }catch(e){
													  console.warn(e);
												  }  
											  },
											  error: (error) => console.warn(error)
											});

	    dispatch(addSubscriptionObject({key: subKey, sub: sub, reconnect: () => reconnectConnections({dispatch: dispatch})}));
});

export const subscribeToNotifications = createAsyncThunk('subscriptions/subscribeToNotifications', 
  async (params, { getState, dispatch, extra }) => {
	  const { user } = getState();
	  const subManager= extra;
	  const subKey= "notifications";
	  if (subManager.hasSubscription(subKey)) {
		  console.log("Subscription already exists for notifications.");
		  return;
	  }
	  
		// Subscribe to updates of notifications
	  console.log("Creating new subscription for notifications.");
	  const authToken= (await fetchAuthSession()).tokens?.idToken?.toString();
	  const sub = client.graphql({ query: onAddNotification, variables: {userId: user.id}, authToken})
										  .subscribe({
											  next: ({ data }) => {
												  try{
													  const notification= JSON.parse(data.onAddNotification.content);
			  	                  addNewNotification({...params, notification: notification, dispatch: dispatch});
												  }catch(e){
													  console.warn(e);
												  }  
											  },
											  error: (error) => console.warn(error)
											});
											
	  dispatch(addSubscriptionObject({key: subKey, sub: sub, reconnect: () => reconnectNotifications({dispatch: dispatch})}));
});

export const addSubscriptionObject = createAsyncThunk('subscriptions/addSubscriptionObject', 
  async ({key, sub, reconnect}, { getState, dispatch, extra }) => {
	  
	  const subManager= extra;
	  subManager.addSubscription(key, {subscription: sub, reconnect: reconnect});
      dispatch(addSubscriptionKey(key));
});


export const { addSubscriptionKey, removeSubscriptionKey } = subscriptionSlice.actions;

  
function addNewMessage(params) {
    const { message, subscriptionChannelId, publishedAt, userId, dispatch, getState, enqueueSnackbar, closeSnackbar} = params;
  const exists= Boolean(getState().post.entities[message.id]);
  if (exists) {
  	console.log("New message with id already exists: " + message.id);
  	return;
  }
	const viewingChannelIds= getState().channels.viewingChannelIds;
	console.log("Adding message with id: " + message.id);

	//Add message to state
	dispatch(addPost({post: message, 
			              subscriptionChannelId: subscriptionChannelId,
		  	  	        publishedAt: publishedAt}));
	
	//If user is currently viewing the channel, the snackbar is redudant and annoying
	if (viewingChannelIds.includes(message.channelId)){
		console.log("Currently viewing channel containing message: " + message.id);
		//Set channel read since user is in the channel so the server has a read record
		//after this new message
	    dispatch(setChannelRead({channelId: message.channelId}));
		return;
	}
	
	//Set channel unread
	dispatch(setChannelUnread(message.channelId));
	//dispatch(setChannelUnread(subscriptionChannelId));
	
	//Send the snackbar notification
	const title= "Message from @" + message.author.screenName;
	const item= {...message, title: title};
    enqueueSnackbar(title, {
      key: message.id,
      autoHideDuration: 3000,
      content: () => (
        <MessageTemplate
          item={item}
          onClose={() => {
            closeSnackbar(item.id);
          }}
        />
      ),
    });  
  }
 
 function addNewNotification(params) {
	const { notification, dispatch, enqueueSnackbar, closeSnackbar} = params;

	const item = NotificationModel(notification);
	console.log("Adding notification with id: " + item.id);
	console.log(item);
	
	//process any data fetches / state updates needed
	handleNotificationUpdates({dispatch: dispatch, notification: item, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar});
	
	//Send snackbar message
    enqueueSnackbar(item.title, {
      key: item.id,
      autoHideDuration: 3000,
      content: () => (
        <NotificationTemplate
          item={item}
          onClose={() => {
            closeSnackbar(item.id);
          }}
        />
      ),
    });
    
	  //Add to notification list
    dispatch(addNotification(item));
  }
  
  function processChannelUpdate(dispatch, getState, userId, closeSnackbar, enqueueSnackbar,
                               {type, contentType, channelId, publishedAt, content}){
	  const publishType= type;
	  if (contentType === 'ContentPost') {
		  const message= JSON.parse(content);
		  console.log("Got message via subscription.");
		  console.log(message);
		  
		  //If user is author we can just return
		  if (message.author.id == userId ) return;
		  
		  if (publishType === TYPE_ADD){
	  	  	addNewMessage({message: message, 
	  	  	           subscriptionChannelId: channelId,
	  	  	           publishedAt: publishedAt,
					   userId: userId, 
					   dispatch: dispatch,
					   getState: getState, 
					   enqueueSnackbar: enqueueSnackbar,
					   closeSnackbar: closeSnackbar});
		  }else if (publishType === TYPE_MODIFY){
		    dispatch(updatePost({post: message, 
		    		  	  	     subscriptionChannelId: channelId,
	  	  	                     publishedAt: publishedAt}));
		  }else if (publishType === TYPE_REMOVE){
		    dispatch(removePost({post: message, 
		    		  	  	         subscriptionChannelId: channelId,
	  	  	                   publishedAt: publishedAt}));
		  }  
	  }
	  else if (contentType === 'PostInteraction') {
		  const interaction= JSON.parse(content);
		  console.log("Got interaction via subscription.");
		  console.log(interaction);
	
		  //If user is author we can just return
		  if (interaction.userId == userId ) return;
		  
		  if (publishType === TYPE_ADD){
	  	  	dispatch(addPostInteraction({interaction: interaction, 
	  	  	                             subscriptionChannelId: channelId,
	  	  	                             publishedAt: publishedAt} ));
		  }else if (publishType === TYPE_REMOVE){
		    dispatch(removePostInteraction({interaction: interaction, 
		                                    subscriptionChannelId: channelId,
	  	  	                                publishedAt: publishedAt})); 
		 
	      }
	  }
  }
  
  function processChatStatusUpdate(dispatch, getState, {contentType, sourceUserId, targetUserId, content}){
	    const chatStatus= JSON.parse(content);
	    if (contentType === 'connection') {
		    dispatch(updateConnection({ ...chatStatus.user, ...chatStatus.connection}));
	    }
	    else if (contentType === 'referralInterest') dispatch(updateInterest({ ...chatStatus.referral, ...chatStatus.interest}));
  	  else if (contentType === 'referralInterestUser') dispatch(updateReferralInterest({ ...chatStatus.user, ...chatStatus.interest}));
  	  else if (contentType === 'authorizedUser') dispatch(updateClientAuthUser({ ...chatStatus.user, ...chatStatus.clientAuthorizedUser}));
  	  else if (contentType === 'authorizedUserClient') dispatch(updateAuthorizedClient({ ...chatStatus.client, ...chatStatus.clientAuthorizedUser}));
  }
  
  function handleNotificationUpdates({dispatch, notification, enqueueSnackbar, closeSnackbar}){
		if (notification.type === 'Follow') dispatch(getContactsByType({type: 'followers', refresh: true}));
		else if (notification.type === 'Post') dispatch(fetchPost(notification.postId))
		else if (notification.type === 'ConnectionInvite') dispatch(getInvites({ refresh: true}));
		else if (notification.type === 'PostInteraction') dispatch(fetchPost(notification.postId));
		else if (notification.type === 'ChannelInvite') {}
		else if (notification.type === 'Mention') {}
		else if (notification.type === 'ReferralInterest') dispatch(fetchReferral(notification.referralId));
		else if (notification.type === 'ChannelApplicationAccepted') {
			dispatch(getChannelApplications({}));
			dispatch(getGroup({channelId: notification.channelId, type: notification.channelType, refresh: true, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar }));
		}
		else if (notification.type === 'ChannelApplicationDeclined') dispatch(getChannelApplications({}));
		else if (notification.type === 'ChannelMemberRemoved') dispatch(getGroups({type: notification.channelType, refresh: true, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar}));
		else if (notification.type === 'ChannelApplication') {}
		else if (notification.type === 'ChannelInviteAccepted') dispatch(getGroup({channelId: notification.channelId, type: notification.channelType, refresh: true, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar }));
		else if (notification.type === 'ChannelJoined') dispatch(getGroup({channelId: notification.channelId, type: notification.channelType, refresh: true, enqueueSnackbar: enqueueSnackbar, closeSnackbar: closeSnackbar }));
		else if (notification.type === 'ExternalMessage') dispatch(getExternalMessages({ refresh: true }));
		else if (notification.type === 'ClientAuthorizationPending') dispatch(fetchAuthorizedClients({ refresh: true }));
		else if (notification.type === 'ClientAuthorizationStatus') dispatch(fetchClients({ refresh: true }));
		else if (notification.type === 'ConnectionAccepted') {
			dispatch(getContactsByType({type: 'connections', refresh: true}));
			dispatch(getConnections({}));
			dispatch(getContactStatus({contact: {id: notification.userId}, refresh: true }));
	} 
}

async function reconnectChannel(params){
	const { channelId, dispatch, getState } = params;
	dispatch(setChannelRefresh(channelId));
}

async function reconnectConnections(params){
	const { dispatch } = params;
	dispatch(getConnections({}));
	dispatch(fetchInterests({status: "open"}));
	dispatch(fetchReferrals({status: "open"}));
	dispatch(fetchAuthorizedClients({}));
	dispatch(fetchClients({}));
}

async function reconnectNotifications(params){
    const { dispatch } = params;
    dispatch(getNotifications());
}

export default subscriptionSlice.reducer;
