import lazyLoad from 'app/shared-components/util/lazyLoad';

const ChannelProfile = lazyLoad(() => import('app/shared-components/channel/ChannelProfile'));

const ChannelProfileAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: '/group/:channelId',
      element: <ChannelProfile />,
    },
    {
      path: '/team/:channelId',
      element: <ChannelProfile />,
    },
    {
      path: '/organization/:channelId',
      element: <ChannelProfile />,
    },
  ],
};

export default ChannelProfileAppConfig;
