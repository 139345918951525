import React from 'react';

const ClearAllTags = (props) => {
  return (
    <button className={props.classNames.clearAll} onClick={props.onClick} style={{marginLeft: '6px'}}>
      Clear
    </button>
  );
};

export default ClearAllTags;
