import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import NativeDialog from 'app/shared-components/layout/NativeDialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useThemeMediaQuery from '@fuse/hooks/useThemeMediaQuery';
import { useState, useRef, useEffect } from 'react';
import _ from '@lodash';
import clsx from 'clsx';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { resizeImage, blobToImage } from './util/imageUtils';



function CropImageDialog(props) {
  const { dialogTitle, className, aspectRatio, initialAspectRatio, onSaveCrop, onCancel, open, file, resize } = props;
  const [fileLoaded, setFileLoaded] = useState(false);
  const [image, setImage] = useState(null);
  const cropperRef = useRef(null);
  const fullScreen = useThemeMediaQuery((theme) => theme.breakpoints.down('sm'));
  const height= fullScreen ? "100%" : undefined;
  const width= fullScreen ? "100%" : "500px";
    
  
  useEffect(() => {
    if (file && file.type && open) {
	    const reader = new FileReader();
	    reader.onload = () => {
	      setImage(reader.result);
	      setFileLoaded(true);
	    };
	    reader.readAsDataURL(file);
    }
  }, [file, open])
  
  function handleDiscard() {
    setImage(null);
	  setFileLoaded(false);   
    if (onCancel){
		onCancel();
	}
  }
  

  const onSaveCropClick = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") 
    {
	   try{
		//Save cropped image a url for preview
	    let cropData= cropperRef.current?.cropper.getCroppedCanvas().toDataURL();

		//process image blob
		processImage().then((file) => {
		  //If the callback function is defined, use it
	      if(onSaveCrop){
			  onSaveCrop(cropData, file);
		  }
		})
       }catch(err){
		   console.log(err);
	   }
    }
  };
  
  const processImage= async () => 
  {
	  const blob= await new Promise(resolve => 
	    cropperRef.current?.cropper.getCroppedCanvas().toBlob(resolve, "image/jpeg", 1)
	  );
	  if (!blob)throw new Error('Failed to create blob');
	      
      let useBlob= blob;
      if (resize){
		  const img= await blobToImage(blob);
		  useBlob= await resizeImage(img, resize.width, resize.height);
	  }
      
      //Create file object from the blob
      return new File([useBlob], "newfile", { type: "image/jpeg" });
  }
  

  return (
    <div className={clsx('', className)}>
      
      
      <NativeDialog
        open={open}
        height={height}
        width={width}
      >
        
        <AppBar position="static" color="secondary" elevation={0}>
          <Toolbar className="flex w-full">
            <Typography variant="subtitle1" color="inherit">
              {dialogTitle}
            </Typography>
          </Toolbar>
        </AppBar>

          <DialogContent classes={{ root: 'p-16 pb-0 sm:p-32 sm:pb-0' }}>
              
              <Cropper
		          style={{ height: "100%", width: "100%"}}
		          initialAspectRatio={initialAspectRatio}
		          aspectRatio={aspectRatio}
		          src={image}
		          ref={cropperRef}
		          viewMode={3}
		          guides={true}
		          minCropBoxHeight={20}
		          minCropBoxWidth={20}
		          background={false}
		          responsive={true}
		          checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
		        />
          
          </DialogContent>

          <DialogActions className="flex flex-col sm:flex-row sm:items-center justify-between py-16 sm:py-24 px-24">

            <div className="flex items-center space-x-8 mt-16 sm:mt-0">
 
               <Button
                className=""
                variant="contained"
                color="secondary"
                disabled={!fileLoaded}
                onClick={onSaveCropClick}
              >
                Save
              </Button>
       
              
              <Button className="" 
                variant="contained" 
                color="secondary" 
                onClick={handleDiscard}>
                Cancel
              </Button>
		      
            </div>
          </DialogActions>
      </NativeDialog>
    </div>
  );
}

export default CropImageDialog;
