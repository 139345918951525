import { createSlice } from '@reduxjs/toolkit';

const stateSlice = createSlice({
  name: 'chatPanel/state',
  initialState: {
	  open: false,
	  selectedContactId: null,
  },
  reducers: {
    toggleChatPanel: (state, action) => {
		state.open= !state.open;
	},
    openChatPanel: (state, action) => {
		state.open= true;
	},
    closeChatPanel: (state, action) => {
		state.open= false;
		state.selectedContactId = null;
	},
	setSelectedContactId: (state, action) => {
      state.selectedContactId = action.payload;
    },
    removeSelectedContactId: (state, action) => {
      state.selectedContactId = null;
    },
  },
});

export const { toggleChatPanel, openChatPanel, closeChatPanel, 
               setUnread, removeSelectedContactId, setSelectedContactId } = stateSlice.actions;

export const selectChatPanelState = ({ chatPanel }) => chatPanel.state.open;
export const selectSelectedContactId = ({ chatPanel }) => chatPanel.state.selectedContactId;


export default stateSlice.reducer;
