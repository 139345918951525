import lazyLoad from 'app/shared-components/util/lazyLoad';

const TeamsApp = lazyLoad(() => import('./TeamsApp'));
const TeamApp = lazyLoad(() => import('./team/TeamApp'));
const ChannelAppClientMembers = lazyLoad(() => import('app/shared-components/channel/channelApp/ChannelAppClientMembers'));
const ChannelAppClient = lazyLoad(() => import('app/shared-components/channel/channelApp/ChannelAppClient'));
const ChannelAppClients = lazyLoad(() => import('app/shared-components/channel/channelApp/ChannelAppClients'));
const ChannelFiles = lazyLoad(() => import('app/shared-components/channel/channelApp/ChannelFiles'));
const ChannelAdmin = lazyLoad(() => import('app/shared-components/channel/channelApp/ChannelAdmin'));
const ChannelMembers = lazyLoad(() => import('app/shared-components/channel/channelApp/ChannelMembers'));
const TeamForm = lazyLoad(() => import('./team/TeamForm'));
const ChannelAppChannel = lazyLoad(() => import('app/shared-components/channel/channelApp/ChannelAppChannel'));
const ChannelAppThreads = lazyLoad(() => import('app/shared-components/channel/channelApp/ChannelAppThreads'));
const TopicList = lazyLoad(() => import('app/shared-components/channel/TopicList'));


const TeamsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/teams',
      element: <TeamsApp />,
      children: [
        {
          path: ':id',
          element: <TeamForm />,
        },
        {
          path: 'invites',
          element: <div/>,
        }
      ],
    },
    {
      path: 'apps/team/:groupId',
      element: <TeamApp />,
      children: [
		{
          path: '',
          element: <ChannelAppChannel type="team" />,
        },
        {
          path: 'searchresults',
          element: <ChannelAppChannel type="team" />,
        },
        {
          path: 'channel/:channelId',
          element: <ChannelAppChannel type="team" />,
        },
        {
          path: 'channel/:channelId/searchresults',
          element: <ChannelAppChannel type="team" />,
        },
        {
          path: 'threads',
          element: <ChannelAppThreads type="team"/>,
        },
        {
          path: 'topics',
          element: <TopicList type="team"/>,
        },
        {
          path: 'topic/:topicId',
          element: <TopicList type="team"/>,
        },
        {
          path: 'admin',
          element: <ChannelAdmin type="team" formElement={TeamForm} />,
        },
        {
          path: 'members',
          element: <ChannelMembers type="team" />,
        },
        {
          path: 'member/:memberId',
          element: <ChannelMembers type="team" />,
        },
        {
          path: 'result/:userId',
          element: <ChannelMembers type="team" />,
        },
        {
          path: 'files',
          element: <ChannelFiles type="team" />,
        },
        {
          path: 'files/view/:fileId',
          element: <ChannelFiles type="team" />,
        },
        {
          path: 'files/:folderId',
          element: <ChannelFiles type="team" />,
        },
        {
          path: 'files/:folderId/view/:fileId',
          element: <ChannelFiles type="team" />,
        },
        {
          path: 'clients',
          element: <ChannelAppClients type="team" />,
        },
        {
          path: 'client/:clientId',
          element: <ChannelAppClient type="team" />,
        },
        {
          path: 'client/:clientId/searchresults',
          element: <ChannelAppClient type="team" />,
        },
        {
          path: 'client/:clientId/members',
          element: <ChannelAppClientMembers type="team" />,
        },
      ],
    },
  ],
};

export default TeamsAppConfig;
