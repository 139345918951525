function queryString(...queryParams) {
  let args = 0;
  let out= "?";
  for (const arg of queryParams) {
    if (arg){
      if (args > 0) out += "&";
      out += arg;
      args++;
    }
  }
  if (args == 0) return "";
  else return out;
}

export default queryString;