/* eslint import/no-extraneous-dependencies: off */
import {  createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const videoCallSlice = createSlice({
  name: 'videoCall',
  initialState: {
	  callDetails: null
  },
  reducers: {
    setCallDetails: (state, action) => {
      state.callDetails = action.payload;
    },
  },
});

export const { setCallDetails } = videoCallSlice.actions;

export const selectCallDetails = ({ videoCall }) => videoCall.callDetails;

export default videoCallSlice.reducer;
