import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import NotificationModel from 'app/shared-components/layout/notificationPanel/model/NotificationModel';
import { updateUserInfo } from 'app/store/userSlice';


export const fetchNewNotifications = async (userId, lastNotification) => {
  const apiName = 'CoreAPI';
  let path = '/notification/byuser/' + userId;
  if (lastNotification){
	  path= path + '?startFromId=' + lastNotification.source;
  }
  const options = {
	  headers: {},
	  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  };
  let data= await apiGet(apiName, path, options);
  console.log("fetchNewNotifications retrieved: " + data.length);

  let out= [];
  for (let i in data) {
	const resp= data[i];
	let notification= NotificationModel(resp);
    out.push(notification);
  }
  return out;
}

export const getNotifications = createAsyncThunk('notifications/getNotifications',
  async (params, { getState }) => {
    const { user, notifications } = getState();
    const response = await fetchNewNotifications(user.id, notifications.lastNotification);
    return {lastRead: user.raw.lastReadNotifications, notifications: response};
});

export const dismissAll = createAsyncThunk('notifications/dismissAll', async () => {
  return true;
});

export const dismissItem = createAsyncThunk('notifications/dismissItem', 
   async (id, { dispatch, getState }) => {
    const { user } = getState();
	  const notification= selectNotificationById(getState(), id);
    const apiName = 'CoreAPI';
	  const path = '/notification/' + notification.source + '/target/User:' + user.id;
	  const options = {
		  headers: {},
		  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
	  };
	  let data= await apiDel(apiName, path, options);

  	  return id;
});

export const setNotificationsRead = createAsyncThunk('notifications/setNotificationsRead', 
   async (params, { dispatch, getState }) => {
	  console.log("Setting notifications read timestamp.");
	  await dispatch(updateUserInfo({lastReadNotifications: Date.now()}));
});

const notificationsAdapter = createEntityAdapter({
	sortComparer: (a, b) => b.createdAt - a.createdAt
});

export const { selectAll: selectNotifications, selectById: selectNotificationById } =
  notificationsAdapter.getSelectors((state) => state.notifications.notifications);

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState:{
	  notifications: notificationsAdapter.upsertMany(notificationsAdapter.getInitialState(), []),
	  lastNotification: null,
	  unread: false,
  }, 
  reducers: {
    setLastNotification: {
      reducer: (state, action) => {
        state.lastNotification = action.payload;
      }
    },
    addNotification: {
      reducer: (state, action) => {
    		const notification= action.payload;
    		if (!state.notifications.entities[notification.id]){
    			notificationsAdapter.addOne(state.notifications, action.payload);
    			state.unread= true;
    		}
      }
    },
  },
  extraReducers: builder => {
    builder
    .addCase(getNotifications.fulfilled, (state, action) => {
      const notifications= action.payload.notifications;
		  const lastRead= action.payload.lastRead;
      notificationsAdapter.setMany(state.notifications, notifications);
      if (notifications.length > 0){
			  state.lastNotification= notifications[notifications.length - 1];
			  if (!lastRead) state.unread= true;
			  else if (lastRead < state.lastNotification.createdAt) state.unread= true;
      }
    })
    .addCase(dismissItem.fulfilled, (state, action) => {
      notificationsAdapter.removeOne(state.notifications, action.payload);
    })
    .addCase(setNotificationsRead.fulfilled, (state, action) => {
       state.unread= false;
    })
  }
});

export const selectLastNotification = ({ notifications }) => notifications.lastNotification;
export const selectNotificationsUnread= ({ notifications }) => notifications.unread;

export const { setLastNotification, addNotification } = notificationsSlice.actions;

export default notificationsSlice.reducer;
