import Quill from 'quill';
import sanitizeHtml from 'sanitize-html';

const Clipboard = Quill.import('modules/clipboard');
const Delta = Quill.import('delta');

class QuillSanitizePaste extends Clipboard {
  constructor(quill, options) {
    super(quill, options);
  }
  
  convertHTML(html) {
    return Clipboard.prototype.convertHTML.call(this, sanitizeHtml(html));
  }
 
}

Quill.register('modules/clipboard', QuillSanitizePaste, true);
export default QuillSanitizePaste;