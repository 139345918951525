import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { selectIsLoading} from 'app/store/utilSlice';
import { useSelector } from 'react-redux';
import {useRef, useEffect, forwardRef, useState} from 'react';
import './loadingBackdrop.css';


function LoadingBackdrop() {
    const isLoading = useSelector(selectIsLoading);
    const ref = useRef();
    console.log("Loading Backdrop state: " + isLoading);
    
    useEffect(() => {
	    if (isLoading){
	        ref.current?.showModal();
	    } else {
	        ref.current?.close();
	    }
	}, [isLoading]);
  

  const handleClose = (event, reason) => {
  	if (isLoading) event.preventDefault();
  }

	return (
      isLoading &&
      <dialog className="loading" ref={ref}
              onCancel={handleClose}>
      
      <CircularProgress color="inherit"/>

      </dialog>
   );
	

}

export default LoadingBackdrop;
