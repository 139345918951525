import { combineReducers } from '@reduxjs/toolkit';
import blogPosts from './blogPostsSlice';
import menus from './menusSlice';
import pages from './pagesSlice';
import people from './peopleSlice';
import singleTypes from './singleTypesSlice';
import tags from './tagsSlice';
import testimonials from './testimonialsSlice';

const combinedReducer = combineReducers({
  blogPosts,
  menus,
  pages,
  people,
  singleTypes,
  tags,
  testimonials
});

/** @typedef {ReturnType<typeof combinedReducer>} MarketingState */
/** @typedef {{ marketing: MarketingState, [stateName: string]: any }} RootState */

export default combinedReducer;
