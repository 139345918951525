import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { IconButton, Tooltip } from '@mui/material';
import { confirmationDialog } from 'app/shared-components/utils.js';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsDisconnected } from 'app/store/subscriptionSlice';
import { selectUser } from 'app/store/userSlice';


function DisconnectedIcon() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const isDisconnected= useSelector(selectIsDisconnected);
  const isGuest= !user.role || user.role.length === 0;
  const useDisconnected= !isGuest && isDisconnected;

  function handlClick(){
  	confirmationDialog(dispatch, "Real-time communication disconnected!", 
	                   "The App is currently not receiving real-time messages from our servers. Please check your network connection; if your connection is working and the App is still not receiving messages, click the 'Reload App' button below.",
	 (confirmed) => {
		  if (confirmed){
			 window.location.reload();
	      }
	  },
	 "Cancel", "Reload App",
	);
  }
  
  return (
	useDisconnected &&
	  <Tooltip title="App is disconnected" placement="bottom">
	      <IconButton
	        color="warning"
	        className="w-40 h-40"
	        size="large"
	        onClick={handlClick}
	      >
	        <FuseSvgIcon>material-outline:wifi_off</FuseSvgIcon>
	      </IconButton>
	  </Tooltip>
  );
}

export default DisconnectedIcon;
