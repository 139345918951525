import { escapeRegExp } from 'lodash';
import { SEPARATORS } from './constants';

/**
 * Convert an array of delimiter characters into a regular expression
 * that can be used to split content by those delimiters.
 * @param {Array<char>} delimiters Array of characters to turn into a regex
 * @returns {RegExp} Regular expression
 */
export function buildRegExpFromDelimiters(delimiters) {
  const delimiterChars = delimiters
    .map((delimiter) => {
      // See: http://stackoverflow.com/a/34711175/1463681
      const chrCode = delimiter - 48 * Math.floor(delimiter / 48);
      return String.fromCharCode(96 <= delimiter ? chrCode : delimiter);
    })
    .join('');
  const escapedDelimiterChars = escapeRegExp(delimiterChars);
  return new RegExp(`[${escapedDelimiterChars}]+`);
}

export function getKeyCodeFromSeparator(separator) {
  switch (separator) {
    case SEPARATORS.ENTER:
      // 13 is for enter key and 10 is for carriage return, this might be present when pasting from excel
      return [10, 13];
    case SEPARATORS.TAB:
      return 9;
    case SEPARATORS.COMMA:
      return 188;
    case SEPARATORS.SPACE:
      return 32;
    case SEPARATORS.SEMICOLON:
      return 186;
    // Ideally this should never happen but just in case
    // return 0 (Unidentified key)
    default:
      return 0;
  }
}

