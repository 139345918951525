export const COGNITO_CONFIG = {
  REGION: process.env.REACT_APP_REGION ? process.env.REACT_APP_REGION : "us-east-1",
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID ? process.env.REACT_APP_USER_POOL_ID : "us-east-1_RsYLGiInq",
  APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID ? process.env.REACT_APP_APP_CLIENT_ID : "4bfstb7m8bb6oqu92mu1pvbf9r"
};

export const API_CONFIG = {
  URL_BASE: process.env.REACT_APP_API_URL_BASE ? process.env.REACT_APP_API_URL_BASE : "https://api.dev.halespring.com",
  URL2_BASE: process.env.REACT_APP_API2_URL_BASE ? process.env.REACT_APP_API2_URL_BASE : "https://api.dev.halespring.com",
  GQL_BASE: process.env.REACT_APP_GQL_URL_BASE ? process.env.REACT_APP_GQL_URL_BASE : "https://gql.dev.halespring.com/graphql"
};

export const STRIPE_CONFIG = {
  API_KEY: process.env.REACT_APP_STRIPE_API_KEY ? process.env.REACT_APP_STRIPE_API_KEY : "pk_test_51OP3bAEuUxjankBhBsbt6nawWjPzDGe0HG4UDTjmPNJmNkCFuvlbghJtr8nSIOvVTK05x7nycFkARaw3pgr1OmOe00rq42mywY"
};

export const PERSONA_CONFIG = {
  TEMPLATE_ID: process.env.REACT_APP_PERSONA_TEMPLATE_ID ? process.env.REACT_APP_PERSONA_TEMPLATE_ID : "itmpl_rr7T3CegzCbbSyJ2sWMXrs7oMcsn",
  ENVIRONMENT_ID: process.env.REACT_APP_PERSONA_ENVIRONMENT_ID ? process.env.REACT_APP_PERSONA_ENVIRONMENT_ID : "sandbox"
};


export const FEATURE_CONFIG = {
  COMING_SOON: process.env.REACT_APP_COMING_SOON ? process.env.REACT_APP_COMING_SOON === "true" : true,
  VIDEO_CHAT: process.env.REACT_APP_VIDEO_CHAT ? process.env.REACT_APP_VIDEO_CHAT === "true" : true
};
