import { Button as ButtonBase } from '@mui/base';
import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { prepareForSlot } from '@mui/base/utils';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

const LinkSlot = prepareForSlot(Link);

/**
 * @typedef {{
 *  dense?: boolean
 * } & import('@mui/base').ButtonProps<typeof Link>} Props
 */

/**
 * @param {Props} props 
 * @param {React.ForwardedRef<HTMLElement>} ref 
 */
function Button(props, ref) {
  const { dense = false, className = '', ...buttonProps } = props;

  /** @type {Props['slots']} */
  const slots = {
    root: LinkSlot
  };

  /** @type {Props['slotProps']} */
  const slotProps = {
    root: {
      className: twMerge(classNames(
        'flex md:inline-flex justify-center items-center flex-nowrap whitespace-nowrap',
        'leading-none font-medium uppercase rounded-full transition-colors',
        'outline outline-2 -outline-offset-2 outline-transparent',
        dense ? 'px-20 py-12' : 'px-60 py-20',
        className,
      ))
    }
  };

  return (
    <ButtonBase
      {...buttonProps}
      ref={ref}
      slots={slots}
      slotProps={slotProps}
    />
  )
};

export default forwardRef(Button);
