import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import NativeDialog from 'app/shared-components/layout/NativeDialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  selectUser } from 'app/store/userSlice';
import { selectUserTagsDialogId, setUserTagsDialogId, selectUserById, selectContactByTypeAndId, updateUserTags} from 'app/store/contactsSlice';
import { selectIsLoading, startLoading, stopLoading} from 'app/store/utilSlice';
import { useForm } from 'react-hook-form';
import { useThemeMediaQuery } from '@fuse/hooks';
import ReactTags from 'app/shared-components/tags/ReactTags';
import isValidTagName from 'app/shared-components/tags/isValidTagName';
import { SEPARATORS } from 'app/shared-components/tags/constants';
import DefaultButton from '../layout/DefaultButton';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import { dispatchResultMessage } from 'app/shared-components/utils';
import { warningMessage } from 'app/shared-components/util/message';
import { isEqual } from 'lodash';
import 'app/shared-components/tags/tags.css'; 

function UserTagsDialog({}) {
  const dispatch= useDispatch();
  const userId = useSelector(selectUserTagsDialogId);
  const currentUser = useSelector(selectUser);
  const user = useSelector(selectUserById(userId));
  const contactStatus = useSelector(selectContactByTypeAndId('contactStatus', userId));
  const isStatusLoaded= contactStatus ? true : false;
  const title= "Tags For " + user?.firstName + " " + user?.lastName;
  const userTags= currentUser?.raw?.tags ? currentUser.raw.tags : [];
  const suggestions= userTags.map((tag) => { return {id: tag, className: ''} } );
  const currentTags= isStatusLoaded ? contactStatus.tags : [];
  const useTags= currentTags.map((tag) => { return {id: tag, className: ''} } );
  console.log("useTags: ");
  console.log(useTags);
  const [tags, setTags]= useState(useTags);
  const [hasInput, setHasInput]= useState(false);
  const hasChanged= !isEqual(useTags, tags);
  console.log(tags);
  
  useEffect(() => {
    setTags(useTags);
  }, [userId]);
  
  const handleDelete = (index) => {
    setTags(tags.filter((_, i) => i !== index));
    console.log(tags);
  };

  const handleAddition = (tag) => {
    if (isValidTagName(tag.id)){
      setTags([...tags, tag]);
      console.log(tags);
    }else{
      warningMessage(dispatch, tag.id + " is not valid. Tags must have only lower case letters, numbers, or underscore, and must start with a letter.");
    }
  };
  
  const onTagUpdate = (index, newTag) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1, newTag);
    setTags(updatedTags);
  };

  const handleTagClick = (index) => {
    console.log('The tag at index ' + index + ' was clicked');
  };

  const onClearAll = () => {
    setTags([]);
    console.log(tags);
  };
  

  if (!userId) {
    return null;
  }


  function onSubmit() {
    //set the loading flag
	  dispatch(startLoading());
	   
    //Close the dialog
    onClose();
    
    //Map tags to plain list
    const out= tags.map((tag) => tag.id );
    
    //Execute update
    dispatch(updateUserTags({contactId: userId, tags: out})).then((resp) =>
		 {
			  dispatchResultMessage(dispatch, resp, "Updated Tags");
		 });
  }
  

  function onClose() {
    dispatch(setUserTagsDialogId(null))
  }

  return (
    <NativeDialog
      onClose={onClose}
      open={Boolean(userId)}
      width="400px"
    >
      <AppBar position="static" color="secondary" elevation={0}>
	      <Toolbar className="flex w-full">
	        <Typography variant="subtitle1" color="inherit">
	          {title}
	        </Typography>
	      </Toolbar>
	    </AppBar>

          <DialogContent classes={{ root: 'p-16 pb-0' }}>
            <div className="mb-32 text-center">

               <ReactTags
                  tags={tags}
                  labelField="id"
                  suggestions={suggestions}
                  separators={[SEPARATORS.ENTER, SEPARATORS.COMMA]}
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  handleTagClick={handleTagClick}
                  onTagUpdate={onTagUpdate}
                  inputFieldPosition="bottom"
                  placeholder="Add Tag"
                  editable
                  setHasInput={setHasInput}
                  clearAll={false}
                  onClearAll={onClearAll}
                  maxTags={5}
                />
                
                {hasInput && 
  	            <div className="mt-12">
  	             <Typography color="inherit">
                    Press Enter to add tag
  	             </Typography>
  	            </div>
  	            }
	          </div>
	          
	          

          </DialogContent>

          <DialogActions className="flex flex-col sm:flex-row sm:items-center justify-between py-16 sm:py-24 px-24">
            <div className="flex items-center space-x-8 mt-16 sm:mt-0">
              <DefaultButton text="Cancel" onClick={onClose} />
              <DefaultButton text="Save" onClick={onSubmit}
	              disabled={!hasChanged || hasInput}
	            />
            </div>
          </DialogActions>
        
    </NativeDialog>
  );
}

export default UserTagsDialog;
