import authRoles from '../../../auth/authRoles';
import Providers from './Providers';

const ProvidersConfig = {
  settings: {
    layout: {
      style: 'layoutMarketing',

      config: {
        header: {
          display: true
        },

        footer: {
          display: true
        }
      },
    },
  },

  auth: authRoles.onlyGuest,

  routes: [
    {
      path: '/providers',
      element: <Providers />,
    },
  ],
};

export default ProvidersConfig;
