import FuseDialog from '@fuse/core/FuseDialog';
import { styled } from '@mui/material/styles';
import FuseSuspense from '@fuse/core/FuseSuspense';
import AppContext from 'app/AppContext';
import { memo, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRoutes, useLocation } from 'react-router-dom';
import { selectFuseCurrentLayoutConfig } from 'app/store/fuse/settingsSlice';
import HeaderLayoutMarketing from './components/HeaderLayoutMarketing';
import FooterLayoutMarketing from './components/FooterLayoutMarketing';
import ErrorBoundary from 'app/shared-components/error/ErrorBoundary';
import useVersionCheck from 'app/shared-components/util/useVersionCheck';
import { selectCurrentLanguageDirection } from 'app/store/i18nSlice';
import { wrapUseRoutes } from "@sentry/react";
import { fetchMenus } from 'app/store/marketing/menusSlice';

import './marketing.scss'

const Root = styled('div')(({ config }) => ({
  ...(config.mode === 'boxed' && {
    clipPath: 'inset(0)',
    maxWidth: `${config.containerWidth}px`,
    margin: '0 auto',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  }),
  ...(config.mode === 'container' && {
    '& .container': {
      maxWidth: `${config.containerWidth}px`,
      width: '100%',
      margin: '0 auto',
    },
  }),
}));

const Main = styled('main')(({ config }) => ({
  '& .container': {
    maxWidth: `${config.containerWidth}px`,
    width: '100%',
    margin: '0 auto',
    paddingLeft: '1.6rem',
    paddingRight: '1.6rem',
    '@media(min-width: 1024px)': {
      paddingLeft: '16rem',
      paddingRight: '16rem'
    }
  },
  '@media (min-width: 1024px)': {
    '& .lg\\:container': {
      maxWidth: `${config.containerWidth}px`,
      width: '100%',
      margin: '0 auto',
      paddingLeft: '16rem',
      paddingRight: '16rem'
    }
  }
}))

const useSentryRoutes = wrapUseRoutes(useRoutes);

function LayoutMarketing(props) {
  const config = useSelector(selectFuseCurrentLayoutConfig);
  const appContext = useContext(AppContext);
  const { routes } = appContext;
  const location = useLocation();
  useVersionCheck();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const langDirection = useSelector(selectCurrentLanguageDirection);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMenus());
  }, []);

  return (
    // <FuseTheme theme={pageTheme} direction={langDirection}>
    // </FuseTheme>
    (<Root id="fuse-layout" config={config} className="w-full layout-marketing">
      <div className="lg:pt-12">
        {config.header.display && (
          <ErrorBoundary><HeaderLayoutMarketing /></ErrorBoundary>
        )}

        <Main id="fuse-main" config={config} className="flex flex-col flex-auto min-h-full min-w-0 relative z-10">
          <ErrorBoundary>
            <div className="flex flex-col flex-auto min-h-0 relative z-10">
            
              <FuseDialog />

              <FuseSuspense>{useSentryRoutes(routes)}</FuseSuspense>
              {props.children}
            </div>
          </ErrorBoundary>
        </Main>

        {config.footer.display && (
          <ErrorBoundary><FooterLayoutMarketing /></ErrorBoundary>
        )}
      </div>
    </Root>)
  );
}

export default memo(LayoutMarketing);
