import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectInviteContact, inviteConnection, setInviteContact } from 'app/store/contactsSlice';
import { startLoading} from 'app/store/utilSlice';
import { dispatchResultMessage } from '../utils';
import { useForm } from 'react-hook-form';
import ControlledTextField from '../form/ControlledTextField';
import DefaultButton from '../layout/DefaultButton';

const schema = yup.object().shape({
  message: yup.string().required('You must enter a message'),
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function InviteConnectionDialog(props) {
  const dispatch = useDispatch();
  const contact = useSelector(selectInviteContact);
  const { handleSubmit, formState, control } = useForm({
    mode: 'onChange',
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  if (!contact) {
    return null;
  }
  

  function handleCloseDialog() {
    
  }

  function handleDiscard() {
    dispatch(setInviteContact(null))
  }

  function onSubmit(data) {
	dispatch(startLoading());
    dispatch(inviteConnection({contactId: contact.id, message: data.message})).then((resp) =>{
		dispatchResultMessage(dispatch, resp, "Sent Invite!");
	});
    dispatch(setInviteContact(null));
  }


  return (
    <Dialog
      classes={{
        paper: 'w-full m-24',
      }}
      TransitionComponent={Transition}
      onClose={handleCloseDialog}
      open={Boolean(contact)}
    >
      <AppBar position="static" color="secondary" elevation={0}>
	      <Toolbar className="flex w-full">
	        <Typography variant="subtitle1" color="inherit">
	          Invite User To Connect
	        </Typography>
	      </Toolbar>
	  </AppBar>
	  
      <form noValidate onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
          <DialogContent classes={{ root: 'p-16 pb-0 sm:p-32 sm:pb-0' }}>
            <Card className="mb-32">
              <CardHeader
                className="px-32 pt-24"
                avatar={<Avatar aria-label="Recipe" src={contact.profileImageUrl} />}
                title={
                  <span className="flex items-center space-x-8">
                    <Typography className="font-normal" color="secondary.main" paragraph={false}>
                      {contact.firstName + " " + contact.lastName}
                    </Typography>
                  </span>
                }
                
              />

              <CardContent className="px-32">
	            <ControlledTextField name="message" label="Message"
                             control={control} errors={errors}
             				 required fullWidth multiline
             				 minRows={4} maxRows={8} />
	          </CardContent>
	        </Card>
          </DialogContent>

          <DialogActions className="flex flex-col sm:flex-row sm:items-center justify-between py-16 sm:py-24 px-24">
            <div className="flex items-center space-x-8 mt-16 sm:mt-0">
              <DefaultButton text="Cancel" onClick={handleDiscard} />
              <DefaultButton text="Invite Connection"
	              disabled={_.isEmpty(dirtyFields) || !isValid}
	              submit />
            </div>
          </DialogActions>
        </form>
        
    </Dialog>
  );
}

export default InviteConnectionDialog;
