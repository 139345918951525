import lazyLoad from 'app/shared-components/util/lazyLoad';
import { Navigate } from 'react-router-dom';
import authRoles from '../../../auth/authRoles';
import Page from './Page';

const PageConfig = {
  settings: {
    layout: {
      style: 'layoutMarketing',

      config: {
        header: {
          display: true
        },

        footer: {
          display: true
        }
      },
    },
  },

  auth: authRoles.onlyGuest,

  routes: [
    {
      path: 'marketing/:slug',
      element: <Page />,
    },
  ],
};

export default PageConfig;
