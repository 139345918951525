import { lighten } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DefaultButton from '../layout/DefaultButton';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import UserAvatar from 'app/shared-components/user/UserAvatar';
import UserName from 'app/shared-components/user/UserName';
import DMChat from 'app/shared-components/chat/DMChat';


function SingleChatPanel({title, channelId, targetUser, onClose, onClickMenu, link}) {
  const chatChannelId= channelId;
  console.log("Got selected chat channel id: " + chatChannelId)
  const showTitle = title || link || onClickMenu;

  if (!chatChannelId) {
    return null;
  }
  
  function handleCloseChat(){
	  if (onClose) onClose();
  }

  return (
    <div className="flex flex-col h-full w-full">
      {showTitle &&
      <Box
        className="flex w-full border-b-1"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light'
              ? lighten(theme.palette.background.default, 0.4)
              : lighten(theme.palette.background.default, 0.02),
        }}
      >
        <Toolbar className="flex items-center justify-between px-16 w-full">
          <div className="flex items-center flexGrow">
          
            {onClickMenu &&
            <IconButton
              aria-label="Open menu"
              onClick={onClickMenu}
              className="flex lg:hidden"
              size="large"
            >
              <FuseSvgIcon>heroicons-outline:menu</FuseSvgIcon>
            </IconButton> 
            }
            
           <UserAvatar className="relative mx-8" user={targetUser} />
           <div className='flex flex-col'>
	           <UserName color="inherit" className="text-16 font-semibold px-4" user={targetUser} />
	           {title && <Typography>{title}</Typography>}
	        </div>
           
          </div>
          
          <div className="flex flex-row">
	          {link &&
	          <DefaultButton link={link} text="Go To" />
	          }
	          
	          {onClose &&
	          <IconButton
	              aria-label="Close chat"
	              onClick={() => handleCloseChat(true)}
	              className="flex "
	              size="large"
	            >
	              <FuseSvgIcon>heroicons-outline:x</FuseSvgIcon>
	          </IconButton>
	          }
	       </div>
         
        </Toolbar>
      </Box>
      }
      
      <Paper
	      className='w-full flex flex-col flex-1 relative shadow'
	      sx={{ background: (theme) => theme.palette.background.default }}
	    >
      	<DMChat channelId={chatChannelId} />
      </Paper>
    </div>
  );
}

export default SingleChatPanel;
