import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { Controller } from 'react-hook-form';



function ControlledTextField({control, name, label, errors, className, autoFocus, width, type, infoText, readOnly, ...props}) {
   const useClasses = className ? className : "mt-8 mb-16";
   const useType= type? type : "text";
   
   const sxProps= width ? { width: width } : undefined;
   
   return (
     <Controller
        name={name}
        control={control}
        render={({ field }) => ( 
        	 <TextField
              {...field}
              sx={sxProps}
              id={name}
              className={useClasses}
              label={label}
              autoFocus={autoFocus}
              type={useType}
              error={!!errors[name]}
              helperText={errors[name]?.message ? errors[name].message : infoText}
              variant="outlined"
              inputProps={{ readOnly: Boolean(readOnly) }}
              {...props}
            />
        )}
      />
   );
 
}

export default ControlledTextField;
