import { fetchBlogPostBySlug, selectBlogPostBySlug } from 'app/store/marketing/blogPostsSlice'
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import sanitizeHtml from 'sanitize-html';
import Breadcrumbs from 'app/shared-components/marketing/Breadcrumbs';
import BlogHeaderTags from 'app/shared-components/helmet/BlogHeaderTags';


export default function BlogPost() {
  const routeParams = useParams();
  const dispatch = useDispatch();

  const post = useSelector(selectBlogPostBySlug(routeParams.slug));

  useEffect(() => {
    dispatch(fetchBlogPostBySlug(routeParams.slug));
  }, [routeParams.slug]);
  
  if (!post) return null;

  return (
    <>
    <BlogHeaderTags title={post.title} description={post.excerpt} author={post.author} datePublished={post.createdAt} image={post.image?.url}/>
    <div>
      {/* Breadcrumbs */}
      <section>
        <div className="container py-28">
          <Breadcrumbs
            trail={[
              { label: 'Home', to: '/' },
              { label: 'Blog', to: '/blog' },
              ...(post ? [{ label: post.title, to: `/blog/${post.slug}` }] : [])
            ]}
          />
        </div>
      </section>

      {/* Post content */}
      {post && <section>
        <div className="container pb-20 lg:pb-80">
          <div>
            <div className="aspect-w-16 aspect-h-9 rounded-20 overflow-hidden">
              <img
                src={post.image?.url}
                alt={post.image?.alternativeText}
                className="object-cover"
              />
            </div>

            <div className="body-sm mt-40">
              {post.author} | {new Date(post.createdAt).toLocaleString('en-US', {
                month: 'long',
                day: 'numeric',
                year: 'numeric'
              })}
            </div>

            <h1 className="h3 lg:h1 mt-12">
              {post.title}
            </h1>
          </div>

          <div 
            className="mt-20 prose-lg ck-content max-w-full text-hs-black"
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(post.body) }}
          />
        </div>
      </section>}
    </div>
    </>
  )
}
