import lazyLoad from 'app/shared-components/util/lazyLoad';

const ProfileView = lazyLoad(() => import('./ProfileView'));


const ProfileViewConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'profile/:id',
      element: <ProfileView />,
    },
  ],
};

export default ProfileViewConfig;


