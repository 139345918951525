import FusePageSimple from '@fuse/core/FusePageSimple';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { selectIsLoading, startLoading, stopLoading} from 'app/store/utilSlice';
import { useSelector, useDispatch } from 'react-redux';
import CropImageDialog from 'app/shared-components/CropImageDialog';
import React, { useRef, useEffect} from "react";
import FileSelect from 'app/shared-components/controls/FileSelect';
import { showMessage } from 'app/store/fuse/messageSlice';
import { confirmationDialog, dispatchResultMessage } from 'app/shared-components/utils';
import { errorMessage } from 'app/shared-components/util/message';
import fileTypes from 'app/shared-components/file/fileTypes.json';
const acceptString= fileTypes.map(x => x.type).join(',');
const MB= 1048576;
const maxFileSize= 10 * MB;
const maxSizeText= "10 MB";



function AttachmentFileSelect({fileInputRef, onSelectFile}) {
  const dispatch = useDispatch();

  const selectFile= (file) => {
    const validation= validateFile(file);
    if (validation.isValid){
      onSelectFile(file);
    }else{
      errorMessage(dispatch, validation.message);
    }
  };
  
  
  return (
    
      <FileSelect fileInputRef={fileInputRef} accept={acceptString} onSelectFile={selectFile} />

  );
}

function validateFile(file) 
{
	const out= {};
	const fileType= file?.type;
	const found = fileTypes.find((element) => element.type == fileType);
  out.size= file?.size ? file.size : 0;
  out.type= fileType ? fileType : "NONE";
  out.maxSize= maxFileSize;
  out.maxSizeText= maxSizeText;
  out.validType= Boolean(found);
  out.validSize= out.size < out.maxSize;
  out.isValid= out.validType && out.validSize;
  out.message= !out.validType ? "File type is not allowed: " + fileType :
               !out.validSize ? "Maximum size for type " + fileType + " is " + out.maxSizeText :
                 "";
  return out;
}

export default AttachmentFileSelect;
