import HeaderTags from 'app/shared-components/helmet/HeaderTags';



const OrganizationHeaderTags = ({ description, title, email, keywords }) => {
  const orgName= "Halespring";
  const legalName= "Halespring Public Benefit Corporation";
  const host = window.location.protocol + '//' + window.location.host;
  const orgLogo=  host + '/assets/images/logo/HALESPRING-LOGO-ONLY-1500.png';
  const orgImage=  host + '/assets/images/logo/HALESPRING-HEADER-191.jpg';
  const useType = 'website';
  const useTitle= title ? title : orgName;
  console.log(useTitle);

  const schema=
    {
      "@context": "https://schema.org",
      "@type": "OnlineBusiness",
      "legalName" : legalName,
      "image": orgImage,
      "url": host,
      "logo": orgLogo,
      "name": orgName,
      "description": description,
      "email": email,
    };

  
  return (
    <HeaderTags title={useTitle} description={description} keywords={keywords} type={useType} image={orgImage} schema={schema} 
                canonicalURL={host}/>
  );
};

export default OrganizationHeaderTags;