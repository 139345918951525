import React, { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import clsx from 'clsx';
import { styled } from '@mui/material/styles';
import { useQuill } from './useQuill';
import './QuillSanitizePaste';
import './TargetLink';

// or const { useQuill } = require('react-quilljs');
import 'quill/dist/quill.snow.css'; // Add css for snow theme
// or import 'quill/dist/quill.bubble.css'; // Add css for bubble theme

const Root = styled('div')({

    '& .ql-editor': {
        maxHeight: '500px',
    },
  });


const QuillEditor = forwardRef(
  ({id, readOnly, placeholderText, initialContent, onChange, onSelectionChange, className }, ref) => {

  //Config options
  const containerId = 'quill-' + id;
  const bounds= "#" + containerId;
  const theme = 'snow';
  const placeholder = placeholderText ? placeholderText : 'Enter text here...';
  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ align: [] }],
      [{ list: 'ordered'}, { list: 'bullet' }],
      [{ indent: '-1'}, { indent: '+1' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }],
      ['link'],
    ],
    clipboard: {
      matchVisual: false,
    },
  };
  const formats = [
    'bold', 'italic', 'underline', 'strike',
    'align', 'list', 'indent',
    'header',
    'link', 
    'color', 
  ];
  
  //Use hook to initialize quill object
  const { quill, quillRef } = useQuill({ theme, modules, formats, placeholder, bounds });

  //Set intial value
  useEffect(() => {
    if (quill && initialContent) {
      quill.clipboard.dangerouslyPasteHTML(initialContent);
    }
  }, [quill, initialContent]);
  
  //Update external state on change
  useEffect(() => {
    if (quill) {
      quill.on('text-change', (delta, oldDelta, source) => {
        console.log(quill.getText()); // Get text only
        console.log(quill.getContents()); // Get delta contents
        console.log(quill.root.innerHTML); // Get innerHTML using quill
        console.log(quill.getSemanticHTML());
        onChange(quill.getSemanticHTML());
      });
    }
  }, [quill, onChange]);
  
  


  //editor with container
  return (
    <Root id={containerId} className={clsx('rounded-4 border-1 overflow-hidden w-full', className)} ref={ref}>
      <div ref={quillRef} />
    </Root>
  );
  
});

export default QuillEditor;