import lazyLoad from 'app/shared-components/util/lazyLoad';
const ChannelProfile = lazyLoad(() => import('app/shared-components/channel/ChannelProfile'));

const PublicChannelProfileConfig = {
  settings: {
    layout: {
      config: {
        navbar: {
          display: false,
        },
        toolbar: {
          display: true,
        },
        footer: {
          display: false,
        },
        leftSidePanel: {
          display: false,
        },
        rightSidePanel: {
          display: false,
        },
      },
    },
  },
  auth: null, //anyone
  routes: [
    {
      path: '/public/group/:channelId',
      element: <ChannelProfile />,
    },
    {
      path: '/public/organization/:channelId',
      element: <ChannelProfile />,
    },
  ],
};

export default PublicChannelProfileConfig;


