import lazyLoad from 'app/shared-components/util/lazyLoad';

const GroupsApp = lazyLoad(() => import('./GroupsApp'));
const ManageGroup = lazyLoad(() => import('./group/ManageGroup'));
const GroupForm = lazyLoad(() => import('./group/GroupForm'));
const GroupChannel = lazyLoad(() => import('./group/GroupChannel'));
const GroupInfoPage = lazyLoad(() => import('./group/GroupInfoPage'));
const ChannelAdmin = lazyLoad(() => import('app/shared-components/channel/channelApp/ChannelAdmin'));
const ChannelMembers = lazyLoad(() => import('app/shared-components/channel/channelApp/ChannelMembers'));
const TopicList = lazyLoad(() => import('app/shared-components/channel/TopicList'));

const GroupsAppConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'apps/groups',
      element: <GroupsApp />,
      children: [
        {
          path: ':id',
          element: <GroupForm />,
        },
        {
          path: 'invites',
          element: <div/>,
        }
      ],
    },
    {
      path: 'apps/group/:groupId',
      element: <ManageGroup />,
      children: [
        {
          path: '',
          element: <GroupChannel />,
        },
        {
          path: 'searchresults',
          element: <GroupChannel />,
        },
        {
          path: 'channel/:channelId',
          element: <GroupChannel />,
        },
        {
          path: 'channel/:channelId/searchresults',
          element: <GroupChannel />,
        },
        {
          path: 'topics',
          element: <TopicList type="group" />,
        },
        {
          path: 'topic/:topicId',
          element: <TopicList type="group" />,
        },
        {
          path: 'admin',
          element: <ChannelAdmin type="group" infoPage={GroupInfoPage} />,
        },
        {
          path: 'members',
          element: <ChannelMembers type="group" />,
        },
        {
          path: 'member/:memberId',
          element: <ChannelMembers type="group" />,
        },
        {
          path: 'result/:userId',
          element: <ChannelMembers type="group" />,
        },
      ],
    },
  ],
};

export default GroupsAppConfig;
