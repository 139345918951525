import * as Sentry from '@sentry/browser';
import APIError from 'app/shared-components/error/APIError';
import { logoutUser } from 'app/store/userSlice';

const crashReporter = store => next => action => {
  // we got a thunk, prep it to be handled by redux-thunk middleware
  if (typeof action === 'function') {
    // wrap it in a function to try/catch the downstream invocation
    const wrapAction = fn => (dispatch, getState, extraArgument) => {
      try {
        fn(dispatch, getState, extraArgument);
      } catch (e) {
        handleError(dispatch, e);
      }
    }
    // send wrapped function to the next middleware
    // this should be upstream from redux-thunk middleware
    return next(wrapAction(action));
  }
  
  try {
    return next(action);
  } catch (e) {
      handleError(store.dispatch, e);
  }
};

const handleError = (dispatch, error) => {
  console.log("Caught unhandled thunk error:");
  console.log(error);
  if (error instanceof APIError) {
	    const code= error.code;
      if (code == 401 || code == 403 ||code == 402 || code == 400){
    	  console.log("Got 4xx error, supressing error report.");
    	  if (code == 401){
    	     //This means the user is unauthenticed somehow, process logout
    	     console.log("User is not authenticated, logging out.");
    	     dispatch(logoutUser());
    	  }
    	  return;
      }
  }
  Sentry.captureException(error);
};

export default crashReporter;