import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchPeople, selectPeople } from 'app/store/marketing/peopleSlice';
import Person from 'app/shared-components/marketing/Person';
import FeatureGrid from 'app/shared-components/marketing/FeatureGrid';
import HeaderTags from 'app/shared-components/helmet/HeaderTags';


const ABOUTS = [
  {
    title: 'Who we Are',
    body: 'Halespring is dedicated to transforming the mental health landscape by providing a secure, HIPAA compliant platform with essential features for mental health professionals, patients, and their loved ones.',
    icon: 'help'
  },
  {
    title: 'Our Mission',
    body: 'At Halespring, our mission is to empower providers and patients alike, facilitating better connections, more collaboration, and improved care. Our goal is to drive better patient outcomes through our powerful suite of tools.',
    icon: 'favorite_border'
  }
];

export default function About() {
  const dispatch = useDispatch();
  const people = useSelector(selectPeople());
  const host = window.location.protocol + '//' + window.location.host;
  const orgImage=  host + '/assets/images/logo/HALESPRING-HEADER-191.jpg';
  
  useEffect(() => {
    dispatch(fetchPeople({
      sort: ['createdAt:asc']
    }));
  }, []);

  return (
    <>
      <HeaderTags title="About Halespring: our mission and team" 
                  description="Learn more about our mission, who we are, and what we do." 
                  keywords="Halespring,Team,Mental Health,Therapist,Therapy,Community,Referrals,Pyschologist,Psychiatrist"
                  image={orgImage}
      />
    <div>
      {/* HEADER */}
      <section>
        <div className="container pt-40 lg:pt-48 pb-44 lg:pb-52">
          <h1 className="h2 lg:h1 uppercase lg:normal-case text-center">
            About Halespring
          </h1>
          <p className="body-md lg:body-lg text-center mt-12 lg:mt-20">
            Empowering Mental Health Care
          </p>
        </div>
      </section>

      {/* ABOUT */}
      <section className="bg-white">
        <div className="container bg-white pt-40 pb-40">
          <FeatureGrid features={ABOUTS} />
        </div>
      </section>

      {/* TEAM */}
      <section className="pt-20 lg:pt-40 pb-20 lg:pb-32">
        <div className="container">
          <h2 className="h3 lg:h2">
            Meet Our Team
          </h2>

          <div className="mt-20 lg:mt-28 grid grid-cols-1 lg:grid-cols-3 gap-20">
            {people && people.filter((person) => person.type === 'team_member').map((person) => (
              <Person
                key={person.id}
                person={person}
                className="bg-hs-blue-pale"
              />
            ))}
          </div>
        </div>
      </section>

      {/* ADVISORS */}
      <section className="bg-white pt-20 lg:pt-32 pb-60 lg:pb-80">
        <div className="container">
          <h2 className="h3 lg:h2">
            Our Advisors
          </h2>

          <div className="mt-20 lg:mt-28 grid grid-cols-1 lg:grid-cols-3 gap-20">
            {people && people.filter((person) => person.type === 'advisor').map((person) => (
              <Person
                key={person.id}
                person={person}
                className="bg-hs-grey-light"
              />
            ))}
          </div>
        </div>
      </section>
    </div>
    </>
  )
}
