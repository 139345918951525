import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import {  startLoading, stopLoading} from 'app/store/utilSlice';



export const submitWarning = createAsyncThunk(
  "warning/submitWarning",
  async (params, { dispatch, getState }) => {
	  try{
		    dispatch(startLoading());
	      const { postId, userId, type, message } = params;
	      const apiName = 'CoreAPI';
		    const path = '/warning/';
	      const options = {
  			  body: {foreignModelKey: "ContentPost:" + postId, userId: userId, type: type, message: message},
  			  headers: {},
  			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  		  };
	
		 let data= await apiPut(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)

export const deleteWarning = createAsyncThunk(
  "warning/deleteWarning",
  async (params, { dispatch, getState }) => {
	  try{
		  dispatch(startLoading());
	      const { id } = params;
	      const apiName = 'CoreAPI';
	      const path = '/warning/' + id;
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 let data= await apiDel(apiName, path, options);
		 return data;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)



const warningSlice = createSlice({
  name: 'warning',
  initialState: {
	  warnPost: {}, 
  },
  reducers: {
    setWarnPost: {
      reducer: (state, action) => {
        state.warnPost = action.payload;
      }
    },
  },
});



export const selectWarnPost = ({ warning }) => warning.warnPost;
export const { setWarnPost } = warningSlice.actions;
export default warningSlice.reducer;
