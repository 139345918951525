import * as React from 'react';
import { DialogTitle, DialogActions, DialogContent, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import {openDialog, closeDialog } from 'app/store/fuse/dialogSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { checkScreenName } from 'app/store/contactFunctions';
import { setSubscriptionExceededMessage } from 'app/store/subscriptionPlanSlice';
import _ from '@lodash';


export function convertToPlain(html){

	if (!html) return "";
	
    // Create a new div element
    var tempDivElement = document.createElement("div");

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element 
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

export function truncateText(text, maxLength){
	if (!text) return "";
    if (text.length > maxLength){
		return text.substring(0, maxLength - 3) + "...";
	}else return text;
}

export function parsePost(post){
	  if (!post) return [];
	  const text= post.text;
	  if (!text) return [];
	  let start=0;
	  let out= [];
	  let i= text.indexOf('@');
	  while (i >= 0){
		  let mention= checkMention(text.substring(i), post);
		  if (mention){
			  if (i > 0) out.push({type: 'text', value: text.substring(start, i)});
			  out.push(mention);
			  start= i + mention.name.length + 1;
		  }
		  i= text.indexOf('@', i + 1);
	  }
	  
	  //Add final segment
	  if (start < text.length) out.push({type: 'text', value: text.substring(start)});
	  return out;
  }
  
  function checkMention(text, post){
	  const names= Object.keys(post.userMentions);
	  let i=0;
	  while (i < names.length) {
		  const comparison= '@' + names[i];
		  if (text.startsWith(comparison)){
			  return { type: 'mention', name: names[i], user: post.userMentions[names[i]]};
		  }
		  i++;
	  }
	  return null;
  }

export function confirmationDialog(dispatch, title, message, callback, cancelButton, confirmButton){
	dispatch(openDialog({
        children: (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={()=> {dispatch(closeDialog()); callback(false)}} color="primary">
                        {cancelButton ? cancelButton : "Cancel"}
                    </Button>
                    <Button onClick={()=> {dispatch(closeDialog()); callback(true)}} color="primary" autoFocus>
                        {confirmButton ? confirmButton : "Confirm"} 
                    </Button>
                </DialogActions>
            </React.Fragment>
             )
    }));
}


export function dispatchResultMessage(dispatch, resp, successMessage, messageMapperFn){
  	if (resp.error)
  	{
	  let message= resp.payload?.message ? resp.payload.message :
	                 resp.payload?.error ? resp.payload.error :
	                 resp.error.message;
	  if (messageMapperFn) message= messageMapperFn(message);
	  
	  if (message && message.includes("current Subscription plan")){
	      dispatch(setSubscriptionExceededMessage(message));
	  }
	  else dispatch(showMessage({
            message     : message,
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical  : 'top',//top bottom
                horizontal: 'center'//left center right
            },
            variant: 'error'//success error info warning null
        }));
    }
    else if (successMessage) {
	  dispatch(
        showMessage({
            message     : successMessage,
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical  : 'top',//top bottom
                horizontal: 'center'//left center right
            },
            variant: 'success'//success error info warning null
        }));
	}
}

export function dispatchAxiosErrorMessage(dispatch, err, messageMapperFn){
  	if (err)
  	{
	  let message= err.response?.data?.error ? err.response.data.error  :
	               err.response?.data?.message ? err.response.data.message :
	               err.message;
	  if (messageMapperFn) message= messageMapperFn(message);
	  
	  if (message && message.includes("current Subscription plan")){
	      dispatch(setSubscriptionExceededMessage(message));
	  }
	  else dispatch(showMessage({
            message     : message,
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical  : 'top',//top bottom
                horizontal: 'center'//left center right
            },
            variant: 'error'//success error info warning null
        }));
    }
}

export function dispatchResultAction(dispatch, resp, successDispatch){
  	if (resp.error)
  	{
	  const message= resp.payload?.message ? resp.payload.message :
	                 resp.payload?.error ? resp.payload.error :
	                 resp.error.message;
	  dispatch(
        showMessage({
            message     : message,
            autoHideDuration: 5000,
            anchorOrigin: {
                vertical  : 'top',//top bottom
                horizontal: 'center'//left center right
            },
            variant: 'error'//success error info warning null
        }))
    }else{
	  dispatch(successDispatch);
	}
}

export function bytesToKB(bytes){
	if (!bytes) return "";
    const k= bytes/1024;
    return k + "K";
}

export function replaceAt(
  str,
  replacement,
  index,
  length = 0) 
{
  const prefix = str.substr(0, index);
  const suffix = str.substr(index + length);

  return prefix + replacement + suffix;
}

export function getActiveToken(input, cursorPosition) {
  const tokenizedQuery = input.split(/[\s\n]/).reduce((acc, word, index) => {
  //const tokenizedQuery = input.split(/\W+/).reduce((acc, word, index) => {
    const previous = acc[index - 1];
    const start = index === 0 ? index : previous.range[1] + 1;
    const end = start + word.length;
    return acc.concat([{ word, range: [start, end] }]);
  }, []);

  if (cursorPosition === undefined) {
    return undefined;
  }

  const activeToken = tokenizedQuery.find(
    ({ range }) => range[0] < cursorPosition && range[1] >= cursorPosition
  );

  return activeToken;
}


export const validateScreenName = async (screenName, yupObj) => {
	 console.log("Inside validateScreenName for " + screenName);
	 const { path, createError } = yupObj;
	 const data= await checkScreenName(screenName);
	 if (!data.valid) {
		 return createError({ path: path, message: 'Screen name can only have numbers, letters, and period.' });
	 }
	 if (!data.available) {
		 return createError({ path: path, message: 'Screen name already taken: ' + screenName });
	 }
	 return true;
  }; 
  
export function formatDate(dateString){
	if (!dateString) return "";
    const dateObj= new Date(dateString);
	return pad(dateObj.getMonth() + 1) + '/' + pad(dateObj.getDate()) + '/' + dateObj.getFullYear();
    
}

export function formatMillisToDateString(millis){
	if (!millis) return "";
    const dateObj= new Date(millis);
	return pad(dateObj.getMonth() + 1) + '/' + pad(dateObj.getDate()) + '/' + dateObj.getFullYear();
}

export function formatMillisToUTCString(millis){
	if (!millis) return "";
    const dateObj= new Date(millis);
	return pad(dateObj.getUTCMonth() + 1) + '/' + pad(dateObj.getUTCDate()) + '/' + dateObj.getUTCFullYear();
}



export function dateStringToMillis(dateString){
	if (!dateString) return undefined;
    const dateObj= new Date(dateString);
	return dateObj.getTime();
    
}

export function isUnread(data, fn){
    if (data){
	  if (Array.isArray(data)){
		  for (var i = 0; i < data.length; i++){
			  const val= data[i];
			  const unread= fn ? fn(val) : val.unread;
			  if (unread) return true;
		  } 
	  }else{
		  const keys= Object.keys(data);
		  for (var i = 0; i < keys.length; i++){
			  const key= keys[i];
			  const val= data[key];
			  const unread= fn ? fn(val) : val.unread;
			  if (unread) return true;
		  } 
	  } 
    }
    return false;
}

function pad(n){return n<10 ? '0'+n : ''+n;}

export const debounceValidateScreenName = _.debounce(_.memoize(validateScreenName), 1500);

export const phoneRegex = /^(\+?1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/

export function getPhoneForCognito(phone){
    const stripPhone= (val) => val.replace(/[^0-9\+]/g, '');
    if (!phone) return '';
    let phoneNumber= stripPhone(phone);
	if (phoneNumber.startsWith('1')) phoneNumber= '+' + phoneNumber;
	if (!phoneNumber.startsWith('+')) phoneNumber= '+1' + phoneNumber;
	return phoneNumber;
}

export function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}



