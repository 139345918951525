import { createEntityAdapter, createSelector, createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from './axios';

/**
 * @template T
 * @typedef {import('./strapi').CollectionApiResponseWrapper<T>} CollectionApiResponseWrapper
 */
/** @typedef {import('./marketing').Tag} Tag */

/** @type {import('@reduxjs/toolkit').EntityAdapter<Tag, string>} */
const tagsAdaptor = createEntityAdapter({
  selectId: (tag) => tag.slug
});

const initialState = tagsAdaptor.getInitialState();

/** @type {import('@reduxjs/toolkit').AsyncThunk<Tag, string, {}>}  */
export const fetchTags = createAsyncThunk(
  'marketing/tags/fetchTags',
  async () => {
    /** @type {import('axios').AxiosResponse<CollectionApiResponseWrapper<Tag>>} */
    const response = await axios({
      method: 'GET',
      url: 'tags',
      params: {
        pagination: {
          limit: 99
        }
      }
    });

    return response.data.data;
  }
);

const tagsSlice = createSlice({
  name: 'marketing/tags',

  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(fetchTags.fulfilled, (state, action) => {
        if (action.payload) {
          tagsAdaptor.upsertMany(state, action.payload);
        }
      });
  }
});

/** @typedef {import('./index').RootState} RootState */

const selectTagsState = (/** @type {RootState} */ state) => state.marketing.tags;

export const selectTags = () => createSelector(
  [selectTagsState],
  (tags) => tags.ids.map((id) => tags.entities[id])
);

export default tagsSlice.reducer;
