import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

const SHAPES = {
  onePoint: {
    d: 'M110 220.617C170.751 220.617 220 171.369 220 110.617C220 49.8659 170.751 0.617185 110 0.617183L9.61651e-06 0.617178L4.80825e-06 110.617C2.15273e-06 171.369 49.2487 220.617 110 220.617Z'
  },
  twoPoints: {
    d: 'M0 220.617L100 220.617C166.274 220.617 220 166.891 220 100.617L220 0.617153L120 0.617162C53.7258 0.617168 -1.45362e-05 54.343 -8.74228e-06 120.617L0 220.617Z'
  }
}

const TEXT_DY_EM = 1.5;

/**
 * A text bubble component with scalable text.
 * 
 * @param {{
 *   label?: string | string[];
 *   shape?: 'onePoint' | 'twoPoint';
 *   className?: string;
 *   textClass?: string;
 *   shapeClass?: string;
 * }} props 
 */
export default function TextBubble({ label, shape, className, textClass, shapeClass, ...props }) {
  const shapeData = SHAPES[shape] || SHAPES['onePoint'];

  const labelArr = Array.isArray(label) ? label : [label]

  return (
    <svg
      width="220"
      height="221"
      viewBox="0 0 220 221"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={twMerge(classNames([
        'w-full h-auto', className
      ]))}
      {...props}
    >
      <path 
        d={shapeData.d}
        className={twMerge(classNames([
          'fill-hs-blue-light', shapeClass
        ]))}
        transform-origin="center"
      />
      <text
        className={twMerge(classNames([
          'fill-hs-white whitespace-normal',
          textClass
        ]))}
        style={{ fontSize: `${(1 / labelArr.length) * 5 + 0.6 }rem` }}
        x="50%"
        y="50%"
        width="200"
      >
        {labelArr.map((label, i) =>
          <tspan
            text-anchor="middle"
            x="50%"
            dy={i > 0 ? `${TEXT_DY_EM}em` : `-${(1 + ((labelArr.length - 2) * TEXT_DY_EM)) / 2}em`}
          >
            {label}
          </tspan>
        )}
      </text>
    </svg>
  )
}
