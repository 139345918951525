import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { stopLoading, startLoading} from 'app/store/utilSlice';
import { setChannelRead, setChannelUnread } from './channelsSlice';
import { submitPost } from './postSlice';
import sortComparer from 'app/shared-components/util/lastMessageSortComparer';
import { isUnread } from 'app/shared-components/utils';
import { addDmChannel, addDmChannels, removeDmChannel } from './dmChannelSlice';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';

const authorizedClientsAdapter = createEntityAdapter({
	sortComparer: sortComparer
});

export const fetchAuthorizedClients = createAsyncThunk(
  "clientsAuthorized/fetchAuthorizedClients",
  async ({bulk}, { dispatch, getState }) => {
	dispatch(startLoading());
	try{
	  let data= bulk;
	  if (!data){
		  const { user }= getState();
	      const apiName = 'CoreAPI';
		  const path = '/user/' + user.id + '/clientauthorizeduser/pairs';
	      const options = {
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
		  data= await apiGet(apiName, path, options);
	  }
	  const out= data.map((pair) => ({...pair.client, ...pair.clientAuthorizedUser }));
	  dispatch(addDmChannels(out.map((dm) => ({type: 'authorizedClient', foreignId: dm.id, channelId: dm.channelId, data: dm}))));
	  return out;
	}finally{
	  dispatch(stopLoading());
	}
  }
);


export const updateStatus = createAsyncThunk(
  "clientsAuthorized/updateStatus",
  async ({clientId, status}, { dispatch, getState }) => {
	  dispatch(startLoading());
	  try{
		  const { user }= getState();
	      const apiName = 'CoreAPI';
	      const path = '/client/' + clientId + '/clientauthorizeduser/' + user.id;
	      const options = {
			  body: {status: status},
			  headers: {},
			  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
		  };
	
		 const data= await apiPut(apiName, path, options);
		 const out= {id: clientId, ...data}; //Need id value to upsert record
		 dispatch(addDmChannel({type: 'authorizedClient', foreignId: out.id, channelId: out.channelId, data: out}));
		 return out;
	 }finally{
		 dispatch(stopLoading());
	 }
  }
)


const findClientWithChannelId= (state, channelId) => {
	for (let i in state.ids) {
		const id= state.ids[i];
		const client= state.entities[id];
	    if (client && client.channelId == channelId) return client;
    }
	return null;
}

const { selectById, selectAll } = authorizedClientsAdapter.getSelectors(state => state.clientsAuthorized);


const clientsAuthorizedSlice = createSlice({
  name: "clientsAuthorized",
  initialState: authorizedClientsAdapter.getInitialState({status: 'idle', unread: false}),
  reducers: {
	  updateAuthorizedClient: (state, action) => {
	      authorizedClientsAdapter.upsertOne(state, action.payload);
	      state.unread= isUnread(state.entities);
	  },
  },
  extraReducers: builder => {
    builder
    .addCase(fetchAuthorizedClients.fulfilled, (state, action) => {
        authorizedClientsAdapter.setMany(state, action.payload);
        state.status= 'succeeded';
        state.unread= isUnread(state.entities);
    })
    .addCase(updateStatus.fulfilled, (state, action) => {
        authorizedClientsAdapter.upsertOne(state, action.payload);
    })
    .addCase(setChannelRead.fulfilled, (state, action) =>  {
        const channelId = action.payload;
        const client= findClientWithChannelId(state, channelId);
        if (client) {
        	client.unread= false;
        	state.unread= isUnread(state.entities);
        }
    })
    .addCase(setChannelUnread, (state, action) =>  {
        const channelId = action.payload;
        const client= findClientWithChannelId(state, channelId);
        if (client) {
        	client.unread= true;
        	state.unread= true;
        }
    })
    .addCase(submitPost.fulfilled, (state, action) => {
      const channelId = action.meta.arg.channelId;
      const client= findClientWithChannelId(state, channelId);
      if (client){
		  client.lastMessageTimestamp= action.payload.createdAt;
	  }
    })
  }
})


export const selectAuthClientById = (clientId) => state => {
	if (clientId) return selectById(state, clientId);
	return null;
}

export const selectAuthorizedClients = () => state => {
	return selectAll(state);
}

export const selectAuthorizedClientsUnread = ({ clientsAuthorized }) => clientsAuthorized.unread;
export const {updateAuthorizedClient} = clientsAuthorizedSlice.actions;
export default clientsAuthorizedSlice.reducer;
