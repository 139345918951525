import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import NativeDialog from 'app/shared-components/layout/NativeDialog';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import _ from '@lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { forwardRef, useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserById  } from 'app/store/contactsSlice';
import { selectAllOpenReferrals, selectReferralById} from 'app/store/referralsSlice';
import { selectIsLoading, startLoading, stopLoading} from 'app/store/utilSlice';
import { useForm } from 'react-hook-form';
import { useThemeMediaQuery } from '@fuse/hooks';
import { Link } from 'react-router-dom';
import ControlledSingleSelect from 'app/shared-components/form/ControlledSingleSelect';
import DefaultButton from '../layout/DefaultButton';
import ReferralShareItem from 'app/shared-components/referral/ReferralShareItem';
import { apiGet, apiPut, apiDel, apiPost } from 'app/shared-components/util/restAPI';
import DialogSafeIconMenu from '../layout/DialogSafeIconMenu';



function SelectReferralDialog({open, onSubmit, onClose, title, buttonText}) {
  const referrals = useSelector(selectAllOpenReferrals());
  const hasReferrals = referrals.length > 0;
  const [referralId, setReferralId]= useState();
  const referral = useSelector(selectReferralById(referralId));

  function onSubmitInternal(data){
     onSubmit(referral);
     onClose();
  }


  return (
    <NativeDialog
      onClose={onClose}
      open={open}
      width="400px"
    >
      <AppBar position="static" color="secondary" elevation={0}>
	      <Toolbar className="flex w-full">
	        <Typography variant="subtitle1" color="inherit">
	          {title}
	        </Typography>
	      </Toolbar>
	    </AppBar>
	  
          <DialogContent classes={{ root: 'p-16 pb-0' }}>
            <div className="mb-32">

            {hasReferrals  ?
              <div className="">
                 {referral ?   
                    <Box className="relative mt-10">
            				   <ReferralShareItem referral={referral} />
            		    </Box>
          		            :
          		    <>
          		      {referrals.map((ref) => 
          		         <div className="flex flex-row border-2 rounded-md align-center justify-center items-center p-6 my-12 w-full">
          		           <div className="flex grow">
            		           <Typography className="" color="secondary">
                	          {ref.summary}
                	         </Typography>
              	        </div>
              	        <DefaultButton text="Select" primary onClick={() => setReferralId(ref.id)}/>
          		         </div>
          		      )}
          		    </>
                  }  
	            </div>
	                        :
	             <div className="px-32">
				         <Typography className="text-xl" >
      	          You have no open referrals to share. You can create referrals
      	          <Link className="ml-4" to="/apps/referrals">here</Link>. 
      	        </Typography>
	            </div>
            }
            
	        </div>

          </DialogContent>

          <DialogActions className="flex flex-col sm:flex-row sm:items-center justify-between py-16 sm:py-24 px-24">
            <div className="flex items-center space-x-8 mt-16 sm:mt-0">
			        <DefaultButton text="Cancel" onClick={onClose} />
              <DefaultButton text={buttonText} onClick={onSubmitInternal}
	              disabled={ !hasReferrals || !referral}
	              />
	             {referral && <DefaultButton text="Change" primary onClick={() => setReferralId(null)}/> }
            </div>
          </DialogActions>
    </NativeDialog>
  );
}

export default SelectReferralDialog;
